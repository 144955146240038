import React from "react"
import { Link } from 'react-router-dom'
import messages from '../../../lib/messages.json'
import { formatNumber } from "../../../lib/functions"
import Loader from "../../../components/Loader"
import './index.css'

const golds = [
    {mois: '1', tx: 0 }, {mois: '2', tx: 0 },
    {mois: '3', tx: 0.25 }, {mois: '4', tx: 0.25 }, {mois: '5', tx: 0.25 },
    {mois: '6', tx: 0.30 }, {mois: '7', tx: 0.30 }, {mois: '8', tx: 0.30 },
    {mois: '9', tx: 0.35 }, {mois: '10', tx: 0.35 }, {mois: '11', tx: 0.35 },
    {mois: '12', tx: 0.40 }
]
const diamonds = [
    {mois: '1', tx: 0 }, {mois: '2', tx: 0 },
    {mois: '3', tx: 0.25 }, {mois: '4', tx: 0.25 }, {mois: '5', tx: 0.25 },
    {mois: '6', tx: 0.30 }, {mois: '7', tx: 0.30 }, {mois: '8', tx: 0.30 },
    {mois: '9', tx: 0.35 }, {mois: '10', tx: 0.35 }, {mois: '11', tx: 0.35 },
    {mois: '12', tx: 0.40 }
]

const platiniums = [
    {mois: '1', tx: 0 }, {mois: '2', tx: 0 },
    {mois: '3', tx: 0.25 }, {mois: '4', tx: 0.25 }, {mois: '5', tx: 0.25 },
    {mois: '6', tx: 0.30 }, {mois: '7', tx: 0.30 }, {mois: '8', tx: 0.30 },
    {mois: '9', tx: 0.35 }, {mois: '10', tx: 0.35 }, {mois: '11', tx: 0.35 },
    {mois: '12', tx: 0.40 }
]

const mois = [
    {value: '1', label: '1 Mois'}, {value: '2', label: '2 Mois'},  {value: '3', label: '3 Mois'}, {value: '4', label: '4 Mois'},
    {value: '5', label: '5 Mois'}, {value: '6', label: '6 Mois'}, {value: '7', label: '7 Mois'}, {value: '8', label: '8 Mois'},
    {value: '9', label: '9 Mois'}, {value: '10', label: '10 Mois'}, {value: '11', label: '11 Mois'}, {value: '12', label: '12 Mois'},
]

const initialState = {
    gold: '1', diamond: '1', platinium: '1',
    duree: '1', montant: 5000, type: 1, btnLoad: false,
    packPlatinium: false, packGold: true, packDiamond: false,
    montantGold: 5000, montantDiamond: 10000, montantPlatinium: 15000
}

const Pack = ({head, comment=false, itineraire=false, gestion=false, color}) =>{
    return(
        <>
            <div className="ayn-pack-head" style={{borderTop: `6px solid ${color}`}}>
                <h2>{head}</h2>
            </div>
            <div className="ayn-pack-content">
                <div className="ayn-pack-section">
                    {comment && <i className="fas fa-check"></i>}
                    {!comment && <i className="fas fa-times"></i>}
                    <span>Possibilité de répondre aux golds</span>
                </div>
            </div>
            <div className="ayn-pack-content">
                <div className="ayn-pack-section">
                    {itineraire && <i className="fas fa-check"></i>}
                    {!itineraire && <i className="fas fa-times"></i>}
                    <span>Proposer un itinéraire à vos clients</span>
                </div>
            </div>
            <div className="ayn-pack-content">
                <div className="ayn-pack-section">
                    {gestion && <i className="fas fa-check"></i>}
                    {!gestion && <i className="fas fa-times"></i>}
                    <span>Possibilité de personnalisation de son profil</span>
                </div>
            </div>
        </>
    )
}
export default class Promotion extends React.Component {
    constructor(props){
        super(props)
        this.state = { 
            ...initialState, calendrier: [],
            currentcouverture: '', startXcouverture: 0, startYcouverture: 0, sWidthcouverture: 0, sHeightcouverture: 0
        }
    }


    handleGoldChange(duree){
        const gold = golds.find(u=>u.mois === duree)
        const tx = gold?.tx
        const montant = (duree * 5000) - ((duree * 5000) * tx)
        this.setState({ montantGold: montant, gold: duree, montant: montant, duree })
    }
    handleDiamondChange(duree){
        const diamond = diamonds.find(u=>u.mois === duree)
        const tx = diamond?.tx
        const montant = (duree * 10000) - ((duree * 10000) * tx)
        this.setState({ montantDiamond: montant, diamond: duree, montant: montant, duree })
    }
    handlePlatiniumChange(duree){
        const platinium = platiniums.find(u=>u.mois === duree)
        const tx = platinium?.tx
        const montant = (duree * 15000) - ((duree * 15000) * tx)
        this.setState({ montantPlatinium: montant, platinium: duree, montant: montant, duree })
    }

    handleGoldCheck(val){
        const check = (val === 'true')
        this.setState({ packGold: !check, packDiamond: false, packPlatinium: false, type: 1  })
    }

    handleDiamondCheck(val){
        const check = (val === 'true')
        this.setState({ packGold: false, packDiamond: !check, packPlatinium: false, type: 2  })
    }
    handlePlatiniumCheck(val){
        const check = (val === 'true')
        this.setState({ packGold: false, packDiamond: false, packPlatinium: !check, type: 3  })
    }

    handleSubmit(){
        this.setState({ btnLoad: true })
        
        const { duree, type, montant } = this.state
        if(this.props.marchandId){
            const forfait = { duree, type, montant, marchandId: this.props.marchandId }

            this.props.controller.save(forfait)
            .then(res => {
                if (typeof (res.pror) === 'undefined') {
                    this.setState({ btnLoad: false, ...initialState })
                    this.props.toast(messages.save, { appearance: 'success' })
                } else {
                    if (res.pror.indexOf('401') > 0) {
                        this.props.toast(messages.sessionExpired, { appearance: 'info' })
                    } else {
                        this.props.toast(res.pror, { appearance: 'error' })
                        this.setState({ btnLoad: false })
                    }
                }
            })

        }else{
            this.props.toast(messages.requestFailed, { appearance: 'error' })
            this.setState({ btnLoad: false })
        }
        
    }

    render() {
        const { gold, diamond, platinium, btnLoad,
            packPlatinium, packGold, packDiamond,
            montantGold, montantDiamond, montantPlatinium } = this.state

        return (
            <div className="wt-haslayout wt-main-section" style={{padding: 0}}>
					<div className="container">
						<div className="row justify-content-md-center">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-10 push-lg-1">
								<div className="marchand-admin-card">

                                    <div className="marchand-admin-card-section-header">
                                        <h4>Description</h4>
                                    </div>
                                    <div className="marchand-admin-card-section-body">
                                        <p className="marchand-admin-card-paragraphe">Les forfaits sont des packs de services et de fonctionnalités vendus de manière groupée.</p>
                                    </div>

                                    <div className="marchand-admin-card-section-header">
                                        <h4>Packs</h4>
                                    </div>
                                    <div className="marchand-admin-card-section-body">
                                        <p className="marchand-admin-card-paragraphe">Il existe 3 packs sur la plateforme :</p>
                                    </div>
                                    <div className="marchand-admin-card-section-body">
                                        <div className="ayn-pack-content">
                                            <div className="ayn-pack">
                                                <Pack head="Pack Gold" comment={true} color="#4caf50" />
                                                <div className="ayn-pack-price-content" style={{alignItems: 'start'}}>
                                                    <p className="marchand-admin-card-paragraphe level-two">● 5.000 FCFA/mois</p>
                                                    <p className="marchand-admin-card-paragraphe level-two">● Remise de 25% à partir de 3 mois</p>
                                                    <p className="marchand-admin-card-paragraphe level-two">● Remise de 30% à partir de 6 mois</p>
                                                    <p className="marchand-admin-card-paragraphe level-two">● Remise de 35% à partir de 9 mois</p>
                                                    <p className="marchand-admin-card-paragraphe level-two">● Remise de 40% pour abonnement de 1 an</p>
                                                </div>
                                                <div className="ayn-pack-price-content">
                                                    <h6 className="ayn-pack-price">Prix</h6>
                                                    <h5>{`${formatNumber(montantGold)} FCFA`}</h5>
                                                </div>
                                                <div className="ayn-pack-select">
                                                    <span className="form-select">
                                                        <select value={gold} style={{height: 30, fontSize: 12, padding: '4px 10px'}} onChange={(e)=>this.handleGoldChange(e.target.value)}>
                                                            {mois.map((r,i)=> <option value={r.value} key={i}>{r.label}</option>)}
                                                        </select>
                                                    </span>
                                                </div>
                                                <div className="ayn-pack-foot">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value={packGold} checked={packGold} id="packGold" onChange={(e)=>this.handleGoldCheck(e.target.value)} />
                                                        <label class="form-check-label" for="packGold">
                                                            Cocher le pack
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ayn-pack">
                                                <Pack head="Pack Diamond" comment={true} itineraire={true} color="#fbdd00" />
                                                <div className="ayn-pack-price-content" style={{alignItems: 'start'}}>
                                                    <p className="marchand-admin-card-paragraphe level-two">● 10.000 FCFA/mois</p>
                                                    <p className="marchand-admin-card-paragraphe level-two">● Remise de 25% à partir de 3 mois</p>
                                                    <p className="marchand-admin-card-paragraphe level-two">● Remise de 30% à partir de 6 mois</p>
                                                    <p className="marchand-admin-card-paragraphe level-two">● Remise de 35% à partir de 9 mois</p>
                                                    <p className="marchand-admin-card-paragraphe level-two">● Remise de 40% pour abonnement de 1 an</p>
                                                </div>
                                                <div className="ayn-pack-price-content">
                                                    <h6 className="ayn-pack-price">Prix</h6>
                                                   <h5>{`${formatNumber(montantDiamond)} FCFA`}</h5>
                                                </div>
                                                <div className="ayn-pack-select">
                                                    <span className="form-select">
                                                        <select value={diamond} style={{height: 30, fontSize: 12, padding: '4px 10px'}} onChange={(e)=>this.handleDiamondChange(e.target.value)}>
                                                            {mois.map((r,i)=> <option value={r.value} key={i}>{r.label}</option>)}
                                                        </select>
                                                    </span>
                                                </div>
                                                <div className="ayn-pack-foot">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value={packDiamond} checked={packDiamond} id="packDiamond" onChange={(e)=>this.handleDiamondCheck(e.target.value)} />
                                                        <label class="form-check-label" for="packDiamond">
                                                            Cocher le pack
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ayn-pack">
                                                <Pack head="Pack Platinium" comment={true} itineraire={true} gestion={true} color="#f66f02" />
                                                <div className="ayn-pack-price-content" style={{alignItems: 'start'}}>
                                                    <p className="marchand-admin-card-paragraphe level-two">● 15.000 FCFA/mois</p>
                                                    <p className="marchand-admin-card-paragraphe level-two">● Remise de 25% à partir de 3 mois</p>
                                                    <p className="marchand-admin-card-paragraphe level-two">● Remise de 30% à partir de 6 mois</p>
                                                    <p className="marchand-admin-card-paragraphe level-two">● Remise de 35% à partir de 9 mois</p>
                                                    <p className="marchand-admin-card-paragraphe level-two">● Remise de 40% pour abonnement de 1 an</p>
                                                </div>
                                                <div className="ayn-pack-price-content">
                                                    <h6 className="ayn-pack-price">Prix</h6>
                                                   <h5>{`${formatNumber(montantPlatinium)} FCFA`}</h5>
                                                </div>
                                                <div className="ayn-pack-select">
                                                    <span className="form-select">
                                                        <select value={platinium} style={{height: 30, fontSize: 12, padding: '4px 10px'}} onChange={(e)=>this.handlePlatiniumChange(e.target.value)}>
                                                            {mois.map((r,i)=> <option value={r.value} key={i}>{r.label}</option>)}
                                                        </select>
                                                    </span>
                                                </div>
                                                <div className="ayn-pack-foot">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value={packPlatinium} checked={packPlatinium} id="packPlatinium" onChange={(e)=>this.handlePlatiniumCheck(e.target.value)}/>
                                                        <label class="form-check-label" for="packPlatinium">
                                                            Cocher le pack
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>    
                                    <div className="marchand-admin-card-action-btn">
                                        <Link to="/marchands/demandes" type="btn" className="wt-btn marchand-admin-card-btn marchand-admin-card-btn-secondary">Annuler</Link>
                                        <button type="btn" className="wt-btn marchand-admin-card-btn marchand-admin-card-btn-primary" disabled={btnLoad} onClick={()=>this.handleSubmit()}>
                                            {!btnLoad && 'Valider'}
                                            {btnLoad && <Loader size={16} />}
                                        </button>
                                    </div>

								</div>
							</div>
						</div>
					</div>
				</div>
        )
    }
}