import { Link } from 'react-router-dom'
import logo from '../assets/images/logo-footer.png'
import './footer.css'

const Footer = () => {
    return (
        <footer id="wt-footer" className="wt-footer wt-haslayout ayn-footer">
            <div className="wt-haslayout wt-footerbottom">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ayn-footer-col">
                            <p className="wt-copyrights" style={{margin: 0}}>
                                <strong>
                                    <Link to='/front/home' style={{ fontSize: 20, fontWeight: 'bold', color: '#fff', textDecoration: 'none' }}>
                                        <img src={logo} alt="logo" style={{height: 48}} />
                                    </Link>
                                </strong>
                            </p>
                            <nav className="ayn-footer-copyright-nav">
                                <span>
                                    © Copyright | <a href="https://logineo.com" target="_blank" rel="noreferrer">Logineo</a> | 2021
                                </span><br />
                                <Link to='/front/politiques'>Politique d'utilisation</Link>
                                {/* <span to='/front/politiques'>Politique d'utilisation</span> */}
                            </nav>
                            <nav className="ayn-footer-contact-nav">
                                <a href="https://logineo.com/#contact" className="wt-btn ayn-footer-contact-btn">Contactez-nous</a>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default Footer