import React from 'react';
import Page from '../../components/Page'
import Recherche from '../../features/client/Recherche'
import { MarchandsSvc } from '../../services'

const RecherchePage = (props) => {
    const marchandService = new MarchandsSvc(props.session)
    return (
        <Page>
            <Recherche {...props} marchandController={marchandService} />
        </Page>
    )
}
export default RecherchePage