/*eslint array-callback-return: "off"*/
import React from 'react'
import Table from '../../../components/Table/index'
import messages from '../../../lib/messages.json'
/*eslint consistent-return: ["error", { "treatUndefinedAsUnspecified": false }]*/


// Les colonne du tableua
const columns = [
    { name: 'createdAt', header: 'Date', timeStamp: true },
    // { name: 'debut', header: 'debut', datefr: true  },
    // { name: 'fin', header: 'fin', datefr: true  },
    { name: 'status', header: 'statut' },
    { name: 'marchandId', header: 'marchand', attributes: ['boutique'] },
    { name: 'montant', header: 'montant' },
    { name: 'marchandId', header: 'url', url: true, attributes: ['url'] },
    // { name: 'duree', header: 'duree', timeCalc: true, begin: 'debut', end: 'fin'},
]

export default class Certification extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [], filterData: [], research: '', load: true
        }

    }

    componentDidMount() {
        this.props.controller.fetch()
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                this.setState({ data, filterData: data, load: false })
            } else {
                if (data.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                    this.setState({ load: false })
                }
            }
        })
    }

    searchChange(event) {
        const value = event.target.value
        const data = this.state.filterData.filter(dt => {
           return (
              dt?.marchandId.boutique.toLowerCase().includes(value.toLowerCase()) ||
              dt?.marchandId.url.toLowerCase().includes(value.toLowerCase()) ||
              dt.stauts.toLowerCase().includes(value.toLowerCase()) ||
              dt.createAt.toLowerCase().includes(value.toLowerCase())
           )
        })
        this.setState({ data, research: value })
    }
    
    render() {
        // const { crud, permission } = this.props
        const { data, research, load } = this.state
        
        return (
            <div>
                <div className="table-actions-container">
                    <div className="">
                        <input type="text" value={research} className="form-control search-input" onChange={(e)=>this.searchChange(e)} placeholder="Rechercher dans le tableau" />
                    </div>
                </div>
                <Table
                    columns={columns}
                    data={data}
                    permission={'2'}
                    load={load}
                    viewOnly={true}
                    onViewOpen={(id) => this.props.history.push(`/admin/certification/${id}`)}
                />

            </div>
        )
    }

}