import React from 'react';
import Header from '../../components/marchand/Header';
import Page from '../../components/Page'
import Profile from '../../features/marchand/Profile'
import { UsersSvc, MarchandsSvc } from '../../services';

const ProfilePage = (props) => {
    const usersService = new UsersSvc(props.session)
    const marchandsService = new MarchandsSvc(props.session)
    const user = props.session.get('user')
    return (
        <Page mt={0} isAdmin={true}>
            <Header pathname="Profil" username={user?.username} session={props.session} toast={props.toast} />
            <Profile {...props} controller={usersService} marchandController={marchandsService} />
        </Page>
    )
}
export default ProfilePage