/*eslint array-callback-return: "off"*/
import React from 'react'
import { Link } from 'react-router-dom'
// import messages from '../../../lib/messages.json'
/*eslint consistent-return: ["error", { "treatUndefinedAsUnspecified": false }]*/


const Btn = ({icon, title, to})=>{
    return(
        <Link className="ayn-btn-marchand-demande" to={to}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <i className={icon}></i>
                <h6>{title}</h6>
            </div>
        </Link>
    )
}

export default class Demande extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            filterData: [],
            research: '', currentElement: {}, currentUser: {},
            load: true, detailModal: false, btnAction: false,
        }

    }

    componentDidMount() {

    }


    render() {
        // const { crud, permission } = this.props
        
        return (
            <div>
                <div style={{display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap', alignItems: 'center'}}>
                    <Btn icon="fas fa-pencil-alt" title="Modification d'imformations" to="/admin/modifications" />
                    <Btn icon="fas fa-certificate" title="Cetifications" to="/admin/certifications" />
                    <Btn icon="fas fa-gift" title="Promotions" to="/admin/promotions" />
                    <Btn icon="fas fa-credit-card" title="Forfaits" to="/admin/forfaits" />
                </div>

            </div>
        )
    }

}