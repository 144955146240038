import React from 'react';
import PropTypes from 'prop-types';
import './index.css'
import { Link } from 'react-router-dom';
import Poper from '../Poper'
import vitepay from '../../assets/images/vitepay.png'
import certifie from '../../assets/images/certifie.png'
import { formatTimeStampDate, timeCalculator, formatDate } from '../../lib/functions';
import Loader from '../Loader';
// import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
// import Table from '@material-ui/core/Table';
// import TablePagination from '@material-ui/core/TablePagination';
// import { Button, TableHead, Tooltip } from '@material-ui/core';

// import TablePopover from '../TablePopover/TablePopover'
// import TablePopover2 from '../TablePopover/TablePopover2'
// import Loader from '../Loader';
// import { VisibilityOutlined } from '@material-ui/icons';
// import Badge2 from '../Badge/components/Badge2'
// import Badge3 from '../Badge/components/Badge3'
// import Badge4 from '../Badge/components/Badge4'
// import './style.css'

// const useStyles1 = makeStyles(theme => ({
//   root: {
//     flexShrink: 0,
//     color: theme.palette.text.secondary,
//     marginLeft: theme.spacing(2.5),
//   },
// }));

function TablePaginationActions(props) {
//   const classes = useStyles1();
//   const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <nav>
        <ul className="pagination">
            <li className={`page-item ${page === 0 ? 'disabled' : ''}`}>
                <Link className="page-link" to="#" onClick={handleFirstPageButtonClick}>
                    <i className="fas fa-angle-double-left"></i>
                </Link>
            </li>
            <li className={`page-item ${page === 0 ? 'disabled' : ''}`}>
              <Link className="page-link" to="#" onClick={handleBackButtonClick}>
                <i className="fas fa-angle-left"></i></Link>
            </li>
            <li className="page-item active">
              <Link className="page-link disabled" to="#">
                {page+1}
              </Link>
            </li>
            <li className={`page-item ${page >= Math.ceil(count / rowsPerPage) - 1 ? 'disabled' : ''}`}>
              <Link className="page-link" to="#" onClick={handleNextButtonClick}>
                <i className="fas fa-angle-right"></i>
              </Link>
            </li>
            <li className={`page-item ${page >= Math.ceil(count / rowsPerPage) - 1 ? 'disabled' : ''}`}>
                <Link className="page-link" to="#" onClick={handleLastPageButtonClick}>
                    <i className="fas fa-angle-double-right"></i>
                </Link>
            </li>
        </ul>
    </nav>
  )
}

TablePaginationActions.propTypes = {
  //count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const cellsRow = (data, size) => {
  let cells = []
  for (let y = 0; y < size; y++) {
    cells[y] = <td key={y}>{data[y]}</td>
  }
  return cells
}

const RowTable = (row, size, key) => {
  return <tr key={key}>{cellsRow(row, size)}</tr>
}

class Table extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      rowsPerPage: 10,
      editStateFilter: []
    }

    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
  }

  handleEdit(id) { this.props.onEditOpen(id)  }
  handleView(id){ this.props.onViewOpen(id) }
  handleDelete(id) { this.props.onDeleteOpen(id) }
  handleSetStatus(id, event) { this.props.onSetStatusOpen(id) }
  handleChangeStatus(index, id){ this.props.onChangeStatus(index, id) }
  handleLinkClicked(id){ this.props.onLinkClick(id) }
  handleCertifie(id, certif){ this.props.onCertifie(id, certif) }

  handleChangePage(event, newPage) { this.setState({ page: newPage }) }
  handleChangeRowsPerPage(event) { this.setState({ rowsPerPage: parseInt(event.target.value, 10), age: 0 }) }

  render() {
    const { rowsPerPage, page } = this.state
    const { /*idPoper, openPoper, anchorEl, onClosePoper,*/ paginate, columns, data, /*classes,*/ permission, crud, viewOnly, load, stat } = this.props
   //const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    const tRows = []
    // CONTENU DU TABLEAU
    if (data.length > 0) {
      data.map((mod, i) => {
        let temp = []
        for (let y = 0; y < columns.length; y++) {
          // cas ou le tableu doit contenir un object ou l'on doit afficher au moins un de ses attributs
          if (typeof mod[columns[y].name] === 'object' && mod[columns[y].name] !== null) {
            const obj = mod[columns[y].name]
            if(obj?.type !=="undefined" && obj !== null){
              //temp.push( jsx`${mod[columns[y].name]}`)
              //}else{
                let attributes = ''
                let content = obj
                for (let x = 0; x < columns[y].attributes.length; x++) {
                  for(let z = 0; z < columns[y].attributes[x].split('.').length; z++){
                    if(typeof(content) !== 'undefined'){
                      content = content[`${columns[y].attributes[x].split('.')[z]}`] 
                    }else{
                      content = ''
                    }
                  //content = `${content}['${columns[y].attributes[x].split('.')[z]}']`
                  // console.log("11111111", content)
                }

                // attributes = `${attributes} ${obj[columns[y].attributes[x]] }` 
                attributes = content
                // console.log(y+"3333333", content)
              }
              if(typeof (columns[y].url) !== 'undefined'){
                temp.push(<a href={attributes} target="_blank" rel="noreferrer" style={{color: 'blue'}}>{attributes}</a>)
              }else{
                temp.push(attributes)
              }
            }
          } else { 
            if(typeof (columns[y].vitepay) !== 'undefined'){
              mod[columns[y].name] ? temp.push(<img src={vitepay} alt="img vitepay" className="ayn-vpay-img" />) : temp.push('')
            }else{
              if(typeof (columns[y].certifie) !== 'undefined'){
                // mod[columns[y].name] ? temp.push(<img src={certifie} alt="img certifie" className="ayn-certifie-img" />) : temp.push(<i className="far fa-times-circle" style={{color: '#767676', fontSize: 16}}></i>)
                mod[columns[y].name] ? temp.push(<img src={certifie} alt="img certifie" className="ayn-certifie-img"  style={{cursor: 'pointer'}} onClick={()=>this.handleCertifie(mod._id, false)} />) : temp.push(<i className="far fa-times-circle" style={{color: '#767676', cursor: 'pointer', fontSize: 16}} onClick={()=>this.handleCertifie(mod._id, true)}></i>)
              }else{
                if(typeof (columns[y].timeStamp) !== 'undefined'){
                  temp.push(formatTimeStampDate(mod[columns[y].name]))
                }else{
                  if(typeof (columns[y].stat) !== 'undefined'){
                    mod[columns[y].name] ? temp.push(<i className="fa fa-check-circle" style={{color: '#00db00', fontSize: 16}}></i>) : temp.push(<i className="far fa-times-circle" style={{color: '#767676', fontSize: 16}} ></i>)
                  }else{
                    if(typeof (columns[y].check) !== 'undefined'){
                      // temp.push(<Badge3 stat={mod[columns[y].name]} />)
                    }else{
                      if(typeof (columns[y].customStat) !== 'undefined'){
                      //   temp.push(<Badge4 label={mod[columns[y].name]} onChangeStatus={(index)=>this.handleChangeStatus(index, mod._id)} />)
                      }else{
                        if(typeof(columns[y].link) !== 'undefined'){
                          // temp.push(<Button onClick={()=>this.handleLinkClicked(mod[columns[y].id])} color="primary" style={{padding: 0, fontSize: 14}}> {mod[columns[y].name]} </Button>)
                        }else{
                          if(typeof (columns[y].admin) !== 'undefined'){
                            temp.push(mod[columns[y].name] ? 'Administrateur' : 'Utilisateur')
                          }else{
                            if(typeof (columns[y].timeCalc) !== 'undefined'){
                              temp.push(timeCalculator(mod[columns[y].begin],  mod[columns[y].end]))
                            }else{
                              if(typeof (columns[y].url) !== 'undefined'){
                                temp.push(<a href={mod[columns[y].name]} target="_blank" rel="noreferrer" style={{color: 'blue'}}>{mod[columns[y].name]}</a>)
                              }else{
                                if(typeof (columns[y].datefr) !== 'undefined'){
                                  temp.push(mod[columns[y].name] !== null ? formatDate(mod[columns[y].name]) : '-')
                                }else{
                                  temp.push(mod[columns[y].name]) 
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        if(permission === '2' && crud){
          if(viewOnly){
            // temp.push(<i class="fas fa-eye" onClick={()=>this.handleView(null, mod._id)} ></i><VisibilityOutlined id={mod._id} onClick={()=>this.handleView(null, mod._id)}  /></Tooltip>)
              temp.push(<span onClick={()=>this.handleView(mod._id)} className="table-action-item" style={{color: '#001e37', cursor: 'pointer'}}><i className="fas fa-eye" style={{fontSize: 12}}></i></span>)
          }else{
            if(stat){
            //   temp.push(<TablePopover2 id={mod._id} onClickModifier={(node)=>this.handleEdit(node, mod._id)} onClickActif={this.handleActif.bind(this, mod._id)} />)
            temp.push(
              <Poper trigger={<i className="fas fa-ellipsis-v" style={{fontSize: 12, paddingLeft: 2, paddingRight: 2}}></i>}>
                  <span className="pop-item table-action-item" onClick={()=>this.handleEdit(mod._id)}>Modifier</span>
                  <span className="pop-item table-action-item" onClick={()=>this.handleDelete(mod._id)}>Supprimer</span>
                  <span className="pop-item table-action-item" onClick={()=>this.handleSetStatus(mod._id)}>Activer/Désactiver</span>
              </Poper>)
            }else{
            //   temp.push(<TablePopover id={mod._id} onClickModifier={(node)=>this.handleEdit(node, mod._id)} onClickSupprimer={()=>this.handleDelete(mod._id)} />)
            temp.push(
              <Poper trigger={<i className="fas fa-ellipsis-v" style={{fontSize: 12}}></i>}>
                  <span className="pop-item table-action-item" onClick={()=>this.handleEdit(mod._id)}>Modifier</span>
                  <span className="pop-item table-action-item" onClick={()=>this.handleDelete(mod._id)}>Supprimer</span>
              </Poper>)
            }
          }
        }
        return tRows[i] = temp
      })
    }
    // console.log("TABLE",data.length)
    return (
      <div style={{margin:"0 40px"}}>

        {load && <Loader size={24} color="#001e37" />  }
        {!load &&
          <div //className={classes.root}
          >
            <div style={{ boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)", backgroundColor: '#fff'}} className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    {
                      columns.map((col, key) => {
                        return <th key={key}>{col.header.toUpperCase()}</th>
                      })
                    }
                    {crud && permission === '2' && <th align="right"></th>}
                  </tr>
                </thead>
                <tbody>
                  {tRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, key) => {
                    return (permission === '2' && crud) ? RowTable(row, columns.length+1, key) : RowTable(row, columns.length, key)
                  })
                  }

                  {typeof(data[0]) === 'undefined' &&(
                    <tr style={{ height: 48 }}>
                      <td colSpan={(permission === '2') ? columns.length+1 : columns.length} style={{textAlign: 'center'}} >
                        Aucune information trouvée.
                      </td>
                    </tr>
                  )}
                </tbody>
                {paginate && 
                  <tfoot>
                    <tr>
                        <td colSpan={(permission === '2' && crud)?columns.length+1 : columns.length} style={{textAlign: 'end', borderBottom: 'none', borderRight: 'none'}}>
                            {/* <TablePagination
                                labelRowsPerPage='Lignes par page:'
                                rowsPerPageOptions={[10, 25, 30, 50]}
                                colSpan={(permission === '2' && crud)?columns.length+1 : columns.length}
                                count={(typeof(data.length) !== 'undefined' ) ? data.length : 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                inputProps: { 'aria-label': 'lignes par page' },
                                native: true,
                                }}
                                labelDisplayedRows={({ from, to, count }) =>`${from}-${to === -1 ? count: to} sur ${count}`}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            /> */}
                            <span className="pagination-span">Lignes par page:</span>
                            <select className="custom-select pagination-select" onChange={this.handleChangeRowsPerPage}>
                              <option value={10}>10</option>
                              <option value={25}>25</option>
                              <option value={30}>30</option>
                              <option value={50}>50</option>
                            </select>
                              <span className="pagination-span pagin-from">
                                {data.length === 0 ? 0 : page * rowsPerPage + 1} -
                              </span>
                              <span className="pagination-span pagin-to">
                                {data.length  !== -1 ? Math.min(data.length , (page + 1) * rowsPerPage) : (page + 1) * rowsPerPage} sur
                              </span>
                              <span className="pagination-span pagin-count">
                                {data.length  === -1 ? -1 : data.length }
                              </span>
                            {/* <span>{page+1}</span> */}
                            <TablePaginationActions page={page} rowsPerPage={rowsPerPage} count={data.length} onChangePage={this.handleChangePage} />
                        </td>
                    </tr>
                  </tfoot>
                }
              </table>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default Table
// export default withStyles(styles)(Table)
Table.defaultProps = { paginate: true, crud: true, viewOnly: false, load: true, openPoper: false, stat: false, filterLoad: false }