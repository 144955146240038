const Progress = ({ process = 0, color = 'orange', number }) => {

    return (
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: 10, marginBottom: 4}}>
            <span style={{marginBottom: 4, marginLeft: 2}}>{number}</span>
            <div className="progress ayn-progress" style={{width: '97%'}}>
                <div className="progress-bar" style={{ width: `${process}%`, backgroundColor: color}}></div>
            </div>
        </div>
    )
}
export default Progress