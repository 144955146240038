/**
 * COMPOSANT PROPOSITION DU MARCHAND QUI DANS LA PAGE DETAIL DU MARCHAND POUR LA PARTIE DE L'APPLICATION MISE A LA DISPOSITION DE LA GRANDE PUBLiQUE
 */
import React from "react"

const styles = {
    p: {fontSize: 12, opacity: 0.8, color: '#000', lineHeight: '24px', marginBottom: 2},
    container: {display: "flex", flexDirection: 'column', padding: '0 16px 16px 16px', borderBottom: '1px solid #ddd'}
}

export default class Propositions extends React.Component {

    render() {
        const {tags, services, /*categories, souscategories*/} = this.props
        return (
            <div>
                <div style={styles.container}>
                    <h6 style={{marginBottom: 4}}>Services supplémentaires</h6>
                    
                    <div className="form-group form-radio-container" style={{flexWrap: 'wrap'}}>
                        <p style={styles.p}>Livraison à domicile</p>
                        <div className="wt-radioboxholder form-radio-content">
                            <div className="form-radio">
                                <span className="form-radio-label">Non disponible</span>
                                <span className="wt-radio">
                                    <input id="liv-nondisponible" type="radio" name="livraison" value="Non disponible"  checked={services.livraison === 'Non disponible'} readOnly/>
                                    <label htmlFor="liv-nondisponible"></label>
                                </span>
                            </div>
                            <div className="form-radio">
                                <span className="form-radio-label">Gratuite</span>
                                <span className="wt-radio">
                                    <input id="liv-gratuite" type="radio" name="livraison" value="Gratuite" checked={services.livraison === 'Gratuite'} readOnly/>
                                    <label htmlFor="liv-gratuite"></label>
                                </span>
                            </div>
                            <div className="form-radio">
                                <span className="form-radio-label">Payante</span>
                                <span className="wt-radio">
                                    <input id="liv-payant" type="radio" name="livraison" value="Payante" checked={services.livraison === 'Payante'} readOnly/>
                                    <label htmlFor="liv-payant"></label>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="form-group form-radio-container"  style={{flexWrap: 'wrap'}}>
                        <p style={styles.p}>Service après vente</p>
                        <div className="wt-radioboxholder form-radio-content">
                            <div className="form-radio">
                                <span className="form-radio-label">Oui</span>
                                <span className="wt-radio">
                                    <input id="sav-oui" type="radio" name="serviceApresVente" checked={services.livraison ? true : false} readOnly/>
                                    <label htmlFor="sav-oui"></label>
                                </span>
                            </div>
                            <div className="form-radio">
                                <span className="form-radio-label">Non</span>
                                <span className="wt-radio">
                                    <input id="sav-non" type="radio" name="serviceApresVente" checked={services.livraison ? false : true} readOnly/>
                                    <label htmlFor="sav-non"></label>
                                </span>
                            </div>
                        </div>
                    </div>

                    <h6 style={{marginBottom: 8, marginTop: 8}}>Fourchette de prix</h6>
                    <div className="form-group" style={{display: 'flex', margin: 0}}>
                        <label>Minimum:</label>
                        <span className="wt-select filter-select" style={{width: 150, marginLeft: 16}}>
                            <select style={{height: 40, margin: 0, marginBottom: 8}} value={tags.prix.minimum} readOnly>
                                <option value={tags.prix.minimum}>{tags.prix.minimum}</option>
                            </select>
                        </span>
                    </div>
                    <div className="form-group" style={{display: 'flex', margin: 0}}>
                        <label>Maximum:</label>
                        <span className="wt-select filter-select" style={{width: 150, marginLeft: 16}}>
                            <select style={{height: 40, margin: 0, marginBottom: 8}} value={tags.prix.maximum} readOnly>
                                <option value={tags.prix.maximum}>{tags.prix.maximum}</option>
                            </select>
                        </span>
                    </div>

                    {/* {sevices.map((s,i)=>{
                        return( <p style={styles.p} key={i}>{s}</p> )
                    })} */}
                    <h6 style={{marginBottom: 8, marginTop: 8}}>Catégories</h6>
                    <ul className="wt-filtertag ayn-cat-ul form-subcat-ul">
                        {tags.categories.map((c,i)=>{
                            return(
                                <li className="ayn-cat-li" key={i}>
                                    <span className='cat-chip active'>
                                        <span>{c}</span>
                                        <i className="fa fa-times close cat-closer"></i>
                                    </span>
                                </li>
                            )
                        })}
					</ul>

                    <h6 style={{marginBottom: 8, marginTop: 8}}>Sous-catégories</h6>
                    <ul className="wt-filtertag ayn-cat-ul form-subcat-ul">
                        {tags.sousGategories.map((c,i)=>{
                            return(
                                <li className="ayn-cat-li" key={i}>
                                    <span className='cat-chip active'>
                                        <span>{c}</span>
                                        <i className="fa fa-times close cat-closer"></i>
                                    </span>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        )
    }
}