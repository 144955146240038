/*eslint array-callback-return: "off"*/
import React from 'react'
import Switch from "react-switch"
import { Link } from "react-router-dom"
import Loader from '../../../components/Loader'
import Statcard from '../../../components/Statcard'
import messages from '../../../lib/messages.json'
import categoriesJSON from '../../../lib/categories.json'

/*eslint consistent-return: ["error", { "treatUndefinedAsUnspecified": false }]*/
const boutique = { id: '', boutique: '', url: '', emailBoutique: '', adresse: '', description: '', region: '', indicatif: '+223', telephone: '', vitepay: false, certifie: false, logo: '', couverture: '', produits: [] }
const reseaux = { facebook: '', instagram: '', linkedin: '' }
const prix = { minimum: 0, maximum: 0 }
const services = { livraison: 'Non disponible', service: "false" }
const fields = { name: '', email: '', role: '', status: false, current: '', password: '', confirmation: '', currentId: ''}
const fieldsError = { password: {}, confirmation: {}, current: {}}
const validations = { 
	current: {type: 'checkRequired', required: true, min: 8, number: false},
	password: {type: 'checkRequired', required: true, min: 8, number: false},
	confirmation: {type: 'checkConfirmation', min: 8, val2: 'password'}
}
const formOneItems = ['password', 'confirmation']

const Row = ({ title, value, bt, bb }) => {
    return (
        <div className="row" style={{ border: `2px solid #ddd`, borderTop: `${bt}px solid #ddd`, borderBottom: `${bb}px solid #ddd`, margin: 0 }}>
            <div className="col-5 ayn-detail-title" style={{ backgroundColor: '#f7f7f7' }}>
                {title}
            </div>
            <div className="col-7 ayn-detail-text" style={{ borderLeft: '2px solid #ddd' }}>
                {value}
            </div>
        </div>
    )
}

export default class Profile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            errors: { ...fieldsError },
            categoriesSelected: [],
			subcategoriesSelected: [],
			categories: categoriesJSON,
			subcategories: [],
            ...fields,
            ...boutique,
			...reseaux,
			...prix,
			...services,
            user: {},
            btnLoad: false

        }
        this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this)

    }

    handleTextChange(e, name){ this.setState({ [name]: e.target.value }) }
    checkConfirmation(name, val, val2, min){
		const errors = this.state.errors
		errors[name]['required'] = !val ? `*Ce champ est obligatoire` : null
		errors[name]['min'] = val.length < min ? `*Ce champ doit avoir au moins ${min} caracteres` : null
		errors[name]['different'] = val !== val2 ? `*Mot de passe et confirmation ne correspondent pas` : null
		this.setState({errors})
	}
	checkRequired(name, val, min, number = false){
		const errors = this.state.errors
		errors[name]['required'] = !val ? `*Ce champ est obligatoire` : null
		errors[name]['min'] = val.length < min ? `*Ce champ doit avoir au moins ${min} caracteres` : null
		if(number){
			errors[name]['number'] = isNaN(val) ? `*Ce champ doit être numérique` : null
		}
		this.setState({errors})
	}
    checkValidations(items){
		for(let i=0; i<items.length; i++){
			let item = validations[items[i]]
			if(typeof(item) !== 'undefined'){
				if(item['type'] === 'checkRequired'){
					this.checkRequired(items[i], this.state[items[i]], item['min'], item['number'] )
				}else{
					if(item['type'] === 'checkConfirmation'){
						this.checkConfirmation(items[i], this.state[items[i]], this.state[item['val2']], item['min'] )
					}else{
						if(item['type'] === 'checkEmail'){
							this.checkEmail(items[i], this.state[items[i]], item['required'] )
						}else{
							if(item['type'] === 'checkUrl'){
								this.checkUrl(items[i], this.state[items[i]], item['required'] )
							}else{
								if(item['type'] === 'checkFile'){
									let params = this.state[`${items[i]}Params`]
									if(item['multiple']){
										this.checkFileOnSubmit(items[i], this.state[item['name']][item['pos']], item['width'], item['height'], params ? params.w : null, params ? params.h : null )
									}else{
										this.checkFileOnSubmit(items[i], this.state[items[i]], item['width'], item['height'], params ? params.w : null, params ? params.h : null )
									}
								}else{
									if(item['type'] === 'checkChip'){
										this.checkChip(items[i], this.state[items[i]], item['min'] )
									}
								}
							}
						}	
					}
				}
			}
		}
	}

    hasError(errors){
		let erreur = false
		Object.keys(errors).map(key => {
			let error = errors[key]
			Object.keys(error).map(k => {
				if(error[k]){
					erreur = true
				}
			})
		})
		return erreur
	}

    filter(flt){
        let res = []
        for(let i=0; i<flt.length; i++){
            const f = categoriesJSON.filter(c=>c.name === flt[i])
            res.push(...f)
        }
        return res
    }

	selectCategorie(cat){
        const { categoriesSelected } = this.state
        let slt = categoriesSelected
        if(this.state[`cat_${cat}`]){
            slt = categoriesSelected.filter(s=> s !== cat)
            if(slt[0]){
                const subcategories = this.filter(slt)
                this.setState({ [`cat_${cat}`]: '', categoriesSelected: slt, subcategories })
            }else{
                this.setState({ [`cat_${cat}`]: '', categoriesSelected: slt, subcategories: [] })
            }
        }else{
            slt.push(cat)
            const subcategories = this.filter(slt)
            this.setState({[`cat_${cat}`]: 'active', categoriesSelected: slt, subcategories})
        }
    }

	selectSubcategorie(cat){
        const { subcategoriesSelected } = this.state
        let slt = subcategoriesSelected
        if(this.state[`subcat_${cat}`]){
            slt = subcategoriesSelected.filter(s=> s !== cat)
            if(slt[0]){
                // const subcategories = this.filter(slt)
                this.setState({ [`subcat_${cat}`]: '', subcategoriesSelected: slt/*, subcategories*/ })
            }else{
                this.setState({ [`subcat_${cat}`]: '', subcategoriesSelected: slt/*, subcategories: []*/ })
            }
        }else{
            slt.push(cat)
            // const subcategories = this.filter(slt)
            this.setState({[`subcat_${cat}`]: 'active', subcategoriesSelected: slt/*, subcategories*/})
        }
    }

    handleSubmitUpdate(e){
        e.preventDefault()
        this.setState({ btnLoad: true })
		this.checkValidations(formOneItems)
		const error = this.hasError(this.state.errors)
		if(!error){
            const { password, current, user } = this.state
            // const user = this.props.session.get('user')
			const proprietaire = { id: user?.userId, name: user?.name, email: user?.email, role: user?.role, admin: user.role === 'Admin' ? true : false, status: user?.status, password }
            
            // console.log("proprietaire", proprietaire)
            // console.log("current", current)
            // console.log("user.password", user.password)

            if(current === user.password){
                if(user){
                    this.props.controller.save(proprietaire)
                    .then(res => {
                        if (typeof (res.pror) === 'undefined') {
                            user.password = password
                            // console.log(user)
                            this.props.session.login(user)
                            this.props.toast(messages.updateUserPassword, { appearance: 'success' })
                            this.setState({ btnLoad: false })
                        } else {
                            if (res.pror.indexOf('401') > 0) {
                                this.props.toast(messages.sessionExpired, { appearance: 'info' })
                            } else {
                                this.props.toast(messages.requestFailed, { appearance: 'error' })
                                this.setState({ btnLoad: false })
                            }
                        }
                    })
                }else{
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                    this.setState({ btnLoad: false })
                }
            }else{
                this.props.toast(messages.currentPasswordInvalid, { appearance: 'error' })
                this.setState({ btnLoad: false })
            }
        }else{
            this.props.toast(messages.formError, { appearance: 'error' })
            this.setState({ btnLoad: false })
        }
    }

    getMarchandInformation(marchandId){
        if(marchandId){
            this.props.marchandController.findById(marchandId)
            .then(res => {
                if(res){
                    if (typeof (res.pror) === 'undefined') {
                        this.setState({
                            id: res._id, boutique: res.boutique, url: res.url, adresse: res.adresse, telephone: res.telephone.split(' ')[1], emailBoutique: res.email, vitepay: res.vitepay,
                            facebook: res.reseaux?.facebook, instagram: res.reseaux?.instagram, linkedin: res.reseaux?.linkedin, description: res.description, region: res.region,
                            minimum: res.tags.prix.minimum, maximum: res.tags.prix.maximum, livraison: res.services.livraison, service: `${res.services.service}`,
                            prdPrvImg1: res.produits[0], prdPrvImg2: res.produits[1], prdPrvImg3: res.produits[2],
                            logoPrvImg: res.logo, couverturePrvImg: res.couverture,
                            logo: res.logo, couverture: res.couverture, produits: [res.produits[0], res.produits[1], res.produits[2]],
                            // categoriesSelected: res.tags.categories,
                            // userId: res.userId
                            // marchandId: res._id
                        })
                        for(let i=0; i< res.tags.categories.length; i++){
                            this.selectCategorie(res.tags.categories[i])
                        }
                        for(let i=0; i< res.tags.sousGategories.length; i++){
                            this.selectSubcategorie(res.tags.sousGategories[i])
                        }				
                    } else {
                        if (res.pror.indexOf('401') > 0) {
                            this.props.toast(messages.sessionExpired, { appearance: 'info' })
                        } else {
                            this.props.toast(res.pror, { appearance: 'error' })
                        }
                    }
                }
            })
        }else{
            this.props.toast(messages.requestFailed, { appearance: 'error' })
            // this.setState({ btnLoad: false })
        }
    }

    componentDidMount(){
        const user = this.props.session.get('user')
        this.getMarchandInformation(user.id)
        this.setState({user})
    }

    render() {
        // const { crud, permission } = this.props
        const { 
            user, btnLoad, password, confirmation, current, errors,

            boutique, url, emailBoutique, adresse, region, indicatif, telephone, description, vitepay,
			minimum, maximum,
			livraison, service,
			facebook, instagram, linkedin,
			logoPrvImg, couverturePrvImg, prdPrvImg1, prdPrvImg2, prdPrvImg3,
			categories, subcategories, categoriesSelected, subcategoriesSelected 
        } = this.state

        return (
            <div className="row" style={{ margin: "0 40px" }}>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <div className="wt-registerformhold" style={{backgroundColor: 'transparent', paddingTop: 0}}>
                        <div className="wt-registerformmain">
                            {/* <div className="wt-registerhead" style={{padding: 0}}>
                                <div className="wt-title">
                                    <h3 style={{marginBottom: 4}}>Informations demandées</h3>
                                </div>
                                <div className="wt-description">
                                    <p style={{lineHeight: '14px', fontSize: 12}}>Les informations nouvellement demandées</p>
                                </div>
                            </div> */}
                            <div className="wt-joinforms">
                                <form className="wt-formtheme wt-formregister" id="form-inscription">
                                    <fieldset className="wt-registerformgroup">
                                        <div className="form-card">
                                            <div className="form-card-header">
                                                <h4>INFORMATION DE LA BOUTIQUE</h4>
                                            </div>

                                            <div className="form-card-body">
                                                <div className="form-group">
                                                    <label>Marchand/Nom de la boutique en ligne*</label>
                                                    <input type="text" className="form-control" placeholder="ex: Boutique" value={boutique} readOnly/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Email de la boutique*</label>
                                                    <input type="text" className="form-control" placeholder="ex: exemple@domaine.com" value={emailBoutique} readOnly/>
                                                </div>
                                                <div className="form-group has-feedback">
                                                    <label>URL du site web*</label>
                                                    <input type="text" className="form-control" placeholder="ex: https://nomdedomaine.com" value={url} readOnly/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Adresse de la boutique*</label>
                                                    <input type="text" className="form-control" placeholder="ex: Kalaban coura" value={adresse} readOnly/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Region*</label>
                                                    <span className="wt-select">
                                                        <select  value={region} disabled>
                                                            <option value={region}> {region} </option>
                                                            {/* {listRegion.map((r,i)=> <option value={r} key={i}>{r}</option>)} */}
                                                        </select>
                                                    </span>
                                                </div>
                                                <div className="form-group">
                                                    <label>Numéro de téléphone de la boutique *</label>
                                                    <div className="input-group mb-3">
                                                        <span className="wt-select" style={{width: 100}}>
                                                            <select value={indicatif} readOnly>
                                                                <option>+223</option>
                                                            </select>
                                                        </span>
                                                        <input type="number" minLength={8} maxLength={8} className="form-control" placeholder="ex: 20 24 49 15" value={telephone} readOnly/>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label>Description de la boutique *</label>
                                                    <textarea rows="2" className="form-control" placeholder="Description de la boutique" value={description} readOnly></textarea>
                                                </div>								

                                                <div className="form-group" style={{display: 'flex', alignItems: 'center'}}>
                                                    <span style={{marginRight: 32, fontSize: 12}}>Etes-vous un marchand Vitepay ?</span>
                                                    <Switch onChange={()=>this.setState({vitepay})} checkedIcon={false} uncheckedIcon={false} onColor='#001e37' height={24} checked={vitepay} />
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <div className="form-card">
                                            <div className="form-card-header">
                                                <h4>LIENS DES RESEAUX SOCIAUX</h4>
                                            </div>
                                            <div className="form-card-body">
                                                <div className="form-group">
                                                    <label>Facebook</label>
                                                    <input type="text" className="form-control" placeholder="Lien du compte facebook" value={facebook} readOnly/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Instagram</label>
                                                    <input type="text" className="form-control" placeholder="Lien du compte instagram" value={instagram} readOnly/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Linkedin</label>
                                                    <input type="text" className="form-control" placeholder="Lien du compte linkedin" value={linkedin} readOnly/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-card">
                                            <div className="form-card-header">
                                                <h4>CATEGORIES ET SOUS CATEGORIES</h4>
                                            </div>
                                            <div className="form-card-body">
                                                <div className="form-group" style={{marginTop: 8}}>
                                                    <h6 className="form-title">Categories</h6>
                                                </div>
                                                <ul className="wt-filtertag ayn-cat-ul form-cat-ul" style={{marginBottom: 8}}>
                                                    {categories.map((c,i)=>{
                                                        return(
                                                            <li className="ayn-cat-li" key={i}>
                                                                <Link to="#" className={this.state[`cat_${c.name}`]}><span>{c.name}</span></Link>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                                
                                                {categoriesSelected.length > 0 &&
                                                <ul className="wt-filtertag ayn-cat-ul form-subcat-ul">
                                                {categoriesSelected.map((c,i)=>{
                                                    return(
                                                        <li className="ayn-cat-li" key={i}>
                                                            <Link to="#" className={this.state[`cat_${c}`]}>
                                                                <span>{c}</span>
                                                                <i className="fa fa-times close cat-closer"></i>
                                                            </Link>
                                                        </li>
                                                    )
                                                })}
                                                </ul>}
                                                
                                                {subcategories.length > 0 &&
                                                <>
                                                    <div className="form-group form-title-container">
                                                        <h6 className="form-title">Sous categories</h6>
                                                    </div>
                                                    <ul className="wt-filtertag ayn-cat-ul" style={{justifyContent: 'start', flexWrap: 'wrap'}}>
                                                        {subcategories.map((c,i)=>{
                                                            return(
                                                                <ul className="wt-filtertag ayn-cat-ul form-subcat-ul" style={{margin: '8px 0'}} key={i}>
                                                                    {c.children.map((s,j)=>{
                                                                        return(
                                                                            <li className="ayn-cat-li" key={j}>
                                                                                <Link to="#" className={this.state[`subcat_${s.name}`]}><span>{s.name}</span></Link>
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            )
                                                        })}
                                                    </ul>
                                                </>}

                                                {subcategoriesSelected.length > 0 &&
                                                <ul className="wt-filtertag ayn-cat-ul form-subcat-ul">
                                                {subcategoriesSelected.map((c,i)=>{
                                                    return(
                                                        <li className="ayn-cat-li" key={i}>
                                                            <Link to="#" className={this.state[`subcat_${c}`]}>
                                                                <span>{c}</span>
                                                                <i className="fa fa-times close cat-closer"></i>
                                                            </Link>
                                                        </li>
                                                    )
                                                })}
                                                </ul>}
                                            </div>
                                        </div>


                                        <div className="form-group form-title-container">
                                            <h6 className="form-title">Fourchette de prix</h6>
                                        </div>
                                        <div className="form-group" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                            <label>Min:*</label>
                                            <span className="wt-select" style={{width: 150}}>
                                                <select value={minimum} disabled>
                                                    <option value={minimum}>{minimum}</option>
                                                </select>
                                            </span>
                                            <label>Max:*</label>
                                            <span className="wt-select" style={{width: 150}}>
                                                <select value={maximum} disabled>
                                                    <option value={maximum}>{maximum}</option>
                                                </select>
                                            </span>
                                        </div>

                                        <div className="form-group form-title-container">
                                            <h6 className="form-title">Services suplementaire</h6>
                                        </div>
                                        <div className="form-group form-radio-container">
                                            <label style={{width: 200}}>Livraison:</label>
                                            <div className="wt-radioboxholder form-radio-content">
                                                <div className="form-radio">
                                                    <span className="form-radio-label">Non disponible</span>
                                                    <span className="wt-radio">
                                                        <input id="liv-nondisponible" type="radio" name="livraison" value="Non disponible" checked={livraison === "Non disponible"} readOnly/>
                                                        <label htmlFor="liv-nondisponible"></label>
                                                    </span>
                                                </div>
                                                <div className="form-radio">
                                                    <span className="form-radio-label">Gratuite</span>
                                                    <span className="wt-radio">
                                                        <input id="liv-gratuite" type="radio" name="livraison" value="Gratuite" checked={livraison === "Gratuite"} readOnly/>
                                                        <label htmlFor="liv-gratuite"></label>
                                                    </span>
                                                </div>
                                                <div className="form-radio">
                                                    <span className="form-radio-label">Payante</span>
                                                    <span className="wt-radio">
                                                        <input id="liv-payant" type="radio" name="livraison" value="Payante" checked={livraison === "Payante"} readOnly/>
                                                        <label htmlFor="liv-payant"></label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group form-radio-container">
                                            <label style={{width: 200}}>Services après vente:</label>
                                            <div className="wt-radioboxholder form-radio-content">
                                                <div className="form-radio">
                                                    <span className="form-radio-label">Oui</span>
                                                    <span className="wt-radio">
                                                        <input id="sav-oui" type="radio" name="serviceApresVente" value={true} checked={service === "true"} readOnly/>
                                                        <label htmlFor="sav-oui"></label>
                                                    </span>
                                                </div>
                                                <div className="form-radio">
                                                    <span className="form-radio-label">Non</span>
                                                    <span className="wt-radio">
                                                        <input id="sav-non" type="radio" name="serviceApresVente" value={false} checked={service === "false"} readOnly/>
                                                        <label htmlFor="sav-non"></label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="form-group form-group-label form-file-selecter logo-selecter">
                                            <label className="file-label">Image du logo</label>
                                            
                                            <div className="wt-uploadingbox" style={{margin: 8}}>
                                                <div className="wt-designimg">
                                                    <label htmlFor="demoq">
                                                        <img src={logoPrvImg} alt="img logo" style={{borderRadius: 8, width: 100, height: 100}} />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group form-group-label form-file-selecter">
                                            <label className="file-label">Image de couverture</label>
                                            <div className="wt-uploadingbox" style={{margin: 8}}>
                                                <div className="wt-designimg">
                                                    <label htmlFor="demoq">
                                                        <img src={couverturePrvImg} alt="img couverture" style={{borderRadius: 8, width: 400, height: 200}} />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group form-group-label form-file-selecter">
                                            <label className="file-label">Images de trois produits phares</label>
                                        </div>

                                        <div className="form-group" style={{display: 'flex'}}>
                                            <div className="wt-uploadingbox" style={{margin: 8}}>
                                                <div className="wt-designimg">
                                                    <label htmlFor="demoq">
                                                        <img src={prdPrvImg1} alt="img produit 1" style={{borderRadius: 8, width: 200, height: 200}} />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="wt-uploadingbox" style={{margin: 8}}>
                                                <div className="wt-designimg">
                                                    <label htmlFor="demoq">
                                                        <img src={prdPrvImg2} alt="img produit 2" style={{borderRadius: 8, width: 200, height: 200}}  />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="wt-uploadingbox" style={{margin: 8}}>
                                                <div className="wt-designimg">
                                                    <label htmlFor="demoq">
                                                        <img src={prdPrvImg3} alt="img produit 3" style={{borderRadius: 8, width: 200, height: 200}}  />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <Statcard title="Informations générales" mb={24}>
                        <Row title="Nom et Prénom" value={user.name ? user.name : '' } bt='2' bb='1' />
                        <Row title="Email" value={user.email ? user.email : ''} bt='1' bb='1' />
                        <Row title="Rôle" value={user.role ? user.role : ''} bt='1' bb='2' />
                    </Statcard>
                    <Statcard title="Changer le mot de passe" mb={24} float='left'>
                        <form className="wt-formtheme wt-formregister" id="form-inscription" onSubmit={this.handleSubmitUpdate}>
                            <fieldset className="wt-registerformgroup">
                                <div className="form-group">
                                    <label>Actuel mot de passe *</label>
                                    <input type="password" onBlur={()=>this.checkRequired('current', current, 4)} className="form-control" placeholder="Actuel mot de passe" value={current} onChange={(e)=>this.handleTextChange(e, 'current')} required/>
                                    {Object.keys(errors.current).map((k, i) => {
                                        return <span key={i} className="error-span">{errors.current[k]}</span>
                                    })}
                                </div>
                                <div className="form-group">
                                    <label>Nouveau mot de passe *</label>
                                    <input type="password" onBlur={()=>this.checkRequired('password', password, 8)} className="form-control" placeholder="Nouveau mot de passe" value={password} onChange={(e)=>this.handleTextChange(e, 'password')} required/>
                                    {Object.keys(errors.password).map((k, i) => {
                                        return <span key={i} className="error-span">{errors.password[k]}</span>
                                    })}
                                </div>
                                <div className="form-group">
                                    <label>Confirmer le mot de passe *</label>
                                    <input type="password" onBlur={()=>this.checkConfirmation('confirmation', confirmation, password, 8)} className="form-control" placeholder="Confirmer le mot de passe" value={confirmation} onChange={(e)=>this.handleTextChange(e, 'confirmation')} required/>
                                    {Object.keys(errors.confirmation).map((k, i) => {
                                        return <span key={i} className="error-span">{errors.confirmation[k]}</span>
                                    })}
                                </div>
                                
                                <div className="filter-actions-container" style={{float: 'right', marginTop: 16, marginBottom: 4}}>
                                    <button className="btn btn-primary filter-action-btn" type="submit">
                                        {btnLoad && <Loader size={16} />}
                                        {!btnLoad && 'Modifier'}
                                    </button>
                                    {/* <button type="button" className="btn btn-secondary filter-action-btn" onClick={()=>this.setState({ ...fields, modal: false }) }>Annuler</button> */}
                                </div>
                            </fieldset>
                        </form>
                    </Statcard>
                </div>
            </div>
        )
    }

}