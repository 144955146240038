import { EntityService } from './service';

//===========================================================================================//
//                                       CLIENTS                                          //
//=========================================================================================//
export class StoresSvc extends EntityService {
  constructor(session) {
    super('stores', session)
  }
}

export class AvisSvc extends EntityService {
  constructor(session) {
    super('avis', session)
  }
}

export class NotesSvc extends EntityService {
  constructor(session) {
    super('notes', session)
  }
  stats = (marchandId) => this._get(`/stats/${marchandId}`)
}


//===========================================================================================//
//                                       ADMINS                                             //
//=========================================================================================//
export class StatsSvc extends EntityService {
  constructor(session) {
    super('stats', session)
  }
  total = () => this._get(`/marchands/total`)
  demande = () => this._get(`/marchands/demande`)
  accepter = () => this._get(`/marchands/total-accepter`)
  rejeter = () => this._get(`/marchands/total-rejeter`)
  inscription = () => this._get(`/marchands/total-inscription`)
  month = () => this._get(`/marchands/month`)
  region = () => this._get(`/marchands/region`)
  
  newModification = () => this._get(`/marchandupdates/new`)
  demandeModification = () => this._get(`/marchandupdates/demande`)

  newCertification = () => this._get(`/certifications/new`)
  demandeCertification = () => this._get(`/certifications/demande`)

  newPromotion = () => this._get(`/promotions/new`)
  demandePromotion = () => this._get(`/promotions/demande`)

  newForfait = () => this._get(`/forfaits/new`)
  demandeForfait = () => this._get(`/forfaits/demande`)
}

export class LoginSvc extends EntityService {
  constructor(session) {
    super('login', session)
  }
}

//===========================================================================================//
//                                       MARCHANDS                                          //
//=========================================================================================//

export class MarchandUpdate extends EntityService {
  constructor(session) {
    super('marchandupdates', session)
  }
  updateMarchand = (files, body) => this._importMultiple('', files, body)
  findById = (id) => this._get(`/${id}`)
  getMarchandUpdateHistorique = (id) =>this._get(`/marchand/${id}`)
}

export class NotificatonsSvc extends EntityService {
  constructor(session) {
    super('notifications', session)
  }
  getMarchandNotificaton = (id) =>this._get(`/marchand/${id}`)
  sendNotification = (data) => this._post(`/app-mobile`, { data }) //=======ADMINS
}

// export class CommentairesSvc extends EntityService {
//   constructor(session) {
//     super('commentaires', session)
//   }
//   // updateMarchand = (files, body) => this._importMultiple('', files, body)
//   // findById = (id) => this._get(`/${id}`)
// }


//===========================================================================================//
//                                       COMMONS                                            //
//=========================================================================================//
export class UsersSvc extends EntityService {
  constructor(session) {
    super('users', session)
  }
 
  getById = (id) => this._get(`/${id}`) //======ADMINS
  search = (email) => this._get(`/email/${email}`) //=======ADMINS
  filter = (role) => this._get(`/role/${role}`) //=======ADMINS
  status = (id, data) => this._put(`status/${id}`, {data}) //=======ADMINS
  reinitPassword = (id) => this._get(`reinit-password/${id}`) //=======ADMINS

  signup = (data) => this._post(`/signup`, { data }) //======MARCHANDS
}

export class MarchandsSvc extends EntityService {
  constructor(session) {
    super('marchands', session)
  }
  createMarchand = (files, body) => this._importMultiple('/signup', files, body) //=====MARCHANDS
  
  inscriptions = () => this._get(`/inscription`) //=======ADMINS
  marchands = () => this._get(`/marchand`) //=======ADMINS
  search = (data) => this._post(`/url`, {data}) //=======ADMINS
  filter = (data) => this._post(`/filter`, {data}) //=======ADMINS
  // certifie = (data) => this._put(`certifie`, {data}) //=======ADMINS

  findById = (id) => this._get(`/${id}`) //=======ADMINS && MARCHANDS

  certifies = () => this._get(`/certifie`) //=======CLIENTS
  vitepays = () => this._get(`/vitepay`) //=======CLIENTS
  populaires = (data, skip=0) => this._post(`/categorie/${skip}`, {data}) //=======CLIENTS
  recherches = (data, skip=0) => this._post(`/souscategorie/${skip}`, {data}) //=======CLIENTS
  marchandNoms = () => this._get(`/boutique`) //=======CLIENTS
  boutiqueInfo = (id) => this._get(`/user/${id}`) //=======MARCHANDS
}

export class CertificationsSvc extends EntityService {
  constructor(session) {
    super('certifications', session)
  }
  getCertificationHistorique = (id) =>this._get(`/marchand/${id}`) //======= MARCHANDS
}

export class ForfaitsSvc extends EntityService {
  constructor(session) {
    super('forfaits', session)
  }
  getForfaitPermission = (id) =>this._get(`/marchand/${id}`) //=======CLIENTS && MARCHANDS
  getForfaitHistorique = (id) =>this._get(`/marchand/all/${id}`) //======= MARCHANDS
}

export class PromotionsSvc extends EntityService {
  constructor(session) {
    super('promotions', session)
  }

  demande = (files, body) => this._importMultiple('', files, body) //=====MARCHANDS
  calendrier = () => this._get(`/calendrier`) //=======MARCHANDS
  getPromotionHistorique = (id) =>this._get(`/marchand/${id}`) //======= MARCHANDS

  getSlider1 = (id) =>this._get(`/slider1`) //======= CLIENTS
  getSlider2 = (id) =>this._get(`/slider2`) //======= CLIENTS
  getSlider3 = (id) =>this._get(`/slider3`) //======= CLIENTS
}