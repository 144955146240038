import React from "react"
import ReactGA from 'react-ga'
import DetailCard from "../../../components/DetailCard"
import Tab from "../../../components/Tab"
import Aperçu from "./Apercu"
import Avis from "./Avis"
import About from "./About"
import Propositions from "./Propositions"
import Loader from "../../../components/Loader"

const tabs = [
    {name: 'Aperçu', slug: 'apercu' },
    {name: 'Avis', slug: 'avis' },
    {name: 'Propositions', slug: 'propositions' },
    {name: 'A propos', slug: 'about' },
]
export default class DetailCategorie extends React.Component {
    constructor(props){
        super(props)
        this.state = { 
            slug: 'apercu', url: '', iframe: false, load: false,
        }
        this.iframeRef = React.createRef()
    }

    componentDidMount(){
        ReactGA.pageview(window.location.pathname)
    }

    async handleOpenUrl(url){
        this.setState({ url, iframe: true, load: true })
        // this.iframeRef.current.scrollIntoView()
    }

    render() {
        const { slug, url, iframe, load } = this.state
        const { isMobile, avisController, noteController, forfaitController, toast } = this.props
        const { store } = this.props.history.location.state
        
        return (
            <div className="wt-haslayout">
                <div className="container" style={{width: iframe ? '100%' : '', maxWidth: iframe ? '100%' : '' }}>
                    <div className="row">
                        <div id="wt-twocolumns" className="wt-twocolumns wt-haslayout">
                            <div className="col-12"  style={{height: iframe ? "100vh" : '' }} ref={this.iframeRef}>
                                {!iframe &&
                                <DetailCard onOpenUrl={(url)=>this.handleOpenUrl(url)} name={store.boutique} url={store.url} address={store.adresse} isVerified={store.certifie} cover={store.couverture} logo={store.logo} rating={store.avis} vitepay={store.vitepay} isMobile={isMobile} >
                                    <div className="ayn-detail-content">
                                        <Tab tabs={tabs} onSelectTab={(slug)=>this.setState({slug})} />
                                        {slug === 'apercu' && <Aperçu onOpenUrl={(url)=>this.handleOpenUrl(url)} canFrame={false/*store.frame*/} url={store.url} telephone={store.telephone} produit1={store.produits[0]} produit2={store.produits[1]} produit3={store.produits[2]} />}
                                        {slug === 'avis' && <Avis avisController={avisController} noteController={noteController} marchandId={store._id} avis={store.avis} toast={toast} />}
                                        {slug === 'propositions' && <Propositions tags={store.tags} services={store.services} />}
                                        {slug === 'about' && <About reseaux={store.reseaux} telephone={store.telephone} email={store.email} apropos={store.description} controller={forfaitController} marchandId={store._id} name={store.boutique} />}
                                    </div>
                                </DetailCard>}
                                {load && <div style={{display: 'flex', marginTop: '10%', justifyContent: 'center'}}><Loader size={24} color="#001e37" /></div>}
                                {iframe &&
                                <>
                                {!load && <div style={{ cursor: 'pointer', width: 36, height: 36, background: 'red', right: 0, position: 'relative', float: 'right', zIndex: 9999, borderRadius: 36, justifyContent: 'center', display: 'flex', alignItems: 'center' }} onClick={()=>this.setState({ iframe: false, load: false })}>
                                    <i className="fas fa-times" style={{color: '#fff'}}></i>
                                </div>}
                                <iframe title={url} src={url} style={{ height: "100vh", position: 'absolute', left: 0 }} samesite="none" secure='true' referrerPolicy="origin-when-cross-origin strict-origin-when-cross-origin" onEncrypted={(er)=>console.log('err dd', er)}  onLoad={(e)=>{
                                    // console.log('err', e.target)
                                    this.setState({load: false})
                                }} />
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}