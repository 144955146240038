import React from "react"
import './detail.css'
import { Link } from 'react-router-dom'
import messages from '../../../lib/messages.json'

export default class Detail extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            debut: '', fin: '', motif: '', motifError: false, debutError: false, finError: false
        }
    }

    handleChangeDebut(debut){
        const begin = new Date(debut)
        const end = new Date(begin.setMonth(begin.getMonth()+3))
        const fin = `${end.getFullYear()}-${("0" + (end.getMonth()+1)).slice(-2)}-${("0" + end.getDate()).slice(-2)}`
        this.setState({ debut, fin })
    }

    save(certification){
        this.props.controller.save(certification)
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                // this.setState({ data })
                this.props.toast(messages.certifie, { appearance: 'success' })
            } else {
                if (data.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(data.pror, { appearance: 'error' })
                }
            }
        })
    }

    checkForm(input){
        if(this.state[input] === ''){ this.setState({ [`${input}Error`]: true }) }
        else{ this.setState({ [`${input}Error`]: false }) }
    }

    checkValidations(type){
        let err = 0
        const { /*debut, fin,*/ motif } = this.state
        if(type === 'Accepter'){
            // if(debut === ''){ this.setState({ debutError: true }); err = err + 1 }
            // else{ this.setState({ debutError: false }) }
            // if(fin === ''){ this.setState({ finError: true }); err = err + 1 }
            // else{ this.setState({ finError: false }) }
        }else{
            // if(debut === ''){ this.setState({ debutError: true }); err = err + 1 }
            // else{ this.setState({ debutError: false }) }
            // if(fin === ''){ this.setState({ finError: true }); err = err + 1 }
            // else{ this.setState({ finError: false }) }
            if(motif.trim() === ''){ this.setState({ motifError: true }); err = err + 1 }
            else{ this.setState({ motifError: false }) }
        }
        return err
    }

    handleSubmit(status){
        this.setState({ debutError: false, finError: false, motifError: false })
        const { id } = this.props.match.params
        const { /*debut, fin, */motif } = this.state
        const certification = { id, /*debut, fin,*/ motif, status }
        const err = this.checkValidations(status)
        if(err === 0){
            this.save(certification)
        }
    }


    render() {
        const { /*debut, fin,*/ motif, motifError/*, debutError, finError*/ } = this.state
        // const { isMobile } = this.props

        return (
            <div className="wt-haslayout wt-main-section" style={{padding: 0}}>
					<div className="container">
						<div className="row justify-content-md-center">
							<div className="col-xs-12 col-sm-12 col-md-10 push-md-1 col-lg-8 push-lg-2">
								<div className="marchand-admin-card">

                                    {/* <div className="marchand-admin-card-section-header">
                                        <img src="https://www.computerhope.com/jargon/l/link.jpg" style={{width: 100, height: 100}} alt="logo" />
                                    </div>
                                    <div className="marchand-admin-card-section-body">
                                        <p><strong>Nom de la boutique:</strong> xxxxxxxxxxxxxxxxxxxxx</p>
                                        <p><strong>URL de la boutique:</strong> xxxxxxxxxxxxxxxxxxxxx</p>
                                        <p><strong>Adresse de la boutique:</strong> xxxxxxxxxxxxxxxxxxxxx</p>
                                        <p><strong>Numéro de téléphone de la boutique:</strong> xxxxxxxxxxxxxxxxxxxxx</p>
                                        <p><strong>Email de la boutique:</strong> xxxxxxxxxxxxxxxxxxxxx</p>
                                        <p><strong>Marchand vitepay:</strong> xxxxxxxxxxxxxxxxxxxxx</p>
                                    </div> */}

                                    {/* <div className="marchand-admin-card-section-header">
                                        <h4>Durée</h4>
                                    </div>
                                    <div className="marchand-admin-card-section-body" style={{width: '100%'}}>
                                        <div className="form-group form-group-half form-group-certif">
                                            <label>Début *</label>
                                            <input type="date" value={debut} onBlur={()=>this.checkForm('debut')} onChange={(e)=>{this.handleChangeDebut(e.target.value); this.checkForm('debut')}} className="form-control" placeholder="Début" />
                                            {debutError && <span className="error-span">*Ce champ est obligatoire.</span>}
                                        </div>
                                        <div className="form-group form-group-half form-group-certif">
                                            <label>Fin *</label>
                                            <input type="date" value={fin} onBlur={()=>this.checkForm('fin')} onChange={(e)=>{this.setState({fin: e.target.value}); this.checkForm('fin')}}  className="form-control" placeholder="Fin" />
                                            {finError && <span className="error-span">*Ce champ est obligatoire.</span>}
                                        </div>
                                    </div><br/> */}

                                    <div className="marchand-admin-card-section-header">
                                        <h4>Motif</h4>
                                    </div>
                                    <div className="marchand-admin-card-section-body">
                                        <div className="form-group">
                                            <label>Motif *</label>
                                            <textarea className="form-control" style={{height: 100}} placeholder="Motif" value={motif} onChange={(e)=>this.setState({motif: e.target.value})}></textarea>
                                            {motifError && <span className="error-span">*Ce champ est obligatoire s'il s'agit d'un rejet</span>}
                                        </div>
                                    </div>
                                            
                                    <div className="marchand-admin-card-action-btn">
                                        <Link to="/admin/certifications" type="btn" className="wt-btn marchand-admin-card-btn marchand-admin-card-btn-secondary">Annuler</Link>
                                        <button type="btn" className="wt-btn marchand-admin-card-btn marchand-admin-card-btn-danger" onClick={()=>this.handleSubmit('Rejeter')}>Rejeter</button>
                                        <button type="btn" className="wt-btn marchand-admin-card-btn marchand-admin-card-btn-primary" onClick={()=>this.handleSubmit('Accepter')}>Accepter</button>
                                    </div>

								</div>
							</div>
						</div>
					</div>
				</div>
        )
    }
}