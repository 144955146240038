import React from 'react';
import Page from '../../components/Page'
import DetailCategorie from '../../features/client/DetailCategorie'
import { AvisSvc, NotesSvc, ForfaitsSvc } from '../../services';

const DetailCategoriePage = (props) => {
    const avisService = new AvisSvc(props.session)
    const notesService = new NotesSvc(props.session)
    const forfaitsService = new ForfaitsSvc(props.session)
    return (
        <Page>
            <DetailCategorie {...props} avisController={avisService} noteController={notesService} forfaitController={forfaitsService} />
        </Page>
    )
}
export default DetailCategoriePage 