/**
 * PAGE APROPOS DANS LE DETAIL DU MARCHAND POUR LA PARTIE DE L'APPLICATION MISE A LA DISPOSITION DE LA GRANDE PUBLiQUE
 */
import React from "react"
import Itineraire from "./Itineraire"

const styles = {
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    iconContent: {
        display: 'flex',
        width: 58,
        height: 58,
        borderRadius: 80,
        // border: '1px solid #001e37',
        alignItems: 'center',
        justifyContent: 'center'
    },
    icon: {fontSize: 24, color: '#fff' },
    label: { fontSize: 12, color: '#001e37' },
    iconDesc: {fontSize: 20, color: '#001e37', marginRight: 8, opacity: 1 }
}
// const about = {
//     description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//     fixe: '(+223) 20 24 49 15/ 20 24 15 15',
//     mobile: '(+223) 79 45 23 65',
//     email: 'store@domaine.com',
//     facebook: '',
//     instagram: '',
//     linkedin: '',
//     snapchat: ''
// }

export default class About extends React.Component {
    constructor(props){
        super(props)
        this.state = { iteneraire: false, storeLat: '', storeLng: '', myLat: '', myLng: '' }
    }

    //Recuperer les forfait du marchand pour afficher ou pas le map
    getForfait(){
        const that = this
        if(this.props.marchandId){
            this.props.controller.getForfaitPermission(this.props.marchandId)
            .then(res => {
                if(res){
                    if (typeof (res.pror) === 'undefined') {
                        if(res.type){
                            if(res.type === 1){
                                navigator.geolocation.getCurrentPosition(function(position) {
                                    that.setState({ 
                                        iteneraire: true,
                                        storeLat: res.coordonnees.latitude,
                                        storeLng: res.coordonnees.longitude,
                                        myLat: position.coords.latitude,
                                        myLng: position.coords.longitude,
                                    })
                              
                                });
                            }
                        }
                    }
                }
            })
        }
    }

    componentDidMount(){
        this.getForfait()
    }

    render() {
        const { reseaux, apropos, email, telephone, iteneraire, name } = this.props
        const { storeLat, storeLng, myLat, myLng } = this.state
        return (
           <div>
               <div style={{display: "flex", flexDirection: 'column', padding: '0 16px 16px 16px', borderBottom: '1px solid #ddd'}}>
                    <h6 style={{marginBottom: 4}}> <i className="fas fa-info-circle" style={styles.iconDesc}></i></h6>
                    <p style={{fontSize: 12, opacity: 0.7, color: '#000', lineHeight: '14px', marginBottom: 8}}>{apropos}</p>
                    {/* <p style={{fontSize: 12, opacity: 0.8, color: '#000', lineHeight: '14px', marginBottom: 2}}>Numéros fixes: {about.fixe}</p> */}
                    <p style={{marginBottom: 8}}>
                        <i className="fas fa-phone-square" style={styles.iconDesc}></i><span style={{fontSize: 12, opacity: 0.8, color: '#000', lineHeight: '14px'}}>{telephone}</span>
                    </p>
                    <p style={{marginBottom: 2}}>
                        <i className="fas fa-envelope" style={styles.iconDesc}></i><span style={{fontSize: 12, opacity: 0.8, color: '#000', lineHeight: '14px'}}>{email}</span>
                    </p>
               </div>

                <div style={{padding: 16, borderBottom: '1px solid #ddd'}}>
                    <h6 style={{marginBottom: 4}}>Lien reseaux sociaux</h6>
                    <div style={{display: "flex", justifyContent: 'space-evenly'}}>
                        {reseaux.facebook !== '' &&
                        <a href={reseaux.facebook} target="_blank" rel="noreferrer">
                            <div style={{...styles.iconContent, backgroundColor: '#077ae9'}}> <i className="fa fa-facebook-f" style={styles.icon}></i> </div>
                        </a>}
                        {reseaux.facebook === '' && <div style={{...styles.iconContent, backgroundColor: '#077ae9'}}> <i className="fa fa-facebook-f" style={styles.icon}></i> </div>}
                        {reseaux.instagram !== '' &&
                        <a href={reseaux.instagram} target="_blank" rel="noreferrer">
                            <div style={{...styles.iconContent, backgroundColor: '#dc4d5a'}}> <i className="fa fa-instagram" style={styles.icon}></i> </div>
                        </a>}
                        {reseaux.instagram === '' && <div style={{...styles.iconContent, backgroundColor: '#dc4d5a'}}> <i className="fa fa-instagram" style={styles.icon}></i> </div>}
                        {reseaux.linkedin !== '' &&
                        <a href={reseaux.linkedin} target="_blank" rel="noreferrer">
                            <div style={{...styles.iconContent, backgroundColor: '#0274b3'}}> <i className="fa fa-linkedin-in" style={styles.icon}></i> </div>
                        </a>}
                        {reseaux.linkedin === '' && <div style={{...styles.iconContent, backgroundColor: '#0274b3'}}> <i className="fa fa-linkedin-in" style={styles.icon}></i> </div>}
                    </div>
                </div>

                {iteneraire && <div style={{padding: 16}}>
                    <h6 style={{marginBottom: 4}}>Itinéraire</h6>
                    <div>
                        <Itineraire name={name} myLat={myLat} myLng={myLng} sLat={storeLng} sLng={storeLat}  />
                    </div>
                </div>}
           </div>
        )
    }
}