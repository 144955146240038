/**
 * PAGE DE RECHERCHE POUR LA PARTIE DE L'APPLICATION MISE A LA DISPOSITION DE LA GRANDE PUBLUQUE
 *
 * @format
 */

 import React from "react";
 import ReactGA from "react-ga";
 import StoreCarousel from "../../../components/StoreCarousel";
 import WebStoreCard from "../../../components/WebStoreCard";
 import MobileStoreCard from "../../../components/MobileStoreCard";
 import { Link } from "react-router-dom";
 import categoriesJSON from "../../../lib/categories.json";
 import subCategoriesJSON from "../../../lib/subcategories.json";
 import Poper from "../../../components/Poper";
 import { moyenNote } from "../../../lib/functions";
 
 const listRegion = [
   "Bamako",
   "Kayes",
   "Koulikoro",
   "Sikasso",
   "Ségou",
   "Mopti",
   "Tombouctou",
   "Gao",
   "Kidal",
 ];
 
 const categories = [
   { id: 1, nom: "electronique" },
   { id: 2, nom: "fashion" },
   { id: 3, nom: "alimentation" },
 ];
 
 export default class Recherche extends React.Component {
   constructor(props) {
     super(props);
     this.state = {
       activeItemIndex: 0,
       firstTime: 0,
       isAllCleared: false,
       filterSearch: false,
       reload: false,
       certifies: [],
       resultats: [],
       tempResultats: [],
       tempCertifies: [],
       key: "",
       selection: [],
       currentName: "",
       filtered: [],
       souscategories: [],
       categories,
       stores: [],
       tempStores: [],
       region: "",
       plus: false,
     };
   }
 
   // Element ajouter au filtre
   handleAddToFiltered(name, type, val) {
     const data = this.state.filtered;
     let filterData = [];
 
     if (
       type === "prix-min" ||
       type === "prix-max" ||
       type === "region" ||
       type === "service" ||
       type === "livraison"
     ) {
       if (val !== "") {
         const temp = data.filter((d) => d.type !== type);
         filterData = [...temp, { name, type, val }];
       } else {
         const temp = data.filter((d) => d.type !== type);
         filterData = [...temp];
       }
     } else {
       const chk = data.find((f) => f.name === name);
       if (chk) {
         const f = data.filter((d) => d.name !== name);
         filterData = f;
       } else {
         filterData = [...this.state.filtered, { name, type, val }];
       }
     }
 
     this.filter(filterData);
   }
 
   // Effacer les filtres
   clear() {
     const cat = {};
     this.state.categories.map((c) => (cat[c.nom] = ""));
     this.setState({ ...cat, stores: this.state.tempStores });
   }
 
   //Filter le tableau de recherche de type de filtre
   filter(filtered) {
     const { tempResultats, tempCertifies } = this.state;
     let resultats = tempResultats;
     let certifies = tempCertifies;
 
     for (let i = 0; i < filtered.length; i++) {
       if (filtered[i]["type"] === "sub") {
         resultats = resultats.filter((t) =>
           t.tags.sousGategories.includes(filtered[i]["name"])
         );
         certifies = certifies.filter((t) =>
           t.tags.sousGategories.includes(filtered[i]["name"])
         );
       } else {
         if (filtered[i]["type"] === "region") {
           resultats = resultats.filter((t) => t.region === filtered[i]["name"]);
           certifies = certifies.filter((t) => t.region === filtered[i]["name"]);
         } else {
           if (filtered[i]["type"] === "service") {
             if (filtered[i]["val"] === "true") {
               resultats = resultats.filter((t) => t.services.service === true);
               certifies = certifies.filter((t) => t.services.service === true);
             } else {
               resultats = resultats.filter((t) => t.services.service === false);
               resultats = resultats.filter((t) => t.services.service === false);
             }
           } else {
             if (filtered[i]["type"] === "livraison") {
               resultats = resultats.filter(
                 (t) => t.services.livraison === filtered[i]["val"]
               );
               certifies = certifies.filter(
                 (t) => t.services.livraison === filtered[i]["val"]
               );
             } else {
               if (filtered[i]["type"] === "classe") {
                 if (filtered[i]["name"] === "Marchands certifiés") {
                   if (filtered[i]["val"] === "Marchands certifiés") {
                     resultats = resultats.filter((t) => t.certifie === true);
                     certifies = certifies.filter((t) => t.certifie === true);
                   } else {
                     resultats = resultats.filter((t) => t.certifie === false);
                     certifies = certifies.filter((t) => t.certifie === false);
                   }
                 } else {
                   if (filtered[i]["val"] === "Marchands vitepay") {
                     resultats = resultats.filter((t) => t.vitepay === true);
                     certifies = certifies.filter((t) => t.vitepay === true);
                   } else {
                     resultats = resultats.filter((t) => t.vitepay === false);
                     certifies = certifies.filter((t) => t.vitepay === false);
                   }
                 }
               } else {
                 if (filtered[i]["type"] === "prix-min") {
                   resultats = resultats.filter(
                     (t) => t.tags.prix.minimum >= parseInt(filtered[i]["val"])
                   );
                   certifies = certifies.filter(
                     (t) => t.tags.prix.minimum >= parseInt(filtered[i]["val"])
                   );
                 } else {
                   if (filtered[i]["type"] === "prix-max") {
                     resultats = resultats.filter(
                       (t) => t.tags.prix.maximum <= parseInt(filtered[i]["val"])
                     );
                     certifies = certifies.filter(
                       (t) => t.tags.prix.maximum <= parseInt(filtered[i]["val"])
                     );
                   }
                 }
               }
             }
           }
         }
       }
     }
 
     if (filtered.length > 0) {
       this.setState({ filtered, resultats, certifies });
     } else {
       this.setState({
         filtered,
         resultats: tempResultats,
         certifies: tempCertifies,
         reload: false,
       });
     }
   }
 
   //Lorsqu'une categorie est selectionnee
   selectCat(cat) {
     const { selection, tempStores } = this.state;
     let slt = selection;
     if (this.state[cat]) {
       slt = selection.filter((s) => s !== cat);
       if (slt[0]) {
         const stores = this.filter(slt);
         this.setState({ [cat]: "", selection: slt, stores });
       } else {
         this.setState({ [cat]: "", selection: slt, stores: tempStores });
       }
     } else {
       slt.push(cat);
       const stores = this.filter(slt);
       this.setState({ [cat]: "active", selection: slt, stores });
     }
   }
 
   // Effacer tous les filtres
   handleClearAll() {
     this.setState({ reload: false, filtered: [], isAllCleared: true });
     // this.setState({ filtered: [{name: searchKey, type: 'sub', val: ''}], resultats: this.state.tempResultats, certifies: this.state.tempCertifies})
     if (this.props.location.state.type === 2) {
       // si on est sur tous les marchands
       this.setState({
         filtered: [],
         resultats: this.state.tempResultats,
         certifies: this.state.tempCertifies,
       });
     } else {
       // si on est sur recherche ==> request pour basculer sur tous les marchands
       if (this.state.firstTime === 0) {
         this.query("", "", 2, 1);
       } else {
         this.setState({
           filtered: [],
           resultats: this.state.tempResultats,
           certifies: this.state.tempCertifies,
         });
       }
     }
   }
 
   //Requette pour rechercher les marchand
   query(cat, label, type = 0, first = 0) {
     if (type !== 2) {
       // si c'est une recherche par sous-categorie ou par nom de la boutique
       this.props.marchandController
         .recherches({ libelle: cat, type })
         .then((res) => {
           if (typeof res.pror === "undefined") {
             const souscategories = res[0]
               ? categoriesJSON.find((c) =>
                   c.children.find((cc) => cc.name === cat)
                 )
               : null;
 
             // console.log("res de chercher==> ", souscategories);
             // console.log("res all==> ", res);
             // console.log("donnée a chercher==> ", cat, " type==> ", type);
 
             const cert = res.filter((r) => r.certifie === true);
             const rslt = res.filter((r) => r.certifie !== true);
 
             const certifies = cert.sort(
               (a, b) => moyenNote(a.avis) < moyenNote(b.avis)
             );
             const resultats = rslt.sort(
               (a, b) => moyenNote(a.avis) < moyenNote(b.avis)
             );
             this.setState({
               reload: true,
               resultats,
               certifies,
               tempCertifies: certifies,
               tempResultats: resultats,
               plus: res.length >= 50 ? true : false,
               key: cat,
               filtered: [{ name: label, type: "sub", val: "" }],
               souscategories: souscategories ? souscategories.children : [],
             });
           } else {
           }
         });
     } else {
       // si c'est pour afficher tous les marchands
       this.props.marchandController.fetch().then((res) => {
         if (typeof res.pror === "undefined") {
           const souscategories = subCategoriesJSON;
 
           const cert = res.filter((r) => r.certifie === true);
           const rslt = res.filter((r) => r.certifie !== true);
 
           const certifies = cert.sort(
             (a, b) => moyenNote(a.avis) < moyenNote(b.avis)
           );
           const resultats = rslt.sort(
             (a, b) => moyenNote(a.avis) < moyenNote(b.avis)
           );
           this.setState({
             firstTime: first,
             reload: false,
             resultats,
             certifies,
             tempCertifies: certifies,
             tempResultats: resultats,
             plus: res.length >= 50 ? true : false,
             key: cat,
             filtered: [],
             souscategories: souscategories ? souscategories : [],
           });
         } else {
         }
       });
     }
   }
 
   //Requette pour rechercher les marchand cas de skip
   queryPlus(cat, label, type = 0, skip) {
     if (type !== 2) {
       // si c'est une recherche par sous-categorie ou par mon de la boutique
       this.props.marchandController
         .recherches({ libelle: cat, type }, skip)
         .then((res) => {
           if (typeof res.pror === "undefined") {
             const cert = res.filter((r) => r.certifie === true);
             const rslt = res.filter((r) => r.certifie !== true);
 
             const c = cert.sort(
               (a, b) => moyenNote(a.avis) < moyenNote(b.avis)
             );
             const r = rslt.sort(
               (a, b) => moyenNote(a.avis) < moyenNote(b.avis)
             );
 
             const resultats = [...this.state.resultats, ...r];
             const certifies = [...this.state.certifies, ...c];
             const tempResultats = [...this.state.tempResultats, ...r];
             const tempCertifies = [...this.state.tempCertifies, ...c];
 
             this.setState({
               reload: true,
               resultats,
               certifies,
               tempCertifies,
               tempResultats,
               plus: res.length >= 50 ? true : false,
             });
           } else {
           }
         });
     } else {
       // si c'est pour afficher tous les marchands
       this.props.marchandController.fetch().then((res) => {
         if (typeof res.pror === "undefined") {
           const cert = res.filter((r) => r.certifie === true);
           const rslt = res.filter((r) => r.certifie !== true);
 
           const c = cert.sort((a, b) => moyenNote(a.avis) < moyenNote(b.avis));
           const r = rslt.sort((a, b) => moyenNote(a.avis) < moyenNote(b.avis));
 
           const resultats = [...this.state.resultats, ...r];
           const certifies = [...this.state.certifies, ...c];
           const tempResultats = [...this.state.tempResultats, ...r];
           const tempCertifies = [...this.state.tempCertifies, ...c];
           this.setState({
             reload: false,
             resultats,
             certifies,
             tempCertifies,
             tempResultats,
             plus: res.length >= 50 ? true : false,
           });
         } else {
         }
       });
     }
   }
 
   componentDidUpdate() {
    //  console.log(
    //    "key: ",
    //    this.props.location.state.key,
    //    "type: ",
    //    this.props.location.state.type,
    //    "value: ",
    //    this.props.location.state.value,
    //  );
     if (
       this.props.location.state.key !== this.state.key &&
       this.state.reload === true
     ) {
       this.query(this.props.location.state.key, this.props.location.state.value, this.props.location.state.type);
     } else {
       // console.log("this.props.location.state.key", this.props.location.state.key)
       // console.log(" this.state.key",  this.state.key)
       // if(this.state.reload === false && (this.props.location.state.key !== this.state.key) && this.state.isAllCleared){
       //     console.log("this.state.reload", this.state.reload)
       //     if(this.state.isAllCleared){
       //         this.setState({ key: this.props.location.state.key, isAllCleared: false })
       //         console.log("key ==>", this.props.location.state.key)
 
       //     }else{
       //         if(this.props.location.state.key !== this.state.key){
       //             this.setState({ key: this.props.location.state.key, isAllCleared: false })
       //             // this.handleAddToFiltered(this.props.location.state.key, 'sub', '')
       //         }
       //     }
       // }else{
       //     // this.handleAddToFiltered(this.props.location.state.key, 'sub', '')
       // }
       if (this.state.isAllCleared) {
         this.setState({ key: "", isAllCleared: false, filterSearch: true });
         // console.log("key ==>", this.props.location.state.key)
         this.handleAddToFiltered(this.props.location.state.key, "sub", "");
       } else {
         // console.log("key 23==>", this.state.filterSearch, this.props.location.state.key)
         if (this.state.filterSearch) {
           this.setState({ filterSearch: false });
           this.handleAddToFiltered(this.props.location.state.key, "sub", "");
           // console.log("key 2==>", this.props.location.state.key)
         }
       }
     }
   }
 
   componentDidMount() {
     this.query(this.props.location.state.key, this.props.location.state.value, this.props.location.state.type);
   }
   //Google analytic lorsque le detail de marchand est visualise
   gaView() {
     ReactGA.event({
       category: "Detail marchand",
       action: `Click sur le marchand pour plus de detail`,
     });
   }
 
   render() {
     const { isMobile } = this.props;
     const searchKey = this.props.location.state.key;
     // const type = this.props.location.state.type
     const {
       certifies,
       resultats,
       /*tempCertifies, tempResultats,*/ souscategories,
       filtered,
       reload /*, plus, currentName*/,
     } = this.state;
 
     return (
       <div className="wt-haslayout">
         <div className="container">
           <div className="row">
             <div id="wt-twocolumns" className="wt-twocolumns wt-haslayout">
               {/* <div className="wt-userlistingtitle">
                                 <span>Resultat de la recherche <em>"{this.props.location.state.key}"</em></span>
                             </div> */}
               <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-4 float-left">
                 <div
                   className="d-sm-block d-md-none"
                   style={{ marginBottom: 30 }}
                 >
                   <Poper
                     trigger={
                       <button
                         className="wt-btn ayn-btn-next"
                         style={{ lineHeight: "38px", padding: "0 24px" }}
                       >
                         <i className="fas fa-filter"></i> Cliquer pour filtrer
                       </button>
                     }
                     containerStyle={{
                       backgroundColor: "#f7f7f7",
                       right: -118,
                       border: "1px solid #ddd",
                     }}
                   >
                     <aside id="wt-sidebar" className="wt-sidebar">
                       <div className="wt-widget wt-startsearch">
                         <div className="wt-widgettitle">
                           <h2>Régions</h2>
                         </div>
                         <div className="wt-widgetcontent">
                           <form className="wt-formtheme wt-formsearch">
                             <fieldset>
                               <div className="form-group">
                                 <span className="wt-select">
                                   <select
                                     value={
                                       filtered.find((f) => f.type === "region")
                                         ? filtered.find(
                                             (f) => f.type === "region"
                                           )["val"]
                                         : ""
                                     }
                                     onChange={(e) => {
                                       this.handleAddToFiltered(
                                         e.target.value,
                                         "region",
                                         e.target.value
                                       );
                                     }}
                                   >
                                     <option value="">Région </option>
                                     {listRegion.map((r, i) => (
                                       <option value={r} key={i}>
                                         {r}
                                       </option>
                                     ))}
                                   </select>
                                 </span>
                               </div>
                             </fieldset>
                           </form>
                         </div>
                       </div>
                       <div className="wt-widget wt-effectiveholder">
                         <div className="wt-widgettitle">
                           <h2>Type D'article</h2>
                         </div>
                         <div className="wt-widgetcontent">
                           <form className="wt-formtheme wt-formsearch">
                             <fieldset>
                               <ul className="wt-filtertag ayn-cat-ul form-subcat-ul">
                                 {souscategories.map((c, i) => {
                                   return (
                                     <li className="ayn-cat-li" key={i}>
                                       <span
                                         onClick={() => {
                                           // if(searchKey !== c.name){ this.handleAddToFiltered(c.name, 'sub', '') }
                                           this.handleAddToFiltered(
                                             c.name,
                                             "sub",
                                             ""
                                           );
                                         }}
                                         className={
                                           searchKey === c.name && reload
                                             ? "cat-chip active"
                                             : filtered.find(
                                                 (f) => f.name === c.name
                                               )
                                             ? "cat-chip active"
                                             : "cat-chip"
                                         }
                                       >
                                         <span>{c.name}</span>
                                         <i className="fa fa-times close cat-closer"></i>
                                       </span>
                                     </li>
                                   );
                                 })}
                               </ul>
                             </fieldset>
                           </form>
                         </div>
                       </div>
                       <div className="wt-widget wt-effectiveholder">
                         <div className="wt-widgettitle">
                           <h2>Fourchette de prix</h2>
                         </div>
                         <div className="wt-widgetcontent">
                           <form className="wt-formtheme wt-formsearch">
                             <fieldset>
                               <div
                                 className="form-group"
                                 style={{
                                   display: "flex",
                                   justifyContent: "space-between",
                                   alignItems: "center",
                                   margin: 0,
                                 }}
                               >
                                 <label>Min:</label>
                                 <span
                                   className="wt-select filter-select"
                                   style={{ width: 150 }}
                                 >
                                   <select
                                     style={{
                                       height: 40,
                                       margin: 0,
                                       marginBottom: 8,
                                     }}
                                     value={
                                       filtered.find(
                                         (f) => f.type === "prix-min"
                                       )
                                         ? filtered.find(
                                             (f) => f.type === "prix-min"
                                           )["val"]
                                         : ""
                                     }
                                     onChange={(e) => {
                                       this.handleAddToFiltered(
                                         `${e.target.value} F`,
                                         "prix-min",
                                         e.target.value
                                       );
                                     }}
                                   >
                                     <option value="">Min</option>
                                     <option value={100}>100</option>
                                     <option value={1000}>1.000</option>
                                     <option value={25000}>25.000</option>
                                   </select>
                                 </span>
                               </div>
                               <div
                                 className="form-group"
                                 style={{
                                   display: "flex",
                                   justifyContent: "space-between",
                                   alignItems: "center",
                                   margin: 0,
                                 }}
                               >
                                 <label>Max:</label>
                                 <span
                                   className="wt-select filter-select"
                                   style={{ width: 150 }}
                                 >
                                   <select
                                     style={{ height: 40, margin: 0 }}
                                     value={
                                       filtered.find(
                                         (f) => f.type === "prix-max"
                                       )
                                         ? filtered.find(
                                             (f) => f.type === "prix-max"
                                           )["val"]
                                         : ""
                                     }
                                     onChange={(e) => {
                                       this.handleAddToFiltered(
                                         `${e.target.value} F`,
                                         "prix-max",
                                         e.target.value
                                       );
                                     }}
                                   >
                                     <option value="">Max</option>
                                     <option value={25000}>25.000</option>
                                     <option value={30000}>30.000</option>
                                     <option value={100000}>100.000</option>
                                   </select>
                                 </span>
                               </div>
                             </fieldset>
                           </form>
                         </div>
                       </div>
                       <div className="wt-widget wt-effectiveholder">
                         <div className="wt-widgettitle">
                           <h2>Services</h2>
                         </div>
                         <div className="wt-widgetcontent">
                           <form className="wt-formtheme wt-formsearch">
                             <fieldset>
                               <div
                                 className="form-group"
                                 style={{ flexWrap: "wrap" }}
                               >
                                 <label style={{ width: 200 }}>Livraison:</label>
                                 <div className="wt-radioboxholder form-radio-content">
                                   <div className="form-radio">
                                     <span className="form-radio-label">
                                       Non disponible
                                     </span>
                                     <span className="wt-radio">
                                       <input
                                         id="liv-nondisponible"
                                         type="radio"
                                         name="livraison"
                                         value="Non disponible"
                                         onChange={(e) =>
                                           this.handleAddToFiltered(
                                             "Livraison non disponible",
                                             "livraison",
                                             e.target.value
                                           )
                                         }
                                         checked={filtered.find(
                                           (f) =>
                                             f.name ===
                                             "Livraison non disponible"
                                         )}
                                       />
                                       <label htmlFor="liv-nondisponible"></label>
                                     </span>
                                   </div>
                                   <div className="form-radio">
                                     <span className="form-radio-label">
                                       Gratuite
                                     </span>
                                     <span className="wt-radio">
                                       <input
                                         id="liv-gratuite"
                                         type="radio"
                                         name="livraison"
                                         value="Gratuite"
                                         onChange={(e) =>
                                           this.handleAddToFiltered(
                                             "Livraison gratuite",
                                             "livraison",
                                             e.target.value
                                           )
                                         }
                                         checked={filtered.find(
                                           (f) => f.name === "Livraison gratuite"
                                         )}
                                       />
                                       <label htmlFor="liv-gratuite"></label>
                                     </span>
                                   </div>
                                   <div className="form-radio">
                                     <span className="form-radio-label">
                                       Payante
                                     </span>
                                     <span className="wt-radio">
                                       <input
                                         id="liv-payant"
                                         type="radio"
                                         name="livraison"
                                         value="Payante"
                                         onChange={(e) =>
                                           this.handleAddToFiltered(
                                             "Livraison payante",
                                             "livraison",
                                             e.target.value
                                           )
                                         }
                                         checked={filtered.find(
                                           (f) => f.name === "Livraison payante"
                                         )}
                                       />
                                       <label htmlFor="liv-payant"></label>
                                     </span>
                                   </div>
                                 </div>
                               </div>
                               <div
                                 className="form-group form-radio-container"
                                 style={{ flexWrap: "wrap" }}
                               >
                                 <label style={{ width: 200, height: 45 }}>
                                   Services après vente:
                                 </label>
                                 <div className="wt-radioboxholder form-radio-content">
                                   <div className="form-radio">
                                     <span className="form-radio-label">
                                       Oui
                                     </span>
                                     <span className="wt-radio">
                                       <input
                                         id="sav-oui"
                                         type="radio"
                                         name="serviceApresVente"
                                         value={true}
                                         onChange={(e) =>
                                           this.handleAddToFiltered(
                                             "Service après vente: Oui",
                                             "service",
                                             e.target.value
                                           )
                                         }
                                         checked={filtered.find(
                                           (f) =>
                                             f.name ===
                                             "Service après vente: Oui"
                                         )}
                                       />
                                       <label htmlFor="sav-oui"></label>
                                     </span>
                                   </div>
                                   <div className="form-radio">
                                     <span className="form-radio-label">
                                       Non
                                     </span>
                                     <span className="wt-radio">
                                       <input
                                         id="sav-non"
                                         type="radio"
                                         name="serviceApresVente"
                                         value={false}
                                         onChange={(e) =>
                                           this.handleAddToFiltered(
                                             "Service après vente: Non",
                                             "service",
                                             e.target.value
                                           )
                                         }
                                         checked={filtered.find(
                                           (f) =>
                                             f.name ===
                                             "Service après vente: Non"
                                         )}
                                       />
                                       <label htmlFor="sav-non"></label>
                                     </span>
                                   </div>
                                 </div>
                               </div>
                             </fieldset>
                           </form>
                         </div>
                       </div>
                       <div className="wt-widget wt-effectiveholder">
                         <div className="wt-widgettitle">
                           <h2>Classe des marchands</h2>
                         </div>
                         <div className="wt-widgetcontent">
                           <form className="wt-formtheme wt-formsearch">
                             <fieldset>
                               <div
                                 className="wt-checkboxholder wt-verticalscrollbar"
                                 style={{ overflow: "auto" }}
                               >
                                 <span className="wt-checkbox">
                                   <input
                                     id="wt-certifie"
                                     type="checkbox"
                                     value="Marchands certifiés"
                                     onChange={(e) =>
                                       this.handleAddToFiltered(
                                         "Marchands certifiés",
                                         "classe",
                                         e.target.value
                                       )
                                     }
                                     checked={filtered.find(
                                       (f) => f.name === "Marchands certifiés"
                                     )}
                                   />
                                   <label htmlFor="wt-certifie">
                                     Marchands certifiés
                                   </label>
                                 </span>
                                 <span className="wt-checkbox">
                                   <input
                                     id="wt-vitepay"
                                     type="checkbox"
                                     value="Marchands vitepay"
                                     onChange={(e) =>
                                       this.handleAddToFiltered(
                                         "Marchands vitepay",
                                         "classe",
                                         e.target.value
                                       )
                                     }
                                     checked={filtered.find(
                                       (f) => f.name === "Marchands vitepay"
                                     )}
                                   />
                                   <label htmlFor="wt-vitepay">
                                     Marchands vitepay
                                   </label>
                                 </span>
                               </div>
                             </fieldset>
                           </form>
                         </div>
                       </div>
                     </aside>
                   </Poper>
                 </div>
                 <aside
                   id="wt-sidebar"
                   className="wt-sidebar d-none d-sm-none d-md-block"
                 >
                   <div className="wt-widget wt-startsearch">
                     <div className="wt-widgettitle">
                       <h2>Régions</h2>
                     </div>
                     <div className="wt-widgetcontent">
                       <form className="wt-formtheme wt-formsearch">
                         <fieldset>
                           <div className="form-group">
                             <span className="wt-select">
                               <select
                                 value={
                                   filtered.find((f) => f.type === "region")
                                     ? filtered.find((f) => f.type === "region")[
                                         "val"
                                       ]
                                     : ""
                                 }
                                 onChange={(e) => {
                                   this.handleAddToFiltered(
                                     e.target.value,
                                     "region",
                                     e.target.value
                                   );
                                 }}
                               >
                                 <option value="">Région </option>
                                 {listRegion.map((r, i) => (
                                   <option value={r} key={i}>
                                     {r}
                                   </option>
                                 ))}
                               </select>
                             </span>
                           </div>
                         </fieldset>
                       </form>
                     </div>
                   </div>
                   <div className="wt-widget wt-effectiveholder">
                     <div className="wt-widgettitle">
                       <h2>Type d'article</h2>
                     </div>
                     <div className="wt-widgetcontent">
                       <form className="wt-formtheme wt-formsearch">
                         <fieldset>
                           <ul className="wt-filtertag ayn-cat-ul form-subcat-ul">
                             {souscategories.map((c, i) => {
                               return (
                                 <li className="ayn-cat-li" key={i}>
                                   <span
                                     onClick={() => {
                                       // if(searchKey !== c.name){ this.handleAddToFiltered(c.name, 'sub', '') }
                                       this.handleAddToFiltered(
                                         c.name,
                                         "sub",
                                         ""
                                       );
                                     }}
                                     className={
                                       searchKey === c.name && reload
                                         ? "cat-chip active"
                                         : filtered.find(
                                             (f) => f.name === c.name
                                           )
                                         ? "cat-chip active"
                                         : "cat-chip"
                                     }
                                   >
                                     <span>{c.name}</span>
                                     <i className="fa fa-times close cat-closer"></i>
                                   </span>
                                 </li>
                               );
                             })}
                           </ul>
                         </fieldset>
                       </form>
                     </div>
                   </div>
                   <div className="wt-widget wt-effectiveholder">
                     <div className="wt-widgettitle">
                       <h2>Fourchette de prix</h2>
                     </div>
                     <div className="wt-widgetcontent">
                       <form className="wt-formtheme wt-formsearch">
                         <fieldset>
                           <div
                             className="form-group"
                             style={{
                               display: "flex",
                               justifyContent: "space-between",
                               alignItems: "center",
                               margin: 0,
                             }}
                           >
                             <label>Min:</label>
                             <span
                               className="wt-select filter-select"
                               style={{ width: 150 }}
                             >
                               <select
                                 style={{
                                   height: 40,
                                   margin: 0,
                                   marginBottom: 8,
                                 }}
                                 value={
                                   filtered.find((f) => f.type === "prix-min")
                                     ? filtered.find(
                                         (f) => f.type === "prix-min"
                                       )["val"]
                                     : ""
                                 }
                                 onChange={(e) => {
                                   this.handleAddToFiltered(
                                     `${e.target.value} F`,
                                     "prix-min",
                                     e.target.value
                                   );
                                 }}
                               >
                                 <option value="">Min</option>
                                 <option value={100}>100</option>
                                 <option value={1000}>1.000</option>
                                 <option value={25000}>25.000</option>
                               </select>
                             </span>
                           </div>
                           <div
                             className="form-group"
                             style={{
                               display: "flex",
                               justifyContent: "space-between",
                               alignItems: "center",
                               margin: 0,
                             }}
                           >
                             <label>Max:</label>
                             <span
                               className="wt-select filter-select"
                               style={{ width: 150 }}
                             >
                               <select
                                 style={{ height: 40, margin: 0 }}
                                 value={
                                   filtered.find((f) => f.type === "prix-max")
                                     ? filtered.find(
                                         (f) => f.type === "prix-max"
                                       )["val"]
                                     : ""
                                 }
                                 onChange={(e) => {
                                   this.handleAddToFiltered(
                                     `${e.target.value} F`,
                                     "prix-max",
                                     e.target.value
                                   );
                                 }}
                               >
                                 <option value="">Max</option>
                                 <option value={25000}>25.000</option>
                                 <option value={30000}>30.000</option>
                                 <option value={100000}>100.000</option>
                               </select>
                             </span>
                           </div>
                         </fieldset>
                       </form>
                     </div>
                   </div>
                   <div className="wt-widget wt-effectiveholder">
                     <div className="wt-widgettitle">
                       <h2>Services</h2>
                     </div>
                     <div className="wt-widgetcontent">
                       <form className="wt-formtheme wt-formsearch">
                         <fieldset>
                           <div
                             className="form-group"
                             style={{ flexWrap: "wrap" }}
                           >
                             <label style={{ width: 200 }}>Livraison:</label>
                             <div className="wt-radioboxholder form-radio-content">
                               <div className="form-radio">
                                 <span className="form-radio-label">
                                   Non disponible
                                 </span>
                                 <span className="wt-radio">
                                   <input
                                     id="liv-nondisponible"
                                     type="radio"
                                     name="livraison"
                                     value="Non disponible"
                                     onChange={(e) =>
                                       this.handleAddToFiltered(
                                         "Livraison non disponible",
                                         "livraison",
                                         e.target.value
                                       )
                                     }
                                     checked={filtered.find(
                                       (f) =>
                                         f.name === "Livraison non disponible"
                                     )}
                                   />
                                   <label htmlFor="liv-nondisponible"></label>
                                 </span>
                               </div>
                               <div className="form-radio">
                                 <span className="form-radio-label">
                                   Gratuite
                                 </span>
                                 <span className="wt-radio">
                                   <input
                                     id="liv-gratuite"
                                     type="radio"
                                     name="livraison"
                                     value="Gratuite"
                                     onChange={(e) =>
                                       this.handleAddToFiltered(
                                         "Livraison gratuite",
                                         "livraison",
                                         e.target.value
                                       )
                                     }
                                     checked={filtered.find(
                                       (f) => f.name === "Livraison gratuite"
                                     )}
                                   />
                                   <label htmlFor="liv-gratuite"></label>
                                 </span>
                               </div>
                               <div className="form-radio">
                                 <span className="form-radio-label">
                                   Payante
                                 </span>
                                 <span className="wt-radio">
                                   <input
                                     id="liv-payant"
                                     type="radio"
                                     name="livraison"
                                     value="Payante"
                                     onChange={(e) =>
                                       this.handleAddToFiltered(
                                         "Livraison payante",
                                         "livraison",
                                         e.target.value
                                       )
                                     }
                                     checked={filtered.find(
                                       (f) => f.name === "Livraison payante"
                                     )}
                                   />
                                   <label htmlFor="liv-payant"></label>
                                 </span>
                               </div>
                             </div>
                           </div>
                           <div
                             className="form-group form-radio-container"
                             style={{ flexWrap: "wrap" }}
                           >
                             <label style={{ width: 200, height: 45 }}>
                               Services après vente:
                             </label>
                             <div className="wt-radioboxholder form-radio-content">
                               <div className="form-radio">
                                 <span className="form-radio-label">Oui</span>
                                 <span className="wt-radio">
                                   <input
                                     id="sav-oui"
                                     type="radio"
                                     name="serviceApresVente"
                                     value={true}
                                     onChange={(e) =>
                                       this.handleAddToFiltered(
                                         "Service après vente: Oui",
                                         "service",
                                         e.target.value
                                       )
                                     }
                                     checked={filtered.find(
                                       (f) =>
                                         f.name === "Service après vente: Oui"
                                     )}
                                   />
                                   <label htmlFor="sav-oui"></label>
                                 </span>
                               </div>
                               <div className="form-radio">
                                 <span className="form-radio-label">Non</span>
                                 <span className="wt-radio">
                                   <input
                                     id="sav-non"
                                     type="radio"
                                     name="serviceApresVente"
                                     value={false}
                                     onChange={(e) =>
                                       this.handleAddToFiltered(
                                         "Service après vente: Non",
                                         "service",
                                         e.target.value
                                       )
                                     }
                                     checked={filtered.find(
                                       (f) =>
                                         f.name === "Service après vente: Non"
                                     )}
                                   />
                                   <label htmlFor="sav-non"></label>
                                 </span>
                               </div>
                             </div>
                           </div>
                         </fieldset>
                       </form>
                     </div>
                   </div>
                   <div className="wt-widget wt-effectiveholder">
                     <div className="wt-widgettitle">
                       <h2>Classe des marchands</h2>
                     </div>
                     <div className="wt-widgetcontent">
                       <form className="wt-formtheme wt-formsearch">
                         <fieldset>
                           <div
                             className="wt-checkboxholder wt-verticalscrollbar"
                             style={{ overflow: "auto" }}
                           >
                             <span className="wt-checkbox">
                               <input
                                 id="wt-certifie"
                                 type="checkbox"
                                 value="Marchands certifiés"
                                 onChange={(e) =>
                                   this.handleAddToFiltered(
                                     "Marchands certifiés",
                                     "classe",
                                     e.target.value
                                   )
                                 }
                                 checked={filtered.find(
                                   (f) => f.name === "Marchands certifiés"
                                 )}
                               />
                               <label htmlFor="wt-certifie">
                                 Marchands certifiés
                               </label>
                             </span>
                             <span className="wt-checkbox">
                               <input
                                 id="wt-vitepay"
                                 type="checkbox"
                                 value="Marchands vitepay"
                                 onChange={(e) =>
                                   this.handleAddToFiltered(
                                     "Marchands vitepay",
                                     "classe",
                                     e.target.value
                                   )
                                 }
                                 checked={filtered.find(
                                   (f) => f.name === "Marchands vitepay"
                                 )}
                               />
                               <label htmlFor="wt-vitepay">
                                 Marchands vitepay
                               </label>
                             </span>
                           </div>
                         </fieldset>
                       </form>
                     </div>
                   </div>
                 </aside>
               </div>
               <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-8 float-left">
                 <div className="wt-filterholder">
                   <ul
                     className="wt-filtertag ayn-cat-ul"
                     style={{ justifyContent: "start" }}
                   >
                     <li
                       className="wt-filtertagclear ayn-cat-li"
                       style={{ whiteSpace: "nowrap" }}
                     >
                       <span
                         className="chip-clear-all"
                         onClick={() => this.handleClearAll()}
                       >
                         <i className="fa fa-times"></i>{" "}
                         <span>Effacer les filtres</span>
                       </span>
                     </li>
                     {filtered.map((c, i) => {
                       return (
                         <li
                           className="ayn-cat-li"
                           key={i}
                           style={{ whiteSpace: "nowrap" }}
                         >
                           <span className="cat-chip">
                             <span>{c.name}</span>
                           </span>
                         </li>
                       );
                     })}
                   </ul>
                 </div>
                 {/* {certifies.length > 0 && <h3 className="ayn-title ayn-categorie-title">Marchands certifié</h3>} */}
                 <h3
                   className="ayn-title ayn-categorie-title"
                   style={isMobile ? { fontSize: 18 } : {}}
                 >
                   Marchands recommandés
                 </h3>
                 <StoreCarousel
                   isMobile={isMobile}
                   stores={certifies}
                   search={true}
                   onView={() => this.gaView()}
                 />
 
                 <div className="wt-companysinfoholder">
                   <h3
                     className="ayn-title ayn-categorie-title"
                     style={isMobile ? { fontSize: 18 } : {}}
                   >
                     Résultat de la recherche{" "}
                   </h3>
                   <div className="row" style={{ margin: 0 }}>
                     {!isMobile &&
                       resultats.map((c, i) => {
                         return (
                           <div
                             className="ccol-12 col-sm-6 col-md-6 col-lg-4"
                             key={i}
                           >
                             <Link
                               to={{
                                 pathname: "/front/categories/details",
                                 state: { store: c },
                               }}
                               onClick={() => this.gaView()}
                             >
                               <WebStoreCard
                                 name={c.boutique}
                                 url={c.url}
                                 address={c.adresse}
                                 isVerified={c.certifie}
                                 vitepay={c.vitepay}
                                 rating={c.avis}
                                 cover={c.couverture}
                                 logo={c.logo}
                               />
                             </Link>
                           </div>
                         );
                       })}
 
                     {isMobile &&
                       resultats.map((c, i) => {
                         return (
                           <div
                             className="col-12 col-sm-12 col-md-6 col-lg-4"
                             key={i}
                           >
                             <Link
                               to={{
                                 pathname: "/front/categories/details",
                                 state: { store: c },
                               }}
                               onClick={() => this.gaView()}
                             >
                               <MobileStoreCard
                                 name={c.boutique}
                                 url={c.url}
                                 address={c.adresse}
                                 isVerified={c.certifie}
                                 vitepay={c.vitepay}
                                 rating={c.avis}
                                 cover={c.couverture}
                                 logo={c.logo}
                               />
                             </Link>
                           </div>
                         );
                       })}
                   </div>
                   {/* {plus && this.props.location.state.type !== 2 &&
                                     <div style={{display: "flex", justifyContent: 'center'}} onClick={()=>this.queryPlus(searchKey, type, (tempCertifies.length + tempResultats.length +1))} >
                                         <button className="wt-btn ayn-skip-btn">Plus de marchands</button>
                                     </div>} */}
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     );
   }
 }