/**
 * PAGE D'ACCUEIL POUR LA PARTIE DE L'APPLICATION MISE A LA DISPOSITION DE LA GRANDE PUBLUQUE
 */
import React from "react"
import ReactGA from 'react-ga'
import imgVitepay from '../../../assets/images/vitepay.png'
import imgCertifie from '../../../assets/images/certifie.png'
import PubCarousel from "../../../components/PubCarousel"
import StoreCarousel from "../../../components/StoreCarousel"
import WebStoreCard from "../../../components/WebStoreCard"
import MobileStoreCard from "../../../components/MobileStoreCard"
import { Link } from "react-router-dom"
import { moyenNote } from "../../../lib/functions"

const Info = (props) =>{
    return(
        <div className="ayn-cert-vpay-info-container">
            <div style={{backgroundColor: '#f7f7f7', borderBottom: '1px solid #ddd', padding: '4px 8px'}}>
                <h6 style={{margin: 0}}><img src={props.image} style={{width: 16, marginRight: 8}} alt="img info" />{props.header} </h6>
            </div>
            <div style={{padding: '8px 8px 0px 8px'}}>
                {props.children}
            </div>
        </div>
    )
}


const imgAlimentation = "https://daneela-cdn.sfo3.cdn.digitaloceanspaces.com/daneela/upload_1623849119_alimentation.jpg"
const imgElectronique = "https://daneela-cdn.sfo3.cdn.digitaloceanspaces.com/daneela/upload_1623849167_electronique.jpg"
const imgRestauration = "https://daneela-cdn.sfo3.cdn.digitaloceanspaces.com/daneela/upload_1623849224_restauration.jpg"
const imgSupermarche = "https://daneela-cdn.sfo3.cdn.digitaloceanspaces.com/daneela/upload_1623849246_supermarche.jpg"
const imgFashion = "https://daneela-cdn.sfo3.cdn.digitaloceanspaces.com/daneela/upload_1623849191_fashion.jpg"

const marchandVitepayDescription = `Vitepay est un service de paiement électronique sécurisé.`
const marchandVitepayDescription1 = `Un marchand vitepay est un marchand sur le site duquel il est possible d’effectuer des paiements Orange Money à travers  Vitepay.`

const marchandCertifieDescription = `Un marchand certifié est un marchand ayant un badge et répondant à certains critères.`
const marchandCertifieDescription1 = `Le badge est un véritable atout car il sert à rassurer l’utilisateur sur la fiabilité du marchand.`


export default class Home extends React.Component {
    constructor(props){
        super(props)
        this.state = { 
            activeItemIndex: 0,
            categorieActive: '',
            vitepays: [],
            certifies: [],
            populaires: [],
            plus: false,
            certifieInfo: false,
            vitepayInfo: false,
            bannerNiveau1: [],
            bannerNiveau2: [],
            bannerNiveau3: []
        }
    }

    // Reccuperer les images de promotions sur le 1er slider
    getBannerNiveau1(){
        this.props.promotionController.getSlider1()
        .then(res => {
            if (typeof (res.pror) === 'undefined') {
                if(res.length){
                    this.setState({ bannerNiveau1: [...res.map(s=>s.slider)] })
                }
            }
        })
    }
    // Reccuperer les images de promotions sur le 2e slider    
    getBannerNiveau2(){
        this.props.promotionController.getSlider2()
        .then(res => {
            if (typeof (res.pror) === 'undefined') {
                this.setState({ bannerNiveau2: [...res.map(s=>s.slider)] })
            }
        })
    }
    // Reccuperer les images de promotions sur le 3e slider 
    getBannerNiveau3(){
        this.props.promotionController.getSlider3()
        .then(res => {
            if (typeof (res.pror) === 'undefined') {
                this.setState({ bannerNiveau3: [...res.map(s=>s.slider)] })
            }
        })
    }

    // Reccuperer les marchands certifies
    getCertifies(){
        this.props.marchandController.certifies()
        .then(res => {
            if (typeof (res.pror) === 'undefined') {
                const certifies = res.sort((a, b) => moyenNote(a.avis) < moyenNote(b.avis))
                this.setState({ certifies })
            } else {
                
            }
        })
    }
    // Reccuperer les marchands vitepay
    getVitepays(){
        this.props.marchandController.vitepays()
        .then(res => {
            if (typeof (res.pror) === 'undefined') {
                const vitepays = res.sort((a, b) => moyenNote(a.avis) < moyenNote(b.avis))
                this.setState({ vitepays })
            } else {
                
            }
        })
    }
    // Reccuperer les marchands par categorie populaire
    getPopulaires(cat){
        this.props.marchandController.populaires({libelle: cat})
        .then(res => {
            if (typeof (res.pror) === 'undefined') {
                const populaires = res.sort((a, b) => moyenNote(a.avis) < moyenNote(b.avis))
                this.setState({ populaires, plus: populaires.length >= 200 ? true : false, categorieActive: cat })
            } else {
                
            }
        })
    }
    // Reccuperer les marchands par categorie populaire en cas de skip
    getPopulairesPlus(cat,skip){
        this.props.marchandController.populaires({libelle: cat}, skip)
        .then(res => {
            if (typeof (res.pror) === 'undefined') {
                const pop = res.sort((a, b) => moyenNote(a.avis) < moyenNote(b.avis))
                const populaires = [...this.state.populaires, ...pop]
                this.setState({ populaires, plus: res.length >= 200 ? true : false, categorieActive: cat })
            } else {
                
            }
        })
    }

    componentDidMount(){
        this.getBannerNiveau1()
        this.getBannerNiveau2()
        this.getBannerNiveau3()
        this.getCertifies()
        this.getVitepays()
        this.getPopulaires('Alimentation')

        ReactGA.pageview(window.location.pathname)
    }

    // Google analytic lorsque le detail du marchand est ouvert
    gaView(){
        ReactGA.event({ category: 'Detail marchand', action: `Click sur le marchand pour plus de detail` })
    }

    render() {
        const { categorieActive, certifies, vitepays, populaires, plus, certifieInfo, vitepayInfo, bannerNiveau1, bannerNiveau2, bannerNiveau3 } = this.state
        const { isMobile } = this.props
        return (
            <div className="wt-haslayout">
                {certifieInfo && <div style={{height: '100vh', width: '100vw', position: 'fixed', zIndex: 2}} onClick={()=>this.setState({certifieInfo: false, vitepayInfo: false})}></div>}
                {vitepayInfo && <div style={{height: '100vh', width: '100vw', position: 'fixed', zIndex: 2}} onClick={()=>this.setState({certifieInfo: false, vitepayInfo: false})}></div>}
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <PubCarousel images={bannerNiveau1} />
                        </div>
                        <div id="wt-twocolumns" className="wt-twocolumns wt-haslayout">
                            <div className="col-12">
                                {certifieInfo &&
                                <Info header="Marchands certifiés" image={imgCertifie}>
                                    <p style={{margin: 0, fontSize: 12}}>{marchandCertifieDescription}<br/>{marchandCertifieDescription1}</p>
                                </Info>}
                                <h3 className="ayn-title ayn-categorie-title">Marchands certifiés 
                                    <span className="span-info" onClick={()=>this.setState({ certifieInfo: !certifieInfo })}><i className="fas fa-info-circle"></i></span>
                                </h3>
                                <StoreCarousel isMobile={isMobile} stores={certifies} onView={()=>this.gaView()} />
                            </div>

                            <div className="col-12">
                                <PubCarousel images={bannerNiveau2} />
                            </div>

                            <div className="col-12">
                                {vitepayInfo &&
                                <Info header="Marchands vitepay" image={imgVitepay}>
                                    <p style={{margin: 0, fontSize: 12}}>
                                        {marchandVitepayDescription}<br/>{marchandVitepayDescription1}<br/>
                                        {/* {marchandVitepayDescription2} */}
                                    </p>
                                </Info>}
                                <h3 className="ayn-title ayn-categorie-title">Marchands vitepay
                                <span className="span-info" onClick={()=>this.setState({ vitepayInfo: !vitepayInfo })}><i className="fas fa-info-circle"></i></span>
                            </h3>
                                <StoreCarousel isMobile={isMobile} stores={vitepays} onView={()=>this.gaView()} />
                            </div>

                            <div className="col-12">
                                <PubCarousel images={bannerNiveau3} />
                            </div>
                            
                            <div className="col-12">
                                <h3 className="ayn-title ayn-categorie-title">Catégories populaires</h3>
                                {/* <h2 className="ayn-title ayn-categorie-title" style={{textAlign: "center"}}>Catégories populaires</h2> */}
                                <div style={{display: "flex", justifyContent: 'space-between', minWidth: '100%', overflowY: 'auto', marginTop: 24, textAlign: 'center'}}>
                                    <div style={{minWidth: 100, width: 100, marginRight: 24}}>
                                        <Link to="#" onClick={()=>this.getPopulaires('Alimentation')}>
                                            <img src={imgAlimentation} alt="img categrie populaire" style={{width: 100, height: 100, borderRadius: 180, opacity: categorieActive === 'Alimentation' ? 1 : 0.4}} />
                                            <h6 className="ayn-title ayn-categorie-title" style={ categorieActive === 'Alimentation' ? {} : {color: '#999'}}>Alimentation</h6>
                                        </Link>
                                    </div>
                                    <div style={{minWidth: 100, width: 100, marginRight: 24}}>
                                        <Link to="#" onClick={()=>this.getPopulaires('Fashion')}>
                                            <img src={imgFashion} alt="img categrie populaire" style={{width: 100, height: 100, borderRadius: 180, opacity: categorieActive === 'Fashion' ? 1 : 0.4 }} />
                                            <h6 className="ayn-title ayn-categorie-title" style={ categorieActive === 'Fashion' ? {} : {color: '#999'}}>Fashion</h6>
                                        </Link>
                                    </div>
                                    <div style={{minWidth: 100, width: 100, marginRight: 24}}>
                                        <Link to="#" onClick={()=>this.getPopulaires('Électronique')}>
                                            <img src={imgElectronique} alt="img categrie populaire" style={{width: 100, height: 100, borderRadius: 180, opacity: categorieActive === 'Électronique' ? 1 : 0.4 }} />
                                            <h6 className="ayn-title ayn-categorie-title" style={ categorieActive === 'Électronique' ? {} : {color: '#999'}}>Électronique</h6>
                                        </Link>
                                    </div>
                                    <div style={{minWidth: 100, width: 100, marginRight: 24}}>
                                        <Link to="#" onClick={()=>this.getPopulaires('Supermarché')}>
                                            <img src={imgSupermarche} alt="img categrie populaire" style={{width: 100, height: 100, borderRadius: 180, opacity: categorieActive === 'Supermarché' ? 1 : 0.4 }} />
                                            <h6 className="ayn-title ayn-categorie-title" style={ categorieActive === 'Supermarché' ? {} : {color: '#999'}}>Supermarché</h6>
                                        </Link>
                                    </div>
                                    <div style={{minWidth: 100, width: 100, marginRight: 24}}>
                                        <Link to="#" onClick={()=>this.getPopulaires('Restaurations')}>
                                            <img src={imgRestauration} alt="img categrie populaire" style={{width: 100, height: 100, borderRadius: 180, opacity: categorieActive === 'Restaurations' ? 1 : 0.4 }} />
                                            <h6 className="ayn-title ayn-categorie-title" style={ categorieActive === 'Restaurations' ? {} : {color: '#999'}}>Restauration</h6>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="wt-companysinfoholder" style={{marginTop: 24}}>
                                <div className="row" style={{margin: 0}}>
                                    {!isMobile &&
                                    populaires.map((c,i)=>{
                                        return(
                                            <div className="col-12 col-sm-6 col-md-6 col-lg-3" key={i}>
                                                <Link to={{pathname: '/front/categories/details', state: {store: c} }} onClick={()=>this.gaView()}>
                                                    <WebStoreCard name={c.boutique} url={c.url} address={c.adresse} isVerified={c.certifie} vitepay={c.vitepay} rating={c.avis} cover={c.couverture} logo={c.logo} />
                                                </Link>
                                            </div>
                                        )
                                    }) }

                                    {isMobile &&
                                    populaires.map((c,i)=>{
                                        return(
                                            <div className="col-12 col-sm-6 col-md-6 col-lg-3" key={i}>
                                                <Link to={{pathname: '/front/categories/details', state: {store: c} }} onClick={()=>this.gaView()}>
                                                    <MobileStoreCard name={c.boutique} url={c.url} address={c.adresse} isVerified={c.certifie} vitepay={c.vitepay} rating={c.avis} cover={c.couverture} logo={c.logo} />
                                                </Link>
                                            </div>
                                        )
                                    }) }
                                </div>
                                {plus &&
                                <div style={{display: "flex", justifyContent: 'center'}} onClick={()=>this.getPopulairesPlus(categorieActive, populaires.length+1)}>
                                    <button className="wt-btn ayn-skip-btn">Plus de marchands</button>
                                </div>}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}