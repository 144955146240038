import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const PubCarousel = ({images}) => {
    return (
        <Carousel
            additionalTransfrom={0}
            arrows
            autoPlay
            autoPlaySpeed={5000}
            centerMode={false}
            className=""
            containerClass=""
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
                desktop: {
                    breakpoint: { max: 3000, min: 1024 },
                    items: 1
                },
                mobile: {
                    breakpoint: { max: 464, min: 0 },
                    items: 1
                },
                tablet: {
                    breakpoint: { max: 1024, min: 464 },
                    items: 1
                }
            }}
            showDots
            sliderClass=""
            slidesToSlide={1}
            swipeable
        >
            {images.map((img,i)=> <img src={img} key={i} style={{ display: 'block', height: '100%',   margin: 'auto',  width: '100%' }} alt='carousel' /> )}
        </Carousel>
    )
}
export default PubCarousel