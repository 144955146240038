/*eslint array-callback-return: "off"*/
import React from 'react'
import Table from '../../../components/Table/index'
import messages from '../../../lib/messages.json'
/*eslint consistent-return: ["error", { "treatUndefinedAsUnspecified": false }]*/


// Les colonne du tableua
const columns = [
    { name: 'marchandId', header: 'Ancien Nom', attributes: ['boutique'] },
    { name: 'boutique', header: 'Nouveau Nom' },
    { name: 'marchandId', header: 'Ancien url', url: true, attributes: ['url'] },
    { name: 'url', header: 'Nouveau url', url: true },
    { name: 'status', header: 'statut' }
]

export default class Modification extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [], filterData: [], research: '', load: true,
        }

    }

    componentDidMount() {
        this.props.controller.fetch()
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                this.setState({ data, filterData: data, load: false })
            } else {
                if (data.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                    this.setState({ load: false })
                }
            }
        })
    }
    



    render() {
        // const { crud, permission } = this.props
        const { data, research, load } = this.state
        
        return (
            <div>
                <div className="table-actions-container">
                    <div className="">
                        <input type="text" value={research} className="form-control search-input" onChange={(e)=>this.searchChange(e)} placeholder="Rechercher dans le tableau" />
                    </div>
                </div>
                <Table
                    columns={columns}
                    data={data}
                    permission={'2'}
                    load={load}
                    viewOnly={true}
                    onViewOpen={(id) => this.props.history.push(`/admin/modification/${id}`)}
                />

            </div>
        )
    }

}