/*eslint array-callback-return: "off"*/
import React from 'react'
import Loader from '../../../components/Loader'
import Statcard from '../../../components/Statcard'
import messages from '../../../lib/messages.json'

/*eslint consistent-return: ["error", { "treatUndefinedAsUnspecified": false }]*/

const fields = { name: '', email: '', role: '', status: false, current: '', password: '', confirmation: '', currentId: ''}
const fieldsError = { password: {}, confirmation: {}}
const validations = { 
	// current: {type: 'checkRequired', required: true, min: 8, number: false},
	password: {type: 'checkRequired', required: true, min: 8, number: false},
	confirmation: {type: 'checkConfirmation', min: 8, val2: 'password'}
}
const formOneItems = ['password', 'confirmation']

const Row = ({ title, value, bt, bb }) => {
    return (
        <div className="row" style={{ border: `2px solid #ddd`, borderTop: `${bt}px solid #ddd`, borderBottom: `${bb}px solid #ddd`, margin: 0 }}>
            <div className="col-5 ayn-detail-title" style={{ backgroundColor: '#f7f7f7' }}>
                {title}
            </div>
            <div className="col-7 ayn-detail-text" style={{ borderLeft: '2px solid #ddd' }}>
                {value}
            </div>
        </div>
    )
}

export default class Profile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            errors: { ...fieldsError },
            ...fields,
            user: {},
            btnLoad: false

        }
        this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this)

    }

    handleTextChange(e, name){ this.setState({ [name]: e.target.value }) }
    checkConfirmation(name, val, val2, min){
		const errors = this.state.errors
		errors[name]['required'] = !val ? `*Ce champ est obligatoire` : null
		errors[name]['min'] = val.length < min ? `*Ce champ doit avoir au moins ${min} caracteres` : null
		errors[name]['different'] = val !== val2 ? `*Mot de passe et confirmation ne correspondent pas` : null
		this.setState({errors})
	}
	checkRequired(name, val, min, number = false){
		const errors = this.state.errors
		errors[name]['required'] = !val ? `*Ce champ est obligatoire` : null
		errors[name]['min'] = val.length < min ? `*Ce champ doit avoir au moins ${min} caracteres` : null
		if(number){
			errors[name]['number'] = isNaN(val) ? `*Ce champ doit être numérique` : null
		}
		this.setState({errors})
	}
    checkValidations(items){
		for(let i=0; i<items.length; i++){
			let item = validations[items[i]]
			if(typeof(item) !== 'undefined'){
				if(item['type'] === 'checkRequired'){
					this.checkRequired(items[i], this.state[items[i]], item['min'], item['number'] )
				}else{
					if(item['type'] === 'checkConfirmation'){
						this.checkConfirmation(items[i], this.state[items[i]], this.state[item['val2']], item['min'] )
					}else{
						if(item['type'] === 'checkEmail'){
							this.checkEmail(items[i], this.state[items[i]], item['required'] )
						}else{
							if(item['type'] === 'checkUrl'){
								this.checkUrl(items[i], this.state[items[i]], item['required'] )
							}else{
								if(item['type'] === 'checkFile'){
									let params = this.state[`${items[i]}Params`]
									if(item['multiple']){
										this.checkFileOnSubmit(items[i], this.state[item['name']][item['pos']], item['width'], item['height'], params ? params.w : null, params ? params.h : null )
									}else{
										this.checkFileOnSubmit(items[i], this.state[items[i]], item['width'], item['height'], params ? params.w : null, params ? params.h : null )
									}
								}else{
									if(item['type'] === 'checkChip'){
										this.checkChip(items[i], this.state[items[i]], item['min'] )
									}
								}
							}
						}	
					}
				}
			}
		}
	}

    hasError(errors){
		let erreur = false
		Object.keys(errors).map(key => {
			let error = errors[key]
			Object.keys(error).map(k => {
				if(error[k]){
					erreur = true
				}
			})
		})
		return erreur
	}

    handleSubmitUpdate(e){
        e.preventDefault()
        this.setState({ btnLoad: true })
		this.checkValidations(formOneItems)
		const error = this.hasError(this.state.errors)
		if(!error){
			const { password } = this.state
            const user = this.props.session.get('user')
			const proprietaire = { id: user?.id, name: user?.name, email: user?.email, role: user?.role, admin: user.role === 'Admin' ? true : false, status: user?.status, password }
            if(user){
                this.props.controller.save(proprietaire)
                .then(res => {
                    if (typeof (res.pror) === 'undefined') {
                        user.password = password
                        this.props.session.login(user)
                        this.props.toast(messages.updateUserPassword, { appearance: 'success' })
                        this.setState({ btnLoad: false })
                    } else {
                        if (res.pror.indexOf('401') > 0) {
                            this.props.toast(messages.sessionExpired, { appearance: 'info' })
                        } else {
                            this.props.toast(messages.requestFailed, { appearance: 'error' })
                            this.setState({ btnLoad: false })
                        }
                    }
                })
            }else{
                this.props.toast(messages.requestFailed, { appearance: 'error' })
                this.setState({ btnLoad: false })
            }
        }else{
            this.props.toast(messages.formError, { appearance: 'error' })
            this.setState({ btnLoad: false })
        }
    }

    render() {
        // const { crud, permission } = this.props
        const { btnLoad, password, confirmation, errors } = this.state
        const user = this.props.session.get('user')

        return (
            <div className="row" style={{ margin: "0 40px" }}>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                    <Statcard title="Informations générales" mb={24}>
                        <Row title="Nom et Prénom" value={user.name ? user.name : '' } bt='2' bb='1' />
                        <Row title="Email" value={user.email ? user.email : ''} bt='1' bb='1' />
                        <Row title="Rôle" value={user.role ? user.role : ''} bt='1' bb='2' />
                    </Statcard>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                    <Statcard title="Changer le mot de passe" mb={24} float='left'>
                        <form className="wt-formtheme wt-formregister" id="form-inscription" onSubmit={this.handleSubmitUpdate}>
                            <fieldset className="wt-registerformgroup">
                                {/* <div className="form-group">
                                    <label>Actuel mot de passe *</label>
                                    <input type="text" onBlur={()=>this.checkRequired('current', current, 4)} className="form-control" placeholder="ex: Massire Demeble" value={current} onChange={(e)=>this.handleTextChange(e, 'current')} required/>
                                    {Object.keys(errors.current).map((k, i) => {
                                        return <span key={i} className="error-span">{errors.current[k]}</span>
                                    })}
                                </div> */}
                                <div className="form-group">
                                    <label>Nouveau mot de passe *</label>
                                    <input type="password" onBlur={()=>this.checkRequired('password', password, 8)} className="form-control" placeholder="Nouveau mot de passe" value={password} onChange={(e)=>this.handleTextChange(e, 'password')} required/>
                                    {Object.keys(errors.password).map((k, i) => {
                                        return <span key={i} className="error-span">{errors.password[k]}</span>
                                    })}
                                </div>
                                <div className="form-group">
                                    <label>Confirmer le mot de passe *</label>
                                    <input type="password" onBlur={()=>this.checkConfirmation('confirmation', confirmation, password, 8)} className="form-control" placeholder="Confirmer le mot de passe" value={confirmation} onChange={(e)=>this.handleTextChange(e, 'confirmation')} required/>
                                    {Object.keys(errors.confirmation).map((k, i) => {
                                        return <span key={i} className="error-span">{errors.confirmation[k]}</span>
                                    })}
                                </div>
                                
                                <div className="filter-actions-container" style={{float: 'right', marginTop: 16, marginBottom: 4}}>
                                    <button className="btn btn-primary filter-action-btn" type="submit">
                                        {btnLoad && <Loader size={16} />}
                                        {!btnLoad && 'Modifier'}
                                    </button>
                                    {/* <button type="button" className="btn btn-secondary filter-action-btn" onClick={()=>this.setState({ ...fields, modal: false }) }>Annuler</button> */}
                                </div>
                            </fieldset>
                        </form>
                    </Statcard>
                </div>
            </div>
        )
    }

}