import React from 'react';
import Header from '../../components/admin/Header';
import Page from '../../components/Page'
import Utilisateur from '../../features/admin/Utilisateur';
import { UsersSvc } from '../../services';

const UtilisateurPage = (props) => {
    const userService = new UsersSvc(props.session)
    const user = props.session.get('user')
    if(user?.role !== 'Admin' ){
        return window.location = '/admin/profile'
    }else{
        return (
            <Page mt={0} isAdmin={true}>
                <Header pathname="Utilisateurs" username={user?.username} session={props.session} toast={props.toast} />
                <Utilisateur {...props} controller={userService} />
            </Page>
        )
    }
}
export default UtilisateurPage