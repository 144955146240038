import React from 'react';
import Header from '../../components/marchand/Header';
import Page from '../../components/Page'
import Forfait from '../../features/marchand/Forfait'
import { ForfaitsSvc } from '../../services';

const ForfaitPage = (props) => {
    const forfaitService = new ForfaitsSvc(props.session)
    const user = props.session.get('user')
    return (
        <Page mt={0} isAdmin={true}>
            <Header pathname="Forfaits" username={user?.username} session={props.session} toast={props.toast} />
            <Forfait {...props} controller={forfaitService} />
        </Page>
    )
}

export default ForfaitPage