/**
 * PAGE DES CATEGORIES DE MARCHANDS POUR LA PARTIE DE L'APPLICATION MISE A LA DISPOSITION DE LA GRANDE PUBLiQUE
 */
import React from "react"
import { Link } from "react-router-dom"
import MobileStoreCard from "../../../components/MobileStoreCard"
import WebStoreCard from "../../../components/WebStoreCard"
import categoriesJSON from '../../../lib/categories.json'
import './index.css'

const listRegion = ['Bamako', 'Kayes', 'Koulikoro', 'Sikasso', 'Ségou', 'Mopti', 'Tombouctou', 'Gao', 'Kidal']
const categories = [ {id: 1, nom: 'electronique'}, {id: 2, nom: 'fashion'}, {id: 3, nom: 'alimentation'} ]
// const fourchette = [ {value: '0-1000',  label: '0F - 1.000F'}, {value: '2000-10000', label: '2.000F - 10.000F'}, {value: '50000-100000', label: '50.000F - 100.000F'} ]
// const fourchette = [ {min: 0, max:1000,  label: '0F - 1.000F'}, {min: 2000, max: 10000, label: '2.000F - 10.000F'}, {min: 50000, max: 100000, label: '50.000F - 100.000F'} ]

export default class Categories extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            selection: [],
            currentName: '',
            filtered: [],
            souscategories: [],
            categories, stores: [], tempStores: [], region: ''
        }
    }

    // Effacer un element concernant les filtres
    clear(){
        const cat = {}
        this.state.categories.map(c=> cat[c.nom] = '')
        this.setState({ ...cat, stores: this.state.tempStores })
    }

    filter(filtered){
        const { tempStores } = this.state
        const allStores = []
        for(let i=0; i<filtered.length; i++){
            if(filtered[i]['type'] === 'sub'){
                let fil = tempStores.filter(t=>t.tags.sousGategories.includes(filtered[i]['name']))
                allStores.push(...fil)
            }else{
                if(filtered[i]['type'] === 'region'){
                    let fil = tempStores.filter(t=>t.region === filtered[i]['name'])
                    allStores.push(...fil)
                }else{
                    if(filtered[i]['type'] === 'service'){
                        if(filtered[i]['name'] === 'Service après vente'){
                            if(filtered[i]['val'] === 'true'){
                                let fil = tempStores.filter(t=>t.services.service === true)
                                allStores.push(...fil)
                            }else{
                                let fil = tempStores.filter(t=>t.services.service === false)
                                allStores.push(...fil)
                            }
                        }else{
                            let fil = tempStores.filter(t=>t.services.livraison === filtered[i]['val'])
                            allStores.push(...fil)
                        }
                    }else{
                        if(filtered[i]['type'] === 'livraison'){
                            let fil = tempStores.filter(t=>t.services.livraison === filtered[i]['val'])
                            allStores.push(...fil)
                        }else{
                            if(filtered[i]['type'] === 'classe'){
                                if(filtered[i]['name'] === 'Marchands certifiés'){
                                    if(filtered[i]['val'] === 'true'){
                                        let fil = tempStores.filter(t=>t.certifie === true)
                                        allStores.push(...fil)
                                    }else{
                                        let fil = tempStores.filter(t=>t.certifie === false)
                                        allStores.push(...fil)
                                    }
                                }else{
                                    if(filtered[i]['val'] === 'true'){
                                        let fil = tempStores.filter(t=>t.vitepay === true)
                                        allStores.push(...fil)
                                    }else{
                                        let fil = tempStores.filter(t=>t.vitepay === false)
                                        allStores.push(...fil)
                                    }
                                }
                            }else{
                                if(filtered[i]['type'] === 'prix'){
                                    let fil = tempStores.filter(t=>t.tags.prix.minimum <= filtered[i]['val']['min'] && t.tags.prix.maximum >= filtered[i]['val']['max'])
                                    allStores.push(...fil)
                                }
                            }
                        }
                    }
                }
            }
        }

        const stores = Array.from(new Set(allStores.map(a => a.id))).map(id => {
        return allStores.find(a => a.id === id)
        })
        if(filtered.length > 0){
            this.setState({ filtered, stores })
        }else{
            this.setState({ filtered, stores: tempStores })
        }
        // let res = []
        // for(let i=0; i<flt.length; i++){
        //     const f = this.state.tempStores.filter(s=>s.categorie === flt[i])
        //     res.push(...f)
        // }
        // return res
    }

    // Categorie selectionner pour le filtre
    selectCat(cat){
        const { selection, tempStores } = this.state
        let slt = selection
        if(this.state[cat]){
            slt = selection.filter(s=> s !== cat)
            if(slt[0]){
                const stores = this.filter(slt)
                this.setState({ [cat]: '', selection: slt, stores })
            }else{
                this.setState({ [cat]: '', selection: slt, stores: tempStores })
            }
        }else{
            slt.push(cat)
            const stores = this.filter(slt)
            this.setState({[cat]: 'active', selection: slt, stores})
        }
    }

    //Requette pour ramener les marchands des categories populaires par categorie
    query(cat){
        const path = this.props.history.location.pathname
        this.props.marchandController.populaires({libelle: cat})
        .then(res => {
            if (typeof (res.pror) === 'undefined') {
                const souscategories = categoriesJSON.find(c=>c.name === cat)
                this.setState({ stores: res, tempStores: res, souscategories: souscategories ? souscategories.children : [], currentName: cat, pathname: path })
            } else {
                if (res.pror.indexOf('401') > 0) {
                    // this.setState({ alert: true, message: "Information! la session est expirée", typeMessage: 'info' })
                } else {
                    // this.setState({ alert: true, message: res.pror, typeMessage: 'error', modalBtnLoad: false, modal: false, modalContrat: false })
                }
            }
        })
    }

    // handleAddToFiltered(item){
    //     const { filtered } = this.state
    //     if(filtered.includes(item)){
    //         const f = filtered.filter(d=>d !== item)
    //         this.setState({ filtered: f })
    //     }else{
    //         filtered.push(item)
    //         this.setState({filtered})
    //     }
    // }

    // Ajouter un element dans la liste de filtre
    handleAddToFiltered(name, type, val){
        const data = this.state.filtered
        let filterData = []
        
        if(type === 'prix-min' || type === 'prix-max' || type === 'region' || type === 'service' || type === 'livraison'){
            if(val !== ''){
                const temp = data.filter(d=>d.type !== type)
                // this.setState({filtered: [...temp, {name, type, val}] })
                filterData =  [...temp, {name, type, val}]
            }else{
                const temp = data.filter(d=>d.type !== type)
                // this.setState({filtered: [...temp] })
                filterData =  [...temp]
            }
        }else{
            const chk = data.find(f=>f.name === name)
            if(chk){
                const f = data.filter(d=>d.name !== name)
                // this.setState({ filtered: f })
                filterData =  f
            }else{
                // this.setState({filtered: [...this.state.filtered, {name, type, val}] })
                filterData =  [...this.state.filtered, {name, type, val}]
            }
        }

        this.filter(filterData)

    }


    componentDidUpdate(prevProps, nextProps){
        // if(prevProps !== this.props){
        //     const name = this.props.history.location.state.name
        //     if(name !== this.state.currentName){
        //         this.query(name)
        //     }
        // }

    }
    componentDidMount(){
        // const name = this.props.history.location.state.name
        // this.query(name)
    }

    render() {
        const { isMobile } = this.props
        const { stores, souscategories, filtered, /*region, pathname,*/ currentName } = this.state

        return (
            <div className="wt-haslayout">
                <div className="container">
                    <div className="row">
                        <div id="wt-twocolumns" className="wt-twocolumns wt-haslayout">
                            <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-4 float-left">
                                <aside id="wt-sidebar" className="wt-sidebar">
                                    <div className="wt-widget wt-startsearch">
                                        <div className="wt-widgettitle">
                                            <h2>Régions</h2>
                                        </div>
                                        <div className="wt-widgetcontent">
                                            <form className="wt-formtheme wt-formsearch">
                                                <fieldset>
                                                    <div className="form-group">
                                                        <span className="wt-select">
                                                            <select value={filtered.find(f=> f.type === 'region') ? filtered.find(f=> f.type === 'region')['val'] : ''} onChange={(e)=>{this.handleAddToFiltered(e.target.value, 'region', e.target.value) }}>
                                                                <option value="">Région </option>
                                                                {listRegion.map((r,i)=> <option value={r} key={i}>{r}</option>)}
                                                            </select>
                                                        </span>
                                                    </div>
                                                </fieldset>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="wt-widget wt-effectiveholder">
                                        <div className="wt-widgettitle">
                                            <h2>Sous-catégories</h2>
                                        </div>
                                        <div className="wt-widgetcontent">
                                            <form className="wt-formtheme wt-formsearch">
                                                <fieldset>
                                                    <ul className="wt-filtertag ayn-cat-ul form-subcat-ul">
                                                    {souscategories.map((c,i)=>{
                                                        return(
                                                            <li className="ayn-cat-li" key={i}>
                                                                <span onClick={()=>this.handleAddToFiltered(c.name, 'sub', '')}  className={ filtered.find(f=> f.name === c.name) ? 'cat-chip active' : 'cat-chip'}>
                                                                    <span>{c.name}</span>
                                                                    <i className="fa fa-times close cat-closer"></i>
                                                                </span>
                                                            </li>
                                                        )
                                                    })}
                                                    </ul>
                                                </fieldset>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="wt-widget wt-effectiveholder">
                                        <div className="wt-widgettitle">
                                            <h2>Fourchette de prix</h2>
                                        </div>
                                        <div className="wt-widgetcontent">
                                            <form className="wt-formtheme wt-formsearch">
                                                <fieldset>
                                                    <div className="form-group" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: 0}}>
														<label>Min:</label>
														<span className="wt-select filter-select" style={{width: 150}}>
															<select style={{height: 40, margin: 0, marginBottom: 8}} value={filtered.find(f=> f.type === 'prix-min') ? filtered.find(f=> f.type === 'prix-min')['val'] : ''} onChange={(e)=>{this.handleAddToFiltered(`${e.target.value} F`, 'prix-min', e.target.value) }}>
																<option value=''>Min</option>
																<option value={100}>100</option>
																<option value={1000}>1.000</option>
																<option value={25000}>25.000</option>
															</select>
														</span>
                                                    </div>
                                                    <div className="form-group" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: 0}}>
														<label>Max:</label>
														<span className="wt-select filter-select" style={{width: 150}}>
															<select style={{height: 40, margin: 0}} value={filtered.find(f=> f.type === 'prix-max') ? filtered.find(f=> f.type === 'prix-max')['val'] : ''} onChange={(e)=>{this.handleAddToFiltered(`${e.target.value} F`, 'prix-max', e.target.value) }}>
																<option value=''>Max</option>
																<option value={25000}>25.000</option>
																<option value={30000}>30.000</option>
																<option value={100000}>100.000</option>
															</select>
														</span>
													</div>
                                                </fieldset>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="wt-widget wt-effectiveholder">
                                        <div className="wt-widgettitle">
                                            <h2>Services</h2>
                                        </div>
                                        <div className="wt-widgetcontent">
                                            <form className="wt-formtheme wt-formsearch">
                                                <fieldset>
                                                    <div className="form-group" style={{flexWrap: 'wrap'}}>
														<label style={{width: 200}}>Livraison:</label>
														<div className="wt-radioboxholder form-radio-content">
															<div className="form-radio">
																<span className="form-radio-label">Non disponible</span>
																<span className="wt-radio">
																	<input id="liv-nondisponible" type="radio" name="livraison" value="Non disponible" onChange={(e)=>this.handleAddToFiltered('Livraison non disponible', 'livraison', e.target.value)}  checked={filtered.find(f=> f.name === 'Livraison non disponible')} />
																	<label htmlFor="liv-nondisponible"></label>
																</span>
															</div>
															<div className="form-radio">
																<span className="form-radio-label">Gratuite</span>
																<span className="wt-radio">
																	<input id="liv-gratuite" type="radio" name="livraison" value="Gratuite" onChange={(e)=>this.handleAddToFiltered('Livraison gratuite', 'livraison', e.target.value)}  checked={filtered.find(f=> f.name === 'Livraison gratuite')} />
																	<label htmlFor="liv-gratuite"></label>
																</span>
															</div>
															<div className="form-radio">
																<span className="form-radio-label">Payante</span>
																<span className="wt-radio">
																	<input id="liv-payant" type="radio" name="livraison" value="Payante" onChange={(e)=>this.handleAddToFiltered('Livraison payante', 'livraison', e.target.value)}  checked={filtered.find(f=> f.name === 'Livraison payante')} />
																	<label htmlFor="liv-payant"></label>
																</span>
															</div>
														</div>
													</div>
													<div className="form-group form-radio-container"  style={{flexWrap: 'wrap'}}>
														<label style={{width: 200, height: 45}}>Services après vente:</label>
														<div className="wt-radioboxholder form-radio-content">
															<div className="form-radio">
																<span className="form-radio-label">Oui</span>
																<span className="wt-radio">
																	<input id="sav-oui" type="radio" name="serviceApresVente" value={true} onChange={(e)=>this.handleAddToFiltered('Service après vente: Oui', 'service', e.target.value)} checked={filtered.find(f=> f.name === "Service après vente: Oui")} />
																	<label htmlFor="sav-oui"></label>
																</span>
															</div>
															<div className="form-radio">
																<span className="form-radio-label">Non</span>
																<span className="wt-radio">
																	<input id="sav-non" type="radio" name="serviceApresVente" value={false} onChange={(e)=>this.handleAddToFiltered('Service après vente: Non', 'service', e.target.value)} checked={filtered.find(f=> f.name === "Service après vente: Non")} />
																	<label htmlFor="sav-non"></label>
																</span>
															</div>
														</div>
													</div>
                                                </fieldset>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="wt-widget wt-effectiveholder">
                                        <div className="wt-widgettitle">
                                            <h2>Classe des marchands</h2>
                                        </div>
                                        <div className="wt-widgetcontent">
                                            <form className="wt-formtheme wt-formsearch">
                                                <fieldset>
                                                    <div className="wt-checkboxholder wt-verticalscrollbar" style={{overflow: 'auto'}}>
                                                        <span className="wt-checkbox">
                                                            <input id="wt-certifie"
                                                            type="checkbox"
                                                            value="Marchands certifiés"
                                                            onChange={(e)=>this.handleAddToFiltered('Marchands certifiés', 'classe', e.target.value)}
                                                            checked={filtered.find(f=> f.name === "Marchands certifiés")}  />
                                                            <label htmlFor="wt-certifie">Marchands certifiés</label>
                                                        </span>
                                                        <span className="wt-checkbox">
                                                            <input id="wt-vitepay"
                                                            type="checkbox"
                                                            value="Marchands vitepay"
                                                            onChange={(e)=>this.handleAddToFiltered('Marchands vitepay', 'classe', e.target.value)}
                                                            checked={filtered.find(f=> f.name === "Marchands vitepay")}  />
                                                            <label htmlFor="wt-vitepay">Marchands vitepay</label>
                                                        </span>
                                                    </div>
                                                </fieldset>
                                            </form>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-8 float-left">
                                <div className="wt-userlistingholder wt-haslayout">
                                    <div className="wt-filterholder">
                                        <ul className="wt-filtertag ayn-cat-ul" style={{justifyContent: 'start'}}>
                                            <li className="wt-filtertagclear ayn-cat-li">
                                                <Link to={{pathname: '', state: {name: currentName } }} onClick={()=>this.setState({ filtered: []})}><i className="fa fa-times"></i> <span>Effacer les filtres</span></Link>
                                            </li>
                                            {filtered.map((c,i)=>{
                                                return(
                                                    <li className="ayn-cat-li" key={i}>
                                                        <Link to={{pathname: '', state: {name: currentName } }} /*onClick={()=>this.selectCat(c.nom)} className={this.state[c.nom]}*/><span>{c.name}</span></Link>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                    <div className="wt-companysinfoholder">
                                        <div className="row">
                                            {!isMobile &&
                                            stores.map((c,i)=>{
                                                return(
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-4" key={i}>
                                                        <Link to={{pathname: '/front/categories/details', state: {store: c} }}>
                                                            <WebStoreCard name={c.boutique} url={c.url} address={c.adresse} isVerified={c.certifie} vitepay={c.vitepay} rating={c.rating} cover={c.couverture} logo={c.logo} />
                                                        </Link>
                                                    </div>
                                                )
                                            })}

                                            {isMobile &&
                                            stores.map((c,i)=>{
                                                return(
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-3" key={i}>
                                                        <Link to={{pathname: '/front/categories/details', state: {store: c} }}>
                                                            <MobileStoreCard name={c.boutique} url={c.url} address={c.adresse} isVerified={c.certifie} vitepay={c.vitepay} rating={c.rating} cover={c.couverture} logo={c.logo} />
                                                        </Link>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}