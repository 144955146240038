import React from 'react';
import Header from '../../components/admin/Header';
import Page from '../../components/Page'
import ModificationDetail from '../../features/admin/Modification/Detail'
import { MarchandUpdate, UsersSvc } from '../../services';

const ModificationDetailPage = (props) => {
    const marchandService = new MarchandUpdate(props.session)
    const userService = new UsersSvc(props.session)
    const user = props.session.get('user')
    return (
        <Page mt={0} isAdmin={true}>
            <Header pathname="Modification d'informations" username={user?.username} session={props.session} toast={props.toast} />
            <ModificationDetail {...props} userController={userService} controller={marchandService} />
        </Page>
    )
}
export default ModificationDetailPage