const Page = (props) => {
    return (
        <main id="wt-main" className="wt-main wt-haslayout wt-innerbgcolor" 
            style={{
                paddingTop: typeof(props.mt) !== 'undefined' ? props.mt : 20,
                marginLeft: props.isAdmin ? 348 : 0,
                marginBottom: props.isAdmin ? 0 : /*168*/ 0,
                backgroundColor: props.login ? 'transparent' : '#f7f7f7'
            }}
        >
            {props.children}
        </main>
    )
}
export default Page