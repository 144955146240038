import React from 'react';
import Header from '../../components/admin/Header';
import Page from '../../components/Page'
import Notification from '../../features/admin/Notification';
import { NotificatonsSvc } from '../../services';

const NotificationPage = (props) => {
    const notificationsService = new NotificatonsSvc(props.session)
    const user = props.session.get('user')
    return (
        <Page mt={0} isAdmin={true}>
            <Header pathname="Notification" username={user?.username} session={props.session} toast={props.toast} />
            <Notification {...props} controller={notificationsService} />
        </Page>
    )
}
export default NotificationPage