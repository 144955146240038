import React from 'react';
import Page from '../../components/Page'
import Inscription from '../../features/marchand/Inscription'
import { MarchandsSvc, UsersSvc } from '../../services';

const InscriptionPage = (props) => {
    const marchandService = new MarchandsSvc(props.session)
    const userService = new UsersSvc(props.session)
    return (
        <Page>
            <Inscription {...props} userController={userService} controller={marchandService} />
        </Page>
    )
}
export default InscriptionPage