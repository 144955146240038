import React from 'react';
import Header from '../../components/admin/Header';
import Page from '../../components/Page'
import Marchand from '../../features/admin/Marchand'
import { MarchandsSvc, UsersSvc } from '../../services';

const MarchandPage = (props) => {
    const marchandService = new MarchandsSvc(props.session)
    const userService = new UsersSvc(props.session)
    const user = props.session.get('user')
    return (
        <Page mt={0} isAdmin={true}>
            <Header pathname="Marchands" username={user?.username} session={props.session} toast={props.toast} />
            <Marchand {...props} controller={marchandService}  userController={userService} />
        </Page>
    )
}
export default MarchandPage