/*eslint array-callback-return: "off"*/
import React from 'react'
// import Switch from "react-switch"
import Rating from "react-rating"
import ReactModal from 'react-modal'
import axios from "axios"
import { moyenNote, ratingNote } from "../../../lib/functions"
import Progress from "../../../components/Progress"
// import Detail from '../../../components/admin/Detail'
// import Poper from '../../../components/Poper'
// import Table from '../../../components/Table/index'
import messages from '../../../lib/messages.json'
import Loader from '../../../components/Loader';

/*eslint consistent-return: ["error", { "treatUndefinedAsUnspecified": false }]*/

const styles = {
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    head: { textTransform: 'inherit', color: '#001e37' },
    icon: {fontSize: 36, color: '#001e37', fontWeight: '100' },
    label: { textAlign: 'center' },
    imageContent: {
        display: 'flex',
        width: 58,
        height: 58,
        minWidth: 58,
        borderRadius: 80,
        border: '1px solid #001e37',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 16
    }
}

const filterFields = { filterStatut: '', filterRegion: '', filterCertifie: false, filterVitepay: false }
const searchFields = { searchUrl: '' }
export default class Marchand extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ...filterFields,
            ...searchFields,
            tempData: [],
            filterData: [],
            research: '', currentElement: {}, currentUser: {}, currentId: '',
            btnLoad: false, load: true, modal: false,
            message: '', reponse: '', maxChar: 300,
            data: [], notes: {}, avis: [], canComment: false
        }
        this.handleReply = this.handleReply.bind(this)
        this.handleApplyFilter = this.handleApplyFilter.bind(this)
		this.handleApplySearch = this.handleApplySearch.bind(this)
    }
    handleTextChange(e, name){ this.setState({ [name]: e.target.value }) }

    getNotes(){
        if(this.props.marchandId){
            this.props.noteController.stats(this.props.marchandId)
            .then(res => {
                if (typeof (res.pror) === 'undefined') {
                    const notes = ratingNote(res)
                    this.setState({ notes })
                }
            })
        }
    }

    getForfaits(){
        if(this.props.marchandId){
            this.props.forfaitController.getForfaitPermission(this.props.marchandId)
            .then(res => {
                if(res){
                    if (typeof (res.pror) === 'undefined') {
                        if(res.type){
                            this.setState({ canComment: true })
                        }
                    }
                }
            })
        }
    }

    componentDidMount() {
        this.props.controller.findById(this.props.marchandId)
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                const avis = data.avis? data.avis : []
                this.setState({ data: avis, tempData: avis, filterData: avis, load: false })
            } else {
                if (data.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                    this.setState({ load: false })
                }
            }
        })
        this.getNotes()
        this.getForfaits()
    }
    handleOpenReplyBox(id){
        const { data } = this.state
        const currentElement = data.find(d=>d._id === id)
        if(currentElement){
            this.setState({message: currentElement.message, reponse: currentElement.reponse ? currentElement.reponse.reponse : '', modal: true })
        }
    }

    handleReply(){
        // const { currentId, data, reponse } = this.state
        // this.setState({ btnLoad: true })
        // if(currentId){
        //     this.props.controller.save({id: currentId, certifie: true })
        //     .then(res => {
        //         if (typeof (res.pror) === 'undefined') {
        //             // const data = this.state.data.filter(d=>d.id !== ccurrentId)
        //             const pos = data.findIndex(d=>d.id === currentId)
        //             const st = data.find(s=> s.id === currentId)
        //             st.certifie = true
        //             data[pos] = st
        //             this.setState({ data, load: false, btnLoad: false, modal: false })
        //             this.props.toast(messages.certifie, { appearance: 'success' })
        //         } else {
        //             if (res.pror.indexOf('401') > 0) {
        //                 this.props.toast(messages.sessionExpired, { appearance: 'info' })
        //             } else {
        //                 this.props.toast(messages.requestFailed, { appearance: 'error' })
        //                 this.setState({ btnLoad: false })
        //             }
        //         }
        //     })
        // }
    }

    handleApplyFilter(e){
        e.preventDefault()
        this.setState({ btnLoad: true })
        const { filterStatut, filterRegion, filterCertifie, filterVitepay } = this.state
        this.props.controller.filter({ status: filterStatut, region: filterRegion, certifie: filterCertifie, vitepay: filterVitepay })
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                this.setState({ data, filterData: data, load: false, btnLoad: false })
                this.props.toast(messages.filter, { appearance: 'success' })
            } else {
                if (data.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                    this.setState({ btnLoad: false })
                }
            }
        })
    }

    handleApplySearch(e){
        e.preventDefault()
        this.setState({ btnLoad: true })
        const { searchUrl } = this.state
        this.props.controller.search({url: searchUrl})
        .then(data => {
            if(data){
                if (typeof (data.pror) === 'undefined') {
                    this.setState({ data: [data], filterData: [data], load: false, btnLoad: false })
                    this.props.toast(messages.search, { appearance: 'success' })
                } else {
                    if (data.pror.indexOf('401') > 0) {
                        this.props.toast(messages.sessionExpired, { appearance: 'info' })
                    } else {
                        this.props.toast(messages.requestFailed, { appearance: 'error' })
                        this.setState({ btnLoad: false })
                    }
                }
            }else{
                this.setState({ data: [], filterData: [], load: false, btnLoad: false })
                    this.props.toast(messages.search, { appearance: 'success' })
            }
        })
    }

    searchChange(event) {
        const value = event.target.value
        const data = this.state.filterData.filter(dt => {
           return (
              dt.boutique.toLowerCase().includes(value.toLowerCase()) ||
              dt.url.toLowerCase().includes(value.toLowerCase())
           )
        })
        this.setState({ data, research: value })
    }

    handleResponseChange(value){
        const { maxChar } = this.state
        if(value.length <= maxChar){
            this.setState({ reponse: value })
        }
    }

    getImage = (id) => {
        return axios.get(`https://graph.facebook.com/v3.2/${id}/picture?type=normal&access_token=2862393507333579%7Caafa6b1ae9715c6d6573009d8bb0265f`,{responseType: 'blob'})
        .then(function (response) {
            let imageNode = document.getElementById(`ayena_${id}`);
            let url = URL.createObjectURL(response.data)
            if(imageNode) imageNode.src =  url
        })
        .catch(function(e){
            let imageNode = document.getElementById(`ayena_${id}`);
            if(imageNode) imageNode.src =  ''
        })
    }


    render() {
        // const { crud, permission } = this.props
        const { data, canComment, /*currentElement, currentUser, load,*/ btnLoad, /*detailModal,*/ modal, notes,
            reponse, /*message,*/ maxChar,
            /*filterStatut, filterRegion, filterCertifie, filterVitepay, searchUrl, research */} = this.state

        return (
            <div>
                <div className="table-actions-container">
                    
                </div>
                <div style={{display: "flex", flexDirection: 'column', alignItems: 'center', padding: '0 16px 16px 16px', borderBottom: '1px solid #ddd'}}>
                    <div style={styles.item}>
                        <i className="fa fa-user-circle" style={styles.icon}></i>
                    </div>

                    <h6 style={styles.head}>Donner une note</h6>
                    <p style={styles.label}>Partager votre expérience afin d'aider les autres utilisateur</p>

                    <span>
                        <Rating
                            initialRating={moyenNote(data)}
                            emptySymbol={<i className="fa fa-star-o fa-3x"></i>}
                            fullSymbol={<i className="fa fa-star fa-3x" style={{color: 'orange'}}></i>}
                            readonly
                            onClick={r=>this.handleSubmitNote(r)}
                        />
                    </span>
                </div>

                <div style={{padding: 16, borderBottom: '1px solid #ddd'}}>
                    <p style={{color: '#001e37'}}>Résumé des notes clients</p>
                    <div className="row">
                        <div className="col-8">
                            <Progress process={notes.cinq} number='5' />
                            <Progress process={notes.quatre} number='4' />
                            <Progress process={notes.trois} number='3' />
                            <Progress process={notes.deux} number='2' />
                            <Progress process={notes.un} number='1' />
                        </div>
                        
                        <div className="col-4">
                            <h2 style={{textAlign: 'center', fontWeight: 400, marginBottom: 0, position: 'relative', top: -12}}>{moyenNote(data)}</h2>
                            <div style={{textAlign: 'center', position: 'relative', top: -20}}>
                                <Rating
                                    initialRating={moyenNote(data)}
                                    emptySymbol={<i className="fa fa-star-o"></i>}
                                    fullSymbol={<i className="fa fa-star" style={{color: 'orange'}}></i>}
                                    readonly
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{padding: 16, borderBottom: '1px solid #ddd'}}>
                    <p style={{color: '#001e37'}}>Commentaires et avis</p>
                        {data.map((c,i)=>{
                            return(
                                c.avis &&
                                <div style={{display: 'flex'}} key={i}>
                                    <div style={styles.imageContent}>
                                        <img id={`ayena_${c.fbUserId}`} src={this.getImage(c.fbUserId)} alt="profil" style={{width: 58, height: 58, borderRadius: 80}} />
                                    </div>
                                    <div style={{width: "calc(100% - 78px)"}}>
                                        <h6 style={{fontWeight: 400, marginBottom: 0, marginTop: 8}}>{c.auteur}</h6>
                                        {/* <span style={{fontSize: 10, position: 'relative', top: -10}}>{c.rating} avis</span> */}
                                        <div style={{position: 'relative', top: -5, marginBottom: 4}}>
                                            <Rating
                                                initialRating={c.note}
                                                emptySymbol={<i className="fa fa-star-o"></i>}
                                                fullSymbol={<i className="fa fa-star" style={{color: 'orange'}}></i>}
                                                readonly
                                            />
                                            <span style={{fontSize: 10, marginLeft: 16}}>{c.avis?.date}</span>
                                        </div>
                                        <p style={{fontSize: 12, opacity: 0.7, color: '#000', lineHeight: '14px', position: 'relative', top: -12, marginBottom: 0}}>{c.avis?.message}</p>
                                        <div>
                                            {canComment && <button className="ayn-btn-donner-avis" onClick={()=>this.handleOpenReplyBox(c._id)}>Repondre</button>}
                                            <span style={{fontSize: 12, fontWeight: 'bold', float: 'right', position: 'relative', top: -14, color: '#000'}}>{`Reponses(${c.avis?.reponses ? 1/*c.avis.reponses.length*/ : 0 })`}</span>
                                        </div>
                                        {c.avis && 
                                        // c.avis.reponse.map((r,j)=>{
                                            // return(
                                                c.avis.reponse &&
                                                <div style={{marginLeft: 16, marginBottom: 8, paddingLeft: 8, borderLeft: '1px solid #ddd'}}>
                                                    <h6 style={{fontWeight: 400, display: 'inline'}}>{c.avis.reponse.username}</h6>
                                                    <span style={{fontSize: 10, marginLeft: 16}}>{c.avis.reponse.date}</span>
                                                    <p style={{fontSize: 12, opacity: 0.7, color: '#000', lineHeight: '14px', marginBottom: 0}}>{c.avis.reponse.response}</p>
                                                </div>
                                                
                                            // )
                                        // })
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    
                </div>
                {/* <Table 
                    columns={columns} data={data} permission={'2'}
                    load={load} viewOnly={true}  onViewOpen={(id) => this.handleOpenReplyBox(id)}
                /> */}

                <ReactModal isOpen={modal} ariaHideApp={false} className="alert-modal commentaire-reply">
                    <div className="modal-alert-body-content">
                        <h5>Répondre au commentaire</h5>

                        <form className="wt-formtheme wt-formregister" id="form-inscription" onSubmit={this.handleReply} >
                            <fieldset className="wt-registerformgroup">
                                <div className="form-group">
                                    <label>Redigez votre réponse *</label>
                                    <textarea className="form-control" style={{height: 120}} placeholder="Votre réponse"
                                        value={reponse} onChange={(e)=>this.handleResponseChange(e.target.value)} required>
                                    </textarea>
                                    <span className="error-span" style={{float: 'right'}}>{`${reponse.length}/${maxChar}`}</span>
                                </div>
                                
                                <div className="filter-actions-container" style={{float: 'right', marginTop: 16}}>
                                    <button type="button" className="btn btn-secondary filter-action-btn" onClick={()=>this.setState({ modal: false, reponse: '' }) }>Fermer</button>
                                    <button className="btn btn-primary filter-action-btn" type="submit" disabled={reponse ? false : true}>
                                        {btnLoad && <Loader size={12} />}
                                        {!btnLoad && 'Répondre'}
                                    </button>
                                </div>
                            </fieldset>
                        </form>
                        
                    </div>

                    {/* <div className="modal-alert-actions-container">
                        <button type="button" className="btn btn-secondary modal-action-btn" onClick={()=>this.setState({modal: false, currentId: '' })}>Fermer</button>
                        <button className="btn btn-primary modal-action-btn" onClick={()=>this.handleCerifie()}>
                            {btnLoad && <Loader size={16} />}
                            {!btnLoad && 'Répondre'}
                        </button>
                    </div> */}
                </ReactModal>

            </div>
        )
    }

}