import { Link } from 'react-router-dom'
import { useState } from 'react'
import './index.css'
import Poper from '../../Poper'
import messages from '../../../lib/messages.json'
import logo from '../../../assets/images/logo.png'

const Header = ({pathname, username, session, toast}) => {


	const handleLogout = ()=>{
		toast(messages.logout, { appearance: 'info' })
		setTimeout(function(){ 
			session.reset() 
			window.location = '/admin/login'
		}, 1000);
	}

	const [active, setActive] = useState(window.location.pathname)
	const [side, setSide] = useState(false)

	return (
		<>
			<div className="ayn-top-bar">
				<button className="ayn-admin-btn-toggle-mobile btn" type="button" onClick={()=>setSide(!side)}>
					{!side && <i className="lnr lnr-menu"></i>}
					{side && <i className="lnr lnr-cross"></i>}
				</button>
				<h6>{pathname}</h6>
				<Poper
					style={{display: 'flex', alignItems: 'center'}}
					trigger={
						<>
						<div className="ayn-avatar">
							<span>{username ? username.substring(0,2).toUpperCase() : '?'}</span>
						</div>
						<h6 style={{marginLeft: 8, marginBottom: 0, fontSize: 12, fontWeight: 200}} className="ayn-dropbtn">{username}</h6>
						</>
					}
				>
					<>
						<Link to="/admin/profile" className="pop-item">
							<i className="fas fa-user" style={{marginRight: 8}}></i>
							Profil
						</Link>
						<Link to="#" onClick={()=>handleLogout()} className="pop-item">
							<i className="fas fa-sign-in-alt" style={{marginRight: 8}}></i>
							Deconnection
						</Link>
					</>
				</Poper>
			</div>
			<div className={`d-flex flex-column nav-container ${side ? 'admin-nav-container-mobile-display' : 'admin-nav-container-mobile-none'}`}>
				<Link to="/admin/dashbord" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
					<img src={logo} style={{height: 48, margin: '6px 16px'}} alt="logo" />
				</Link>
				<hr style={{margin: 0}} />
				<ul className="nav nav-pills flex-column mb-auto">
					<li className="nav-item nav-head">Dashboard</li>
					<li className={`nav-item ${active === '/admin/dashbord' ? 'active' : ''}`}>
						<Link to="/admin/dashbord" className="ayn-nav-link" onClick={()=>setActive('/admin/dashbord')}>
							<i className="fas fa-tachometer-alt ayn-nav-icon"></i>
							Tableau de bord
						</Link>
					</li>
					
					<li className="nav-item nav-head">Gestion des marchands</li>
					<li className={`nav-item ${active === '/admin/inscriptions' ? 'active' : ''}`}>
						<Link to="/admin/inscriptions" className="ayn-nav-link" onClick={()=>setActive('/admin/inscriptions')}>
						<i className="fas fa-user-plus ayn-nav-icon"></i>
							Inscriptions
						</Link>
					</li>
					<li className={`nav-item ${active === '/admin/marchands' ? 'active' : ''}`}>
						<Link to="/admin/marchands" className="ayn-nav-link" onClick={()=>setActive('/admin/marchands')}>
							<i className="fas fa-address-card ayn-nav-icon"></i>
							Marchands
						</Link>
					</li>
					<li className={`nav-item ${active === '/admin/demandes' ? 'active' : ''}`}>
						<Link to="/admin/demandes" className="ayn-nav-link" onClick={()=>setActive('/admin/demandes')}>
							<i className="fas fa-inbox ayn-nav-icon"></i>
							Demandes
						</Link>
					</li>

					<li className="nav-item nav-head">Administrations</li>
					<li className={`nav-item ${active === '/admin/utilisateurs' ? 'active' : ''}`}>
						<Link to="/admin/utilisateurs" className="ayn-nav-link" onClick={()=>setActive('/admin/utilisateurs')}>
							<i className="fas fa-users ayn-nav-icon"></i>
							Utilisateurs
						</Link>
					</li>
				</ul>
			</div>
		</>
	)
}
export default Header