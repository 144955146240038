/*eslint array-callback-return: "off"*/
import React from 'react'
import { Doughnut, Line } from 'react-chartjs-2'
import Statcard from '../../../components/Statcard'
import messages from '../../../lib/messages.json'

/*eslint consistent-return: ["error", { "treatUndefinedAsUnspecified": false }]*/


export default class Dashbord extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            total: '', accepter: '', rejeter: '', month: '', inscription: '', changement: '', certification: '', promotion: '', forfait: '',
            regionLabels: [], regionDatas: [], demandeInscriptionLabels: [], demandeInscriptionDatas: [],
            demandeCertificationDatas: [], demandePromotionDatas: [], demandeForfaitDatas: [], demandeChangementInfoDatas: []

        }

    }

    getTotal(){
        this.props.controller.total()
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                this.setState({ total: data })
            } else {
                if (data.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                }
            }
        })
    }

    getAccepter(){
        this.props.controller.accepter()
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                this.setState({ accepter: data })
            } else {
                if (data.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                }
            }
        })
    }

    getRejeter(){
        this.props.controller.rejeter()
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                this.setState({ rejeter: data })
            } else {
                if (data.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                }
            }
        })
    }

    getRegion(){
        this.props.controller.region()
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                const regionLabels = []
                const regionDatas = []
                for(let i=0; i<data.length; i++){
                    regionLabels[i] = data[i]['_id']
                    regionDatas[i] = data[i]['count']
                }
                this.setState({ regionDatas, regionLabels})
            } else {
                if (data.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                }
            }
        })
    }

    getDemande(){
        this.props.controller.demande()
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                const demandeInscriptionLabels = []
                const demandeInscriptionDatas = []
                for(let i=0; i<data.length; i++){
                    demandeInscriptionLabels[i] = data[i]['_id']
                    demandeInscriptionDatas[i] = data[i]['nombre']
                }
                this.getDemandeCertification(demandeInscriptionLabels)
                this.setState({ demandeInscriptionDatas, demandeInscriptionLabels})
            } else {
                if (data.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                }
            }
        })
    }

    getMonth(){
        this.props.controller.month()
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                this.setState({ month: data })
            } else {
                if (data.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                }
            }
        })
    }

    getInscription(){
        this.props.controller.inscription()
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                this.setState({ inscription: data })
            } else {
                if (data.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                }
            }
        })
    }

    getNewModification(){
        this.props.controller.newModification()
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                this.setState({ changement: data })
            } else {
                if (data.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                }
            }
        })
    }

    getNewCertification(){
        this.props.controller.newCertification()
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                this.setState({ certification: data })
            } else {
                if (data.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                }
            }
        })
    }

    getNewPromotion(){
        this.props.controller.newPromotion()
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                this.setState({ promotion: data })
            } else {
                if (data.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                }
            }
        })
    }

    getNewForfait(){
        this.props.controller.newForfait()
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                this.setState({ forfait: data })
            } else {
                if (data.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                }
            }
        })
    }

    getDemandeCertification(labels){
        this.props.controller.demandeCertification()
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                const demandeCertificationDatas = []
                for(let i=0; i<labels.length; i++){
                    const demande = data.find(d=>d.id === labels[i])
                    demandeCertificationDatas[i] = demande ? demande['nombre'] : 0
                }
                this.getDemandeModification(labels)
                this.setState({ demandeCertificationDatas })
            } else {
                if (data.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                }
            }
        })
    }

    getDemandeModification(labels){
        this.props.controller.demandeModification()
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                const demandeChangementInfoDatas = []
                for(let i=0; i<labels.length; i++){
                    const demande = data.find(d=>d.id === labels[i])
                    demandeChangementInfoDatas[i] = demande ? demande['nombre'] : 0
                }
                this.getDemandePromotion(labels)
                this.setState({ demandeChangementInfoDatas })
            } else {
                if (data.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                }
            }
        })
    }

    getDemandePromotion(labels){
        this.props.controller.demandePromotion()
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                const demandePromotionDatas = []
                for(let i=0; i<labels.length; i++){
                    const demande = data.find(d=>d.id === labels[i])
                    demandePromotionDatas[i] = demande ? demande['nombre'] : 0
                }
                this.getDemandeForfait(labels)
                this.setState({ demandePromotionDatas })
            } else {
                if (data.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                }
            }
        })
    }

    getDemandeForfait(labels){
        this.props.controller.demandeForfait()
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                const demandeForfaitDatas = []
                for(let i=0; i<labels.length; i++){
                    const demande = data.find(d=>d.id === labels[i])
                    demandeForfaitDatas[i] = demande ? demande['nombre'] : 0
                }
                this.setState({ demandeForfaitDatas })
            } else {
                if (data.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                }
            }
        })
    }

    componentDidMount() {
        this.getTotal()
        this.getAccepter()
        this.getRejeter()
        this.getMonth()
        this.getRegion()
        this.getInscription()
        this.getDemande()

        this.getNewModification()
        this.getNewCertification()
        this.getNewPromotion()
        this.getNewForfait()
    }


    render() {
        // const { crud, permission } = this.props
        const { total, accepter, rejeter, month, regionDatas, regionLabels,
            inscription, certification, changement, promotion, forfait,
            demandeInscriptionLabels, demandeInscriptionDatas, demandeCertificationDatas, demandeChangementInfoDatas,
            demandeForfaitDatas, demandePromotionDatas  } = this.state

        const line = {
            // labels: ['Janv', 'Fev', 'Mars', 'Avr', 'Mai', 'Juin'],
            labels: demandeInscriptionLabels,
            datasets: [
               {
                label: "Inscriptions", backgroundColor: 'transparent', borderColor: '#001e37',
                borderWidth: 3, fill: false, data: demandeInscriptionDatas
              },
              {
                label: "Changement d'informations", backgroundColor: 'transparent',  borderColor: '#6930c3',
                borderWidth: 3, fill: false, data: demandeChangementInfoDatas
              },
              {
                label: "Certifications", backgroundColor: 'transparent',  borderColor: '#C1CFDA',
                borderWidth: 3, fill: false, data: demandeCertificationDatas
              },
              {
                label: "Promotions", backgroundColor: 'transparent',  borderColor: '#20A4F3',
                borderWidth: 3, fill: false, data: demandePromotionDatas
              },
              {
                label: "Forfaits", backgroundColor: 'transparent',  borderColor: '#03191E',
                borderWidth: 3, fill: false, data: demandeForfaitDatas
              }
            ]
          }

          
          const pie= {
            labels: regionLabels,
            datasets: [{
               data: regionDatas,
               backgroundColor: [ '#6930c3', '#bd2000', 'yellow', '#C1CFDA', '#20A4F3', '#03191E', '#e1701a', '#3f3697', '#ffc93c', '#c06014', '#e84545', '#fff600', '#faf3e0', '#ef4f4f', '#e05297' ],
            //    hoverBackgroundColor: [ '#FF6384', '#36A2EB', ]
            }]
         }

        return (
            <div className="row" style={{ margin: "0 40px" }}>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <Statcard title="Total des marchands" mb={24}>
                                <h2 style={{display: 'inline'}}>{total}</h2>
                            </Statcard>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <Statcard title="Total des marchands / mois" mb={24}>
                                <h2 style={{display: 'inline'}}>{month}</h2>
                            </Statcard>
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <Statcard title="Total des marchands validés / mois" mb={24}>
                                <h2>{accepter}</h2>
                            </Statcard>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <Statcard title="Total des marchands rejetés / mois" mb={24}>
                                <h2>{rejeter}</h2>
                            </Statcard>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                            <Statcard title="Marchands par région" mb={24}>
                                <Doughnut data={pie} options={{legend:{display: true, position: 'right'}}} /> 
                            </Statcard>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                            <div className="stat-card-container" style={{padding: 0, marginBottom: 24}}>
                                <div style={{height: 36, background: '#001e37', borderRadius: 3, padding: 8, display: 'flex', alignItems: 'center',}}>
                                    <i className="fas fa-bell" style={{fontSize: 20, color: '#fff', marginRight: 8}}></i>
                                    <h6 className="stat-card-head" style={{color: '#fff', margin: 0, textOverflow: 'ellipsis', overflow: 'hidden'}}>Notification</h6>
                                </div>
                                <div style={{padding: '12px 8px'}}>
                                    <ul style={{overflow: 'auto'}}>
                                        <li style={{whiteSpace: 'nowrap'}}>
                                            Inscriptions <span className="badge rounded-pill bg-success" style={{color: '#fff', marginLeft: 4}}>{inscription}</span>
                                        </li>
                                        <li style={{whiteSpace: 'nowrap'}}>
                                            Changement d'info. <span className="badge rounded-pill bg-success" style={{color: '#fff', marginLeft: 4}}>{changement}</span>
                                        </li>
                                        <li style={{whiteSpace: 'nowrap'}}>
                                            Certification <span className="badge rounded-pill bg-success" style={{color: '#fff', marginLeft: 4}}>{certification}</span>
                                        </li>
                                        <li style={{whiteSpace: 'nowrap'}}>
                                            Promotion <span className="badge rounded-pill bg-success" style={{color: '#fff', marginLeft: 4}}>{promotion}</span>
                                        </li>
                                        <li style={{whiteSpace: 'nowrap'}}>
                                            Forfait <span className="badge rounded-pill bg-success" style={{color: '#fff', marginLeft: 4}}>{forfait}</span>
                                        </li>
                                    </ul>
                                    {/* <span style={{fontSize: 12, fontWeight: 300}}></span> */}
                                </div>
                            </div>
                            {/* <Statcard title="Notifications">
                                
                            </Statcard> */}
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <Statcard title="Demandes des marchands traitées / mois " mb={24}>
                        <Line data={line} options={{  scales: { yAxes: [{ ticks: { min: 0 } }] }, }} />
                    </Statcard>
                </div>
            </div>
        )
    }

}