import { pendingInscription, removeInscription } from './marchandInscriptionActions'
import { pendingInscriptionUserIdSelector} from './marchandInscriptionSelector'

const initialState = localStorage.getItem('pendingInscription')

const marchandInscriptionReducer = (state = initialState, action) =>{
    switch (action.type) {
        case 'pending':
            localStorage.setItem('pendingInscription', action.payload)
            return action.payload
        case 'remove':
            localStorage.removeItem('pendingInscription')
            return initialState
        default:
            return state
    }
}

export default marchandInscriptionReducer
export {
    pendingInscription,
    removeInscription,
    pendingInscriptionUserIdSelector
}