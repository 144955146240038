/*eslint array-callback-return: "off"*/
import React from 'react'
import ReactModal from 'react-modal'
// import Poper from '../../../components/Poper'
import Table from '../../../components/Table/index'
import Detail from '../../../components/admin/Detail'
import messages from '../../../lib/messages.json'
/*eslint consistent-return: ["error", { "treatUndefinedAsUnspecified": false }]*/


// Les colonne du tableua
const columns = [
    { name: 'boutique', header: 'Nom' },
    { name: 'url', header: 'url', url: true },
    { name: 'status', header: 'statut' },
    { name: 'createdAt', header: 'date', timeStamp: true },
]

export default class Inscription extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            filterData: [],
            research: '', currentElement: {}, currentUser: {},
            load: true, detailModal: false, btnAction: false,
        }

    }

    componentDidMount() {
        this.props.controller.inscriptions()
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                this.setState({ data, filterData: data, load: false })
            } else {
                if (data.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                    this.setState({ load: false })
                }
            }
        })
    }
    

    handleView(id){
        const { data } = this.state
        const currentElement = data.find(d=>d.id === id)
        if(currentElement){
            this.props.userController.getById(currentElement.userId)
            .then(res => {
                if (typeof (res.pror) === 'undefined') {
                    this.setState({ currentElement, currentUser: res, detailModal: true })
                } else {
                    if (res.pror.indexOf('401') > 0) {
                        this.props.toast(messages.sessionExpired, { appearance: 'info' })
                    } else {
                        this.props.toast(messages.requestFailed, { appearance: 'error' })
                    }
                }
            })
        }
    }

    handleTreat(status){
        const { currentElement } = this.state
        currentElement.status = status

        this.props.controller.save({id: currentElement.id, status})
        .then(res => {
            if (typeof (res.pror) === 'undefined') {

                if(status === 'Accepter'){
                    this.props.userController.status(currentElement.userId, { status: true })
                    .then(ress => {
                        if (typeof (ress.pror) === 'undefined') {
                            const data = this.state.data.filter(d=>d.id !== currentElement.id)
                            this.setState({ data, load: false, btnAction: true })
                            this.props.toast(messages.statusChange, { appearance: 'success' })
                        } else {
                            if (ress.pror.indexOf('401') > 0) {
                                this.props.toast(messages.sessionExpired, { appearance: 'info' })
                            } else {
                                this.props.toast(messages.requestFailed, { appearance: 'error' })
                                this.setState({ btnLoad: false })
                            }
                        }
                    })
                }

            } else {
                if (res.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                }
            }
        })
    }

    searchChange(event) {
        const value = event.target.value
        const data = this.state.filterData.filter(dt => {
           return (
              dt.boutique.toLowerCase().includes(value.toLowerCase()) ||
              dt.url.toLowerCase().includes(value.toLowerCase())
           )
        })
        this.setState({ data, research: value })
    }


    render() {
        // const { crud, permission } = this.props
        const { data, research, currentElement, currentUser, load, detailModal, btnAction } = this.state
        
        return (
            <div>
                <div className="table-actions-container">
                    <div className="">
                        <input type="text" value={research} className="form-control search-input" onChange={(e)=>this.searchChange(e)} placeholder="Rechercher dans le tableau" />
                    </div>

                    <a href="/marchands/inscription" className="btn table-actions-btn table-action-btn-search">Nouvelle</a>
                    {/* <div>
                        <button className="btn table-actions-btn table-action-btn-filter">Filtrer</button>
                        <button className="btn table-actions-btn table-action-btn-search" onClick={()=>{ this.props.toast('Saved Successfully', { appearance: 'success' }) }}>Rechercher</button>
                    </div> */}
                </div>
                <Table
                    columns={columns}
                    data={data}
                    permission={'2'}
                    load={load}
                    viewOnly={true}
                    onDeleteOpen={(id) => console.log("***onDeleteOpen", id)}
                    onEditOpen={(id) => console.log("***onEditOpen", id)}
                    onViewOpen={(id) => this.handleView(id)}
                />

                <ReactModal isOpen={detailModal} ariaHideApp={false} className="detail">
                    <div className="modal-body-content">
                        <h5>Les informations détaillées</h5>
                        <Detail data={currentElement} user={currentUser} />
                    </div>

                    <div className="modal-actions-container">
                        <button className="btn btn-primary modal-action-btn" disabled={btnAction} onClick={()=>this.handleTreat('Accepter') }>Acceper</button>
                        <button className="btn btn-danger modal-action-btn" disabled={btnAction} onClick={()=>this.handleTreat('Rejeter') }>Rejeter</button>
                        <button type="button" className="btn btn-secondary modal-action-btn" onClick={()=>this.setState({detailModal: false, btnAction: false })}>Fermer</button>
                    </div>
                </ReactModal>

            </div>
        )
    }

}