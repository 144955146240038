import React from "react"
import Switch from "react-switch";
import { Link } from "react-router-dom"
// import './index.css'
import categoriesJSON from '../../../lib/categories.json'
import messages from '../../../lib/messages.json'
import axios from "axios";
import { Cropper } from "react-cropper";
import Resizer from "react-image-file-resizer";
import "cropperjs/dist/cropper.css";

const listRegion = ['Bamako', 'Kayes', 'Koulikoro', 'Sikasso', 'Ségou', 'Mopti', 'Tombouctou', 'Gao', 'Kidal']

const proprietaire = { name: '', email: '', password: '', confirmation: ''}
const boutique = { id: '', boutique: '', url: '', emailBoutique: '', adresse: '', description: '', region: '', indicatif: '+223', telephone: '', vitepay: false, frame: true, certifie: false, logo: '', couverture: '', produits: [] }
const reseaux = { facebook: '', instagram: '', linkedin: '' }
const prix = { minimum: 0, maximum: 0 }
const services = { livraison: 'Non disponible', service: "false" }

const proprietaireError = { name: {}, email: {}, password: {}, confirmation: {}}
const boutiqueError = { boutique: {}, url: {}, emailBoutique: {}, adresse: {}, region: {}, description: {}, indicatif: {}, telephone: {},  logo: {}, couverture: {}, produit1: {}, produit2: {}, produit3: {} }
const reseauxError = { facebook: {}, instagram: {}, linkedin: {} }
const prixError = { minimum: {}, maximum: {} }
const categoriesError = { categoriesSelected: {}, subcategoriesSelected: {} }


// checkRequired(name, val, min, number = false)
// checkConfirmation(name, val, val2, min)
// checkEmail(name, val, required = true)
// checkUrl(name, val, required = true)
// checkFile(name, width, height, naturalWidth, naturalHeight)

const proprietaireValid = { 
	name: {type: 'checkRequired', required: true, min: 4, max: 1024*4, number: false},
	email: {type: 'checkEmail', required: true},
	password: {type: 'checkRequired', required: true, min: 8, max: 1024*4, number: false},
	confirmation: {type: 'checkConfirmation', min: 8, val2: 'password'}
}
const boutiqueValid = { 
	boutique: {type: 'checkRequired', required: true, min: 3, max: 1024*4, number: false},
	url: {type: 'checkUrl', required: true},
	emailBoutique: {type: 'checkEmail', required: true},
	adresse: {type: 'checkRequired', required: true, min: 3, max: 1024*4, number: false},
	description: {type: 'checkRequired', required: true, min: 8, max: 1024*4, number: false},
	region: {type: 'checkRequired', required: true, min: 0, max: 1024*4, number: false},
	indicatif: {type: 'checkRequired', required: true, min: 0, max: 1024*4, number: false},
	telephone: {type: 'checkRequired', required: true, min: 8, max: 1024*4, number: true}, 
	logo: {type: 'checkFile', width: 100, height: 100, name: 'logo', multiple: false},
	couverture: {type: 'checkFile', width: 800, height: 400, name: 'couverture', multiple: false},
	produit1: {type: 'checkFile', width: 300, height: 300, name: 'produits', multiple: true, pos: 0},
	produit2: {type: 'checkFile', width: 300, height: 300, name: 'produits', multiple: true, pos: 1},
	produit3: {type: 'checkFile', width: 300, height: 300, name: 'produits', multiple: true, pos: 2}
}
const reseauxValid = { 
	facebook: {type: 'checkUrl', required: false},
	instagram: {type: 'checkUrl', required: false},
	linkedin: {type: 'checkUrl', required: false} 
}
const prixValid = { 
	minimum: {type: 'checkRequired', required: true, min: 0, max: 1024*4, number: false},
	maximum: {type: 'checkRequired', required: true, min: 0, max: 1024*4, number: false}
}
const servicesValid = { livraison: {type: 'none'}, service: {type: 'none'} }
const categoriesValid = { 
	categoriesSelected: {type: 'checkChip', min: 1},
	subcategoriesSelected: {type: 'checkChip', min: 1}
}

const validations = { ...proprietaireValid, ...boutiqueValid, ...reseauxValid, ...prixValid, ...servicesValid, ...categoriesValid }

// const formOneItems = ['name', 'email', 'password', 'confirmation']

const formTwoItems = ['boutique', 'url', 'adresse', 'region', 'indicatif', 'telephone', 'emailBoutique', /*'vitepay', 'certifie', */
	'facebook', 'instagram', 'linkedin', 'categoriesSelected', 'subcategoriesSelected',
	'minimum', 'maximum', 'livraison', 'service', 'logo', 'couverture', 'produit1', 'produit2', 'produit3'
]

const logoCrop =  { width: 100, height: 100 }
const couvertureCrop =  { width: 800, height: 400 }
const produitCrop =  { width: 300, height: 300 }

export default class Modification extends React.Component {
    constructor(props){
        super(props)
        this.state = {
			errors: {...proprietaireError, ...boutiqueError, ...reseauxError, ...prixError, ...categoriesError },
			urlvalid: 0,
			categoriesSelected: [],
			subcategoriesSelected: [],
			categories: categoriesJSON,
			subcategories: [],

			// userId: '6066efee8ec39409ca89def8',

			...proprietaire,
			...boutique,
			...reseaux,
			...prix,
			...services,

			produits: [],
			prdPrvImg1: '',
			prdPrvImg2: '',
			prdPrvImg3: '',

			logoPrvImg: '',
			couverturePrvImg: '',

			currentlogo: '', startXlogo: 0, startYlogo: 0, sWidthlogo: 0, sHeightlogo: 0,
			currentcouverture: '', startXcouverture: 0, startYcouverture: 0, sWidthcouverture: 0, sHeightcouverture: 0,
			currentproduit1: '', startXproduit1: 0, startYproduit1: 0, sWidthproduit1: 0, sHeightproduit1: 0,
			currentproduit2: '', startXproduit2: 0, startYproduit2: 0, sWidthproduit2: 0, sHeightproduit2: 0,
			currentproduit3: '', startXproduit3: 0, startYproduit3: 0, sWidthproduit3: 0, sHeightproduit3: 0,
        }

		this.handleSubmit = this.handleSubmit.bind(this)
		// this.handleProduitsChoose = this.handleProduitsChoose.bind(this)
    }

	validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    validURL(url) {
        const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocole
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // nom de domaine
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // ou adresse ip v4
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and chemin
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query paramatre
          '(\\#[-a-z\\d_]*)?$','i'); // localisateur de fagment
        return !!pattern.test(url);
    }

	handleTextChange(e, name){ this.setState({ [name]: e.target.value }) }

	checkConfirmation(name, val, val2, min){
		const errors = this.state.errors
		errors[name]['required'] = !val ? `*Ce champ est obligatoire` : null
		errors[name]['min'] = val.length < min ? `*Ce champ doit avoir au moins ${min} caracteres` : null
		errors[name]['different'] = val !== val2 ? `*Mot de passe et confirmation ne correspondent pas` : null
		this.setState({errors})
	}

	checkRequired(name, val, min, number = false, max=1024*4){
		// console.log("Number(val)", val)
		const errors = this.state.errors
		errors[name]['required'] = !val ? `*Ce champ est obligatoire` : null
		errors[name]['min'] = val.length < min ? `*Ce champ doit avoir au moins ${min} caracteres` : null
		errors[name]['max'] = val.length > max ? `*Ce champ doit avoir au plus ${max} caracteres` : null
		if(number){
			// console.log("Number(val)", val, isNaN(val))
			errors[name]['number'] = isNaN(val) ? `*Ce champ doit être numérique` : null
		}
		this.setState({errors})
	}
	checkEmail(name, val, required = true){
		const errors = this.state.errors
		errors[name]['required'] = !val && required ? `*Ce champ est obligatoire` : null
		errors[name]['valide'] = val ? this.validateEmail(val) ? null : `*Cet adresse email n'est valide` : ''
		this.setState({errors})
	}
	checkUrl(name, val, required = true){
		const errors = this.state.errors
		errors[name]['required'] = !val && required ? `*Ce champ est obligatoire` : null
		errors[name]['valide'] = val ? this.validURL(val) ? null : `*Cet URL n'est valide` : null
		this.setState({errors})
		// if(name === 'url'){
		// 	this.checkUrlValid(val)
		// }
	}

	checkFile(name, width, height, naturalWidth, naturalHeight){
		const errors = this.state.errors
				
		if(name === 'logo' || name === 'produit1' || name === 'produit2' || name === 'produit3'){ errors[name]['square'] = naturalWidth === naturalHeight ?  null : `*La taille du fichier n'est pas valide, votre fichier doit être un carré` }
		if(name === 'couverture'){ errors[name]['rectangle'] = naturalWidth > naturalHeight ?  null : `*La taille du fichier n'est pas valide, votre fichier doit être rectangulaire` }
		
		if(name === 'logo'){ errors[name]['min'] = naturalWidth < 100 || naturalHeight < 100 ?  `*La taille du fichier n'est pas valide, votre fichier doit avoir une taille minimale de 100x100` : null }
		if( name === 'produit1' || name === 'produit2' || name === 'produit3'){ errors[name]['min'] = naturalWidth < 100 || naturalHeight < 100 ?  `*La taille du fichier n'est pas valide, votre fichier doit avoir une taille minimale de 300x300` : null }
		if(name === 'couverture'){ errors[name]['min'] = naturalWidth < 800 || naturalHeight < 400 ?  `*La taille du fichier n'est pas valide, votre fichier doit avoir une taille minimale de 800x400` : null }

		errors[name]['valide'] = width === naturalWidth && height === naturalHeight ?  null : `*La taille du fichier n'est pas valide, votre fichier est de (${naturalWidth}x${naturalHeight}) au lieu de (${width}x${height})`
		this.setState({errors})
	}

	checkFileOnSubmit(name, val, width, height, naturalWidth, naturalHeight){
		const errors = this.state.errors
				
		if(name === 'logo' || name === 'produit1' || name === 'produit2' || name === 'produit3'){ errors[name]['square'] = naturalWidth === naturalHeight ?  null : `*La taille du fichier n'est pas valide, votre fichier doit être un carré` }
		if(name === 'couverture'){ errors[name]['rectangle'] = naturalWidth > naturalHeight ?  null : `*La taille du fichier n'est pas valide, votre fichier doit être rectangulaire` }
		
		if(name === 'logo'){ errors[name]['min'] = naturalWidth < 100 || naturalHeight < 100 ?  `*La taille du fichier n'est pas valide, votre fichier doit avoir une taille minimale de 100x100` : null }
		if( name === 'produit1' || name === 'produit2' || name === 'produit3'){ errors[name]['min'] = naturalWidth < 100 || naturalHeight < 100 ?  `*La taille du fichier n'est pas valide, votre fichier doit avoir une taille minimale de 300x300` : null }
		if(name === 'couverture'){ errors[name]['min'] = naturalWidth < 800 || naturalHeight < 400 ?  `*La taille du fichier n'est pas valide, votre fichier doit avoir une taille minimale de 800x400` : null }

		errors[name]['required'] = !val ? `*Ce champ est obligatoire` : null
		errors[name]['valide'] = width === naturalWidth && height === naturalHeight ?  null : `*La taille du fichier n'est pas valide, votre fichier est de (${naturalWidth}x${naturalHeight}) au lieu de (${width}x${height})`
		this.setState({errors})
	}

	checkChip(name, val, min = 1){
		const errors = this.state.errors
		errors[name]['required'] = val.length < min ? `*Vous devez chosir au moins ${min} élément` : null
		this.setState({errors})
	}

	checkUrlValid(url){
		// 0 initial; 1 load; 2 valid; 3 invalid
		this.setState({urlvalid: 1 })
		const errors = this.state.errors
		axios.get(url)
		.then(()=> {
			errors['url']['fonctionne'] = `*Cet URL n'est pas accessible` 
			this.setState({urlvalid: 2, errors })
		})
		.catch(()=> {
			errors['url']['fonctionne'] = `*Cet URL n'est pas accessible` 
			this.setState({urlvalid: 3, errors })
		})
	}

	handleFileLoad(e, name, w, h){
		this.setState({[`${name}Params`]: { w: e.target.naturalWidth, h: e.target.naturalHeight} })
		this.checkFile(name, w, h, e.target.naturalWidth, e.target.naturalHeight)
	}

	formHasError(items){
		let err = false
		let errors = {}
		// eslint-disable-next-line
		Object.keys(this.state.errors).map((key, i) => {
			for(let t = 0; t<items.length; t++){
				if(items[t] === key){
					let error = this.state.errors[key]
					let e = {}
					// eslint-disable-next-line
					Object.keys(error).map((k, j) => {
						if(error[k]){
							err = true
							e[k] = error[k]
						}
					})
					errors[key] = e
				}
			}
		})
		
		return { err, errors }
	}

	checkValidations(items){
		// let err = false
		// let errors = {}
		for(let i=0; i<items.length; i++){
			// console.log("===>",items[i])
			let item = validations[items[i]]
			if(typeof(item) !== 'undefined'){
				if(item['type'] === 'checkRequired'){
					this.checkRequired(items[i], this.state[items[i]], item['min'], item['number'], item['max'] )
				}else{
					if(item['type'] === 'checkConfirmation'){
						this.checkConfirmation(items[i], this.state[items[i]], this.state[item['val2']], item['min'] )
					}else{
						if(item['type'] === 'checkEmail'){
							this.checkEmail(items[i], this.state[items[i]], item['required'] )
						}else{
							if(item['type'] === 'checkUrl'){
								this.checkUrl(items[i], this.state[items[i]], item['required'] )
							}else{
								if(item['type'] === 'checkFile'){
									let params = this.state[`${items[i]}Params`]
									// console.log("params=====>", params)
									if(item['multiple']){
										// console.log("multiple item['name']=====>", item['name'])
										// console.log("multiple [item['pos']]=====>", item['pos'])
										this.checkFileOnSubmit(items[i], this.state[item['name']][item['pos']], item['width'], item['height'], params ? params.w : null, params ? params.h : null )
									}else{
										this.checkFileOnSubmit(items[i], this.state[items[i]], item['width'], item['height'], params ? params.w : null, params ? params.h : null )
									}
								}else{
									if(item['type'] === 'checkChip'){
										this.checkChip(items[i], this.state[items[i]], item['min'] )
									}
								}
							}
						}	
					}
				}
			}
		}
	}

	hasError(errors){
		let erreur = false
		// eslint-disable-next-line
		Object.keys(errors).map(key => {
			let error = errors[key]
			// eslint-disable-next-line
			Object.keys(error).map(k => {
				if(error[k]){
					erreur = true
				}
			})
		})
		return erreur
	}

	async handleImageChoose(e, name){
		if(name === 'produits'){
			if(e.target.files.length < 3){
				// console.log("ssss", typeof(e.target.files[0]))
				const produits = this.state.produits
				produits.push(...e.target.files)
				// console.log("ss+++++ss", typeof(produits[produits.length - 2])) 
				this.setState({
					produits: produits, 
					prdPrvImg1: produits[produits.length - 1] ? typeof(produits[produits.length - 1]) === 'string' ? this.state.prdPrvImg1 : URL.createObjectURL(produits[produits.length - 1]) : null,
					prdPrvImg2: produits[produits.length - 2] ? typeof(produits[produits.length - 2]) === 'string' ? this.state.prdPrvImg2 : URL.createObjectURL(produits[produits.length - 2]) : null,
					prdPrvImg3: produits[produits.length - 3] ? typeof(produits[produits.length - 3]) === 'string' ? this.state.prdPrvImg3 : URL.createObjectURL(produits[produits.length - 3]) : null
				})

			}else{
				this.setState({ 
					produits: [e.target.files[0], e.target.files[1], e.target.files[2]], 
					prdPrvImg1: URL.createObjectURL(e.target.files[0]),
					prdPrvImg2: URL.createObjectURL(e.target.files[1]),
					prdPrvImg3: URL.createObjectURL(e.target.files[2])
				})
			}
		}else{
			if(name === 'logo'){
				this.setState({ logo: e.target.files[0], logoPrvImg: URL.createObjectURL(e.target.files[0]) })
			}else{
				this.setState({ couverture: e.target.files[0], couverturePrvImg: URL.createObjectURL(e.target.files[0]) })
			}
		}
	}

	handleImageChoose2(file, name, pos=0){
		if(name !== 'logo' && name !== 'couverture'){
			const produits = this.state.produits
			produits[pos] = file
			this.setState({ produits: produits, [`prdPrvImg${pos+1}`]: URL.createObjectURL(file)})
		}else{
			if(name === 'logo'){
				this.setState({ logo: file, logoPrvImg: URL.createObjectURL(file) })
			}else{
				this.setState({ couverture: file, couverturePrvImg: URL.createObjectURL(file) })
			}
		}
	}

	handleSubmit(e){
		e.preventDefault()
		this.checkValidations(formTwoItems)
		const error = this.hasError(this.state.errors)

		if(!error){
			const { id, boutique, url, adresse, region, indicatif, telephone, emailBoutique, vitepay, certifie, frame, description,
				facebook, instagram, linkedin, categoriesSelected, subcategoriesSelected,
				minimum, maximum, livraison, service,/* userId,*/
				logo, couverture, produits
			} = this.state


			const body = {
				marchandId: id, boutique, url, adresse, region, telephone: `${indicatif} ${telephone}`, email: emailBoutique, vitepay, frame, certifie, description,
				reseaux: { facebook, instagram, linkedin, },
				tags: { categories: categoriesSelected, sousGategories: subcategoriesSelected,
					prix: { minimum: parseInt(minimum), maximum: parseInt(maximum) },
				},
				services: { livraison, service: service === 'true' ? true : false },
				// userId: userId
			}
			const files = {logo: logo, couverture, produits: [produits[0], produits[1], produits[2]]}

			// console.log(";;;;", body)

			this.props.updateController.updateMarchand(files, JSON.stringify(body))
			.then(data => {
				if (typeof (data.pror) === 'undefined') {
					this.props.toast(messages.save, { appearance: 'success' })
					// this.setState({
					// 	urlvalid: 0, etape: 1,
					// 	errors: {...proprietaireError, ...boutiqueError, ...reseauxError, ...prixError, ...categoriesError },
					// 	categoriesSelected: [], subcategoriesSelected: [], categories: categoriesJSON, subcategories: [],
					// 	/*...proprietaire,*/ ...boutique, ...reseaux, ...prix, ...services,
					// 	prdPrvImg1: '', prdPrvImg2: '', prdPrvImg3: '', logoPrvImg: '', couverturePrvImg: ''
					// })
					
				} else {
					if (data.pror.indexOf('401') > 0) {
						this.props.toast(messages.sessionExpired, { appearance: 'info' })
					} else {
						this.props.toast(messages.requestFailed, { appearance: 'error' })
						// this.setState({ alert: true, message: data.pror, typeMessage: 'error', modalBtnLoad: false, modal: false, modalContrat: false })
					}
				}
			})
		}else{
			this.props.toast(messages.formError, { appearance: 'error' })
		}
	}

	filter(flt){
        let res = []
        for(let i=0; i<flt.length; i++){
            const f = categoriesJSON.filter(c=>c.name === flt[i])
            res.push(...f)
        }
        return res
    }

	selectCategorie(cat){
        const { categoriesSelected } = this.state
        let slt = categoriesSelected
        if(this.state[`cat_${cat}`]){
            slt = categoriesSelected.filter(s=> s !== cat)
            if(slt[0]){
                const subcategories = this.filter(slt)
                this.setState({ [`cat_${cat}`]: '', categoriesSelected: slt, subcategories })
            }else{
                this.setState({ [`cat_${cat}`]: '', categoriesSelected: slt, subcategories: [] })
            }
        }else{
            slt.push(cat)
            const subcategories = this.filter(slt)
            this.setState({[`cat_${cat}`]: 'active', categoriesSelected: slt, subcategories})
        }
    }

	selectSubcategorie(cat){
        const { subcategoriesSelected } = this.state
        let slt = subcategoriesSelected
        if(this.state[`subcat_${cat}`]){
            slt = subcategoriesSelected.filter(s=> s !== cat)
            if(slt[0]){
                // const subcategories = this.filter(slt)
                this.setState({ [`subcat_${cat}`]: '', subcategoriesSelected: slt/*, subcategories*/ })
            }else{
                this.setState({ [`subcat_${cat}`]: '', subcategoriesSelected: slt/*, subcategories: []*/ })
            }
        }else{
            slt.push(cat)
            // const subcategories = this.filter(slt)
            this.setState({[`subcat_${cat}`]: 'active', subcategoriesSelected: slt/*, subcategories*/})
        }
    }

	getCroppedImg(image, detail, name) {
		// this.setState({ currentLogo: image, startX: detail.x, startY: detail.y, sWidth: detail.width, sHeight: detail.height })
		this.setState({ [`current${name}`]: image, [`startX${name}`]: detail.x, [`startY${name}`]: detail.y, [`sWidth${name}`]: detail.width, [`sHeight${name}`]: detail.height })
	}

	croppedImg(crop, filename, name, pos=0) {
		// const { currentLogo, startX, startY, sWidth, sHeight } = this.state
		const canvas = document.createElement('canvas');
		canvas.width = this.state[`sWidth${name}`]
		canvas.height = this.state[`sHeight${name}`]
		const ctx = canvas.getContext('2d');
		
		ctx.drawImage(
			this.state[`current${name}`],
			this.state[`startX${name}`],
			this.state[`startY${name}`],
			this.state[`sWidth${name}`],
			this.state[`sHeight${name}`],
			0,
			0,
			this.state[`sWidth${name}`],
			this.state[`sHeight${name}`],
		);

		const base64Image = canvas.toDataURL('image/png')
		const file = this.b64toFile(base64Image, filename)
		this.resizeFile(file, crop.width, crop.height, name, pos)
	}

	resizeFile = (file, width, height, name, pos=0) =>{
		new Promise((resolve) => {
			Resizer.imageFileResizer( file, width, height, "png", 0, 0,
			(uri) => { resolve(uri);
				this.handleImageChoose2(uri, name, pos)
			},
			"file", width, height);
		});
	}

	b64toByteArrays(b64Data) {
		// contentType = "image/png";
		const sliceSize = 512;
	
		const byteCharacters = atob(
		  b64Data.toString().replace(/^data:image\/(png|jpeg|jpg|webp);base64,/, "")
		);
		const byteArrays = [];
	
		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		  let slice = byteCharacters.slice(offset, offset + sliceSize);
	
		  const byteNumbers = new Array(slice.length);
		  for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		  }
	
		  const byteArray = new Uint8Array(byteNumbers);
	
		  byteArrays.push(byteArray);
		}
		return byteArrays;
	}

	b64toFile(b64Data, fileName="test-logo") {
		const byteArrays = this.b64toByteArrays(b64Data);
		const file = new File(byteArrays, fileName, { type: 'image/png', lastModified: new Date() });
		return file;
	}

	componentDidMount(){
		this.props.controller.findById(this.props.marchandId)
		.then(res => {
			if (typeof (res.pror) === 'undefined') {
				this.setState({
					id: res._id, boutique: res.boutique, url: res.url, adresse: res.adresse, telephone: res.telephone.split(' ')[1], emailBoutique: res.email, vitepay: res.vitepay,
					facebook: res.reseaux?.facebook, instagram: res.reseaux?.instagram, linkedin: res.reseaux?.linkedin, description: res.description, region: res.region,
					minimum: res.tags.prix.minimum, maximum: res.tags.prix.maximum, livraison: res.services.livraison, service: `${res.services.service}`,
					prdPrvImg1: res.produits[0], prdPrvImg2: res.produits[1], prdPrvImg3: res.produits[2],
					logoPrvImg: res.logo, couverturePrvImg: res.couverture,
					logo: res.logo, couverture: res.couverture, produits: [res.produits[0], res.produits[1], res.produits[2]],
					// categoriesSelected: res.tags.categories,
					// userId: res.userId
					// marchandId: res._id
				})
				for(let i=0; i< res.tags.categories.length; i++){
					this.selectCategorie(res.tags.categories[i])
				}
				for(let i=0; i< res.tags.sousGategories.length; i++){
					this.selectSubcategorie(res.tags.sousGategories[i])
				}				
			} else {
				if (res.pror.indexOf('401') > 0) {
					this.props.toast(messages.sessionExpired, { appearance: 'info' })
				} else {
					this.props.toast(res.pror, { appearance: 'error' })
				}
			}
		})
	}

    render() {
        const {
			boutique, url, emailBoutique, adresse, region, indicatif, telephone, description, vitepay, frame, /*logo, couverture, produits,*/
			minimum, maximum,
			livraison, service, errors, urlvalid,
			facebook, instagram, linkedin,
			logoPrvImg, couverturePrvImg, prdPrvImg1, prdPrvImg2, prdPrvImg3,
			categories, subcategories, categoriesSelected, subcategoriesSelected,
		} = this.state
        // const { isMobile } = this.props

        return (
            <div className="wt-haslayout wt-main-section" style={{padding: 0}}>
					<div className="container">
						<div className="row justify-content-md-center">
							<div className="col-xs-12 col-sm-12 col-md-10 push-md-1 col-lg-8 push-lg-2">
								<div className="wt-registerformhold" style={{backgroundColor: 'transparent', paddingTop: 0}}>
									<div className="wt-registerformmain">
										<div className="wt-registerhead">
											<div className="wt-title">
												<h3 style={{marginBottom: 4}}>Modification de vos informations</h3>
											</div>
											<div className="wt-description">
												<p style={{lineHeight: '14px', fontSize: 12}}>Veuillez saisir vos nouvelles informations</p>
											</div>
										</div>
										<div className="wt-joinforms">
											<form className="wt-formtheme wt-formregister" id="form-inscription" onSubmit={this.handleSubmit}>
												<fieldset className="wt-registerformgroup">
													<div className="form-card">
														<div className="form-card-header">
															<h4>INFORMATION DE LA BOUTIQUE</h4>
														</div>

														<div className="form-card-body">
															<div className="form-group">
																<label>Marchand/Nom de la boutique en ligne*</label>
																<input onBlur={()=>this.checkRequired('boutique', boutique, 3)} type="text" className="form-control" placeholder="ex: Boutique" value={boutique} onChange={(e)=>this.handleTextChange(e, 'boutique')} required/>
																{Object.keys(errors.boutique).map((k, i) => {
																	return <span key={i} className="error-span">{errors.boutique[k]}</span>
																})}
															</div>
															<div className="form-group">
																<label>Email de la boutique*</label>
																<input onBlur={()=>this.checkEmail('emailBoutique', emailBoutique)} type="text" className="form-control" placeholder="ex: exemple@domaine.com" value={emailBoutique} onChange={(e)=>this.handleTextChange(e, 'emailBoutique')} required/>
																{Object.keys(errors.emailBoutique).map((k, i) => {
																	return <span key={i} className="error-span">{errors.emailBoutique[k]}</span>
																})}
															</div>
															<div className="form-group has-feedback">
																<label>URL du site web*</label>
																<input onBlur={()=>this.checkUrl('url', url)} type="text" className="form-control" placeholder="ex: https://nomdedomaine.com" value={url} onChange={(e)=>this.handleTextChange(e, 'url')} required/>
																{urlvalid === 1 && <span className="fa fa-spinner form-control-feedback myspin"></span>}
																{urlvalid === 2 && <span className="fa fa-check-circle success form-control-feedback"></span>}
																{urlvalid === 3 && <span className="fa fa-times-circle error form-control-feedback"></span>}
																{Object.keys(errors.url).map((k, i) => {
																	return <span key={i} className="error-span">{errors.url[k]}</span>
																})}
															</div>
															<div className="form-group">
																<label>Adresse de la boutique*</label>
																<input onBlur={()=>this.checkRequired('adresse', adresse, 3)} type="text" className="form-control" placeholder="ex: Kalaban coura" value={adresse} onChange={(e)=>this.handleTextChange(e, 'adresse')} required/>
																{Object.keys(errors.adresse).map((k, i) => {
																	return <span key={i} className="error-span">{errors.adresse[k]}</span>
																})}
															</div>
															<div className="form-group">
																<label>Region*</label>
																<span className="wt-select">
																	<select  value={region} onChange={(e)=>{this.handleTextChange(e, 'region'); this.checkRequired('region', e.target.value, 0) }} required>
																		<option value=""> Region </option>
																		{listRegion.map((r,i)=> <option value={r} key={i}>{r}</option>)}
																	</select>
																</span>
																{Object.keys(errors.region).map((k, i) => {
																	return <span key={i} className="error-span">{errors.region[k]}</span>
																})}
															</div>
															<div className="form-group">
																<label>Numéro de téléphone de la boutique *</label>
																<div className="input-group mb-3">
																	<span className="wt-select" style={{width: 100}}>
																		<select required value={indicatif} readOnly>
																			<option>+223</option>
																		</select>
																	</span>
																	<input onBlur={()=>this.checkRequired('telephone', telephone, 8, true, 8)} type="number" minLength={8} maxLength={8} className="form-control" placeholder="ex: 20 24 49 15" value={telephone} onChange={(e)=>this.handleTextChange(e, 'telephone')} required/>
																</div>
																{Object.keys(errors.telephone).map((k, i) => {
																	return <span key={i} className="error-span">{errors.telephone[k]}</span>
																})}
															</div>

															<div className="form-group">
																<label>Description de la boutique *</label>
																<textarea onBlur={()=>this.checkRequired('description', description, 8)} rows="2" className="form-control" placeholder="Description de la boutique" value={description} onChange={(e)=>this.handleTextChange(e, 'description')} required></textarea>
																{Object.keys(errors.description).map((k, i) => {
																	return <span key={i} className="error-span">{errors.description[k]}</span>
																})}
															</div>								

															<div className="form-group" style={{display: 'flex', alignItems: 'center'}}>
																<span style={{marginRight: 32, fontSize: 12}}>Etes-vous un marchand Vitepay ?</span>
																<Switch onChange={(val)=>this.setState({vitepay: val})} checkedIcon={false} uncheckedIcon={false} onColor='#001e37' height={24} checked={vitepay} />
															</div>

															<div className="form-group" style={{display: 'flex', alignItems: 'center'}}>
																<span style={{marginRight: 32, fontSize: 12}}>Le site peut-il s'afficher sur daneela ?</span>
																<Switch onChange={(val)=>this.setState({frame: val})} checkedIcon={false} uncheckedIcon={false} onColor='#001e37' height={24} checked={frame} />
															</div>
														</div>
														
													</div>

													<div className="form-card">
														<div className="form-card-header">
															<h4>LIENS DES RESEAUX SOCIAUX</h4>
														</div>
														<div className="form-card-body">
															<div className="form-group">
																<label>Facebook</label>
																<input onBlur={()=>this.checkUrl('facebook', facebook, false)} type="text" className="form-control" placeholder="Lien du compte facebook" value={facebook} onChange={(e)=>this.handleTextChange(e, 'facebook')} />
																{Object.keys(errors.facebook).map((k, i) => {
																	return <span key={i} className="error-span">{errors.facebook[k]}</span>
																})}
															</div>
															<div className="form-group">
																<label>Instagram</label>
																<input onBlur={()=>this.checkUrl('instagram', instagram, false)} type="text" className="form-control" placeholder="Lien du compte instagram" value={instagram} onChange={(e)=>this.handleTextChange(e, 'instagram')} />
																{Object.keys(errors.instagram).map((k, i) => {
																	return <span key={i} className="error-span">{errors.instagram[k]}</span>
																})}
															</div>
															<div className="form-group">
																<label>Linkedin</label>
																<input onBlur={()=>this.checkUrl('linkedin', linkedin, false)} type="text" className="form-control" placeholder="Lien du compte linkedin" value={linkedin} onChange={(e)=>this.handleTextChange(e, 'linkedin')} />
																{Object.keys(errors.linkedin).map((k, i) => {
																	return <span key={i} className="error-span">{errors.linkedin[k]}</span>
																})}
															</div>
														</div>
													</div>

													<div className="form-card">
														<div className="form-card-header">
															<h4>CATEGORIES ET SOUS CATEGORIES</h4>
														</div>
														<div className="form-card-body">
															<div className="form-group" style={{marginTop: 8}}>
																{Object.keys(errors.categoriesSelected).map((k, i) => {
																	return <span key={i} className="error-span">{errors.categoriesSelected[k]}</span>
																})}
																<h6 className="form-title">Categories</h6>
															</div>
															<ul className="wt-filtertag ayn-cat-ul form-cat-ul" style={{marginBottom: 8}}>
																{categories.map((c,i)=>{
																	return(
																		<li className="ayn-cat-li" key={i}>
																			<Link to="#" onClick={()=>this.selectCategorie(c.name)} 
																				className={this.state[`cat_${c.name}`]}><span>{c.name}</span></Link>
																		</li>
																	)
																})}
															</ul>
															
															{categoriesSelected.length > 0 &&
															<ul className="wt-filtertag ayn-cat-ul form-subcat-ul">
															{categoriesSelected.map((c,i)=>{
																return(
																	<li className="ayn-cat-li" key={i}>
																		<Link to="#" onClick={()=>this.selectCategorie(c)}  className={this.state[`cat_${c}`]}>
																			<span>{c}</span>
																			<i className="fa fa-times close cat-closer"></i>
																		</Link>
																	</li>
																)
															})}
															</ul>}
															
															{subcategories.length > 0 &&
															<>
																<div className="form-group form-title-container">
																	{Object.keys(errors.subcategoriesSelected).map((k, i) => {
																		return <span key={i} className="error-span">{errors.subcategoriesSelected[k]}</span>
																	})}
																	<h6 className="form-title">Sous categories</h6>
																</div>
																<ul className="wt-filtertag ayn-cat-ul" style={{justifyContent: 'start', flexWrap: 'wrap'}}>
																	{subcategories.map((c,i)=>{
																		return(
																			<ul className="wt-filtertag ayn-cat-ul form-subcat-ul" style={{margin: '8px 0'}} key={i}>
																				{c.children.map((s,j)=>{
																					return(
																						<li className="ayn-cat-li" key={j}>
																							<Link to="#"
																								onClick={()=>this.selectSubcategorie(s.name)} 
																								className={this.state[`subcat_${s.name}`]}><span>{s.name}</span></Link>
																						</li>
																					)
																				})}
																			</ul>
																		)
																	})}
																</ul>
															</>}

															{subcategoriesSelected.length > 0 &&
															<ul className="wt-filtertag ayn-cat-ul form-subcat-ul">
															{subcategoriesSelected.map((c,i)=>{
																return(
																	<li className="ayn-cat-li" key={i}>
																		<Link to="#" onClick={()=>this.selectSubcategorie(c)}  className={this.state[`subcat_${c}`]}>
																			<span>{c}</span>
																			<i className="fa fa-times close cat-closer"></i>
																		</Link>
																	</li>
																)
															})}
															</ul>}
														</div>
													</div>


													<div className="form-group form-title-container">
														<h6 className="form-title">Fourchette de prix</h6>
													</div>
													<div className="form-group" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
														<label>Min:*</label>
														<span className="wt-select" style={{width: 150}}>
															<select value={minimum} onChange={(e)=>{ this.handleTextChange(e, 'minimum'); this.checkRequired('minimum', e.target.value, 0) }} required>
																<option value=''>Min</option>
																<option value={100}>100</option>
																<option value={1000}>1.000</option>
																<option value={25000}>25.000</option>
															</select>
															{Object.keys(errors.minimum).map((k, i) => {
																return <span key={i} className="error-span">{errors.minimum[k]}</span>
															})}
														</span>
														<label>Max:*</label>
														<span className="wt-select" style={{width: 150}}>
															<select value={maximum} onChange={(e)=>{ this.handleTextChange(e, 'maximum'); this.checkRequired('maximum', e.target.value, 0) }} required>
																<option value=''>Max</option>
																<option value={25000}>25.000</option>
																<option value={30000}>30.000</option>
																<option value={100000}>100.000</option>
															</select>
															{Object.keys(errors.maximum).map((k, i) => {
																return <span key={i} className="error-span">{errors.maximum[k]}</span>
															})}
														</span>
													</div>

													<div className="form-group form-title-container">
														<h6 className="form-title">Services suplementaire</h6>
													</div>
													<div className="form-group form-radio-container">
														<label style={{width: 200}}>Livraison:</label>
														<div className="wt-radioboxholder form-radio-content">
															<div className="form-radio">
																<span className="form-radio-label">Non disponible</span>
																<span className="wt-radio">
																	<input id="liv-nondisponible" type="radio" name="livraison" value="Non disponible" onChange={(e)=>this.handleTextChange(e, 'livraison')} checked={livraison === "Non disponible"} />
																	<label htmlFor="liv-nondisponible"></label>
																</span>
															</div>
															<div className="form-radio">
																<span className="form-radio-label">Gratuite</span>
																<span className="wt-radio">
																	<input id="liv-gratuite" type="radio" name="livraison" value="Gratuite" onChange={(e)=>this.handleTextChange(e, 'livraison')} checked={livraison === "Gratuite"} />
																	<label htmlFor="liv-gratuite"></label>
																</span>
															</div>
															<div className="form-radio">
																<span className="form-radio-label">Payante</span>
																<span className="wt-radio">
																	<input id="liv-payant" type="radio" name="livraison" value="Payante" onChange={(e)=>this.handleTextChange(e, 'livraison')} checked={livraison === "Payante"} />
																	<label htmlFor="liv-payant"></label>
																</span>
															</div>
														</div>
													</div>
													<div className="form-group form-radio-container">
														<label style={{width: 200}}>Services après vente:</label>
														<div className="wt-radioboxholder form-radio-content">
															<div className="form-radio">
																<span className="form-radio-label">Oui</span>
																<span className="wt-radio">
																	<input id="sav-oui" type="radio" name="serviceApresVente" value={true} onChange={(e)=>this.handleTextChange(e, 'service')} checked={service === "true"} />
																	<label htmlFor="sav-oui"></label>
																</span>
															</div>
															<div className="form-radio">
																<span className="form-radio-label">Non</span>
																<span className="wt-radio">
																	<input id="sav-non" type="radio" name="serviceApresVente" value={false} onChange={(e)=>this.handleTextChange(e, 'service')} checked={service === "false"}/>
																	<label htmlFor="sav-non"></label>
																</span>
															</div>
														</div>
													</div>
													
													<div className="form-group form-group-label form-file-selecter logo-selecter">
														<label className="file-label">Veuillez importer le logo</label>
														<span className="file-label-span">(100 x 100)</span>
														<div className="wt-labelgroup">
															<label htmlFor="logo">
																<span className="wt-btn file-selecter-btn">Choisir le logo</span>
																<input type="file" name="file" style={{display: 'none'}} id="logo" min="1" max="1" onChange={(e)=>this.handleImageChoose(e, 'logo')} accept=".png, .jpeg, .jpg" />
															</label>
														</div>
														{logoPrvImg &&
															this.state.logoParams &&
																this.state.logoParams.w !== 100 &&
																	this.state.logoParams.h !== 100 &&
																		<>
																			<Cropper
																				style={{ height: 400, width: "100%" }} zoomTo={0} cropBoxResizable={false}
																				restore={false} src={logoPrvImg} viewMode={1} guides={false}
																				// minCropBoxHeight={100} minCropBoxWidth={100} responsive={true}
																				dragMode='move' data={{ height: 100, width: 100, rotate: 0, scaleX: 1, scaleY: 1 }}
																				initialAspectRatio={16 / 16} aspectRatio={16 / 16} autoCropArea={0.8} heckOrientation={false}
																				
																				crop={(e)=>{
																					this.getCroppedImg(e.target, e.detail, 'logo')
																				}}
																			/>
																			<button className="btn btn-primary" type="button" style={{ fontSize: 12, marginTop: 8 }} onClick={()=>this.croppedImg(logoCrop, 'logo.png', 'logo')}>
																				Redimensionner
																			</button>
																		</>
														}
														<div className="wt-uploadingbox" style={{margin: 8}}>
															<div className="wt-designimg">
																<label htmlFor="demoq">
																	{logoPrvImg && <img src={logoPrvImg} onLoad={(e)=>this.handleFileLoad(e, 'logo', 100, 100)} alt="img logo" style={{borderRadius: 8, width: 100, height: 100}} />}
																	{!logoPrvImg &&
																	<svg className="bd-placeholder-img img-thumbnail"  style={{padding: 0}}
																	width="100" height="100" xmlns="http://www.w3.org/2000/svg" 
																	role="img" preserveAspectRatio="xMidYMid slice" focusable="false">
																		<rect width="100%" height="100%" fill="#dee2e6"></rect>
																		<text x="25%" y="50%" fill="#000" dy=".3em">100x100</text>
																	</svg>}
																</label>
															</div>
														</div>
														{Object.keys(errors.logo).map((k, i) => {
															return <span key={i} className="error-span">{errors.logo[k]}</span>
														})}
													</div>

													<div className="form-group form-group-label form-file-selecter">
														<label className="file-label">Veuillez importer la photo de couverture</label>
														<span className="file-label-span">(800 x 400)</span>
														<div className="wt-labelgroup">
															<label htmlFor="couverture">
																<span className="wt-btn file-selecter-btn">Choisir la photo de couverture</span>
																<input type="file" name="file" style={{display: 'none'}} id="couverture" min="1" max="1" onChange={(e)=>this.handleImageChoose(e, 'couverture')} accept=".png, .jpeg, .jpg" />
															</label>
														</div>
														{couverturePrvImg &&
															this.state.couvertureParams &&
																this.state.couvertureParams.w !== 800 &&
																	this.state.couvertureParams.h !== 400 &&
																		<>
																			<Cropper
																				style={{ height: 400, width: "100%" }} zoomTo={0} cropBoxResizable={false}
																				restore={false} src={couverturePrvImg} viewMode={1} guides={false}
																				// minCropBoxHeight={100} minCropBoxWidth={100} responsive={true}
																				dragMode='move' data={{ height: 800, width: 400, rotate: 0, scaleX: 1, scaleY: 1 }}
																				initialAspectRatio={16 / 8} aspectRatio={16 / 8} autoCropArea={0.7} heckOrientation={false}
																				
																				crop={(e)=>{
																					this.getCroppedImg(e.target, e.detail, 'couverture')
																				}}
																			/>
																			<button className="btn btn-primary" type="button" style={{ fontSize: 12, marginTop: 8 }} onClick={()=>this.croppedImg(couvertureCrop, 'couverture.png', 'couverture')}>
																				Redimensionner
																			</button>
																		</>
														}
														<div className="wt-uploadingbox" style={{margin: 8}}>
															<div className="wt-designimg">
																<label htmlFor="demoq">
																	{couverturePrvImg && <img src={couverturePrvImg} onLoad={(e)=>this.handleFileLoad(e, 'couverture', 800, 400)} alt="img couverture" style={{borderRadius: 8, width: 400, height: 200}} />}
																	{!couverturePrvImg &&
																	<svg className="bd-placeholder-img img-thumbnail"  style={{padding: 0}}
																	width="400" height="200" xmlns="http://www.w3.org/2000/svg" 
																	role="img" preserveAspectRatio="xMidYMid slice" focusable="false">
																		<rect width="100%" height="100%" fill="#dee2e6"></rect>
																		<text x="45%" y="50%" fill="#000" dy=".3em">800x400</text>
																	</svg>}
																</label>
															</div>
														</div>
														{Object.keys(errors.couverture).map((k, i) => {
															return <span key={i} className="error-span">{errors.couverture[k]}</span>
														})}
													</div>

													<div className="form-group form-group-label form-file-selecter">
														<label className="file-label">{`Veuillez importer 3 photos pour vos produits phares`}</label>
														<span className="file-label-span">(300 x 300)</span>
														<div className="wt-labelgroup">
															<label htmlFor="produits">
																<span className="wt-btn file-selecter-btn">Choisir les images</span>
																<input type="file" name="file" style={{display: 'none'}} id="produits" multiple min="3" max="3" onChange={(e)=>this.handleImageChoose(e, 'produits')} accept=".png, .jpeg, .jpg" />
															</label>
														</div>
													</div>
													
													{prdPrvImg1 &&
															this.state.produit1Params &&
																this.state.produit1Params.w !== 300 &&
																	this.state.produit1Params.h !== 300 &&
																		<div style={{ float: "left", textAlign: 'center' }}>
																			<Cropper
																				style={{ height: 400, width: "100%"}} zoomTo={0} cropBoxResizable={false}
																				restore={false} src={prdPrvImg1} viewMode={1} guides={false}
																				// minCropBoxHeight={100} minCropBoxWidth={100} responsive={true}
																				dragMode='move' data={{ height: 300, width: 300, rotate: 0, scaleX: 1, scaleY: 1 }}
																				initialAspectRatio={16 / 16} aspectRatio={16 / 16} autoCropArea={0.8} heckOrientation={false}
																				
																				crop={(e)=> this.getCroppedImg(e.target, e.detail, 'produit1') }
																			/>
																			<button className="btn btn-primary" type="button" style={{ fontSize: 12, marginTop: 8, marginBottom: 8 }} onClick={()=>this.croppedImg(produitCrop, 'produit_1.png', 'produit1', 0)}>
																				Redimensionner
																			</button>
																		</div>
														}

														{prdPrvImg2 &&
															this.state.produit2Params &&
																this.state.produit2Params.w !== 300 &&
																	this.state.produit2Params.h !== 300 &&
																		<div style={{ float: "left", textAlign: 'center' }}>
																			<Cropper
																				style={{ height: 400, width: "100%", float: "left" }} zoomTo={0} cropBoxResizable={false}
																				restore={false} src={prdPrvImg2} viewMode={1} guides={false}
																				// minCropBoxHeight={100} minCropBoxWidth={100} responsive={true}
																				dragMode='move' data={{ height: 300, width: 300, rotate: 0, scaleX: 1, scaleY: 1 }}
																				initialAspectRatio={16 / 16} aspectRatio={16 / 16} autoCropArea={0.8} heckOrientation={false}
																				
																				crop={(e)=> this.getCroppedImg(e.target, e.detail, 'produit2') }
																			/>
																			<button className="btn btn-primary" type="button" style={{ fontSize: 12, marginTop: 8, marginBottom: 8 }} onClick={()=>this.croppedImg(produitCrop, 'produit_2.png', 'produit2', 1)}>
																				Redimensionner
																			</button>
																		</div>
														}

														{prdPrvImg3 &&
															this.state.produit3Params &&
																this.state.produit3Params.w !== 300 &&
																	this.state.produit3Params.h !== 300 &&
																	<div style={{ float: "left", textAlign: 'center' }}>
																			<Cropper
																				style={{ height: 400, width: "100%", float: "left" }} zoomTo={0} cropBoxResizable={false}
																				restore={false} src={prdPrvImg3} viewMode={1} guides={false}
																				// minCropBoxHeight={100} minCropBoxWidth={100} responsive={true}
																				dragMode='move' data={{ height: 300, width: 300, rotate: 0, scaleX: 1, scaleY: 1 }}
																				initialAspectRatio={16 / 16} aspectRatio={16 / 16} autoCropArea={0.8} heckOrientation={false}
																				
																				crop={(e)=> this.getCroppedImg(e.target, e.detail, 'produit3') }
																			/>
																			<button className="btn btn-primary" type="button" style={{ fontSize: 12, marginTop: 8, marginBottom: 8 }} onClick={()=>this.croppedImg(produitCrop, 'produit_3.png', 'produit3', 2)}>
																				Redimensionner
																			</button>
																		</div>
														}

													<div className="form-group" style={{display: 'flex'}}>
														<div className="wt-uploadingbox" style={{margin: 8}}>
															<div className="wt-designimg">
																<label htmlFor="demoq">
																	{prdPrvImg1 && <img src={prdPrvImg1} onLoad={(e)=>this.handleFileLoad(e, 'produit1', 300, 300)} alt="img produit 1" style={{borderRadius: 8, width: 200, height: 200}} />}
																	{!prdPrvImg1 &&
																	<svg className="bd-placeholder-img img-thumbnail"  style={{padding: 0}}
																	width="200" height="200" xmlns="http://www.w3.org/2000/svg" 
																	role="img" preserveAspectRatio="xMidYMid slice" focusable="false">
																		<rect width="100%" height="100%" fill="#dee2e6"></rect>
																		<text x="40%" y="50%" fill="#000" dy=".3em">300x300</text>
																	</svg>}
																</label>
															</div>
															{Object.keys(errors.produit1).map((k, i) => {
																return <span key={i} className="error-span">{errors.produit1[k]}</span>
															})}
														</div>
														<div className="wt-uploadingbox" style={{margin: 8}}>
															<div className="wt-designimg">
																<label htmlFor="demoq">
																	{prdPrvImg2 && <img src={prdPrvImg2} onLoad={(e)=>this.handleFileLoad(e, 'produit2', 300, 300)} alt="img produit 2" style={{borderRadius: 8, width: 200, height: 200}}  />}
																	{!prdPrvImg2 &&
																	<svg className="bd-placeholder-img img-thumbnail"  style={{padding: 0}}
																	width="200" height="200" xmlns="http://www.w3.org/2000/svg" 
																	role="img" preserveAspectRatio="xMidYMid slice" focusable="false">
																		<rect width="100%" height="100%" fill="#dee2e6"></rect>
																		<text x="40%" y="50%" fill="#000" dy=".3em">300x300</text>
																	</svg>}
																</label>
															</div>
															{Object.keys(errors.produit2).map((k, i) => {
																return <span key={i} className="error-span">{errors.produit2[k]}</span>
															})}
														</div>
														<div className="wt-uploadingbox" style={{margin: 8}}>
															<div className="wt-designimg">
																<label htmlFor="demoq">
																	{prdPrvImg3 && <img src={prdPrvImg3} onLoad={(e)=>this.handleFileLoad(e, 'produit3', 300, 300)} alt="img produit 3" style={{borderRadius: 8, width: 200, height: 200}}  />}
																	{!prdPrvImg3 &&
																	<svg className="bd-placeholder-img img-thumbnail"  style={{padding: 0}}
																	width="200" height="200" xmlns="http://www.w3.org/2000/svg" 
																	role="img" preserveAspectRatio="xMidYMid slice" focusable="false">
																		<rect width="100%" height="100%" fill="#dee2e6"></rect>
																		<text x="40%" y="50%" fill="#000" dy=".3em">300x300</text>
																	</svg>}
																</label>
															</div>
															{Object.keys(errors.produit3).map((k, i) => {
																return <span key={i} className="error-span">{errors.produit3[k]}</span>
															})}
														</div>
													</div>
													
													<div className="form-group" style={{display: 'flex', justifyContent: 'center', marginTop: 8}}>
														{/* <span onClick={()=>this.setState({etape: 1})} className="wt-btn ayn-btn-submit" style={{marginRight: 12,cursor: 'pointer'}}>Précédent</span> */}
														<button type="submit" className="wt-btn ayn-btn-submit">Valider</button>
													</div>
												</fieldset>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
        )
    }
}