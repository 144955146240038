import React, { Component } from 'react'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { MarchandsSvc } from '../../services';

export default class FrontLayout extends Component {
    constructor(props) {
        super(props);
        this.state = { isMobile: false, marchands: [] }
    }

    updateDimensions = () => {
        const width = window.innerWidth
        if(width < 576) this.setState({ isMobile: true })
        else this.setState({ isMobile: false })
    }

      
    componentDidMount() {
        this.updateDimensions()
        window.addEventListener('resize', this.updateDimensions);
        const marchandService = new MarchandsSvc(this.props.session)
        marchandService.marchandNoms()
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                this.setState({marchands: data})
            } else {
                this.props.toast(data.pror, { appearance: 'error' })
            }
        })
    }
    componentWillUnmount() { window.removeEventListener('resize', this.updateDimensions) }


    render() {
        const { session, routes, toast } = this.props
        const { isMobile, marchands } = this.state
    
        return (
            <Router>
                <div id="wt-wrapper" className="wt-wrapper wt-haslayout">
                    <div className="wt-contentwrapper">
                        <Header isMobile={isMobile} marchands={marchands} />
                        <Switch>
                            {
                                routes.map((prop, key) => {
                                    return <Route exact path={prop.path} key={key} render={(props) => React.createElement(prop.component, { session, isMobile, toast, ...props }, null)} />
                                })
                            }
                        </Switch>
                        <Footer />
                    </div>
                </div>
            </Router>
        )
    }
}