const privateNavRoutes = [
   {
      head: "Général",
   },
   {
      head: "Catégories",
      children:[
         {
            name: 'Toutes les catégories',
            path: '/categories',
         },
         {
            name: 'Electronique',
            path: '/categories/electronique',
         },
         {
            name: 'Fashion',
            path: '/categories/fashion',
         }
      ]
   },
   // {
   //    hasChild: false,
   //    nav: {
   //       name: 'Statistiques',
   //       path: '/statistiques',
   //       icon: <TrendingUpOutlined />,
   //       visible: true,
   //       home: true
   //    }
   // }
]

export { privateNavRoutes }
