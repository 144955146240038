import Categorie from '../pages/clients/Categorie'
import Categories from '../pages/clients/Categories'
import DetailCategorie from '../pages/clients/DetailCategorie'
import ClientHome from '../pages/clients/Home'
import Recherche from '../pages/clients/Recherche'
import Condition from '../pages/clients/Condition'

import MarchandInscription from '../pages/marchand/Inscription'
import MarchandConnexion from '../pages/marchand/Connexion'
import MarchandHome from '../pages/marchand/Home'
import MarchandDemande from '../pages/marchand/Demande'
import MarchandCommentaire from '../pages/marchand/Commentaire'
import MarchandModification from '../pages/marchand/Modification'
import MarchandPromotion from '../pages/marchand/Promotion'
import MarchandCertification from '../pages/marchand/Certification'
import MarchandForfait from '../pages/marchand/Forfait'
import MarchandProfile from '../pages/marchand/Profile'

import AdminInscription from '../pages/admin/Inscription'
import AdminConnexion from '../pages/admin/Connexion'
import AdminMarchand from '../pages/admin/Marchand'
import AdminDemande from '../pages/admin/Demande'
import AdminDashbord from '../pages/admin/Dashbord'
import AdminModification from '../pages/admin/Modification'
import AdminModificationDetail from '../pages/admin/ModificationDetail'
import AdminCertification from '../pages/admin/Certification'
import AdminCertificationDetail from '../pages/admin/CertificationDetail'
import AdminPromotion from '../pages/admin/Promotion'
import AdminPromotionDetail from '../pages/admin/PromotionDetail'
import AdminForfait from '../pages/admin/Forfait'
import AdminForfaitDetail from '../pages/admin/ForfaitDetail'
import AdminUtilisateur from '../pages/admin/Utilisateur'
import AdminNotification from '../pages/admin/Notification'
import AdminProfile from '../pages/admin/Profile'

const frontRoutes = [
   {
      path: '/front/home',
      component: ClientHome,
   },
   {
      path: '/front/categories/all',
      component: Categories,
   },
   {
      path: '/front/categories/details',
      component: DetailCategorie,
   },
   {
      path: '/front/categorie/:type',
      component: Categorie,
   },
   {
      path: '/front/recherches',
      component: Recherche,
   },
   {
      path: '/front/politiques',
      component: Condition,
   }
]

const adminRoutes = [
   {
      path: '/admin/login',
      component: AdminConnexion,
   },
   {
      path: '/admin/inscription',
      component: AdminInscription,
   },
   {
      path: '/admin/oublie',
      component: DetailCategorie,
   },
   {
      path: '/admin/marchands',
      component: AdminMarchand,
   },
   {
      path: '/admin/demandes',
      component: AdminDemande,
   },
   {
      path: '/admin/dashbord',
      component: AdminDashbord,
   },
   {
      path: '/admin/utilisateurs',
      component: AdminUtilisateur,
   },
   {
      path: '/admin/notifications',
      component: AdminNotification,
   },
   {
      path: '/admin/inscriptions',
      component: AdminInscription,
   },
   {
      path: '/admin/modifications',
      component: AdminModification,
   },
   {
      path: '/admin/modification/:id',
      component: AdminModificationDetail,
   },
   {
      path: '/admin/certifications',
      component: AdminCertification,
   },
   {
      path: '/admin/certification/:id',
      component: AdminCertificationDetail,
   },
   {
      path: '/admin/promotions',
      component: AdminPromotion,
   },
   {
      path: '/admin/promotion/:id',
      component: AdminPromotionDetail,
   },
   {
      path: '/admin/forfaits',
      component: AdminForfait,
   },
   {
      path: '/admin/forfait/:id',
      component: AdminForfaitDetail,
   },
   {
      path: '/admin/profile',
      component: AdminProfile,
   }
]

const marchandRoutes = [
   {
      path: '/marchands/login',
      component: MarchandConnexion,
   },
   {
      path: '/marchands/inscription',
      component: MarchandInscription,
   },
   {
      path: '/marchands/oublie',
      component: DetailCategorie,
   },
   {
      path: '/marchands/home',
      component: MarchandHome,
   },
   {
      path: '/marchands/demandes',
      component: MarchandDemande,
   },
   {
      path: '/marchands/commentaires',
      component: MarchandCommentaire,
   },
   {
      path: '/marchands/modifications',
      component: MarchandModification,
   },
   {
      path: '/marchands/certifications',
      component: MarchandCertification,
   },
   {
      path: '/marchands/promotions',
      component: MarchandPromotion,
   },
   {
      path: '/marchands/forfaits',
      component: MarchandForfait,
   },
   {
      path: '/marchands/profile',
      component: MarchandProfile,
   }
]


export { frontRoutes, adminRoutes, marchandRoutes }