/** @format */

import React from "react";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import MobileStoreCard from "../../../components/MobileStoreCard";
import Poper from "../../../components/Poper";
import WebStoreCard from "../../../components/WebStoreCard";
import categoriesJSON from "../../../lib/categories.json";
import { moyenNote } from "../../../lib/functions";
import "./index.css";

// const stores = [
//     {nom: 'Sodishop', url: 'https://sodishop.com', adresse: 'Hamdallay ACI 2000', rating: 5, verifier: true, logo: img05, cover: img5, categorie: 'electronique'},
//     {nom: 'Sodishop', url: 'https://sodishop.com', adresse: 'Hamdallay ACI 2000', rating: 2, verifier: false, logo: img05, cover: img5, categorie: 'electronique'},
//     {nom: 'Sodishop', url: 'https://sodishop.com', adresse: 'Hamdallay ACI 2000', rating: 1, verifier: true, logo: img05, cover: img5, categorie: 'fashion'},
//     {nom: 'Sodishop', url: 'https://sodishop.com', adresse: 'Hamdallay ACI 2000', rating: 4, verifier: true, logo: img05, cover: img5, categorie: 'fashion'},
//     {nom: 'Sodishop', url: 'https://sodishop.com', adresse: 'Hamdallay ACI 2000', rating: 3, verifier: true, logo: img05, cover: img5, categorie: 'electronique'},
//     {nom: 'Sodishop', url: 'https://sodishop.com', adresse: 'Hamdallay ACI 2000', rating: 4, verifier: false, logo: img05, cover: img5, categorie: 'electronique'},
//     {nom: 'Sodishop', url: 'https://sodishop.com', adresse: 'Hamdallay ACI 2000', rating: 1, verifier: false, logo: img05, cover: img5, categorie: 'alimentation'},
//     {nom: 'Sodishop', url: 'https://sodishop.com', adresse: 'Hamdallay ACI 2000', rating: 0, verifier: false, logo: img05, cover: img5, categorie: 'electronique'},
//     {nom: 'Sodishop', url: 'https://sodishop.com', adresse: 'Hamdallay ACI 2000', rating: 3, verifier: false, logo: img05, cover: img5, categorie: 'fashion'},
//     {nom: 'Sodishop', url: 'https://sodishop.com', adresse: 'Hamdallay ACI 2000', rating: 4, verifier: true, logo: img05, cover: img5, categorie: 'alimentation'},
//     {nom: 'Sodishop', url: 'https://sodishop.com', adresse: 'Hamdallay ACI 2000', rating: 5, verifier: true, logo: img05, cover: img5, categorie: 'alimentation'}
// ]

const listRegion = [
  "Bamako",
  "Kayes",
  "Koulikoro",
  "Sikasso",
  "Ségou",
  "Mopti",
  "Tombouctou",
  "Gao",
  "Kidal",
];

const categories = [
  { id: 1, nom: "electronique" },
  { id: 2, nom: "fashion" },
  { id: 3, nom: "alimentation" },
];
// const fourchette = [ {value: '0-1000',  label: '0F - 1.000F'}, {value: '2000-10000', label: '2.000F - 10.000F'}, {value: '50000-100000', label: '50.000F - 100.000F'} ]
// const fourchette = [ {min: 0, max:1000,  label: '0F - 1.000F'}, {min: 2000, max: 10000, label: '2.000F - 10.000F'}, {min: 50000, max: 100000, label: '50.000F - 100.000F'} ]

export default class Categories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: [],
      currentName: "",
      filtered: [],
      souscategories: [],
      categories,
      stores: [],
      tempStores: [],
      region: "",
      plus: false,
    };
  }

  clear() {
    const cat = {};
    this.state.categories.map((c) => (cat[c.nom] = ""));
    this.setState({ ...cat, stores: this.state.tempStores });
  }

  filter(filtered) {
    const { tempStores } = this.state;
    // const allStores = []
    let stores = tempStores;

    // console.log(stores);

    for (let i = 0; i < filtered.length; i++) {
      if (filtered[i]["type"] === "sub") {
        stores = stores.filter((t) =>
          t.tags.sousGategories.includes(filtered[i]["name"])
        );
        // allStores.push(...stores)
      } else {
        if (filtered[i]["type"] === "region") {
          stores = stores.filter((t) => t.region === filtered[i]["name"]);
          // allStores.push(...stores)
        } else {
          if (filtered[i]["type"] === "service") {
            if (filtered[i]["val"] === "true") {
              stores = stores.filter((t) => t.services.service === true);
              // allStores.push(...stores)
            } else {
              stores = stores.filter((t) => t.services.service === false);
              // allStores.push(...stores)
            }
          } else {
            if (filtered[i]["type"] === "livraison") {
              stores = stores.filter(
                (t) => t.services.livraison === filtered[i]["val"]
              );
              // allStores.push(...stores)
            } else {
              if (filtered[i]["type"] === "classe") {
                if (filtered[i]["name"] === "Marchands certifiés") {
                  if (filtered[i]["val"] === "Marchands certifiés") {
                    stores = stores.filter((t) => t.certifie === true);
                    // allStores.push(...stores)
                  } else {
                    stores = stores.filter((t) => t.certifie === false);
                    // allStores.push(...stores)
                  }
                } else {
                  if (filtered[i]["val"] === "Marchands vitepay") {
                    stores = stores.filter((t) => t.vitepay === true);
                    // allStores.push(...stores)
                  } else {
                    stores = stores.filter((t) => t.vitepay === false);
                    // allStores.push(...stores)
                  }
                }
              } else {
                if (filtered[i]["type"] === "prix-min") {
                  stores = stores.filter(
                    (t) => t.tags.prix.minimum >= parseInt(filtered[i]["val"])
                  );
                  // allStores.push(...stores)
                } else {
                  if (filtered[i]["type"] === "prix-max") {
                    stores = stores.filter(
                      (t) => t.tags.prix.maximum <= parseInt(filtered[i]["val"])
                    );
                    // allStores.push(...stores)
                  }
                }
              }
            }
          }
        }
      }
    }

    // const storesAfterFilter = Array.from(new Set(allStores.map(a => a.id))).map(id => {
    //     return allStores.find(a => a.id === id)
    // })
    if (filtered.length > 0) {
      this.setState({ filtered, stores });
    } else {
      this.setState({ filtered, stores: tempStores });
    }
    // let res = []
    // for(let i=0; i<flt.length; i++){
    //     const f = this.state.tempStores.filter(s=>s.categorie === flt[i])
    //     res.push(...f)
    // }
    // return res
  }

  selectCat(cat) {
    const { selection, tempStores } = this.state;
    let slt = selection;
    if (this.state[cat]) {
      slt = selection.filter((s) => s !== cat);
      if (slt[0]) {
        const stores = this.filter(slt);
        this.setState({ [cat]: "", selection: slt, stores });
      } else {
        this.setState({ [cat]: "", selection: slt, stores: tempStores });
      }
    } else {
      slt.push(cat);
      const stores = this.filter(slt);
      this.setState({ [cat]: "active", selection: slt, stores });
    }
  }

  query(cat) {
    const path = this.props.history.location.pathname;
    this.props.marchandController.populaires({ libelle: cat }).then((res) => {
      if (typeof res.pror === "undefined") {
        const souscategories = categoriesJSON.find((c) => c.name === cat);

        // const sous =souscategories ? souscategories.children : [];
        // const article = sous.map(s=>s?.article)
        // console.log(article[0]);
        // console.log("in categorie: ", souscategories);

        const stores = res.sort(
          (a, b) => moyenNote(a.avis) < moyenNote(b.avis)
        );

        this.setState({
          stores,
          tempStores: stores,
          plus: res.length >= 50 ? true : false,
          souscategories: souscategories ? souscategories.children : [],
          currentName: cat,
          pathname: path,
        });
      } else {
      }
    });
  }

  queryPlus(cat, skip) {
    this.props.marchandController
      .populaires({ libelle: cat }, skip)
      .then((res) => {
        if (typeof res.pror === "undefined") {
          const str = res.sort((a, b) => moyenNote(a.avis) < moyenNote(b.avis));
          const stores = [...this.state.stores, ...str];
          const tempStores = [...this.state.tempStores, ...str];
          this.setState({
            stores,
            tempStores,
            plus: res.length >= 50 ? true : false,
          });
        } else {
        }
      });
  }

  handleAddToFiltered(name, type, val) {
    const data = this.state.filtered;
    let filterData = [];

    if (
      type === "prix-min" ||
      type === "prix-max" ||
      type === "region" ||
      type === "service" ||
      type === "livraison"
    ) {
      if (val !== "") {
        const temp = data.filter((d) => d.type !== type);
        // this.setState({filtered: [...temp, {name, type, val}] })
        filterData = [...temp, { name, type, val }];
      } else {
        const temp = data.filter((d) => d.type !== type);
        // this.setState({filtered: [...temp] })
        filterData = [...temp];
      }
    } else {
      const chk = data.find((f) => f.name === name);
      if (chk) {
        const f = data.filter((d) => d.name !== name);
        // this.setState({ filtered: f })
        filterData = f;
      } else {
        // this.setState({filtered: [...this.state.filtered, {name, type, val}] })
        filterData = [...this.state.filtered, { name, type, val }];
      }
    }

    this.filter(filterData);
  }

  componentDidUpdate(prevProps, nextProps) {
    if (prevProps !== this.props) {
      const name = this.props.history.location.state.name;
      if (name !== this.state.currentName) {
        this.query(name);
      }
    }
  }
  componentDidMount() {
    const name = this.props.history.location.state.name;
    // console.log(name);
    this.query(name);
  }

  gaView() {
    ReactGA.event({
      category: "Detail marchand",
      action: `Click sur le marchand pour plus de detail`,
    });
  }

  render() {
    const { isMobile } = this.props;
    const {
      stores,
      tempStores,
      souscategories,
      filtered,
      plus,
      /*region, pathname,*/ currentName,
    } = this.state;

    // console.log(tempStores);

    return (
      <div className="wt-haslayout">
        <div className="container">
          <div className="row">
            <div id="wt-twocolumns" className="wt-twocolumns wt-haslayout">
              <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-4 float-left">
                <div
                  className="d-sm-block d-md-none"
                  style={{ marginBottom: 30 }}
                >
                  <Poper
                    trigger={
                      <button
                        className="wt-btn ayn-btn-next"
                        style={{
                          lineHeight: "38px",
                          padding: "0 30px",
                          fontSize: 12,
                          fontWeight: 400,
                          borderRadius: 20,
                        }}
                      >
                        <i className="fas fa-filter"></i> Cliquer pour filtrer
                      </button>
                    }
                    containerStyle={{
                      backgroundColor: "#f7f7f7",
                      right: -118,
                      border: "1px solid #ddd",
                    }}
                  >
                    <aside id="wt-sidebar" className="wt-sidebar">
                      <div className="wt-widget wt-startsearch">
                        <div className="wt-widgettitle">
                          <h2>Régions</h2>
                        </div>
                        <div className="wt-widgetcontent">
                          <form className="wt-formtheme wt-formsearch">
                            <fieldset>
                              <div className="form-group">
                                <span className="wt-select">
                                  <select
                                    value={
                                      filtered.find((f) => f.type === "region")
                                        ? filtered.find(
                                            (f) => f.type === "region"
                                          )["val"]
                                        : ""
                                    }
                                    onChange={(e) => {
                                      this.handleAddToFiltered(
                                        e.target.value,
                                        "region",
                                        e.target.value
                                      );
                                    }}
                                  >
                                    <option value="">Région </option>
                                    {listRegion.map((r, i) => (
                                      <option value={r} key={i}>
                                        {r}
                                      </option>
                                    ))}
                                  </select>
                                </span>
                              </div>
                            </fieldset>
                          </form>
                        </div>
                      </div>
                      <div className="wt-widget wt-effectiveholder">
                        <div className="wt-widgettitle">
                          <h2>Type D'article</h2>
                        </div>
                        <div className="wt-widgetcontent">
                          <form className="wt-formtheme wt-formsearch">
                            <fieldset>
                              <ul className="wt-filtertag ayn-cat-ul form-subcat-ul">
                                {souscategories.map((c, i) => {
                                  return (
                                    <li className="ayn-cat-li" key={i}>
                                      <span
                                        onClick={() =>
                                          this.handleAddToFiltered(
                                            c.name,
                                            "sub",
                                            ""
                                          )
                                        }
                                        className={
                                          filtered.find(
                                            (f) => f.name === c.name
                                          )
                                            ? "cat-chip active"
                                            : "cat-chip"
                                        }
                                      >
                                        <span>{c.name}</span>
                                        <i className="fa fa-times close cat-closer"></i>
                                      </span>
                                    </li>
                                  );
                                })}
                              </ul>
                            </fieldset>
                          </form>
                        </div>
                      </div>
                      <div className="wt-widget wt-effectiveholder">
                        <div className="wt-widgettitle">
                          <h2>Fourchette de prix</h2>
                        </div>
                        <div className="wt-widgetcontent">
                          <form className="wt-formtheme wt-formsearch">
                            <fieldset>
                              <div
                                className="form-group"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  margin: 0,
                                }}
                              >
                                <label>Min:</label>
                                <span
                                  className="wt-select filter-select"
                                  style={{ width: 150 }}
                                >
                                  <select
                                    style={{
                                      height: 40,
                                      margin: 0,
                                      marginBottom: 8,
                                    }}
                                    value={
                                      filtered.find(
                                        (f) => f.type === "prix-min"
                                      )
                                        ? filtered.find(
                                            (f) => f.type === "prix-min"
                                          )["val"]
                                        : ""
                                    }
                                    onChange={(e) => {
                                      this.handleAddToFiltered(
                                        `${e.target.value} F`,
                                        "prix-min",
                                        e.target.value
                                      );
                                    }}
                                  >
                                    <option value="">Min</option>
                                    <option value={100}>100</option>
                                    <option value={1000}>1.000</option>
                                    <option value={25000}>25.000</option>
                                  </select>
                                </span>
                              </div>
                              <div
                                className="form-group"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  margin: 0,
                                }}
                              >
                                <label>Max:</label>
                                <span
                                  className="wt-select filter-select"
                                  style={{ width: 150 }}
                                >
                                  <select
                                    style={{ height: 40, margin: 0 }}
                                    value={
                                      filtered.find(
                                        (f) => f.type === "prix-max"
                                      )
                                        ? filtered.find(
                                            (f) => f.type === "prix-max"
                                          )["val"]
                                        : ""
                                    }
                                    onChange={(e) => {
                                      this.handleAddToFiltered(
                                        `${e.target.value} F`,
                                        "prix-max",
                                        e.target.value
                                      );
                                    }}
                                  >
                                    <option value="">Max</option>
                                    <option value={25000}>25.000</option>
                                    <option value={30000}>30.000</option>
                                    <option value={100000}>100.000</option>
                                  </select>
                                </span>
                              </div>
                            </fieldset>
                          </form>
                        </div>
                      </div>
                      <div className="wt-widget wt-effectiveholder">
                        <div className="wt-widgettitle">
                          <h2>Services</h2>
                        </div>
                        <div className="wt-widgetcontent">
                          <form className="wt-formtheme wt-formsearch">
                            <fieldset>
                              <div
                                className="form-group"
                                style={{ flexWrap: "wrap" }}
                              >
                                <label style={{ width: 200 }}>Livraison:</label>
                                <div className="wt-radioboxholder form-radio-content">
                                  <div className="form-radio">
                                    <span className="form-radio-label">
                                      Non disponible
                                    </span>
                                    <span className="wt-radio">
                                      <input
                                        id="liv-nondisponible"
                                        type="radio"
                                        name="livraison"
                                        value="Non disponible"
                                        onChange={(e) =>
                                          this.handleAddToFiltered(
                                            "Livraison non disponible",
                                            "livraison",
                                            e.target.value
                                          )
                                        }
                                        checked={filtered.find(
                                          (f) =>
                                            f.name ===
                                            "Livraison non disponible"
                                        )}
                                      />
                                      <label htmlFor="liv-nondisponible"></label>
                                    </span>
                                  </div>
                                  <div className="form-radio">
                                    <span className="form-radio-label">
                                      Gratuite
                                    </span>
                                    <span className="wt-radio">
                                      <input
                                        id="liv-gratuite"
                                        type="radio"
                                        name="livraison"
                                        value="Gratuite"
                                        onChange={(e) =>
                                          this.handleAddToFiltered(
                                            "Livraison gratuite",
                                            "livraison",
                                            e.target.value
                                          )
                                        }
                                        checked={filtered.find(
                                          (f) => f.name === "Livraison gratuite"
                                        )}
                                      />
                                      <label htmlFor="liv-gratuite"></label>
                                    </span>
                                  </div>
                                  <div className="form-radio">
                                    <span className="form-radio-label">
                                      Payante
                                    </span>
                                    <span className="wt-radio">
                                      <input
                                        id="liv-payant"
                                        type="radio"
                                        name="livraison"
                                        value="Payante"
                                        onChange={(e) =>
                                          this.handleAddToFiltered(
                                            "Livraison payante",
                                            "livraison",
                                            e.target.value
                                          )
                                        }
                                        checked={filtered.find(
                                          (f) => f.name === "Livraison payante"
                                        )}
                                      />
                                      <label htmlFor="liv-payant"></label>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="form-group form-radio-container"
                                style={{ flexWrap: "wrap" }}
                              >
                                <label style={{ width: 200, height: 45 }}>
                                  Services après vente:
                                </label>
                                <div className="wt-radioboxholder form-radio-content">
                                  <div className="form-radio">
                                    <span className="form-radio-label">
                                      Oui
                                    </span>
                                    <span className="wt-radio">
                                      <input
                                        id="sav-oui"
                                        type="radio"
                                        name="serviceApresVente"
                                        value={true}
                                        onChange={(e) =>
                                          this.handleAddToFiltered(
                                            "Service après vente: Oui",
                                            "service",
                                            e.target.value
                                          )
                                        }
                                        checked={filtered.find(
                                          (f) =>
                                            f.name ===
                                            "Service après vente: Oui"
                                        )}
                                      />
                                      <label htmlFor="sav-oui"></label>
                                    </span>
                                  </div>
                                  <div className="form-radio">
                                    <span className="form-radio-label">
                                      Non
                                    </span>
                                    <span className="wt-radio">
                                      <input
                                        id="sav-non"
                                        type="radio"
                                        name="serviceApresVente"
                                        value={false}
                                        onChange={(e) =>
                                          this.handleAddToFiltered(
                                            "Service après vente: Non",
                                            "service",
                                            e.target.value
                                          )
                                        }
                                        checked={filtered.find(
                                          (f) =>
                                            f.name ===
                                            "Service après vente: Non"
                                        )}
                                      />
                                      <label htmlFor="sav-non"></label>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                          </form>
                        </div>
                      </div>
                      <div className="wt-widget wt-effectiveholder">
                        <div className="wt-widgettitle">
                          <h2>Classe des marchands</h2>
                        </div>
                        <div className="wt-widgetcontent">
                          <form className="wt-formtheme wt-formsearch">
                            <fieldset>
                              <div
                                className="wt-checkboxholder wt-verticalscrollbar"
                                style={{ overflow: "auto" }}
                              >
                                <span className="wt-checkbox">
                                  <input
                                    id="wt-certifie"
                                    type="checkbox"
                                    value="Marchands certifiés"
                                    onChange={(e) =>
                                      this.handleAddToFiltered(
                                        "Marchands certifiés",
                                        "classe",
                                        e.target.value
                                      )
                                    }
                                    checked={filtered.find(
                                      (f) => f.name === "Marchands certifiés"
                                    )}
                                  />
                                  <label htmlFor="wt-certifie">
                                    Marchands certifiés
                                  </label>
                                </span>
                                <span className="wt-checkbox">
                                  <input
                                    id="wt-vitepay"
                                    type="checkbox"
                                    value="Marchands vitepay"
                                    onChange={(e) =>
                                      this.handleAddToFiltered(
                                        "Marchands vitepay",
                                        "classe",
                                        e.target.value
                                      )
                                    }
                                    checked={filtered.find(
                                      (f) => f.name === "Marchands vitepay"
                                    )}
                                  />
                                  <label htmlFor="wt-vitepay">
                                    Marchands vitepay
                                  </label>
                                </span>
                              </div>
                            </fieldset>
                          </form>
                        </div>
                      </div>
                    </aside>
                  </Poper>
                </div>

                <aside
                  id="wt-sidebar"
                  className="wt-sidebar d-none d-sm-none d-md-block"
                >
                  <div className="wt-widget wt-startsearch">
                    <div className="wt-widgettitle">
                      <h2>Régions</h2>
                    </div>
                    <div className="wt-widgetcontent">
                      <form className="wt-formtheme wt-formsearch">
                        <fieldset>
                          <div className="form-group">
                            <span className="wt-select">
                              <select
                                value={
                                  filtered.find((f) => f.type === "region")
                                    ? filtered.find((f) => f.type === "region")[
                                        "val"
                                      ]
                                    : ""
                                }
                                onChange={(e) => {
                                  this.handleAddToFiltered(
                                    e.target.value,
                                    "region",
                                    e.target.value
                                  );
                                }}
                              >
                                <option value="">Région </option>
                                {listRegion.map((r, i) => (
                                  <option value={r} key={i}>
                                    {r}
                                  </option>
                                ))}
                              </select>
                            </span>
                          </div>
                        </fieldset>
                      </form>
                    </div>
                  </div>

                  <div className="wt-widget wt-effectiveholder">
                    <div className="wt-widgettitle">
                      <h2>Type D'article</h2>
                    </div>
                    <div className="wt-widgetcontent">
                      <form className="wt-formtheme wt-formsearch">
                        <fieldset>
                          {/* <div className="wt-checkboxholder wt-verticalscrollbar" style={{overflow: 'auto'}}>
                                                        {souscategories.map((c,i)=>{
                                                            return(
                                                                <span className="wt-checkbox" key={i}>
                                                                    <input 
                                                                    id={`wt-${c.name}`} 
                                                                    type="checkbox"
                                                                    onChange={(e)=>this.handleAddToFiltered(e.target.value, 'sub', '')}
                                                                    value={c.name} 
                                                                    checked={pathname === c.path ? true : filtered.includes(c.name) ? true : false} />
                                                                    <label htmlFor={`wt-${c.name}`}>{c.name}</label>
                                                                </span>
                                                            )
                                                        })}
                                                    </div> */}
                          <ul className="wt-filtertag ayn-cat-ul form-subcat-ul">
                            {souscategories.map((c, i) => {
                              return (
                                <li className="ayn-cat-li" key={i}>
                                  <span
                                    onClick={() =>
                                      this.handleAddToFiltered(
                                        c.name,
                                        "sub",
                                        ""
                                      )
                                    }
                                    className={
                                      filtered.find((f) => f.name === c.name)
                                        ? "cat-chip active"
                                        : "cat-chip"
                                    }
                                  >
                                    <span>{c.name}</span>
                                    <i className="fa fa-times close cat-closer"></i>
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </fieldset>
                      </form>
                    </div>
                  </div>
                  <div className="wt-widget wt-effectiveholder">
                    <div className="wt-widgettitle">
                      <h2>Fourchette de prix</h2>
                    </div>
                    <div className="wt-widgetcontent">
                      <form className="wt-formtheme wt-formsearch">
                        <fieldset>
                          {/* <div className="wt-checkboxholder wt-verticalscrollbar" style={{overflow: 'auto'}}>
                                                        {fourchette.map((c,i)=>{
                                                            return(
                                                                <span className="wt-checkbox" key={i}>
                                                                    <input 
                                                                    id={`wt-${c.value}`} 
                                                                    type="checkbox"
                                                                    onChange={()=>this.handleAddToFiltered(c.label, 'prix', {min: c.min, max: c.max})}
                                                                    value={c.value}
                                                                    checked={filtered.includes(c.label)} />
                                                                    <label htmlFor={`wt-${c.value}`}>{c.label}</label>
                                                                </span>
                                                            )
                                                        })}
                                                    </div> */}
                          <div
                            className="form-group"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              margin: 0,
                            }}
                          >
                            <label>Min:</label>
                            <span
                              className="wt-select filter-select"
                              style={{ width: 150 }}
                            >
                              <select
                                style={{
                                  height: 40,
                                  margin: 0,
                                  marginBottom: 8,
                                }}
                                value={
                                  filtered.find((f) => f.type === "prix-min")
                                    ? filtered.find(
                                        (f) => f.type === "prix-min"
                                      )["val"]
                                    : ""
                                }
                                onChange={(e) => {
                                  this.handleAddToFiltered(
                                    `${e.target.value} F`,
                                    "prix-min",
                                    e.target.value
                                  );
                                }}
                              >
                                <option value="">Min</option>
                                <option value={100}>100</option>
                                <option value={1000}>1.000</option>
                                <option value={25000}>25.000</option>
                              </select>
                            </span>
                          </div>
                          <div
                            className="form-group"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              margin: 0,
                            }}
                          >
                            <label>Max:</label>
                            <span
                              className="wt-select filter-select"
                              style={{ width: 150 }}
                            >
                              <select
                                style={{ height: 40, margin: 0 }}
                                value={
                                  filtered.find((f) => f.type === "prix-max")
                                    ? filtered.find(
                                        (f) => f.type === "prix-max"
                                      )["val"]
                                    : ""
                                }
                                onChange={(e) => {
                                  this.handleAddToFiltered(
                                    `${e.target.value} F`,
                                    "prix-max",
                                    e.target.value
                                  );
                                }}
                              >
                                <option value="">Max</option>
                                <option value={25000}>25.000</option>
                                <option value={30000}>30.000</option>
                                <option value={100000}>100.000</option>
                              </select>
                            </span>
                          </div>
                        </fieldset>
                      </form>
                    </div>
                  </div>
                  <div className="wt-widget wt-effectiveholder">
                    <div className="wt-widgettitle">
                      <h2>Services</h2>
                    </div>
                    <div className="wt-widgetcontent">
                      <form className="wt-formtheme wt-formsearch">
                        <fieldset>
                          {/* <div className="wt-checkboxholder wt-verticalscrollbar" style={{overflow: 'auto'}}>
                                                        <span className="wt-checkbox">
                                                            <input id="wt-Livraison" type="checkbox" name="Livraison" 
                                                            value="Livraison"
                                                            onChange={(e)=>this.handleAddToFiltered('Livraison', 'service', e.target.value)}
                                                            checked={filtered.includes('Livraison')} />
                                                            <label htmlFor="wt-Livraison">Livraison</label>
                                                        </span>
                                                        <span className="wt-checkbox">
                                                            <input id="wt-Service" type="checkbox" 
                                                            value="Service après vente" 
                                                            onChange={(e)=>this.handleAddToFiltered('Service après vente', 'service', e.target.value)}
                                                            checked={filtered.includes("Service après vente")} />
                                                            <label htmlFor="wt-Service">Service après vente</label>
                                                        </span>
                                                    </div> */}
                          <div
                            className="form-group"
                            style={{ flexWrap: "wrap" }}
                          >
                            <label style={{ width: 200 }}>Livraison:</label>
                            <div className="wt-radioboxholder form-radio-content">
                              <div className="form-radio">
                                <span className="form-radio-label">
                                  Non disponible
                                </span>
                                <span className="wt-radio">
                                  <input
                                    id="liv-nondisponible"
                                    type="radio"
                                    name="livraison"
                                    value="Non disponible"
                                    onChange={(e) =>
                                      this.handleAddToFiltered(
                                        "Livraison non disponible",
                                        "livraison",
                                        e.target.value
                                      )
                                    }
                                    checked={filtered.find(
                                      (f) =>
                                        f.name === "Livraison non disponible"
                                    )}
                                  />
                                  <label htmlFor="liv-nondisponible"></label>
                                </span>
                              </div>
                              <div className="form-radio">
                                <span className="form-radio-label">
                                  Gratuite
                                </span>
                                <span className="wt-radio">
                                  <input
                                    id="liv-gratuite"
                                    type="radio"
                                    name="livraison"
                                    value="Gratuite"
                                    onChange={(e) =>
                                      this.handleAddToFiltered(
                                        "Livraison gratuite",
                                        "livraison",
                                        e.target.value
                                      )
                                    }
                                    checked={filtered.find(
                                      (f) => f.name === "Livraison gratuite"
                                    )}
                                  />
                                  <label htmlFor="liv-gratuite"></label>
                                </span>
                              </div>
                              <div className="form-radio">
                                <span className="form-radio-label">
                                  Payante
                                </span>
                                <span className="wt-radio">
                                  <input
                                    id="liv-payant"
                                    type="radio"
                                    name="livraison"
                                    value="Payante"
                                    onChange={(e) =>
                                      this.handleAddToFiltered(
                                        "Livraison payante",
                                        "livraison",
                                        e.target.value
                                      )
                                    }
                                    checked={filtered.find(
                                      (f) => f.name === "Livraison payante"
                                    )}
                                  />
                                  <label htmlFor="liv-payant"></label>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            className="form-group form-radio-container"
                            style={{ flexWrap: "wrap" }}
                          >
                            <label style={{ width: 200, height: 45 }}>
                              Services après vente:
                            </label>
                            <div className="wt-radioboxholder form-radio-content">
                              <div className="form-radio">
                                <span className="form-radio-label">Oui</span>
                                <span className="wt-radio">
                                  <input
                                    id="sav-oui"
                                    type="radio"
                                    name="serviceApresVente"
                                    value={true}
                                    onChange={(e) =>
                                      this.handleAddToFiltered(
                                        "Service après vente: Oui",
                                        "service",
                                        e.target.value
                                      )
                                    }
                                    checked={filtered.find(
                                      (f) =>
                                        f.name === "Service après vente: Oui"
                                    )}
                                  />
                                  <label htmlFor="sav-oui"></label>
                                </span>
                              </div>
                              <div className="form-radio">
                                <span className="form-radio-label">Non</span>
                                <span className="wt-radio">
                                  <input
                                    id="sav-non"
                                    type="radio"
                                    name="serviceApresVente"
                                    value={false}
                                    onChange={(e) =>
                                      this.handleAddToFiltered(
                                        "Service après vente: Non",
                                        "service",
                                        e.target.value
                                      )
                                    }
                                    checked={filtered.find(
                                      (f) =>
                                        f.name === "Service après vente: Non"
                                    )}
                                  />
                                  <label htmlFor="sav-non"></label>
                                </span>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </form>
                    </div>
                  </div>
                  <div className="wt-widget wt-effectiveholder">
                    <div className="wt-widgettitle">
                      <h2>Classe des marchands</h2>
                    </div>
                    <div className="wt-widgetcontent">
                      <form className="wt-formtheme wt-formsearch">
                        <fieldset>
                          <div
                            className="wt-checkboxholder wt-verticalscrollbar"
                            style={{ overflow: "auto" }}
                          >
                            <span className="wt-checkbox">
                              <input
                                id="wt-certifie"
                                type="checkbox"
                                value="Marchands certifiés"
                                onChange={(e) =>
                                  this.handleAddToFiltered(
                                    "Marchands certifiés",
                                    "classe",
                                    e.target.value
                                  )
                                }
                                checked={filtered.find(
                                  (f) => f.name === "Marchands certifiés"
                                )}
                              />
                              <label htmlFor="wt-certifie">
                                Marchands certifiés
                              </label>
                            </span>
                            <span className="wt-checkbox">
                              <input
                                id="wt-vitepay"
                                type="checkbox"
                                value="Marchands vitepay"
                                onChange={(e) =>
                                  this.handleAddToFiltered(
                                    "Marchands vitepay",
                                    "classe",
                                    e.target.value
                                  )
                                }
                                checked={filtered.find(
                                  (f) => f.name === "Marchands vitepay"
                                )}
                              />
                              <label htmlFor="wt-vitepay">
                                Marchands vitepay
                              </label>
                            </span>
                          </div>
                        </fieldset>
                      </form>
                    </div>
                  </div>
                </aside>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-8 float-left">
                <div className="wt-userlistingholder wt-haslayout">
                  {/* <div className="wt-userlistingtitle">
                                        <span>01 - 48 of 57143 results for <em>"Software House"</em></span>
                                    </div> */}

                  {/* effacer les filtres dans la page de categorie */}
                  <div className="wt-filterholder">
                    <ul
                      className="wt-filtertag ayn-cat-ul"
                      style={{ justifyContent: "start" }}
                    >
                      <li
                        className="wt-filtertagclear ayn-cat-li"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <span
                          className="chip-clear-all"
                          to={{ pathname: "", state: { name: currentName } }}
                          onClick={() =>
                            this.setState({
                              filtered: [],
                              stores: this.state.tempStores,
                            })
                          }
                        >
                          {/* {console.log(this.state)} */}
                          {/* akougnon */}
                          <i className="fa fa-times"></i>{" "}
                          <span>Effacer les filtres</span>
                        </span>
                      </li>
                      {filtered.map((c, i) => {
                        return (
                          <li
                            className="ayn-cat-li"
                            key={i}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <span className="cat-chip">
                              <span>{c.name}</span>
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  <div className="wt-companysinfoholder">
                    <div className="row">
                      {!isMobile &&
                        stores.map((c, i) => {
                          return (
                            <div
                              className="col-12 col-sm-12 col-md-6 col-lg-4"
                              key={i}
                            >
                              <Link
                                to={{
                                  pathname: "/front/categories/details",
                                  state: { store: c },
                                }}
                                onClick={() => this.gaView()}
                              >
                                <WebStoreCard
                                  name={c.boutique}
                                  url={c.url}
                                  address={c.adresse}
                                  isVerified={c.certifie}
                                  vitepay={c.vitepay}
                                  rating={c.avis}
                                  cover={c.couverture}
                                  logo={c.logo}
                                />
                              </Link>
                            </div>
                          );
                        })}

                      {isMobile &&
                        stores.map((c, i) => {
                          return (
                            <div
                              className="col-12 col-sm-12 col-md-6 col-lg-3"
                              key={i}
                            >
                              <Link
                                to={{
                                  pathname: "/front/categories/details",
                                  state: { store: c },
                                }}
                                onClick={() => this.gaView()}
                              >
                                <MobileStoreCard
                                  name={c.boutique}
                                  url={c.url}
                                  address={c.adresse}
                                  isVerified={c.certifie}
                                  vitepay={c.vitepay}
                                  rating={c.avis}
                                  cover={c.couverture}
                                  logo={c.logo}
                                />
                              </Link>
                            </div>
                          );
                        })}
                    </div>
                    {plus && (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                        onClick={() =>
                          this.queryPlus(currentName, tempStores.length + 1)
                        }
                      >
                        <button className="wt-btn ayn-skip-btn">
                          Plus de marchands
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}