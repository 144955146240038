import Rating from "react-rating"
import vitepay from '../assets/images/vitepay.png'
import certifie from '../assets/images/certifie.png'
import { moyenNote } from '../lib/functions'

const WebStoreCard = (props) => {
    return (
        <div className="wt-companysdetails">
            <figure className="wt-companysimg">
                <img src={props.cover} alt="img description" />
            </figure>
            <div className="wt-companysinfo">
                <div className="ayn-vpay-img-container">
                    {props.vitepay && <img src={vitepay} alt="img vitepay" className="ayn-vpay-img" />}
                </div>
                <figure style={{marginBottom: 4}}><img src={props.logo} alt="logo du marchant" /></figure>
                <div className="wt-title">
                    {props.isVerified &&
                    <span style={{color: '#999', fontSize: 12}}>
                        {/* <i className="fa fa-check-circle" style={{color: '#00db00'}}></i>  */}
                        <img src={certifie} alt="img certifie" className="ayn-certifie-img" /> Marchand certifié
                    </span>}
                    {/* {!props.isVerified && <span style={{color: '#999', fontSize: 12, width: 36, display: 'block'}}></span>} */}
                    <h2 style={{marginBottom: 4, marginTop: props.isVerified ? 0 : 28 }} className="ayn-header">{props.name ? props.name.toLowerCase() : ''}</h2>
                </div>
                <ul className="wt-postarticlemeta" style={{marginLeft: 0}}>
                    <li className="ayn-li ayn-store-card-li">
                        <a href={props.url} target="_blank" rel="noreferrer">
                            Voir le site <i className="fa fa-external-link" aria-hidden="true"></i>
                        </a>
                    </li><br />
                    <li className="ayn-li ayn-store-card-li"><span className="li-span">{props.address}</span></li><br />
                    <li className="ayn-li ayn-store-card-li">
                        <span>
                            <Rating
                                initialRating={moyenNote(props.rating)}
                                emptySymbol={<i className="fa fa-star-o"></i>}
                                fullSymbol={<i className="fa fa-star" style={{color: 'orange'}}></i>}
                                readonly
                            />
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default WebStoreCard