import React from 'react';
import Header from '../../components/admin/Header';
import Page from '../../components/Page'
import Promotion from '../../features/admin/Promotion'
import { PromotionsSvc } from '../../services';

const PromotionPage = (props) => {
    const promotionService = new PromotionsSvc(props.session)
    const user = props.session.get('user')
    return (
        <Page mt={0} isAdmin={true}>
            <Header pathname="Promotions" username={user?.username} session={props.session} toast={props.toast} />
            <Promotion {...props} controller={promotionService} />
        </Page>
    )
}
export default PromotionPage