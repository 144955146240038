const formatEditDate = (strDate) =>{
   const date = new Date(strDate)
   return `${date.getFullYear()}-${("0" + (date.getMonth()+1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`
}
const formatDate = (strDate) =>{
   const date = new Date(strDate)
   return `${("0" + date.getDate()).slice(-2)}/${("0" + (date.getMonth()+1)).slice(-2)}/${date.getFullYear()}`
}

const formatTimeStampDate = (strDate) =>{
   const date = new Date(strDate)
   return `${("0" + date.getDate()).slice(-2)}/${("0" + (date.getMonth()+1)).slice(-2)}/${date.getFullYear()} à ${date.getHours()} H ${date.getMinutes()} mins`
}

const timeCalculator = (beginDate, endDate) =>{
   if(beginDate && endDate){
      const begin = new Date(beginDate)
      const end = new Date(endDate)
      const diffInTime = end.getTime() - begin.getTime();
      
      const DiffInDays = diffInTime / (1000 * 3600 * 24);
   
      return DiffInDays > 1 ? `${DiffInDays} jours` : `${DiffInDays} jour`
   }else{
      return ''
   }
}

const editable = (myArray,myObject) =>{
   let inter = myArray.filter(arr =>{ return arr.id !== myObject.id })
   return [...inter,myObject]
}
const deletable= (myArray,myId) =>{
   return myArray.filter(arr =>{ return arr.id !== myId })
}

const shortText = (str) =>{
   if(str.length >= 25){ return str.substring(0, 25)+"..." }
   else{ return str}
}

const formatNumber = (nbre)=>{
   if(nbre) return nbre.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
   return 0
} 
const statutPaiement = (bol) => {
   return (bol === true )? "Payé" : "Non payé"
}
const calculReliquat = (montant, paye) => {
   return ( ( parseInt(montant) - parseInt(paye) ) >= 0 ) ? (parseInt(montant) - parseInt(paye)) : 0
}

const privilegeLabel = (privilege) =>{
   switch (parseInt(privilege)) {
      case 0:
         return {value: "0", label: "Aucun accés" }
      case 1:
         return {value: "1", label: "Lecture" }
      case 2:
         return {value: "2", label: "Ecriture" }
      default:
         break;
   }
}
const nameInitial = (nom,prenom) =>{
   return (`${nom.charAt(0).toUpperCase()}${prenom.charAt(0).toUpperCase()}`)
}
const addLabel = (entity,genre) =>{
   switch (genre) {
      case "f":
         return `Nouvelle ${entity}`
      case "v":
         return `Nouvel ${entity}`
      case "m":
         return `Nouveau ${entity}`
      default:
         return `Eregistrement`
   }
}
const editLabel = (entity) =>{
   return `Modification ${entity}`
}

const moyenNote =(avis) =>{
   let total = 0;
   if(avis){
      if(avis.length > 0){
         for(let i=0; i< avis.length; i++){
            total = total + avis[i]['note']
         }
         return (total / avis.length).toFixed(1)
      }else{
         return (total).toFixed(1)
      }
   }else{
      return (total).toFixed(1)
   }

}

const ratingNote = (notes) =>{
   let un = 0; let deux = 0; let trois = 0; let quatre = 0; let cinq = 0
   const total = notes['totalNotes']
   const totalByNote = notes['totalByNote']
   for(let i=0; i < totalByNote.length; i++){
      if(totalByNote[i]['_id'] === 1){
         un = un + totalByNote[i]['nombre'] ? parseInt(totalByNote[i]['nombre']) : 0
      }else{
         if(totalByNote[i]['_id'] === 2){
            deux = deux + totalByNote[i]['nombre'] ? parseInt(totalByNote[i]['nombre']) : 0
         }if(totalByNote[i]['_id'] === 3){
            trois = trois + totalByNote[i]['nombre'] ? parseInt(totalByNote[i]['nombre']) : 0
         }else{
            if(totalByNote[i]['_id'] === 4){
               quatre = quatre + totalByNote[i]['nombre'] ? parseInt(totalByNote[i]['nombre']) : 0
            }else{
               if(totalByNote[i]['_id'] === 5){
                  cinq = cinq + totalByNote[i]['nombre'] ? parseInt(totalByNote[i]['nombre']) : 0
               }
            }
         }
      }
   }
   return { un: parseInt((un/total) * 100) , deux: parseInt((deux/total) * 100), trois: parseInt((trois/total) * 100), quatre: parseInt((quatre/total) * 100), cinq: parseInt((cinq/total) * 100) }
}
export { 
   formatDate, formatTimeStampDate, timeCalculator, formatNumber,calculReliquat, privilegeLabel, 
   statutPaiement, formatEditDate,editable,deletable,shortText,
   nameInitial,addLabel,editLabel, moyenNote, ratingNote
}