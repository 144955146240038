/*eslint array-callback-return: "off"*/
import React from 'react'
import ReactModal from 'react-modal'
import { formatNumber, formatTimeStampDate, formatEditDate } from "../../../lib/functions"
import messages from '../../../lib/messages.json'

/*eslint consistent-return: ["error", { "treatUndefinedAsUnspecified": false }]*/

const initialHistoriqueState = { certification: false, forfait: false, modification: false, promotion: false, title: '' }
const icon = { fontSize: 12, color: '#001e37', marginRight: 8, cursor: 'pointer', float: 'right' }
const th = { fontWeight: 500, fontSize: 10 }
const li = { lineHeight: '11px', borderBottom: '1px solid #ddd', cursor: 'pointer', paddingBottom: 4 }

const Certification = ({certification}) =>{
    return(
        <div>
            <div className="marchand-admin-card-section-header">
                <h4>Motif</h4>
            </div>
            <div className="marchand-admin-card-section-body">
                <div className="form-group">
                    <label>Motif *</label>
                    <textarea className="form-control" style={{height: 100}} placeholder="Motif" value={certification?.motif} readOnly></textarea>
                </div>
            </div>
        </div>
    )
}
const Forfait = ({forfait}) =>{
    return(
        <div>
            <div className="marchand-admin-card-section-header">
                <h4>Durée</h4>
            </div>
            <div className="marchand-admin-card-section-body" style={{width: '100%'}}>
                <div className="form-group form-group-half form-group-certif">
                    <label>Durée</label>
                    <input type="text" value={`${forfait?.duree} Mois`} className="form-control" placeholder="Durée" readOnly/>
                </div>
                <div className="form-group form-group-half form-group-certif">
                    <label>Montant</label>
                    <input type="text" value={`${formatNumber(forfait?.montant)} FCFA`} className="form-control" placeholder="Montant" readOnly />
                </div>
            </div><br/>
            
            <div className="marchand-admin-card-section-body" style={{width: '100%'}}>
                <div className="form-group form-group-half form-group-certif">
                    <label>Début *</label>
                    <input type="date" value={formatEditDate(forfait?.debut)} className="form-control" placeholder="Début" readOnly />
                </div>
                <div className="form-group form-group-half form-group-certif">
                    <label>Fin *</label>
                    <input type="date" value={formatEditDate(forfait?.fin)}  className="form-control" placeholder="Fin" readOnly />
                </div>
            </div><br/>

            <div className="marchand-admin-card-section-header">
                <h4>Coordonnées GPS</h4>
            </div>
            <div className="marchand-admin-card-section-body" style={{width: '100%'}}>
                <div className="form-group form-group-half form-group-certif">
                    <label>Latitude *</label>
                    <input type="text" value={forfait?.coordonnees?.latitude} className="form-control" placeholder="Latitude" readOnly />
                </div>
                <div className="form-group form-group-half form-group-certif">
                    <label>longitude *</label>
                    <input type="text" value={forfait?.coordonnees?.longitude} className="form-control" placeholder="Longitude" readOnly />
                </div>
            </div><br/>

            <div className="marchand-admin-card-section-header">
                <h4>Motif</h4>
            </div>
            <div className="marchand-admin-card-section-body">
                <div className="form-group">
                    <label>Motif *</label>
                    <textarea className="form-control" style={{height: 100}} placeholder="Motif" value={forfait?.motif} readOnly></textarea>
                </div>
            </div>
        </div>
    )
}
const Modification = ({modification}) =>{
    return(
        <div>
            <div className="marchand-admin-card-section-header">
                <h4>Motif</h4>
            </div>
            <div className="marchand-admin-card-section-body">
                <div className="form-group">
                    <label>Motif *</label>
                    <textarea className="form-control" style={{height: 100}} placeholder="Motif" value={modification?.motif} readOnly></textarea>
                </div>
            </div>
        </div>
    )
}
const Promotion = ({promotion}) =>{
    return(
        <div>
            <div className="marchand-admin-card-section-header">
                <h4>Image du slider</h4>
            </div>
            <div className="marchand-admin-card-section-header">
                <img src={promotion?.slider} alt="img slider" />
            </div>

            <div className="marchand-admin-card-section-header">
                <h4>Durée</h4>
            </div>
            <div className="marchand-admin-card-section-body" style={{width: '100%'}}>
                <div className="form-group form-group-half form-group-certif">
                    <label>Début *</label>
                    <input type="date" value={formatEditDate(promotion?.debut)} className="form-control" placeholder="Début" readOnly />
                </div>
                <div className="form-group form-group-half form-group-certif">
                    <label>Fin *</label>
                    <input type="date" value={formatEditDate(promotion?.fin)} className="form-control" placeholder="Fin" readOnly />
                </div>
            </div><br/>

            <div className="marchand-admin-card-section-header">
                <h4>Motif</h4>
            </div>
            <div className="marchand-admin-card-section-body">
                <div className="form-group">
                    <label>Motif *</label>
                    <textarea className="form-control" style={{height: 100}} placeholder="Motif" value={promotion?.motif} readOnly></textarea>
                </div>
            </div>
        </div>
)
}

export default class Dashbord extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modal: false,
            certificationHistorique: [], currentCertification: {},
            forfaitHistorique: [], currentForfait: {},
            modificationHistorique: [], currentModification: {},
            promotionHistorique: [], currentPromotion: {},
            notifications: [],
            ...initialHistoriqueState,
            inscription: '',
        }

    }

    getMarchandNotificaton(id){
        this.props.notificationController.getMarchandNotificaton(id)
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                this.setState({ notifications: data })
            } else {
                if (data.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                }
            }
        })
    }

    getCertificationHistorique(id){
        this.props.certificationController.getCertificationHistorique(id)
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                this.setState({ certificationHistorique: data })
            } else {
                if (data.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                }
            }
        })
    }

    getForfaitHistorique(id){
        this.props.forfaitController.getForfaitHistorique(id)
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                this.setState({ forfaitHistorique: data })
            } else {
                if (data.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                }
            }
        })
    }

    getMarchandUpdateHistorique(id){
        this.props.marchandUpdateController.getMarchandUpdateHistorique(id)
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                this.setState({ modificationHistorique: data })
            } else {
                if (data.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                }
            }
        })
    }

    getPromotionHistorique(id){
        this.props.promotionController.getPromotionHistorique(id)
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                this.setState({ promotionHistorique: data })
            } else {
                if (data.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                }
            }
        })
    }

    handleOpenCertification(id){
        const { certificationHistorique } = this.state
        const historique = certificationHistorique.find(c=>c._id === id)
        if(historique){
            this.setState({ currentCertification: historique, certification: true, forfait: false, modification: false, promotion: false, modal: true, title: "Certification"  })
        }
    }
    handleOpenForfait(id){
        const { forfaitHistorique } = this.state
        const historique = forfaitHistorique.find(c=>c._id === id)
        if(historique){
            this.setState({ currentForfait: historique, certification: false, forfait: true, modification: false, promotion: false, modal: true, title: "Forfait" })
        }
    }
    handleOpenModification(id){
        const { modificationHistorique } = this.state
        const historique = modificationHistorique.find(c=>c._id === id)
        if(historique){
            this.setState({ currentModification: historique, certification: false, forfait: false, modification: true, promotion: false, modal: true, title: "Modification d'information" })
        }
    }
    handleOpenPromotion(id){
        const { promotionHistorique } = this.state
        const historique = promotionHistorique.find(c=>c._id === id)
        if(historique){
            this.setState({ currentPromotion: historique, certification: false, forfait: false, modification: false, promotion: true, modal: true, title: "Promotion"  })
        }
    }
    handleViewNotification(id){
        const notif = { id, nouveau: false }
        this.props.notificationController.save(notif)
        .then(res => {
            if (typeof (res.pror) === 'undefined') {
                    const notifications = this.state.notifications.filter(n=>n._id !== id)
                this.setState({ notifications })
            } else {
                if (res.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                }
            }
        })
    }

    componentDidMount() {
        if(this.props.marchandId){
            this.getCertificationHistorique(this.props.marchandId)
            this.getForfaitHistorique(this.props.marchandId)
            this.getMarchandUpdateHistorique(this.props.marchandId)
            this.getPromotionHistorique(this.props.marchandId)
            this.getMarchandNotificaton(this.props.marchandId)
        }
    }


    render() {
        // const { crud, permission } = this.props
        const { certificationHistorique, forfaitHistorique, modificationHistorique, promotionHistorique,
            currentCertification, currentForfait, currentModification, currentPromotion,
            certification, forfait, modification, promotion,
            modal, notifications, title } = this.state




        return (
            <div className="row" style={{ margin: "0 40px" }}>
                <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                    <div className="stat-card-container" style={{padding: 0, marginBottom: 24}}>
                        <div style={{height: 36, borderRadius: 3, padding: 8, display: 'flex', alignItems: 'center',}}>
                            <i className="fas fa-pencil-alt" style={{fontSize: 20, color: '#001e37', marginRight: 8}}></i>
                            <h6 className="stat-card-head" style={{color: '#001e37', margin: 0, textOverflow: 'ellipsis', overflow: 'hidden'}}>Modification d'imformations</h6>
                        </div>
                        <div style={{padding: '12px 8px'}}>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th style={th}> DATE </th>
                                        <th style={th}> STATUT </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {modificationHistorique.map((c,i)=>{
                                        return(
                                            <tr key={i}>
                                                <td>{formatTimeStampDate(c.createdAt)}</td>
                                                <td>{c.status}</td>
                                                <td> <i className="fas fa-eye" style={icon} onClick={()=>this.handleOpenModification(c._id)}></i></td>
                                            </tr>
                                        )
                                    }) }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="stat-card-container" style={{padding: 0, marginBottom: 24}}>
                        <div style={{height: 36, borderRadius: 3, padding: 8, display: 'flex', alignItems: 'center',}}>
                            <i className="fas fa-certificate" style={{fontSize: 20, color: '#001e37', marginRight: 8}}></i>
                            <h6 className="stat-card-head" style={{color: '#001e37', margin: 0, textOverflow: 'ellipsis', overflow: 'hidden'}}>Cetifications</h6>
                        </div>
                        <div style={{padding: '12px 8px'}}>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th style={th}> DATE </th>
                                        <th style={th}> STATUT </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {certificationHistorique.map((c,i)=>{
                                        return(
                                            <tr key={i}>
                                                <td>{formatTimeStampDate(c.createdAt)}</td>
                                                <td>{c.status}</td>
                                                <td> <i className="fas fa-eye" style={icon} onClick={()=>this.handleOpenCertification(c._id)}></i></td>
                                            </tr>
                                        )
                                    }) }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="stat-card-container" style={{padding: 0, marginBottom: 24}}>
                        <div style={{height: 36, borderRadius: 3, padding: 8, display: 'flex', alignItems: 'center',}}>
                            <i className="fas fa-gift" style={{fontSize: 20, color: '#001e37', marginRight: 8}}></i>
                            <h6 className="stat-card-head" style={{color: '#001e37', margin: 0, textOverflow: 'ellipsis', overflow: 'hidden'}}>Promotions</h6>
                        </div>
                        <div style={{padding: '12px 8px'}}>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th style={th}> DATE </th>
                                        <th style={th}> STATUT </th>
                                        <th style={th}> MONTANT </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {promotionHistorique.map((p,i)=>{
                                        return(
                                            <tr key={i}>
                                                <td>{formatTimeStampDate(p.createdAt)}</td>
                                                <td>{p.status}</td>
                                                <td>{`${formatNumber(p.montant)} FCFA`}</td>
                                                <td> <i className="fas fa-eye" style={icon} onClick={()=>this.handleOpenPromotion(p._id)}></i></td>
                                            </tr>
                                        )
                                    }) }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="stat-card-container" style={{padding: 0, marginBottom: 24}}>
                        <div style={{height: 36, borderRadius: 3, padding: 8, display: 'flex', alignItems: 'center',}}>
                            <i className="fas fa-credit-card" style={{fontSize: 20, color: '#001e37', marginRight: 8}}></i>
                            <h6 className="stat-card-head" style={{color: '#001e37', margin: 0, textOverflow: 'ellipsis', overflow: 'hidden'}}>Forfaits</h6>
                        </div>
                        <div style={{padding: '12px 8px'}}>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th style={th}> DATE </th>
                                        <th style={th}> STATUT </th>
                                        <th style={th}> PACK </th>
                                        <th style={th}> MONTANT </th>
                                        <th> </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {forfaitHistorique.map((f,i)=>{
                                        return(
                                            <tr key={i}>
                                                <td>{formatTimeStampDate(f.createdAt)}</td>
                                                <td>{f.status}</td>
                                                <td>{f.type === 1 ? 'GOLD' : f.type === 2 ? 'DIAMOND' : 'PLATINIUM'}</td>
                                                <td>{`${formatNumber(f.montant)} FCFA`}</td>
                                                <td> <i className="fas fa-eye" style={{fontSize: 12, color: '#001e37', marginRight: 8, cursor: 'pointer'}} onClick={()=>this.handleOpenForfait(f._id)}></i></td>
                                            </tr>
                                        )
                                    }) }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                    <div className="stat-card-container" style={{padding: 0, marginBottom: 24}}>
                        <div style={{height: 36, background: '#001e37', borderRadius: 3, padding: 8, display: 'flex', alignItems: 'center',}}>
                            <i className="fas fa-bell" style={{fontSize: 20, color: '#fff', marginRight: 8}}></i>
                            <h6 className="stat-card-head" style={{color: '#fff', margin: 0, textOverflow: 'ellipsis', overflow: 'hidden'}}>Notification</h6>
                        </div>
                        <div style={{padding: '12px 0px'}}>
                            <ul>
                                {notifications.map((n,i)=>{
                                    return(
                                        <li style={li} onClick={()=>this.handleViewNotification(n._id)} key={i}>
                                            <h6 style={{textTransform: 'none', margin: 0, color: '#001e37'}}>{n.type}</h6>
                                            <span style={{fontSize: 11, color: '#767676'}}>{`Votre demande de ${n.type} a été ${n.status === "Accepter" ? 'acceptée' : 'rejetée' }`}</span>
                                        </li>
                                    )
                                }) }
                            </ul>
                        </div>
                    </div>
                </div>
            
                <ReactModal isOpen={modal} ariaHideApp={false} className="alert-modal commentaire-reply">
                    <div className="modal-alert-body-content">
                        <h5>{title}</h5>

                        <form className="wt-formtheme wt-formregister" id="form-inscription">
                            <fieldset className="wt-registerformgroup">
                                <div className="form-group">
                                    {certification && <Certification certification={currentCertification} />}
                                    {forfait && <Forfait forfait={currentForfait} />}
                                    {modification && <Modification modification={currentModification} />}
                                    {promotion && <Promotion promotion={currentPromotion} />}
                                </div>
                                
                                <div className="filter-actions-container" style={{float: 'right', marginTop: 16}}>
                                    <button type="button" className="btn btn-secondary filter-action-btn" onClick={()=>this.setState({ modal: false, ...initialHistoriqueState }) }>Fermer</button>
                                </div>
                            </fieldset>
                        </form>
                        
                    </div>
                </ReactModal>
            </div>
        )
    }

}