import './index.css'
const StatCard = (props) => {
    return (
        <div className="stat-card-container" style={{marginBottom: typeof(props.mb) !== 'undefined' ? props.mb : 0, float: props.float ? props.float : ''}}>
            <div>
                <h6 className="stat-card-head">{props.title}</h6>
            </div>
            <div>
                {props.children}
            </div>
        </div>
    )
}

export default StatCard