import logo from '../assets/images/logo.png'
import { Link, useHistory } from 'react-router-dom'
import { useState } from 'react'
import subcategories from '../lib/subcategories.json'
import articles from '../lib/articles.json'
import routes from '../lib/categories.json'

const Header = ({isMobile, marchands}) => {

	const [active, setActive] = useState('')
	const [searchCategorie, setSearchCategorie] = useState('Article')
	const [articleParent, setArticleParent] = useState('')
	const [mystyle, setMystyle] = useState({})
	const [current, setCurrent] = useState(window.location.pathname)
	const [search, setSearch] = useState('')
	let history = useHistory()

	const handleChange = (value) =>{
		if(searchCategorie === 'Article'){
			// console.log("value", value)
			const art = articles.find(a=>a.name.toLowerCase().includes(value.toLowerCase()))
			setSearch(value)
			setArticleParent(art?.parent)
		}else{
			setSearch(value)
			setArticleParent('')
		}
	}

	const handleSearch = ()=>(e)=>{
		e.preventDefault()
		history.push('/front/recherches', {
			key: searchCategorie === 'Article' ? articleParent : search,
			value: search,
			type: searchCategorie === 'Article' ? 0 : searchCategorie === "Type d'article" ? 0 : 1 
		})
	}

	return (
		<>
			<header id="wt-header" className="wt-header wt-headervtwo wt-haslayout">
				<div className="wt-navigationarea">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<div style={{display: 'block'}}>
									<button className="ayn-btn-toggle-mobile btn d-lg-none d-md-block" type="button" onClick={()=>setActive('active')}>
										<i className="lnr lnr-menu"></i>
									</button>
									<strong className="wt-logo" style={{borderRight: 'none', margin: '14px 0', width: 'auto'}}>
										<Link to='/front/home'><img src={logo} alt="logo" style={{height: 48}} /></Link>
									</strong>
									{/* <strong className="wt-logo" style={{borderRight: 'none'}}>
										<Link to='/front/home' style={{ fontSize: 20, fontWeight: 'bold', color: '#001e37' }}>DANEELA</Link>
									</strong> */}
								</div>
								<div className="ayn-search-container">
									<form className="wt-formtheme wt-formbanner wt-formbannervtwo" onSubmit={handleSearch()}>
										<fieldset className="ayn-fieldset">
											{/* <div className="form-group">
												<input type="text" value={search} list="datalistOptions" onChange={(e)=>setSearch(e.target.value)} className="form-control" placeholder="Rechercher" required />
												<datalist id="datalistOptions">
													{subcategories.map((s,i)=>{
														return <option key={i} value={s.name} />
													})}
												</datalist>
												<div className="wt-formoptions">
													<button type="submit" className="wt-searchbtn" style={{borderRadius: '0 4px 4px 0'}}><i className="lnr lnr-magnifier"></i></button>
												</div>
											</div> */}

											<div className="form-group" style={{display: 'flex', flexWrap: 'wrap'}}>
												<div style={{width: isMobile ? '100%' : 270}}>
													<div className="wt-dropdown" style={{width: isMobile ? '100%' : 270, height: 50, backgroundColor: '#fff', border: '1px solid #ddd', borderRight: 'none', borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }} onClick={()=>setMystyle({})}>
														<span className="ayn-search-group-span">
															<span>Rechercher par :</span>
															<em className="selected-search-type"> {searchCategorie} </em>
															<i className="lnr lnr-chevron-down"></i>
														</span>
													</div>
													<div className="wt-radioholder" style={mystyle}>
														<span className="wt-radio">
															<input id="nx-article" data-title="Article" type="radio" name="searchtype" value="Article" onChange={(e)=>{ setSearchCategorie(e.target.value); setSearch(''); setMystyle({display: 'none'})}} checked={searchCategorie === 'Article' ? true : false} />
															<label htmlFor="nx-article" className="ayn-search-group-item">Article</label>
														</span>
														<span className="wt-radio">
															<input id="nx-type-article" data-title="Type d'article" type="radio" name="searchtype" value="Type d'article" onChange={(e)=>{ setSearchCategorie(e.target.value); setSearch(''); setMystyle({display: 'none'})}} checked={searchCategorie === 'Type-article' ? true : false}  />
															<label htmlFor="nx-type-article" className="ayn-search-group-item" style={{textTransform: 'none'}}>Type d'article</label>
														</span>
														<span className="wt-radio">
															<input id="nx-marchand" data-title="Marchand" type="radio" name="searchtype" value="Marchand" onChange={(e)=>{ setSearchCategorie(e.target.value); setSearch(''); setMystyle({display: 'none'})}} checked={searchCategorie === 'Marchand' ? true : false}  />
															<label htmlFor="nx-marchand" className="ayn-search-group-item" style={{textTransform: 'none'}}>Marchand</label>
														</span>
													</div>
												</div>
												{/* Article ==> article
												Type d'article ==> sous-Categorie */}
												{searchCategorie === 'Article' &&
												<>
													<input type="text" value={search} list="datalistOptions" onChange={(e)=>handleChange(e.target.value)} className="form-control" placeholder="Rechercher" style={{padding: 12, borderRadius: 0, width: isMobile ? "calc(100% - 50px)" : 225}} required />
													<datalist id="datalistOptions">
														{articles.map((s,i)=>{
															return <option key={i} value={s.name} />
														})}
													</datalist>
												</>}
												{searchCategorie === "Type d'article" &&
												<>
													<input type="text" value={search} list="datalistOptions" onChange={(e)=>handleChange(e.target.value)} className="form-control" placeholder="Rechercher" style={{padding: 12, borderRadius: 0, width: isMobile ? "calc(100% - 50px)" : 225}} required />
													<datalist id="datalistOptions">
														{subcategories.map((s,i)=>{
															return <option key={i} value={s.name} />
														})}
													</datalist>
												</>}
												{searchCategorie === 'Marchand' &&
												<>
													<input type="text" value={search} list="datalistOptions" onChange={(e)=>handleChange(e.target.value)} className="form-control" placeholder="Rechercher" style={{padding: 12, borderRadius: 0, width: isMobile ? "calc(100% - 50px)" : 225}} required />
													<datalist id="datalistOptions">
														{marchands.map((s,i)=>{
															return <option key={i} value={s.boutique} />
														})}
													</datalist>
												</>}
												<div className="wt-formoptions">
													<button type="submit" className="wt-searchbtn" style={{borderRadius: '0 4px 4px 0'}}><i className="lnr lnr-magnifier"></i></button>
												</div>
											</div>
										</fieldset>
									</form>
								</div>
								{/* <div className="wt-userlogedin d-none d-sm-none d-md-none d-lg-block">
									<Link className="ayn-link" to="/admin/inscription">Je suis marchand</Link>
								</div> */}
							</div>
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{padding: 0}}>
								
								<nav id="wt-nav" className="wt-nav navbar-expand-lg app-header">
									
									<div className="wt-navigation ayn-navigation">
										
										<button className="ayn-btn-toggle btn d-none d-sm-none d-md-none d-lg-block" type="button" onClick={()=>setActive('active')}>
											<i className="lnr lnr-menu"></i> Toutes les catégories
										</button>

										<ul className="navbar-nav ayn-navbar d-none d-sm-none d-md-none d-lg-block ayn-nav-ul">
											<li className="nav-item ayn-nav-item">
												<Link to='/front/home' onClick={()=> setCurrent('/front/home') } className={'/front/home' === current ? 'big-menu-active' : ''}>Accueil</Link>
											</li>
											{routes.map((r,i)=>{
												return(
													<li className="nav-item ayn-nav-item" key={i}>
														<Link to={{ pathname: r.path, state: {name: r.name} }} onClick={()=> setCurrent(r.path) } className={r.path === current ? 'big-menu-active' : ''}>{r.name}</Link>
													</li>
												)
											})}
										</ul>
										<button className="ayn-btn-toggle btn d-none d-sm-none d-md-none d-lg-block" style={{zIndex: 3}} type="button" onClick={()=>setActive('active')}>
											<i className="fas fa-ellipsis-v"></i>
										</button>
									</div>
								</nav>
			
							</div>
						</div>
					</div>
				</div>
			</header>
			<nav id="sidebar" style={{ left: active === 'active' ? 0 : -250 }}>
				<div>
					<button className="btn" style={{backgroundColor: 'transparent', color: '#fff', fontSize: 36}} onClick={()=>setActive('')}>
						<i className="lnr lnr-menu" style={{color: '#fff'}}></i>
					</button>
				</div>
				
				<div style={{display: 'flex', flexDirection: 'column', paddingLeft: 20, width: '100%'}}>
					<Link style={{color: '#fff', marginBottom: 18, fontSize: 18, fontWeight: 'bold'}} to="/front/home" onClick={()=>{setActive(''); setCurrent('/front/home') }}>Accueil</Link>
					{/* <span style={{color: '#fff', marginBottom: 12, fontSize: 18, fontWeight: 'bold'}} onClick={()=>{setActive(''); setCurrent('') }}>Catégories</span> */}
					<Link style={{color: '#fff', marginBottom: 12, fontSize: 18, fontWeight: 'bold'}} to={{ pathname: "/front/recherches", state: {key: '', type: 2 } }} onClick={()=>{setActive(''); setCurrent('') }}>Catégories</Link>
				</div>							
				<div style={{display: 'flex', flexDirection: 'column', marginLeft: 46}}>
					{routes.map((r,i)=>{
						return <Link to={{ pathname: r.path, state: {name: r.name} }} key={i} style={{color: '#fff', marginBottom: 12, fontSize: 16, fontWeight: 'lighter'}} onClick={()=>{setActive(''); setCurrent(r.path) }}>{r.name}</Link>
					})}
				</div>
			</nav>
			<div className={`overlay ${active}`} onClick={()=>setActive('')}></div>
		</>
	)
}

export default Header