/*eslint array-callback-return: "off"*/
import React from 'react'
import ReactModal from 'react-modal'
import Switch from "react-switch";
import Loader from '../../../components/Loader';
import Poper from '../../../components/Poper'
import Table from '../../../components/Table/index'
import messages from '../../../lib/messages.json'

/*eslint consistent-return: ["error", { "treatUndefinedAsUnspecified": false }]*/


// Les colonne du tableua
const columns = [
    { name: 'name', header: 'Nom complet' },
    { name: 'email', header: 'Email' },
    // { name: 'telephone', header: 'Téléphone' },
    { name: 'role', header: 'Rôle' },
    { name: 'status', header: 'Statut', stat: true },
    
]
const filterFields = { filterRole: '' }
const searchFields = { searchEmail: '' }
const fields = { name: '', email: '', role: '', status: false, password: '', confirmation: '', currentId: '', action: ''}
const fieldsError = { name: {}, email: {}, role: {}, password: {}, confirmation: {}}
const validations = { 
	name: {type: 'checkRequired', required: true, min: 4, number: false},
    role: {type: 'checkRequired', required: true, min: 0, number: false},
	email: {type: 'checkEmail', required: true},
	password: {type: 'checkRequired', required: true, min: 8, number: false},
	confirmation: {type: 'checkConfirmation', min: 8, val2: 'password'}
}
const formOneItems = ['name', 'email', 'role', 'status', 'password', 'confirmation']

export default class Utilisateur extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            errors: { ...fieldsError },
            ...fields,
            ...filterFields,
            ...searchFields,
            data: [],
            tempData: [],
            filterData: [],
            load: true, btnLoad: false, deleteModal: false
        }
        this.handleApplyFilter = this.handleApplyFilter.bind(this)
		this.handleApplySearch = this.handleApplySearch.bind(this)
        this.handleSubmitSave = this.handleSubmitSave.bind(this)
        this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this)
    }
    handleTextChange(e, name){ this.setState({ [name]: e.target.value }) }
    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    checkConfirmation(name, val, val2, min){
		const errors = this.state.errors
		errors[name]['required'] = !val ? `*Ce champ est obligatoire` : null
		errors[name]['min'] = val.length < min ? `*Ce champ doit avoir au moins ${min} caracteres` : null
		errors[name]['different'] = val !== val2 ? `*Mot de passe et confirmation ne correspondent pas` : null
		this.setState({errors})
	}
	checkRequired(name, val, min, number = false){
		const errors = this.state.errors
		errors[name]['required'] = !val ? `*Ce champ est obligatoire` : null
		errors[name]['min'] = val.length < min ? `*Ce champ doit avoir au moins ${min} caracteres` : null
		if(number){
			errors[name]['number'] = isNaN(val) ? `*Ce champ doit être numérique` : null
		}
		this.setState({errors})
	}
	checkEmail(name, val, required = true){
		const errors = this.state.errors
		errors[name]['required'] = !val && required ? `*Ce champ est obligatoire` : null
		errors[name]['valide'] = val ? this.validateEmail(val) ? null : `*Cet adresse email n'est valide` : ''
		this.setState({errors})
	}
    checkValidations(items){
		for(let i=0; i<items.length; i++){
			let item = validations[items[i]]
			if(typeof(item) !== 'undefined'){
				if(item['type'] === 'checkRequired'){
					this.checkRequired(items[i], this.state[items[i]], item['min'], item['number'] )
				}else{
					if(item['type'] === 'checkConfirmation'){
						this.checkConfirmation(items[i], this.state[items[i]], this.state[item['val2']], item['min'] )
					}else{
						if(item['type'] === 'checkEmail'){
							this.checkEmail(items[i], this.state[items[i]], item['required'] )
						}else{
							if(item['type'] === 'checkUrl'){
								this.checkUrl(items[i], this.state[items[i]], item['required'] )
							}else{
								if(item['type'] === 'checkFile'){
									let params = this.state[`${items[i]}Params`]
									if(item['multiple']){
										this.checkFileOnSubmit(items[i], this.state[item['name']][item['pos']], item['width'], item['height'], params ? params.w : null, params ? params.h : null )
									}else{
										this.checkFileOnSubmit(items[i], this.state[items[i]], item['width'], item['height'], params ? params.w : null, params ? params.h : null )
									}
								}else{
									if(item['type'] === 'checkChip'){
										this.checkChip(items[i], this.state[items[i]], item['min'] )
									}
								}
							}
						}	
					}
				}
			}
		}
	}

    componentDidMount() {
        this.props.controller.fetch()
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                this.setState({ data, tempData: data, filterData: data, load: false })
            } else {
                if (data.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                    this.setState({ load: false })
                }
            }
        })
    }

    handleApplyFilter(e){
        e.preventDefault()
        this.setState({ btnLoad: true })
        const { filterRole } = this.state
        this.props.controller.filter(filterRole)
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                this.setState({ data, filterData: data, load: false, btnLoad: false })
                this.props.toast(messages.filter, { appearance: 'success' })
            } else {
                if (data.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                    this.setState({ btnLoad: false })
                }
            }
        })
    }

    handleApplySearch(e){
        e.preventDefault()
        this.setState({ btnLoad: true })
        const { searchEmail } = this.state
        this.props.controller.search(searchEmail)
        .then(data => {
            if(data){
                if (typeof (data.pror) === 'undefined') {
                    this.setState({ data: [data], filterData: [data], load: false, btnLoad: false })
                    this.props.toast(messages.search, { appearance: 'success' })
                } else {
                    if (data.pror.indexOf('401') > 0) {
                        this.props.toast(messages.sessionExpired, { appearance: 'info' })
                    } else {
                        this.props.toast(messages.requestFailed, { appearance: 'error' })
                        this.setState({ btnLoad: false })
                    }
                }
            }else{
                this.setState({ data: [], filterData: [], load: false, btnLoad: false })
                this.props.toast(messages.search, { appearance: 'success' })
            }
        })
    }

    hasError(errors){
		let erreur = false
		Object.keys(errors).map(key => {
			let error = errors[key]
			Object.keys(error).map(k => {
				if(error[k]){
					erreur = true
				}
			})
		})
		return erreur
	}

    handleSubmitSave(e){
        e.preventDefault()
        this.setState({ btnLoad: true })
		this.checkValidations(formOneItems)
		const error = this.hasError(this.state.errors)

		if(!error){
			const { name, email, password, role, status } = this.state
			const proprietaire = { name, email, role, admin: role === 'Admin' ? true : false, status, password }

			this.props.controller.save(proprietaire)
			.then(res => {
			    if (typeof (res.pror) === 'undefined') {
                    const rsps = res
                    rsps.id = res._id
                    this.setState({ data: [rsps, ...this.state.data], ...fields, modal: false, btnLoad: false })
					this.props.toast(messages.saveUser, { appearance: 'success' })
			    } else {
			        if (res.pror.indexOf('401') > 0) {
			            this.props.toast(messages.sessionExpired, { appearance: 'info' })
			        } else {
			            this.props.toast(messages.requestFailed, { appearance: 'error' })
                        this.setState({ btnLoad: false })
			        }
			    }
			})
		}else{
			this.props.toast(messages.formError, { appearance: 'error' })
            this.setState({ btnLoad: false })
		}
    }

    handleSubmitUpdate(e){
        e.preventDefault()
        this.setState({ btnLoad: true })
		this.checkValidations(formOneItems)
		const error = this.hasError(this.state.errors)

		if(!error){
			const { name, email, password, role, status, currentId, data } = this.state
			const proprietaire = { id: currentId, name, email, role, admin: role === 'Admin' ? true : false, status, password }

			this.props.controller.save(proprietaire)
			.then(res => {
			    if (typeof (res.pror) === 'undefined') {
                    proprietaire._id = currentId
                    const pos = data.findIndex(d=>d._id === currentId)
                    data[pos] = proprietaire
                    this.setState({ data, ...fields, modal: false, btnLoad: false })
					this.props.toast(messages.updateUser, { appearance: 'success' })
			    } else {
			        if (res.pror.indexOf('401') > 0) {
			            this.props.toast(messages.sessionExpired, { appearance: 'info' })
			        } else {
			            this.props.toast(messages.requestFailed, { appearance: 'error' })
                        this.setState({ btnLoad: false })
			        }
			    }
			})
		}else{
			this.props.toast(messages.formError, { appearance: 'error' })
            this.setState({ btnLoad: false })
		}
    }

    handleDelete(){
        this.setState({ btnLoad: true })
        const { currentId, data } = this.state
		if(currentId){
			this.props.controller.remove(currentId)
			.then(res => {
			    if (typeof (res.pror) === 'undefined') {
                    const pos = data.filter(d=>d._id !== currentId)
                    this.setState({ data: pos, ...fields, deleteModal: false, btnLoad: false })
					this.props.toast(messages.deleteUser, { appearance: 'success' })
			    } else {
			        if (res.pror.indexOf('401') > 0) {
			            this.props.toast(messages.sessionExpired, { appearance: 'info' })
			        } else {
			            this.props.toast(messages.requestFailed, { appearance: 'error' })
                        this.setState({ btnLoad: false })
			        }
			    }
			})
		}
    }

    handleSetStatus(){
        this.setState({ btnLoad: true })
        const { currentId, data, status } = this.state
		if(currentId){
			this.props.controller.status(currentId, { status: !status })
			.then(res => {
			    if (typeof (res.pror) === 'undefined') {
                    const pos = data.findIndex(d=>d._id !== currentId)
                    const st = data.find(s=> s._id === currentId)
                    st.status = !status
                    data[pos] = st
                    this.setState({ data, ...fields, deleteModal: false, btnLoad: false })
                    this.props.toast(messages.statusChangeUser, { appearance: 'success' })
			    } else {
			        if (res.pror.indexOf('401') > 0) {
			            this.props.toast(messages.sessionExpired, { appearance: 'info' })
			        } else {
			            this.props.toast(messages.requestFailed, { appearance: 'error' })
                        this.setState({ btnLoad: false })
			        }
			    }
			})
		}
    }
    
    searchChange(event) {
        const value = event.target.value
        const data = this.state.filterData.filter(dt => {
           return (
              dt.name.toLowerCase().includes(value.toLowerCase()) ||
              dt.email.toLowerCase().includes(value.toLowerCase()) ||
              dt.role.toLowerCase().includes(value.toLowerCase())
           )
        })
        this.setState({ data, research: value })
    }

    handleOpenEdit(id){
        const elt = this.state.data.find(d=>d._id === id)
        if(elt){ this.setState({ name: elt.name, email: elt.email, role: elt.role, status: elt.status, currentId: id, action: 'update', modal: true }) }
    }

    handleOpenDelete(id){
        const elt = this.state.data.find(d=>d._id === id)
        if(elt){ this.setState({ currentId: id, action: 'delete', deleteModal: true }) }
    }
    handleSetStatusOpen(id){
        const elt = this.state.data.find(d=>d._id === id)
        if(elt){ this.setState({ currentId: id, status: elt.status, action: 'status', deleteModal: true }) }
    }

    render() {
        // const { crud, permission } = this.props
        const { data, load, btnLoad, modal, deleteModal,
            name, email, role, status, password, confirmation, action, errors,
            filterRole, searchEmail, research } = this.state

        return (
            <div>
                <div className="table-actions-container">
                    <div className="">
                        <input type="text" value={research} className="form-control search-input" onChange={(e)=>this.searchChange(e)} placeholder="Rechercher dans le tableau" />
                    </div>
                    
                    <div>
                        <Poper trigger={<button className="btn table-actions-btn table-action-btn-filter">Filtrer</button>}>
                            <form className="wt-formtheme wt-formregister" id="form-filter" onSubmit={this.handleApplyFilter}>
                                <div className="form-group">
                                    <label>Rôle</label>
                                    <span className="wt-select">
                                        <select  value={filterRole} onChange={(e)=>this.handleTextChange(e, 'filterRole')} required>
                                            <option value=""> Rôle </option>
                                            <option value='Admin'>Administrateur</option>
                                            <option value='Commercial'>Commercial</option>
                                            <option value='Marchand'>Marchand</option>
                                        </select>
                                    </span>
                                </div>
                                <div className="filter-actions-container">
                                    <button type="button" className="btn btn-secondary filter-action-btn" onClick={()=>this.setState({ ...filterFields, data: this.state.tempData  }) }>Effacer</button>
                                    <button className="btn btn-primary filter-action-btn" type="submit">
                                        {btnLoad && <Loader size={16} />}
                                        {!btnLoad && 'Appliquer'}
                                    </button>
                                </div>
                            </form>
                        </Poper>
                        <Poper trigger={<button className="btn table-actions-btn table-action-btn-search">Rechercher</button>}>
                            <form className="wt-formtheme wt-formregister" id="form-filter" onSubmit={this.handleApplySearch}>
                                <div className="form-group">
                                    <label>Email *</label>
                                    <input type="text" className="form-control" placeholder="Email" value={searchEmail} onChange={(e)=>this.handleTextChange(e, 'searchEmail')} required/>
                                </div>
                                <div className="filter-actions-container">
                                    <button type="button" className="btn btn-secondary filter-action-btn" onClick={()=>this.setState({ ...searchFields, data: this.state.tempData }) }>Effacer</button>
                                    <button className="btn btn-primary filter-action-btn" type="submit">
                                        {btnLoad && <Loader size={16} />}
                                        {!btnLoad && 'Rechercher'}
                                    </button>
                                </div>
                            </form>
                        </Poper>
                        <button className="btn table-actions-btn table-action-btn-search" style={{marginLeft: 8}} onClick={()=>this.setState({ modal: true, action: 'save' })}>Ajouter un utilisateur</button>
                    </div>
                </div>
                <Table
                    columns={columns} data={data} permission={'2'} load={load} stat={true}
                    onDeleteOpen={(id) =>this.handleOpenDelete(id)}
                    onEditOpen={(id) =>this.handleOpenEdit(id)}
                    onSetStatusOpen={(id)=>this.handleSetStatusOpen(id)}
                />
                <ReactModal isOpen={modal} ariaHideApp={false} className="alert-modal">
                    <div className="modal-alert-body-content">
                        <h5>Ajouter un nouvel utilisateur</h5>
                        <form className="wt-formtheme wt-formregister" id="form-inscription" onSubmit={action === 'save' ? this.handleSubmitSave : this.handleSubmitUpdate}>
                            <fieldset className="wt-registerformgroup" style={{}}>
                                <div className="form-group">
                                    <label>Adresse email *</label>
                                    <input type="email" onBlur={()=>this.checkEmail('email', email)} className="form-control" placeholder="ex: exemple@domaine.com" value={email} onChange={(e)=>this.handleTextChange(e, 'email')} required/>
                                    {Object.keys(errors.email).map((k, i) => {
                                        return <span key={i} className="error-span">{errors.email[k]}</span>
                                    })}
                                </div>
                                <div className="form-group">
                                    <label>Nom et prénom *</label>
                                    <input type="text" onBlur={()=>this.checkRequired('name', name, 4)} className="form-control" placeholder="ex: Massire Demeble" value={name} onChange={(e)=>this.handleTextChange(e, 'name')} required/>
                                    {Object.keys(errors.name).map((k, i) => {
                                        return <span key={i} className="error-span">{errors.name[k]}</span>
                                    })}
                                </div>
                                <div className="form-group">
                                    <label>Rôle *</label>
                                    <span className="wt-select">
                                        <select  value={role} onChange={(e)=>{this.handleTextChange(e, 'role'); this.checkRequired('role', e.target.value, 0) }} required>
                                            <option value=""> Rôle </option>
                                            <option value='Admin'>Administrateur</option>
                                            <option value='Commercial'>Commercial</option>
                                            <option value='Marchand'>Marchand</option>
                                        </select>
                                    </span>
                                    {Object.keys(errors.role).map((k, i) => {
                                        return <span key={i} className="error-span">{errors.role[k]}</span>
                                    })}
                                </div>
                                <div className="form-group" style={{display: 'flex', alignItems: 'center'}}>
                                    <span style={{marginRight: 32, fontSize: 12}}>Statut</span>
                                    <Switch onChange={(val)=>this.setState({status: val})} checkedIcon={false} uncheckedIcon={false} onColor='#001e37' height={24} checked={status} />
                                </div>
                                <div className="form-group">
                                    <label>Mot de passe*</label>
                                    <input type="password" onBlur={()=>this.checkRequired('password', password, 8)} className="form-control" placeholder="Mot de passe" value={password} onChange={(e)=>this.handleTextChange(e, 'password')} required/>
                                    {Object.keys(errors.password).map((k, i) => {
                                        return <span key={i} className="error-span">{errors.password[k]}</span>
                                    })}
                                </div>
                                <div className="form-group">
                                    <label>Confirmer le mot de passe*</label>
                                    <input type="password" onBlur={()=>this.checkConfirmation('confirmation', confirmation, password, 8)} className="form-control" placeholder="Confirmer le mot de passe" value={confirmation} onChange={(e)=>this.handleTextChange(e, 'confirmation')} required/>
                                    {Object.keys(errors.confirmation).map((k, i) => {
                                        return <span key={i} className="error-span">{errors.confirmation[k]}</span>
                                    })}
                                </div>
                                
                                <div className="filter-actions-container" style={{float: 'right', marginTop: 16}}>
                                    <button type="button" className="btn btn-secondary filter-action-btn" onClick={()=>this.setState({ ...fields, modal: false }) }>Annuler</button>
                                    <button className="btn btn-primary filter-action-btn" type="submit">
                                        {btnLoad && <Loader size={16} />}
                                        {!btnLoad && `${action === 'save' ? 'Enregistrer' : 'Modifier'}`}
                                    </button>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </ReactModal>
                <ReactModal isOpen={deleteModal} ariaHideApp={false} className="alert-modal">
                    <div className="modal-alert-body-content">
                        <h5>{`${action === 'delete' ? 'Suppression' : 'Activation/Désactivation'}`}</h5>

                        {action === 'delete' && <p>Êtes-vous sûr de vouloire supprimer cet utilisateur</p>}
                        {action === 'status' && <p>Êtes-vous sûr de vouloire changer le statut de cet utilisateur</p>}
                    </div>

                    <div className="modal-alert-actions-container">
                        <button type="button" className="btn btn-secondary modal-action-btn" onClick={()=>this.setState({deleteModal: false, ...fields })}>Annuler</button>
                        {action === 'delete' && <button className="btn btn-primary modal-action-btn" onClick={()=>this.handleDelete()}>
                            {btnLoad && <Loader size={16} />}
                            {!btnLoad && 'Supprimer'}
                        </button>}
                        {action === 'status' && <button className="btn btn-primary modal-action-btn" onClick={()=>this.handleSetStatus()}>
                            {btnLoad && <Loader size={16} />}
                            {!btnLoad && 'Changer'}
                        </button>}
                    </div>
                </ReactModal>
            </div>
        )
    }

}