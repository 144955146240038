import React from 'react'
import './index.css'

export default class Poper extends React.Component {
    constructor(props) {
        super(props);
    
        this.handleClick = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
    
        this.state = {  open: false };
      }

      handleClick() {
        if (!this.state.open) {
          // attach/remove event handler
          document.addEventListener('click', this.handleOutsideClick, false);
        } else {
          document.removeEventListener('click', this.handleOutsideClick, false);
        }
    
        this.setState(prevState => ({
           open: !prevState.open,
        }));
      }
      
      handleOutsideClick(e) {
        if(this.node){
            if (this.node.contains(e.target)) {
              return;
            }
        }
        
        this.handleClick();
      }
    
    render(){
        return (
            <div className="dropdown" ref={node => { this.node = node; }} style={{display: 'inline'}}>
                <span onClick={this.handleClick} style={{backgroundColor: 'transparent', cursor: 'pointer', padding: 0, ...this.props.style}}>
                    {this.props.trigger}
                </span>
                
                {this.state.open && <div className="pop-container" style={this.props.containerStyle}>
                    {/* <a href="#" className="pop-item">Action</a>
                    <a href="#" className="pop-item">Another action</a>
                    <a href="#" className="pop-item">Something else here</a> */}
                    {this.props.children}
                </div>} 
            </div>
        )
    }
}