import React from 'react';
import Page from '../../components/Page'
import Categories from '../../features/client/Categories'
import { MarchandsSvc } from '../../services'

const CategoriesPage = (props) => {
    const marchandService = new MarchandsSvc(props.session)
    return (
        <Page>
            <Categories {...props} marchandController={marchandService} />
        </Page>
    )
}
export default CategoriesPage