import React from 'react';
import Header from '../../components/marchand/Header';
import Page from '../../components/Page'
import Demande from '../../features/marchand/Demande'
import { MarchandsSvc, UsersSvc } from '../../services';

const DemandePage = (props) => {
    const marchandService = new MarchandsSvc(props.session)
    const userService = new UsersSvc(props.session)
    const user = props.session.get('user')
    return (
        <Page mt={0} isAdmin={true}>
            <Header pathname="Demandes" username={user?.username} session={props.session} toast={props.toast} />
            <Demande {...props} userController={userService} controller={marchandService} />
        </Page>
    )
}

export default DemandePage