import React from 'react';
import Header from '../../components/marchand/Header';
import Page from '../../components/Page'
import Modification from '../../features/marchand/Modification'
import { MarchandsSvc, MarchandUpdate, UsersSvc } from '../../services';

const ModificationPage = (props) => {
    const marchandService = new MarchandsSvc(props.session)
    const userService = new UsersSvc(props.session)
    const updateService = new MarchandUpdate(props.session)
    const user = props.session.get('user')
    return (
        <Page mt={0} isAdmin={true}>
            <Header pathname="Modification d'informations" username={user?.username} session={props.session} toast={props.toast} />
            <Modification {...props} userController={userService} controller={marchandService} updateController={updateService} />
        </Page>
    )
}

export default ModificationPage