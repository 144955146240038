import React from 'react';
import Header from '../../components/admin/Header';
import Page from '../../components/Page'
import ForfaitDetail from '../../features/admin/Forfait/Detail'
import { ForfaitsSvc } from '../../services';

const ForfaitDetailPage = (props) => {
    const forfaitService = new ForfaitsSvc(props.session)
    const user = props.session.get('user')
    return (
        <Page mt={0} isAdmin={true}>
            <Header pathname="Forfaits" username={user?.username} session={props.session} toast={props.toast} />
            <ForfaitDetail {...props} controller={forfaitService} />
        </Page>
    )
}
export default ForfaitDetailPage