import { Link } from 'react-router-dom'
import { useState } from 'react'
import logo from '../../../assets/images/logo.png'
import './index.css'

const NavBar = ({role}) => {

	const [active, setActive] = useState(window.location.pathname)
	
	return (
		<>
			<div className="left-side"></div>
			<div className="d-flex flex-column nav-container">
				<Link to="/admin/dashbord" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
					<img src={logo} alt="logo" style={{height: 48, margin: '6px 16px'}} />
				</Link>
				<hr style={{margin: 0}} />
				<ul className="nav nav-pills flex-column mb-auto">
					<li className="nav-item nav-head">Dashboard</li>
					<li className={`nav-item ${active === '/admin/dashbord' ? 'active' : ''}`}>
						<Link to="/admin/dashbord" className="ayn-nav-link" onClick={()=>setActive('/admin/dashbord')}>
							<i className="fas fa-tachometer-alt ayn-nav-icon"></i>
							Tableau de bord
						</Link>
					</li>
					
					<li className="nav-item nav-head">Gestion des marchands</li>
					<li className={`nav-item ${active === '/admin/inscriptions' ? 'active' : ''}`}>
						<Link to="/admin/inscriptions" className="ayn-nav-link" onClick={()=>setActive('/admin/inscriptions')}>
						<i className="fas fa-user-plus ayn-nav-icon"></i>
							Inscriptions
						</Link>
					</li>
					<li className={`nav-item ${active === '/admin/marchands' ? 'active' : ''}`}>
						<Link to="/admin/marchands" className="ayn-nav-link" onClick={()=>setActive('/admin/marchands')}>
							<i className="fas fa-address-card ayn-nav-icon"></i>
							Marchands
						</Link>
					</li>
					<li className={`nav-item ${active === '/admin/demandes' ? 'active' : ''}`}>
						<Link to="/admin/demandes" className="ayn-nav-link" onClick={()=>setActive('/admin/demandes')}>
							<i className="fas fa-inbox ayn-nav-icon"></i>
							Demandes
						</Link>
					</li>
					<li className={`nav-item ${active === '/admin/notifications' ? 'active' : ''}`}>
						<Link to="/admin/notifications" className="ayn-nav-link" onClick={()=>setActive('/admin/notifications')}>
							<i className="fas fa-bell ayn-nav-icon"></i>
							Notifications
						</Link>
					</li>

					{role === 'Admin' && <li className="nav-item nav-head">Administrations</li>}
					{role === 'Admin' &&
					<li className={`nav-item ${active === '/admin/utilisateurs' ? 'active' : ''}`}>
						<Link to="/admin/utilisateurs" className="ayn-nav-link" onClick={()=>setActive('/admin/utilisateurs')}>
							<i className="fas fa-users ayn-nav-icon"></i>
							Utilisateurs
						</Link>
					</li>}
					
				</ul>
			</div>
		</>
	)
}
export default NavBar