import Rating from "react-rating"
import certifie from '../assets/images/certifie.png'
import { moyenNote } from '../lib/functions'

const MobileStoreCard = (props) => {
    return (
        // <div className="wt-companysdetails ayn-mobile-card" style={{display: 'flex', padding: '8px 16px', backgroundImage: `url(${props.cover})`}}>
        //     <figure style={{margin: 0, marginRight: 24}}><img src={props.logo} style={{minWidth: 100}} alt="logo du marchant" /></figure>
        //     <div className="ayn-mobile-card-content">
        //         <div className="ayn-mobile-card-title">
        //             <h4 style={{marginBottom: 0}} className="ayn-header">{props.name ? props.name.toLowerCase() : ''}</h4>
        //         </div>
        //         {/* {props.isVerified && <span><i className="fa fa-check-circle"></i></span>} */}
        //         {props.isVerified && <span style={{color: '#999', marginBottom: 6, fontSize: 12, lineHeight: '18px' }}><img src={certifie} alt="img certifie" className="ayn-certifie-img" /> Marchand certifié</span>}
        //         <a href={props.url} style={{ marginTop: props.isVerified ? 0 : 24 }} target="_blank" className="ayn-li ayn-store-card-li" rel="noreferrer">
        //             <span>Voir le site <i className="fa fa-external-link" aria-hidden="true"></i></span>
        //         </a>
        //         <span className="ayn-li ayn-store-card-li li-span" style={{color: '#999'}}>{props.address}</span>
        //         <span className="ayn-mobile-card-stars ayn-li ayn-store-card-li" style={{color: '#999'}}>
        //             <Rating
        //                 initialRating={moyenNote(props.rating)}
        //                 emptySymbol={<i className="fa fa-star-o"></i>}
        //                 fullSymbol={<i className="fa fa-star" style={{color: 'orange'}}></i>}
        //                 readonly
        //             />
        //         </span>
        //     </div>
        // </div>
        <div className="wt-companysdetails ayn-mobile-card" style={{display: 'flex', padding: 0, backgroundImage: `url(${props.cover})`}}>
            <figure style={{margin: 0, padding: 8, backgroundColor: 'rgb(28 26 26 / 81%)'}}><img src={props.logo} style={{minWidth: 100, margin: 8, backgroundColor: '#fff'}} alt="logo du marchant" /></figure>
            <div className="ayn-mobile-card-content">
                <div className="ayn-mobile-card-title" id={props.name.length > 10 ? "marquee" : ''}>
                    <h4 style={{marginBottom: 0, color: '#fff'}} id={props.name.length > 10 ? "text" : ''} className="ayn-header">{props.name ? props.name.toLowerCase() : ''}</h4>
                </div>
                {/* {props.isVerified && <span><i className="fa fa-check-circle"></i></span>} */}
                {props.isVerified && <span style={{color: '#fff', marginBottom: 6, fontSize: 12, lineHeight: '18px' }}><img src={certifie} alt="img certifie" className="ayn-certifie-img" /> Marchand certifié</span>}
                <a href={props.url} style={{ marginTop: props.isVerified ? 0 : 24 }} target="_blank" className="ayn-li ayn-store-card-li" rel="noreferrer">
                    <span>Voir le site <i className="fa fa-external-link" aria-hidden="true"></i></span>
                </a>
                <span className="ayn-li ayn-store-card-li li-span" style={{color: '#fff'}}>{props.address}</span>
                <span className="ayn-mobile-card-stars ayn-li ayn-store-card-li" style={{color: '#fff'}}>
                    <Rating
                        initialRating={moyenNote(props.rating)}
                        emptySymbol={<i className="fa fa-star-o"></i>}
                        fullSymbol={<i className="fa fa-star" style={{color: 'orange'}}></i>}
                        readonly
                    />
                </span>
            </div>
        </div>
    )
}
export default MobileStoreCard