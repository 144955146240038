import { http } from './config';

export class Service {

  constructor(baseURL, session) {

    const user = session.get('user')
    const token = user?.token
    // const refresh_token = session.user['refresh_token']
    
    const logoutHandler = (err) => {
     const error = err.message.split('||')
     
      if (err.message.indexOf('401') > 0) {
        setTimeout(function(){ 
          session.reset()
          if(window.location.pathname.includes('/admin/')){ 
            window.location = '/admin/login'
          }else{
            if(window.location.pathname.includes('/marchands/')){
              window.location = '/marchands/login'
            }else{
              // window.location = '/front/home'
            }
          }
        }, 1000);
      }
      return {pror: error[1] ? error[0].indexOf('401') > 0 ? error[0] : error[1] : error[0]};
    };

    this._get = (url, props) => http.get(url, { ...props, bearer: token, baseURL }).catch(logoutHandler);

    this._post = (url, props) => http.post(url, { ...props, responseType: "application/json", bearer: token, baseURL }).catch(logoutHandler);
    
    this._put = (url, props) => http.put('/'+url, { ...props, bearer: token, baseURL }).catch(logoutHandler);

    this._delete = (url, props) => http.delete(url, { ...props, bearer: token, baseURL }).catch(logoutHandler);

    this._report = (url, props) => http.get(url, { ...props, bearer: token, baseURL, responseType: 'blob' }).catch(logoutHandler);
 
    this._postReport = (url, props) => http.post(url, { ...props, bearer: token, baseURL, responseType: 'blob' }).catch(logoutHandler);
    
    // this._import = (url, props) => http.post(url, { ...props, bearer: token, baseURL }).catch(logoutHandler);
    this._import = (url, file) => http.upload(baseURL+'/'+url, file, token).catch(logoutHandler);
    this._importMultiple = (url, files, body) => http.uploadMultiple(baseURL+url, {files, body, token}).catch(logoutHandler)
  }
}

export class EntityService extends Service {

  constructor(domain, session) {
    super('/api/1/' + domain, session) 
  }


  save = (data) => {
    // console.log('data', data)
    var id = data.id ? data.id  : null
    var editData = id ? data : {id: null}
    // console.log('editData', editData)
    delete editData.id
    // console.log('DEL editData', data)
    // delete currentElement.createdAt
    return (id) ? this._put(id, { data: editData }) : this._post('', { data });
  }

  fetch = () => { return this._get(); };

  remove = (id) => this._delete(id);

  // report = () => { return this._get(); };

}