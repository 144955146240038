import { ToastProvider } from 'react-toast-notifications';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';
import Layout from './pages/Layout';
import { privateNavRoutes } from './routers/privateNavRoutes';
import { frontRoutes, adminRoutes, marchandRoutes } from './routers/privateRoutes';
import './App.css'
import './components/header.css'
import stores from './stores';

const TRACKING_ID = "G-PQ27T3JB7W"
ReactGA.initialize(TRACKING_ID);

const App = ({session}) => {
  return (
    <Provider store={stores}>
      <ToastProvider autoDismiss={true} autoDismissTimeout={30000}>
        <Layout navRoutes={privateNavRoutes} routes={frontRoutes} adminRoutes={adminRoutes} marchandRoutes={marchandRoutes} session={session} />
      </ToastProvider>
    </Provider>
  )
}
export default App