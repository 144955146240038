import React from 'react';
import Header from '../../components/admin/Header';
import Page from '../../components/Page'
import Profile from '../../features/admin/Profile';
import { UsersSvc } from '../../services';

const ProfilePage = (props) => {
    const usersService = new UsersSvc(props.session)
    const user = props.session.get('user')
    return (
        <Page mt={0} isAdmin={true}>
            <Header pathname="Profil" username={user?.username} session={props.session} toast={props.toast} />
            <Profile {...props} controller={usersService} />
        </Page>
    )
}
export default ProfilePage