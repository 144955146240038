import React, { Component } from 'react'
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom'
import FrontLayout from './layouts/FrontLayout'
import AdminLayout from './layouts/AdminLayout'
import MarchandLayout from './layouts/MarchandLayout'
import { withToastHook } from './WithToastHook'

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = { isMobile: false }
    }

    updateDimensions = () => {
        const width = window.innerWidth
        if(width < 576) this.setState({ isMobile: true })
        else this.setState({ isMobile: false })
    }
      
    componentDidMount() {
        this.updateDimensions()
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() { window.removeEventListener('resize', this.updateDimensions) }

    render() {
        const { session, routes, adminRoutes, marchandRoutes, addToast /*login, navRoutes, loginComponent*/ } = this.props
        const { isMobile } = this.state

        return (
            <Router>
                <Switch>
                    <Route exact path="/">  
                        <Redirect to="/front/home" />  
                    </Route>
                    <Route path="/front/:path" render={(propos)=><FrontLayout session={session} routes={routes} isMobile={isMobile} toast={addToast} {...propos} /> } />
                    <Route path="/marchands/:path" render={(propos)=><MarchandLayout session={session} routes={marchandRoutes} isMobile={isMobile} toast={addToast} {...propos} /> } />
                    <Route path="/admin/:path" render={(propos)=><AdminLayout session={session} routes={adminRoutes} isMobile={isMobile} toast={addToast} {...propos} /> } />
                </Switch>
            </Router>
        )
    }
}

export default withToastHook(Layout)