import React from "react"
import Switch from "react-switch";
import { Link } from "react-router-dom"
// import './index.css'
import categoriesJSON from '../../../lib/categories.json'
import messages from '../../../lib/messages.json'
// import axios from "axios";
// import { Cropper } from "react-cropper";
// import Resizer from "react-image-file-resizer";
import "cropperjs/dist/cropper.css";
import Loader from "../../../components/Loader";

const proprietaire = { name: '', email: '', password: '', confirmation: ''}
const boutique = { boutique: '', url: '', emailBoutique: '', adresse: '', description: '', region: '', indicatif: '+223', telephone: '', vitepay: false, frame: false, certifie: false, status: 'Default', logo: '', couverture: '', produits: [] }
const reseaux = { facebook: '', instagram: '', linkedin: '' }
const prix = { minimum: 0, maximum: 0 }
const services = { livraison: 'Non disponible', service: "false" }

const boutiqueOld = { boutiqueOld: '', urlOld: '', emailBoutiqueOld: '', adresseOld: '', descriptionOld: '', regionOld: '', indicatifOld: '+223', telephoneOld: '', vitepayOld: false, frameOld: false, certifieOld: false, logoOld: '', couvertureOld: '', produitsOld: [] }
const reseauxOld = { facebookOld: '', instagramOld: '', linkedinOld: '' }
const prixOld = { minimumOld: 0, maximumOld: 0 }
const servicesOld = { livraison: 'Non disponible', service: "false" }

export default class Detail extends React.Component {
    constructor(props){
        super(props)
        this.state = {
			categoriesSelected: [],
			subcategoriesSelected: [],
			categories: categoriesJSON,
			subcategories: [],

			categoriesSelectedOld: [],
			subcategoriesSelectedOld: [],
			categoriesOld: categoriesJSON,
			subcategoriesOld: [],

			...proprietaire,
			...boutique,
			...reseaux,
			...prix,
			...services,

			motif: '',
			...boutiqueOld,
			...reseauxOld,
			...prixOld,
			...servicesOld,

			produits: [],
			prdPrvImg1: '',
			prdPrvImg2: '',
			prdPrvImg3: '',

			logoPrvImg: '',
			couverturePrvImg: '',

			produitsOld: [],
			prdPrvImg1Old: '',
			prdPrvImg2Old: '',
			prdPrvImg3Old: '',

			logoPrvImgOld: '',
			couverturePrvImgOld: '',
			load: true

        }

    }

	handleSubmit(status){
		const { id, motif  } = this.state
		if(this.state.motif === '' && status === 'Rejeter'){
			this.props.toast(messages.formError, { appearance: 'error' })
		}else{
			const body = { id, motif, status }
			
			this.props.controller.save(body)
			.then(data => {
				if (typeof (data.pror) === 'undefined') {
					this.props.toast(messages.save, { appearance: 'success' })
					this.setState({status: 'Traiter'})				
				} else {
					if (data.pror.indexOf('401') > 0) {
						this.props.toast(messages.sessionExpired, { appearance: 'info' })
					} else {
						this.props.toast(messages.requestFailed, { appearance: 'error' })
					}
				}
			})
		}
	}

	filter(flt){
        let res = []
        for(let i=0; i<flt.length; i++){
            const f = categoriesJSON.filter(c=>c.name === flt[i])
            res.push(...f)
        }
        return res
    }

	selectCategorie(cat, categoriesSel, subcates, prefix){
        const categoriesSelected = this.state[categoriesSel]
        let slt = categoriesSelected
        if(this.state[`${prefix}_${cat}`]){
            slt = categoriesSelected.filter(s=> s !== cat)
            if(slt[0]){
                const subcategories = this.filter(slt)
                this.setState({ [`${prefix}_${cat}`]: '', [categoriesSel]: slt, [subcates]: subcategories })
            }else{
                this.setState({ [`${prefix}_${cat}`]: '', [categoriesSel]: slt, [subcates]: [] })
            }
        }else{
            slt.push(cat)
            const subcategories = this.filter(slt)
            this.setState({[`${prefix}_${cat}`]: 'active', [categoriesSel]: slt, [subcates]: subcategories })
        }
    }

	selectSubcategorie(cat, subcategoriesSel, prefix){
        const subcategoriesSelected = this.state[subcategoriesSel]
        let slt = subcategoriesSelected
        if(this.state[`${prefix}_${cat}`]){
            slt = subcategoriesSelected.filter(s=> s !== cat)
            if(slt[0]){
                this.setState({ [`${prefix}_${cat}`]: '', [subcategoriesSel]: slt })
            }else{
                this.setState({ [`${prefix}_${cat}`]: '', [subcategoriesSel]: slt })
            }
        }else{
            slt.push(cat)
            this.setState({[`${prefix}_${cat}`]: 'active', [subcategoriesSel]: slt })
        }
    }

	componentDidMount(){
		const { id } = this.props.match.params
		this.props.controller.findById(id)
		.then(res => {
			if (typeof (res.pror) === 'undefined') {
				const mrd = res.marchandId

				this.setState({
					id: res._id, boutique: res.boutique, url: res.url, adresse: res.adresse, telephone: res.telephone.split(' ')[1], emailBoutique: res.email, vitepay: res.vitepay, frame: res.frame,
					facebook: res.reseaux?.facebook, instagram: res.reseaux?.instagram, linkedin: res.reseaux?.linkedin, description: res.description, region: res.region,
					minimum: res.tags.prix.minimum, maximum: res.tags.prix.maximum, livraison: res.services.livraison, service: `${res.services.service}`,
					prdPrvImg1: res.produits[0], prdPrvImg2: res.produits[1], prdPrvImg3: res.produits[2],
					logoPrvImg: res.logo, couverturePrvImg: res.couverture,
					logo: res.logo, couverture: res.couverture, produits: [res.produits[0], res.produits[1], res.produits[2]],
					userId: res.userId,

					status: res.status,

					idOld: mrd?._id, boutiqueOld: mrd?.boutique, urlOld: mrd?.url, adresseOld: mrd?.adresse, telephoneOld: mrd?.telephone.split(' ')[1], emailBoutiqueOld: mrd?.email, vitepayOld: mrd?.vitepay, frameOld: mrd.frame,
					facebookOld: mrd?.reseaux?.facebook, instagramOld: mrd?.reseaux?.instagram, linkedinOld: mrd?.reseaux?.linkedin, descriptionOld: mrd?.description, regionOld: mrd?.region,
					minimumOld: mrd?.tags.prix.minimum, maximumOld: mrd?.tags.prix.maximum, livraisonOld: mrd?.services.livraison, serviceOld: `${mrd?.services.service}`,
					prdPrvImg1Old: mrd?.produits[0], prdPrvImg2Old: mrd?.produits[1], prdPrvImg3Old: mrd?.produits[2],
					logoPrvImgOld: mrd?.logo, couverturePrvImgOld: mrd?.couverture,
					logoOld: mrd?.logo, couvertureOld: mrd?.couverture, produitsOld: [mrd?.produits[0], mrd?.produits[1], mrd?.produits[2]],
					userIdOld: mrd?.userId, load: false
				})
				for(let i=0; i< res.tags.categories.length; i++){
					this.selectCategorie(res.tags.categories[i], 'categoriesSelected', 'subcategories', 'cat')
				}
				for(let i=0; i< res.tags.sousGategories.length; i++){
					this.selectSubcategorie(res.tags.sousGategories[i], 'subcategoriesSelected', 'subcat')
				}

				for(let i=0; i< mrd?.tags.categories.length; i++){
					this.selectCategorie(mrd?.tags.categories[i], 'categoriesSelectedOld', 'subcategoriesOld', 'cat_old')
				}
				for(let i=0; i< mrd?.tags.sousGategories.length; i++){
					this.selectSubcategorie(mrd?.tags.sousGategories[i], 'subcategoriesSelectedOld', 'subcat_old')
				}


			} else {
				if (res.pror.indexOf('401') > 0) {
					this.props.toast(messages.sessionExpired, { appearance: 'info' })
				} else {
					this.props.toast(res.pror, { appearance: 'error' })
					this.setState({load: false})
				}
			}
		})
	}

    render() {
        const {
			boutique, url, emailBoutique, adresse, region, indicatif, telephone, description, vitepay, frame,
			minimum, maximum,
			livraison, service,
			facebook, instagram, linkedin,
			logoPrvImg, couverturePrvImg, prdPrvImg1, prdPrvImg2, prdPrvImg3,
			categories, subcategories, categoriesSelected, subcategoriesSelected,

			boutiqueOld, urlOld, emailBoutiqueOld, adresseOld, regionOld, indicatifOld, telephoneOld, descriptionOld, vitepayOld, frameOld,
			minimumOld, maximumOld,
			livraisonOld, serviceOld,
			facebookOld, instagramOld, linkedinOld,
			logoPrvImgOld, couverturePrvImgOld, prdPrvImg1Old, prdPrvImg2Old, prdPrvImg3Old,
			categoriesOld, subcategoriesOld, categoriesSelectedOld, subcategoriesSelectedOld,

			motif, load, status
		} = this.state
        // const { isMobile } = this.props

        return (
            <div className="wt-haslayout wt-main-section" style={{padding: 0}}>
					<div className="container">
						{load && <Loader size={16} color="#001e37" />}
						{!load && 
						<div className="row justify-content-md-center">
							<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
								<div className="wt-registerformhold" style={{backgroundColor: 'transparent', paddingTop: 0}}>
									<div className="wt-registerformmain">
										<div className="wt-registerhead" style={{padding: 0}}>
											<div className="wt-title">
												<h3 style={{marginBottom: 4}}>Informations actuelles</h3>
											</div>
											<div className="wt-description">
												<p style={{lineHeight: '14px', fontSize: 12}}>Les informations actuelles de la boutique</p>
											</div>
										</div>
										<div className="wt-joinforms">
											<form className="wt-formtheme wt-formregister" id="form-inscription">
												<fieldset className="wt-registerformgroup">
													<div className="form-card">
														<div className="form-card-header">
															<h4>INFORMATION DE LA BOUTIQUE</h4>
														</div>

														<div className="form-card-body">
															<div className="form-group">
																<label>Marchand/Nom de la boutique en ligne*</label>
																<input type="text" className="form-control" placeholder="ex: Boutique" value={boutiqueOld} readOnly/>
															</div>
															<div className="form-group">
																<label>Email de la boutique*</label>
																<input type="text" className="form-control" placeholder="ex: exemple@domaine.com" value={emailBoutiqueOld} readOnly/>
															</div>
															<div className="form-group has-feedback">
																<label>URL du site web*</label>
																<input type="text" className="form-control" placeholder="ex: https://nomdedomaine.com" value={urlOld} readOnly/>
															</div>
															<div className="form-group">
																<label>Adresse de la boutique*</label>
																<input type="text" className="form-control" placeholder="ex: Kalaban coura" value={adresseOld} readOnly/>
															</div>
															<div className="form-group">
																<label>Region*</label>
																<span className="wt-select">
																	<select  value={regionOld} disabled>
																		<option value={regionOld}> {regionOld} </option>
																	</select>
																</span>
															</div>
															<div className="form-group">
																<label>Numéro de téléphone de la boutique *</label>
																<div className="input-group mb-3">
																	<span className="wt-select" style={{width: 100}}>
																		<select required value={indicatifOld} readOnly>
																			<option>+223</option>
																		</select>
																	</span>
																	<input type="number" minLength={8} maxLength={8} className="form-control" placeholder="ex: 20 24 49 15" value={telephoneOld} readOnly/>
																</div>
															</div>

															<div className="form-group">
																<label>Description de la boutique *</label>
																<textarea rows="2" className="form-control" placeholder="Description de la boutique" value={descriptionOld} readOnly></textarea>
															</div>								

															<div className="form-group" style={{display: 'flex', alignItems: 'center'}}>
																<span style={{marginRight: 32, fontSize: 12}}>Etes-vous un marchand Vitepay ?</span>
																<Switch onChange={()=>this.setState({vitepayOld})} checkedIcon={false} uncheckedIcon={false} onColor='#001e37' height={24} checked={vitepayOld} />
															</div>

															<div className="form-group" style={{display: 'flex', alignItems: 'center'}}>
																<span style={{marginRight: 32, fontSize: 12}}>Le site peut-il s'afficher sur daneela ?</span>
																<Switch onChange={()=>this.setState({frameOld})} checkedIcon={false} uncheckedIcon={false} onColor='#001e37' height={24} checked={frameOld} />
															</div>
														</div>
														
													</div>

													<div className="form-card">
														<div className="form-card-header">
															<h4>LIENS DES RESEAUX SOCIAUX</h4>
														</div>
														<div className="form-card-body">
															<div className="form-group">
																<label>Facebook</label>
																<input type="text" className="form-control" placeholder="Lien du compte facebook" value={facebookOld} readOnly/>
															</div>
															<div className="form-group">
																<label>Instagram</label>
																<input type="text" className="form-control" placeholder="Lien du compte instagram" value={instagramOld} readOnly/>
															</div>
															<div className="form-group">
																<label>Linkedin</label>
																<input type="text" className="form-control" placeholder="Lien du compte linkedin" value={linkedinOld} readOnly/>
															</div>
														</div>
													</div>

													<div className="form-card">
														<div className="form-card-header">
															<h4>CATEGORIES ET SOUS CATEGORIES</h4>
														</div>
														<div className="form-card-body">
															<div className="form-group" style={{marginTop: 8}}>
																<h6 className="form-title">Categories</h6>
															</div>
															<ul className="wt-filtertag ayn-cat-ul form-cat-ul" style={{marginBottom: 8}}>
																{categoriesOld.map((c,i)=>{
																	return(
																		<li className="ayn-cat-li" key={i}>
																			<Link to="#" className={this.state[`cat_old_${c.name}`]}><span>{c.name}</span></Link>
																		</li>
																	)
																})}
															</ul>
															
															{categoriesSelectedOld.length > 0 &&
															<ul className="wt-filtertag ayn-cat-ul form-subcat-ul">
															{categoriesSelectedOld.map((c,i)=>{
																return(
																	<li className="ayn-cat-li" key={i}>
																		<Link to="#" className={this.state[`cat_old_${c}`]}>
																			<span>{c}</span>
																			<i className="fa fa-times close cat-closer"></i>
																		</Link>
																	</li>
																)
															})}
															</ul>}
															
															{subcategoriesOld.length > 0 &&
															<>
																<div className="form-group form-title-container">
																	<h6 className="form-title">Sous categories</h6>
																</div>
																<ul className="wt-filtertag ayn-cat-ul" style={{justifyContent: 'start', flexWrap: 'wrap'}}>
																	{subcategoriesOld.map((c,i)=>{
																		return(
																			<ul className="wt-filtertag ayn-cat-ul form-subcat-ul" style={{margin: '8px 0'}} key={i}>
																				{c.children.map((s,j)=>{
																					return(
																						<li className="ayn-cat-li" key={j}>
																							<Link to="#" className={this.state[`subcat_old_${s.name}`]}><span>{s.name}</span></Link>
																						</li>
																					)
																				})}
																			</ul>
																		)
																	})}
																</ul>
															</>}

															{subcategoriesSelectedOld.length > 0 &&
															<ul className="wt-filtertag ayn-cat-ul form-subcat-ul">
															{subcategoriesSelectedOld.map((c,i)=>{
																return(
																	<li className="ayn-cat-li" key={i}>
																		<Link to="#" className={this.state[`subcat_old_${c}`]}>
																			<span>{c}</span>
																			<i className="fa fa-times close cat-closer"></i>
																		</Link>
																	</li>
																)
															})}
															</ul>}
														</div>
													</div>


													<div className="form-group form-title-container">
														<h6 className="form-title">Fourchette de prix</h6>
													</div>
													<div className="form-group" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
														<label>Min:*</label>
														<span className="wt-select" style={{width: 150}}>
															<select value={minimumOld} disabled>
																<option value={minimumOld}>{minimumOld}</option>
															</select>
														</span>
														<label>Max:*</label>
														<span className="wt-select" style={{width: 150}}>
															<select value={maximumOld} disabled>
																<option value={maximumOld}>{maximumOld}</option>
															</select>
														</span>
													</div>

													<div className="form-group form-title-container">
														<h6 className="form-title">Services suplementaire</h6>
													</div>
													<div className="form-group form-radio-container">
														<label style={{width: 200}}>Livraison:</label>
														<div className="wt-radioboxholder form-radio-content">
															<div className="form-radio">
																<span className="form-radio-label">Non disponible</span>
																<span className="wt-radio">
																	<input id="liv-nondisponible" type="radio" name="livraison" value="Non disponible" checked={livraisonOld === "Non disponible"} readOnly/>
																	<label htmlFor="liv-nondisponible"></label>
																</span>
															</div>
															<div className="form-radio">
																<span className="form-radio-label">Gratuite</span>
																<span className="wt-radio">
																	<input id="liv-gratuite" type="radio" name="livraison" value="Gratuite" checked={livraisonOld === "Gratuite"} readOnly/>
																	<label htmlFor="liv-gratuite"></label>
																</span>
															</div>
															<div className="form-radio">
																<span className="form-radio-label">Payante</span>
																<span className="wt-radio">
																	<input id="liv-payant" type="radio" name="livraison" value="Payante" checked={livraisonOld === "Payante"} readOnly/>
																	<label htmlFor="liv-payant"></label>
																</span>
															</div>
														</div>
													</div>
													<div className="form-group form-radio-container">
														<label style={{width: 200}}>Services après vente:</label>
														<div className="wt-radioboxholder form-radio-content">
															<div className="form-radio">
																<span className="form-radio-label">Oui</span>
																<span className="wt-radio">
																	<input id="sav-oui" type="radio" name="serviceApresVente" value={true} checked={serviceOld === "true"} readOnly/>
																	<label htmlFor="sav-oui"></label>
																</span>
															</div>
															<div className="form-radio">
																<span className="form-radio-label">Non</span>
																<span className="wt-radio">
																	<input id="sav-non" type="radio" name="serviceApresVente" value={false} checked={serviceOld === "false"}/>
																	<label htmlFor="sav-non"></label>
																</span>
															</div>
														</div>
													</div>
													
													<div className="form-group form-group-label form-file-selecter logo-selecter">
														<label className="file-label">Veuillez importer le logo</label>
														
														<div className="wt-uploadingbox" style={{margin: 8}}>
															<div className="wt-designimg">
																<label htmlFor="demoq">
																	<img src={logoPrvImgOld} alt="img logo" style={{borderRadius: 8, width: 100, height: 100}} />
																</label>
															</div>
														</div>
													</div>

													<div className="form-group form-group-label form-file-selecter">
														<label className="file-label">Veuillez importer la photo de couverture</label>
														
														<div className="wt-uploadingbox" style={{margin: 8}}>
															<div className="wt-designimg">
																<label htmlFor="demoq">
																	<img src={couverturePrvImgOld} alt="img couverture" style={{borderRadius: 8, width: 400, height: 200}} />
																</label>
															</div>
														</div>
													</div>

													<div className="form-group form-group-label form-file-selecter">
														<label className="file-label">{`Veuillez importer 3 photos pour vos produits phares`}</label>
													</div>

													<div className="form-group" style={{display: 'flex'}}>
														<div className="wt-uploadingbox" style={{margin: 8}}>
															<div className="wt-designimg">
																<label htmlFor="demoq">
																	<img src={prdPrvImg1Old} alt="img produit 1" style={{borderRadius: 8, width: 200, height: 200}} />
																</label>
															</div>
														</div>
														<div className="wt-uploadingbox" style={{margin: 8}}>
															<div className="wt-designimg">
																<label htmlFor="demoq">
																	<img src={prdPrvImg2Old} alt="img produit 2" style={{borderRadius: 8, width: 200, height: 200}}  />
																</label>
															</div>
														</div>
														<div className="wt-uploadingbox" style={{margin: 8}}>
															<div className="wt-designimg">
																<label htmlFor="demoq">
																	<img src={prdPrvImg3Old} alt="img produit 3" style={{borderRadius: 8, width: 200, height: 200}}  />
																</label>
															</div>
														</div>
													</div>
	
												</fieldset>
											</form>
										</div>
									</div>
									
								</div>
							</div>

                            {/* ********************DEUXIEME PARTIE******************************  */}
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
								<div className="wt-registerformhold" style={{backgroundColor: 'transparent', paddingTop: 0}}>
									<div className="wt-registerformmain">
										<div className="wt-registerhead" style={{padding: 0}}>
											<div className="wt-title">
												<h3 style={{marginBottom: 4}}>Informations demandées</h3>
											</div>
											<div className="wt-description">
												<p style={{lineHeight: '14px', fontSize: 12}}>Les informations nouvellement demandées</p>
											</div>
										</div>
										<div className="wt-joinforms">
											<form className="wt-formtheme wt-formregister" id="form-inscription">
												<fieldset className="wt-registerformgroup">
													<div className="form-card">
														<div className="form-card-header">
															<h4>INFORMATION DE LA BOUTIQUE</h4>
														</div>

														<div className="form-card-body">
															<div className="form-group">
																<label>Marchand/Nom de la boutique en ligne*</label>
																<input type="text" className="form-control" placeholder="ex: Boutique" value={boutique} readOnly/>
															</div>
															<div className="form-group">
																<label>Email de la boutique*</label>
																<input type="text" className="form-control" placeholder="ex: exemple@domaine.com" value={emailBoutique} readOnly/>
															</div>
															<div className="form-group has-feedback">
																<label>URL du site web*</label>
																<input type="text" className="form-control" placeholder="ex: https://nomdedomaine.com" value={url} readOnly/>
															</div>
															<div className="form-group">
																<label>Adresse de la boutique*</label>
																<input type="text" className="form-control" placeholder="ex: Kalaban coura" value={adresse} readOnly/>
															</div>
															<div className="form-group">
																<label>Region*</label>
																<span className="wt-select">
																	<select  value={region} disabled>
																		<option value={region}> {region} </option>
																		{/* {listRegion.map((r,i)=> <option value={r} key={i}>{r}</option>)} */}
																	</select>
																</span>
															</div>
															<div className="form-group">
																<label>Numéro de téléphone de la boutique *</label>
																<div className="input-group mb-3">
																	<span className="wt-select" style={{width: 100}}>
																		<select value={indicatif} readOnly>
																			<option>+223</option>
																		</select>
																	</span>
																	<input type="number" minLength={8} maxLength={8} className="form-control" placeholder="ex: 20 24 49 15" value={telephone} readOnly/>
																</div>
															</div>

															<div className="form-group">
																<label>Description de la boutique *</label>
																<textarea rows="2" className="form-control" placeholder="Description de la boutique" value={description} readOnly></textarea>
															</div>								

															<div className="form-group" style={{display: 'flex', alignItems: 'center'}}>
																<span style={{marginRight: 32, fontSize: 12}}>Etes-vous un marchand Vitepay ?</span>
																<Switch onChange={()=>this.setState({vitepay})} checkedIcon={false} uncheckedIcon={false} onColor='#001e37' height={24} checked={vitepay} />
															</div>

															<div className="form-group" style={{display: 'flex', alignItems: 'center'}}>
																<span style={{marginRight: 32, fontSize: 12}}>Le site peut-il s'afficher sur daneela ?</span>
																<Switch onChange={()=>this.setState({frame})} checkedIcon={false} uncheckedIcon={false} onColor='#001e37' height={24} checked={frame} />
															</div>
														</div>
														
													</div>

													<div className="form-card">
														<div className="form-card-header">
															<h4>LIENS DES RESEAUX SOCIAUX</h4>
														</div>
														<div className="form-card-body">
															<div className="form-group">
																<label>Facebook</label>
																<input type="text" className="form-control" placeholder="Lien du compte facebook" value={facebook} readOnly/>
															</div>
															<div className="form-group">
																<label>Instagram</label>
																<input type="text" className="form-control" placeholder="Lien du compte instagram" value={instagram} readOnly/>
															</div>
															<div className="form-group">
																<label>Linkedin</label>
																<input type="text" className="form-control" placeholder="Lien du compte linkedin" value={linkedin} readOnly/>
															</div>
														</div>
													</div>

													<div className="form-card">
														<div className="form-card-header">
															<h4>CATEGORIES ET SOUS CATEGORIES</h4>
														</div>
														<div className="form-card-body">
															<div className="form-group" style={{marginTop: 8}}>
																<h6 className="form-title">Categories</h6>
															</div>
															<ul className="wt-filtertag ayn-cat-ul form-cat-ul" style={{marginBottom: 8}}>
																{categories.map((c,i)=>{
																	return(
																		<li className="ayn-cat-li" key={i}>
																			<Link to="#" className={this.state[`cat_${c.name}`]}><span>{c.name}</span></Link>
																		</li>
																	)
																})}
															</ul>
															
															{categoriesSelected.length > 0 &&
															<ul className="wt-filtertag ayn-cat-ul form-subcat-ul">
															{categoriesSelected.map((c,i)=>{
																return(
																	<li className="ayn-cat-li" key={i}>
																		<Link to="#" className={this.state[`cat_${c}`]}>
																			<span>{c}</span>
																			<i className="fa fa-times close cat-closer"></i>
																		</Link>
																	</li>
																)
															})}
															</ul>}
															
															{subcategories.length > 0 &&
															<>
																<div className="form-group form-title-container">
																	<h6 className="form-title">Sous categories</h6>
																</div>
																<ul className="wt-filtertag ayn-cat-ul" style={{justifyContent: 'start', flexWrap: 'wrap'}}>
																	{subcategories.map((c,i)=>{
																		return(
																			<ul className="wt-filtertag ayn-cat-ul form-subcat-ul" style={{margin: '8px 0'}} key={i}>
																				{c.children.map((s,j)=>{
																					return(
																						<li className="ayn-cat-li" key={j}>
																							<Link to="#" className={this.state[`subcat_${s.name}`]}><span>{s.name}</span></Link>
																						</li>
																					)
																				})}
																			</ul>
																		)
																	})}
																</ul>
															</>}

															{subcategoriesSelected.length > 0 &&
															<ul className="wt-filtertag ayn-cat-ul form-subcat-ul">
															{subcategoriesSelected.map((c,i)=>{
																return(
																	<li className="ayn-cat-li" key={i}>
																		<Link to="#" className={this.state[`subcat_${c}`]}>
																			<span>{c}</span>
																			<i className="fa fa-times close cat-closer"></i>
																		</Link>
																	</li>
																)
															})}
															</ul>}
														</div>
													</div>


													<div className="form-group form-title-container">
														<h6 className="form-title">Fourchette de prix</h6>
													</div>
													<div className="form-group" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
														<label>Min:*</label>
														<span className="wt-select" style={{width: 150}}>
															<select value={minimum} disabled>
																<option value={minimum}>{minimum}</option>
															</select>
														</span>
														<label>Max:*</label>
														<span className="wt-select" style={{width: 150}}>
															<select value={maximum} disabled>
																<option value={maximum}>{maximum}</option>
															</select>
														</span>
													</div>

													<div className="form-group form-title-container">
														<h6 className="form-title">Services suplementaire</h6>
													</div>
													<div className="form-group form-radio-container">
														<label style={{width: 200}}>Livraison:</label>
														<div className="wt-radioboxholder form-radio-content">
															<div className="form-radio">
																<span className="form-radio-label">Non disponible</span>
																<span className="wt-radio">
																	<input id="liv-nondisponible" type="radio" name="livraison" value="Non disponible" checked={livraison === "Non disponible"} readOnly/>
																	<label htmlFor="liv-nondisponible"></label>
																</span>
															</div>
															<div className="form-radio">
																<span className="form-radio-label">Gratuite</span>
																<span className="wt-radio">
																	<input id="liv-gratuite" type="radio" name="livraison" value="Gratuite" checked={livraison === "Gratuite"} readOnly/>
																	<label htmlFor="liv-gratuite"></label>
																</span>
															</div>
															<div className="form-radio">
																<span className="form-radio-label">Payante</span>
																<span className="wt-radio">
																	<input id="liv-payant" type="radio" name="livraison" value="Payante" checked={livraison === "Payante"} readOnly/>
																	<label htmlFor="liv-payant"></label>
																</span>
															</div>
														</div>
													</div>
													<div className="form-group form-radio-container">
														<label style={{width: 200}}>Services après vente:</label>
														<div className="wt-radioboxholder form-radio-content">
															<div className="form-radio">
																<span className="form-radio-label">Oui</span>
																<span className="wt-radio">
																	<input id="sav-oui" type="radio" name="serviceApresVente" value={true} checked={service === "true"} readOnly/>
																	<label htmlFor="sav-oui"></label>
																</span>
															</div>
															<div className="form-radio">
																<span className="form-radio-label">Non</span>
																<span className="wt-radio">
																	<input id="sav-non" type="radio" name="serviceApresVente" value={false} checked={service === "false"} readOnly/>
																	<label htmlFor="sav-non"></label>
																</span>
															</div>
														</div>
													</div>
													
													<div className="form-group form-group-label form-file-selecter logo-selecter">
														<label className="file-label">Veuillez importer le logo</label>
														
														<div className="wt-uploadingbox" style={{margin: 8}}>
															<div className="wt-designimg">
																<label htmlFor="demoq">
																	<img src={logoPrvImg} alt="img logo" style={{borderRadius: 8, width: 100, height: 100}} />
																</label>
															</div>
														</div>
													</div>

													<div className="form-group form-group-label form-file-selecter">
														<label className="file-label">Veuillez importer la photo de couverture</label>
														<div className="wt-uploadingbox" style={{margin: 8}}>
															<div className="wt-designimg">
																<label htmlFor="demoq">
																	<img src={couverturePrvImg} alt="img couverture" style={{borderRadius: 8, width: 400, height: 200}} />
																</label>
															</div>
														</div>
													</div>

													<div className="form-group form-group-label form-file-selecter">
														<label className="file-label">{`Veuillez importer 3 photos pour vos produits phares`}</label>
													</div>

													<div className="form-group" style={{display: 'flex'}}>
														<div className="wt-uploadingbox" style={{margin: 8}}>
															<div className="wt-designimg">
																<label htmlFor="demoq">
																	<img src={prdPrvImg1} alt="img produit 1" style={{borderRadius: 8, width: 200, height: 200}} />
																</label>
															</div>
														</div>
														<div className="wt-uploadingbox" style={{margin: 8}}>
															<div className="wt-designimg">
																<label htmlFor="demoq">
																	<img src={prdPrvImg2} alt="img produit 2" style={{borderRadius: 8, width: 200, height: 200}}  />
																</label>
															</div>
														</div>
														<div className="wt-uploadingbox" style={{margin: 8}}>
															<div className="wt-designimg">
																<label htmlFor="demoq">
																	<img src={prdPrvImg3} alt="img produit 3" style={{borderRadius: 8, width: 200, height: 200}}  />
																</label>
															</div>
														</div>
													</div>
													
												</fieldset>
											</form>
										</div>
									</div>
									

									

								</div>
							</div>
							<div className="col-12">
								<div style={{padding: 10, borderTop: '1px solid #ddd', marginTop: 48}}>
									<div className="form-group">
										<label>Motif du rejet *</label>
										<textarea style={{height: 100}} className="form-control" placeholder="Motif du rejet" value={motif} onChange={(e)=>this.setState({motif: e.target.value})}></textarea>
									</div>
									<div className="marchand-admin-card-action-btn" style={{justifyContent: 'center'}}>
                                        <Link to="/admin/modifications" type="btn" className="wt-btn marchand-admin-card-btn marchand-admin-card-btn-secondary">Annuler</Link>
                                        <button type="btn" className="wt-btn marchand-admin-card-btn marchand-admin-card-btn-danger" disabled={status !== 'Default' ? false : true} onClick={()=>this.handleSubmit('Rejeter')}>Rejeter</button>
                                        <button type="btn" className="wt-btn marchand-admin-card-btn marchand-admin-card-btn-primary" disabled={status !== 'Default' ? false : true} onClick={()=>this.handleSubmit('Accepter')}>Accepter</button>
                                    </div>
								</div>
							</div>
						</div>}
					</div>
				</div>
        )
    }
}