import React from 'react';
import Header from '../../components/marchand/Header';
import Page from '../../components/Page'
import Home from '../../features/marchand/Home';
import { CertificationsSvc, ForfaitsSvc, MarchandUpdate, PromotionsSvc, NotificatonsSvc } from '../../services';

const HomePage = (props) => {
    const certificationsService = new CertificationsSvc(props.session)
    const forfaitsService = new ForfaitsSvc(props.session)
    const marchandUpdatesService = new MarchandUpdate(props.session)
    const promotionsService = new PromotionsSvc(props.session)
    const notificationsService = new NotificatonsSvc(props.session)

    const user = props.session.get('user')
    return (
        <Page mt={0} isAdmin={true}>
            <Header pathname="Accueil" username={user?.username} session={props.session} toast={props.toast} />
            <Home {...props} 
                certificationController={certificationsService}
                forfaitController={forfaitsService} 
                marchandUpdateController={marchandUpdatesService}
                promotionController={promotionsService}
                notificationController={notificationsService}
            />
        </Page>
    )
}

export default HomePage