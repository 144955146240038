/**
 * PAGE DE LA CONDITION D'UTILISATION POUR LA PARTIE DE L'APPLICATION MISE A LA DISPOSITION DE LA GRANDE PUBLiQUE
 */
import React from "react"
import './index.css'
// import imgVitepay from '../../../assets/images/vitepay.png'
// import imgCertifie from '../../../assets/images/certifie.png'


export default class Home extends React.Component {
    constructor(props){
        super(props)
        this.state = { 
            activeItemIndex: 0,
            categorieActive: '',
            vitepays: [],
            certifies: [],
            populaires: [],
            plus: false,
            certifieInfo: false,
            vitepayInfo: false
        }
    }

    render() {
        // const { categorieActive, certifies, vitepays, populaires, plus, certifieInfo, vitepayInfo } = this.state
        // const { isMobile } = this.props
        return (
            <div className="wt-haslayout">
                <div className="container">
                    <div className="row">
                        <div id="wt-twocolumns" className="wt-twocolumns wt-haslayout">
                            
                            <div className="wt-companysinfoholder" style={{marginTop: 24}}>
                                <div className="row" style={{margin: 0}}>
                                    <div className="col-12">
                                        <h3 className="ayn-cond-head">Politique d'utilisation</h3>
                                    </div>
                                    {/* <div className="col-12">
                                        <h5 className="ayn-cond-subhead"><img src={imgVitepay} style={{width: 16, marginRight: 8}} alt="img vitepay" />Marchand vitepay</h5>
                                    </div> */}

                                    <div className="col-12">
                                        <h5 className="ayn-cond-subhead">1. Introduction</h5>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <p>
                                            {/* <h6 className="ayn-cond-title">1.Xxxxxxxxxxxxxxxxx</h6> */}
                                            <span className="ayn-cond-content">1.1. LOGINEO (« DANEELA » ou « nous » ou « notre ») exploite une plate-forme de commerce électronique composée d'un site Web et d'une application mobile (ci-après désignée par le terme « Marketplace »).</span>
                                            <span className="ayn-cond-content">1.2. Ces Conditions Générales s'appliquent aux utilisateurs et aux marchands sur la Marketplace et régissent leur utilisation de la Marketplace et de tous les services associés.</span>
                                            <span className="ayn-cond-content">1.3. En utilisant notre Marketplace, vous acceptez ces conditions générales dans leur intégralité. Si vous n'êtes pas d'accord avec ces conditions générales ou une partie de ces conditions générales, dans ce cas, vous ne devez pas utiliser notre Marketplace.</span>
                                        </p>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <p>
                                            <span className="ayn-cond-content">1.4. Si vous utilisez notre Marketplace dans le cadre d'une entreprise ou d'un quelconque autre projet commercial, vous:</span>
                                            <span className="ayn-cond-content level-2">1.4.1. confirmez que vous avez obtenu l'autorisation nécessaire pour accepter les présentes conditions générales;</span>
                                            <span className="ayn-cond-content level-2">1.4.2. vous engagez, ainsi que la personne, la société ou toute autre entité juridique qui exploite cette entreprise ou ce projet commercial, à respecter les présentes conditions générales; et</span>
                                            <span className="ayn-cond-content level-2">1.4.3. acceptez que le terme «vous», utilisé dans les présentes conditions générales, fasse référence à la fois à l'utilisateur individuel et à la personne, à la société ou à l'entité juridique concernée, sauf indication contraire du contexte.</span>
                                        </p>
                                    </div>

                                    <div className="col-12">
                                        <h5 className="ayn-cond-subhead">2. Conditions Générales de Vente</h5>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <p>
                                            <span className="ayn-cond-content">2.1. Vous reconnaissez et acceptez que:</span>
                                            <span className="ayn-cond-content level-2">2.1.1. Notre Marketplace offre un lieu de rencontre en ligne et de facilitation des transactions pour les vendeurs afin de lister et de vendre leurs produits sur notre Marketplace, et pour les acheteurs afin d’acheter les produits proposés par les vendeurs;</span>
                                            <span className="ayn-cond-content level-2">2.1.2. Nous acceptons et faisons en sorte de faciliter les transactions et la finalisation des ventes sur notre Marketplace, pour le compte des vendeurs, toutefois, DANEELA n'est pas partie à la transaction entre le vendeur et l'acheteur. Par conséquence, un contrat de vente est formé uniquement entre l’acheteur et le vendeur et DANEELA n’assume aucune responsabilité découlant de ce contrat de vente; et</span>
                                            <span className="ayn-cond-content level-2">2.1.3. un contrat pour la vente et l'achat d'un ou de plusieurs produits entrera en vigueur entre l’acheteur et le vendeur, et en conséquence vous vous engagez à acheter ou vendre le ou les produits concernés, dès la confirmation d'achat par l'acheteur via notre Marketplace.</span>
                                        </p>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <p>
                                            <span className="ayn-cond-content">2.2. Sous réserve des présentes conditions générales, les conditions commerciales du vendeur régissent le contrat de vente et d'achat entre l'acheteur et le vendeur. Nonobstant ce qui précède, les dispositions suivantes seront incorporées dans le contrat de vente et d'achat entre l'acheteur et le vendeur:</span>
                                            <span className="ayn-cond-content level-2">2.2.1. le prix de tout produit sera celui indiqué dans la liste et le descriptif du produit concerné;</span>
                                            <span className="ayn-cond-content level-2">2.2.2. le prix de tout produit doit inclure toutes les taxes et être conforme aux lois applicables en vigueur dans le Territoire;</span>
                                            <span className="ayn-cond-content level-2">2.2.3. les frais de livraison, les frais d'emballage, les frais de manutention, les frais administratifs, les frais d'assurance, les autres frais et charges accessoires ne seront à la charge de l'acheteur que si cela est expressément et clairement indiqué dans la liste et le descriptif des produits concernés;</span>
                                            <span className="ayn-cond-content level-2">2.2.4. les produits concernés doivent être de bonne qualité, adaptés et sûrs pour tout usage spécifié dans le descriptif des produits et conformes à tous les aspects matériels, ainsi que toute autre description des produits fournie ou mis à disposition de l'acheteur par le vendeur; et</span>
                                            <span className="ayn-cond-content level-2">2.2.5. le vendeur garantit qu’il détient un titre de propriété et est le seul propriétaire légal et bénéficiaire des produits qu’il vend et ces produits ne sont soumis à aucun droit des tiers ou restriction ou prohibition, y compris en ce qui concerne les droits de propriété intellectuelle et / ou toute enquête ou procédure pénale, d'insolvabilité ou fiscale.</span>
                                        </p>
                                    </div>

                                    <div className="col-12">
                                        <h5 className="ayn-cond-subhead">3. Paiements</h5>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <p>
                                            <span className="ayn-cond-content">3.1. Vous devez effectuer les paiements dus en vertu des présentes conditions générales conformément aux Informations et Directives sur les Paiements disponibles sur notre Marketplace.</span>
                                        </p>
                                    </div>

                                    <div className="col-12">
                                        <h5 className="ayn-cond-subhead">4. Règles concernant votre contenu</h5>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <p>
                                            <span className="ayn-cond-content">4.1. Dans les présentes conditions générales, "votre contenu" signifie:</span>
                                            <span className="ayn-cond-content level-2">4.1.1. tous les éléments, travaux et matériaux (y compris, sans s'y limiter, les textes, les graphiques, les images, le matériel audio, le matériel vidéo, le matériel audiovisuel, les scripts, les logiciels et les fichiers) que vous nous soumettez à nous ou à notre Marketplace pour stockage ou publication, traitement ou transmission ultérieure ; et</span>
                                            <span className="ayn-cond-content level-2">4.1.2. toutes les communications sur notre Marketplace, y compris les avis sur les produits, les remarques et les commentaires.</span>
                                            
                                            <span className="ayn-cond-content">4.2. Votre contenu et l'utilisation de votre contenu par nous, conformément aux présentes conditions générales, doit être exact, complet et authentique.</span>
                                            <span className="ayn-cond-content">4.3. Votre contenu doit être approprié, civil et de bon goût, et être conforme aux normes d'éthique et de comportement généralement acceptés sur Internet, et ne doit pas:</span>
                                            <span className="ayn-cond-content level-2">4.3.1. être offensant, obscène, indécent, pornographique, suggestif ou sexuellement explicite;</span>
                                            <span className="ayn-cond-content level-2">4.3.2. dépeindre la violence de manière explicite, graphique ou gratuite; ou</span>
                                            <span className="ayn-cond-content level-2">4.3.3. être blasphématoire, en violation de la législation sur la haine raciale ou religieuse ou la discrimination;</span>
                                            <span className="ayn-cond-content level-2">4.3.4. être trompeur, frauduleux, menaçant, abusif, harcelant, antisocial, haineux, discriminatoire ou incendiaire;</span>
                                            <span className="ayn-cond-content level-2">4.3.5. causer de la gêne, des désagréments ou une anxiété inutile à quiconque; ou</span>
                                            <span className="ayn-cond-content level-2">4.3.6. constitue un spam.</span>
                                            
                                            <span className="ayn-cond-content">4.4. Votre contenu ne doit pas être illégal ou illicite, porter atteinte aux droits légaux de toute personne ou être susceptible de donner lieu à des poursuites judiciaires contre toute personne</span>
                                            <span className="ayn-cond-content level-2">4.4.1. tout droit d'auteur, droit moral, droit de base de données, droit de marque, droit de conception de dessins et modèles, droit de substitution ou tout autre droit de propriété intellectuelle;</span>
                                            <span className="ayn-cond-content level-2">4.4.2. tout droit de confidentialité, droit à la vie privée ou droit en vertu de la législation sur la protection des données;</span>
                                            <span className="ayn-cond-content level-2">4.4.3. toute obligation contractuelle due à toute personne; ou</span>
                                            <span className="ayn-cond-content level-2">4.4.4. toute ordonnance du tribunal et décision judiciaire.</span>
                                        </p>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <p>
                                            <span className="ayn-cond-content">4.5. Vous ne devez pas utiliser notre Marketplace pour créer un lien vers un site Web ou une page Web consistant en ou contenant un élément ou contenu qui, s'il était affiché sur notre Marketplace, violerait les dispositions des présentes conditions générales</span>
                                            <span className="ayn-cond-content">4.6. Vous ne devez pas soumettre à notre Marketplace tout matériel ou contenu qui fait ou a déjà fait l'objet de menaces ou de réelles poursuites judiciaires ou de toute autre plainte similaire.</span>
                                            <span className="ayn-cond-content">4.7. La fonction qui permet aux utilisateurs sur notre Marketplace de donner leur avis et commentaires peut être utilisée pour faciliter les évaluations des acheteurs sur les produits. Vous ne devez pas utiliser ladite fonction ou toute autre forme de communication pour fournir des critiques et/ou des avis inexacts, inauthentiques ou faux.</span>
                                            <span className="ayn-cond-content">4.8. Vous ne devez pas interférer avec une transaction en: (i) communiquer avec un utilisateur impliqué dans une transaction active ou terminée pour l'avertir d’éviter un acheteur, un vendeur ou un article particulier; ou (ii) contacter un autre utilisateur avec l'intention de collecter des paiements.</span>
                                            <span className="ayn-cond-content">4.9. Vous reconnaissez que tous les utilisateurs de notre Marketplace sont seuls responsables des interactions avec les autres utilisateurs et vous devez faire preuve de prudence et de bon jugement dans votre communication avec les utilisateurs. Vous ne devez pas leur envoyer des informations personnelles, y compris les détails de votre carte de crédit et tout moyen de paiement.</span>
                                            <span className="ayn-cond-content">4.10. Nous pouvons examiner périodiquement votre contenu et nous nous réservons le droit de supprimer tout contenu à notre discrétion pour quelque raison que ce soit.</span>
                                            <span className="ayn-cond-content">4.11. Si vous avez connaissance de tout élément, contenu ou activité illégale et illicite sur notre Marketplace, ou de tout élément, contenu ou activité qui enfreint les présentes conditions générales, vous pouvez nous en informer en nous contactant </span>
                                        </p>
                                    </div>

                                    <div className="col-12">
                                        <h5 className="ayn-cond-subhead">5. Utilisation du site Web et des applications mobiles</h5>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <p>
                                            <span className="ayn-cond-content">5.1. Dans cette section 5, les mots «Marketplace» et «site Web» doivent être utilisés de manière interchangeable pour désigner les sites Web et les applications mobiles de Daneela.</span>
                                            <span className="ayn-cond-content">5.2. Vous pouvez:</span>
                                            <span className="ayn-cond-content">5.2.1. afficher les pages de notre site Web dans un navigateur Web;</span>
                                            <span className="ayn-cond-content level-2">5.2.2. télécharger des pages de notre site Web pour la mise en cache dans un navigateur Web;</span>
                                            <span className="ayn-cond-content level-2">5.2.3. imprimer des pages de notre site Web pour votre usage personnel et non commercial, à condition que cette impression ne soit pas systématique ou excessive;</span>
                                            <span className="ayn-cond-content level-2">5.2.4. diffuser des fichiers audio et vidéo à partir de notre site Web à l'aide du lecteur multimédia de notre site Web; et</span>
                                            <span className="ayn-cond-content level-2">5.2.5. utiliser nos services de Marketplace au moyen d’un navigateur Web, sous réserve du respect des autres dispositions des présentes conditions générales.</span>
                                            
                                            <span className="ayn-cond-content">5.3. Sauf dans les cas expressément autorisés par la section 8.2 ou les autres dispositions des présentes conditions générales, vous ne devez télécharger aucun élément de notre site Web ni enregistrer un tel élément sur votre ordinateur</span>
                                            <span className="ayn-cond-content">5.4. Vous ne pouvez utiliser notre site Web qu'à des fins personnelles et professionnelles pour vendre ou acheter des produits sur notre Marketplace</span>
                                            <span className="ayn-cond-content">5.5. Sauf dans les cas expressément autorisés par les présentes conditions générales, vous ne devez pas éditer ou modifier, de quelque manière que ce soit, tout élément ou contenu sur notre site Web.</span>
                                            <span className="ayn-cond-content">5.6. À moins que vous ne possédiez ou ne contrôliez des droits réels et pertinents sur le matériel ou le contenu en question, vous ne devez pas:</span>
                                            <span className="ayn-cond-content level-2">5.6.1. republier le contenu ou le matériel de notre site Web (y compris la republication sur un autre site Web);</span>
                                            <span className="ayn-cond-content level-2">5.6.2. vendre, louer ou concéder en sous-licence du contenu ou du matériel de notre site Web;</span>
                                            <span className="ayn-cond-content level-2">5.6.3. montrer tout matériel ou contenu de notre site Web en public;</span>
                                            <span className="ayn-cond-content level-2">5.6.4. exploiter le matériel ou contenu de notre site Web à des fins commerciales; ou</span>
                                            <span className="ayn-cond-content level-2">5.6.5. redistribuer le matériel ou le contenu de notre site Web.</span>
                                            
                                            <span className="ayn-cond-content">5.7. Nonobstant la section 5.6, vous pouvez transmettre des liens vers des produits sur notre site Web et redistribuer notre bulletin d'information (newsletter) et notre matériel promotionnel sous forme imprimée et électronique à toute personne.</span>
                                            <span className="ayn-cond-content">5.8. Nous nous réservons le droit de suspendre ou de restreindre l'accès à notre site Web, à certaines parties de notre site Web et / ou aux fonctionnalités de notre site Web. Nous pouvons, par exemple, suspendre l'accès au site Web pendant la maintenance du serveur ou lorsque nous mettons à jour le site Web. Vous ne devez pas contourner, ou tenter de contourner, toute mesure de restriction d'accès sur le site Web.</span>
                                        </p>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <p>
                                            <span className="ayn-cond-content">5.9. Vous ne devez pas:</span>
                                            <span className="ayn-cond-content level-2">5.9.1. utiliser notre site Web de quelque manière que ce soit ou prendre des mesures qui causent ou peuvent causer des dommages au site Web ou une altération des performances, de la disponibilité, de l'accessibilité, de l'intégrité ou de la sécurité du site Web;</span>
                                            <span className="ayn-cond-content level-2">5.9.2. utiliser notre site Web d'une manière contraire à l'éthique, illégale, illicite, frauduleuse ou nuisible, ou en relation avec un but ou une activité illégale, illicite, frauduleuse ou nuisible;</span>
                                            <span className="ayn-cond-content level-2">5.9.3. pirater ou altérer par tout autre moyen notre site Web;</span>
                                            <span className="ayn-cond-content level-2">5.9.4. sonder, scanner ou tester la vulnérabilité de notre site Web sans notre permission;</span>
                                            <span className="ayn-cond-content level-2">5.9.5. contourner tout système ou processus d'authentification ou de sécurité sur notre site Web ou en relation avec ce dernier;</span>
                                            <span className="ayn-cond-content level-2">5.9.6. utiliser notre site Web pour copier, stocker, héberger, transmettre, envoyer, utiliser, publier ou distribuer tout matériel ou contenu constitué de (ou lié à) tout logiciel espion, virus informatique, cheval de Troie, ver, enregistreur de frappe, rootkit ou tout autre logiciel informatique malveillant ;</span>
                                            <span className="ayn-cond-content level-2">5.9.7. imposer une charge déraisonnablement élevée sur les ressources de notre site Web (y compris la bande passante, la capacité de stockage et la capacité de traitement);</span>
                                            <span className="ayn-cond-content level-2">5.9.8. déchiffrer ou décrypter toute communication envoyée par ou vers notre site Web sans notre permission;</span>
                                            <span className="ayn-cond-content level-2">5.9.9. mener des activités de collecte de données systématiques ou automatisées (y compris, sans limitation, le grattage, l'exploration de données, l'extraction de données et la collecte de données) sur ou en relation avec notre site Web sans notre consentement écrit exprès;</span>
                                            <span className="ayn-cond-content level-2">5.9.10. accéder à notre site Web ou interagir autrement avec lui à l'aide de tout robot, araignée ou tout autre moyen automatisé, sauf à des fins d'indexation des moteurs de recherche;</span>
                                            <span className="ayn-cond-content level-2">5.9.11. utiliser notre site Web sauf au moyen de nos interfaces publiques;</span>
                                            <span className="ayn-cond-content level-2">5.9.12. enfreindre les directives énoncées dans le fichier robots.txt de notre site Web;</span>
                                            <span className="ayn-cond-content level-2">5.9.13. utiliser les données collectées sur notre site Web pour toute activité de marketing direct (y compris, sans limitation, le marketing par courrier électronique, le marketing par SMS, le télémarketing et le publipostage); ou</span>
                                            <span className="ayn-cond-content level-2">5.9.14. faire tout ce qui interfère avec l'utilisation normale de notre site Web.</span>

                                        </p>
                                    </div>

                                    <div className="col-12">
                                        <h5 className="ayn-cond-subhead">6. Propriété intellectuelle (Copyright) et marques déposées</h5>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <p>
                                            <span className="ayn-cond-content">6.1. Sous réserve des dispositions expresses des présentes conditions générales:</span>
                                            <span className="ayn-cond-content level-2">6.1.1. nous, avec nos concédants de licence, détenons et contrôlons tous les droits d'auteur et autres droits de propriété intellectuelle sur notre site Web ainsi que sur le matériel et tout contenu sur notre site Web; et</span>
                                            <span className="ayn-cond-content level-2">6.1.2. tous les droits d'auteur et autres droits de propriété intellectuelle sur notre site Web ainsi que le matériel et contenu sur notre site Web sont réservés.</span>
                                            
                                        </p>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <p>
                                            <span className="ayn-cond-content">6.2. Les logos de Daneela et nos autres marques déposées et non déposées sont des marques qui nous appartiennent; nous n'accordons aucune autorisation pour l'utilisation de ces marques, et une telle utilisation peut constituer une violation de nos droits.</span>
                                            <span className="ayn-cond-content">6.3. Les marques commerciales ou marques de service enregistrées et non enregistrées par des tiers sur notre site Web appartiennent à leurs propriétaires respectifs et nous n'endossons pas et nous ne sommes affiliés à aucun des titulaires de ces droits et, en tant que tels, nous ne pouvons accorder aucune licence pour exercer de tels droits.</span>
                                        </p>
                                    </div>

                                    <div className="col-12">
                                        <h5 className="ayn-cond-subhead">7. Protection des données</h5>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <p>
                                            <span className="ayn-cond-content">7.1. Les acheteurs acceptent le traitement de leurs données personnelles conformément aux conditions de la politique de confidentialité et de protection des données, et de la notification relative aux cookies de Daneela, accessibles sur notre Marketplace.</span>
                                        </p>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <p>
                                            <span className="ayn-cond-content">7.2. Daneela traitera toutes les données personnelles obtenues via la Marketplace et les services associés conformément aux termes de notre Politique de confidentialité et de protection des données et de la notification relative aux Cookies.</span>
                                        </p>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <p>
                                            <span className="ayn-cond-content">7.3. Les vendeurs sont directement responsables envers les acheteurs de toute utilisation abusive de leurs données personnelles et Daneela n'assume aucune responsabilité envers les acheteurs en ce qui concerne toute utilisation abusive de leurs données personnelles par les vendeurs.</span>
                                        </p>
                                    </div>

                                    <div className="col-12">
                                        <h5 className="ayn-cond-subhead">8. Le rôle de Daneela en tant que Marketplace</h5>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <p>
                                            <span className="ayn-cond-content">8.1. Vous reconnaissez que:</span>
                                            <span className="ayn-cond-content level-2">8.1.1. nous ne confirmons pas l'identité de tous les utilisateurs de notre Marketplace, nous ne vérifions pas leur solvabilité ou leur bonne foi, et dans tous les cas, nous ne les contrôlons pas;</span>
                                            <span className="ayn-cond-content level-2">8.1.2. nous ne vérifions pas, nous n’auditons pas et nous ne contrôlons pas toutes les informations contenues dans les listes et les descriptifs des produits vendus sur notre Marketplace ;</span>
                                            <span className="ayn-cond-content level-2">8.1.3. Nous ne sommes parties à aucun contrat de vente ou d'achat de produits listés sur la Marketplace, dans la mesure où notre rôle est de faciliter les transactions réalisées entre les acheteurs et les vendeurs. Par conséquent, nous n’assumons aucune responsabilité ayant pour origine un tel contrat de vente et nous ne sommes ni l’agent ni le mandataire des vendeurs et ces derniers sont responsables des ventes des produits et des réclamations ou tout autre problème survenant ou lié au contrat de vente entre eux et les acheteurs;</span>
                                            <span className="ayn-cond-content level-2">8.1.4. nous ne sommes impliqués dans aucune transaction entre un acheteur et un vendeur de quelque manière que ce soit, sauf que nous facilitons les transactions sur la Marketplace pour les acheteurs et les vendeurs et traitons les paiements et le cas échéant la livraison au nom des vendeurs;</span>
                                            <span className="ayn-cond-content level-2">8.1.5. nous ne sommes pas les agents ou mandataires des acheteurs ou des vendeurs, et en conséquence, nous ne serons pas responsables envers quiconque en ce qui concerne l'offre de vente, la vente ou l'achat de tout produit annoncé et listé sur notre Marketplace; en outre, nous ne sommes pas responsables de l'exécution des obligations contractuelles découlant d'un contrat de vente ou d'achat de tout produit et nous n'aurons aucune obligation de médiation entre les parties à un tel contrat.</span>

                                        </p>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <p>
                                            <span className="ayn-cond-content">8.2. Nous ne garantissons ni ne représentons:</span>
                                            <span className="ayn-cond-content level-2">8.2.1. l'exhaustivité ou l'exactitude des informations publiées sur notre Marketplace;</span>
                                            <span className="ayn-cond-content level-2">8.2.2. que toute information, élément ou contenu publié sur notre Marketplace est à jour;</span>
                                            <span className="ayn-cond-content level-2">8.2.3. que notre Marketplace fonctionnera sans faute ou erreur en tout temps; ou</span>
                                            <span className="ayn-cond-content level-2">8.2.4. que notre Marketplace ou tout service sur notre Marketplace restera disponible en tout temps.</span>

                                            <span className="ayn-cond-content">8.3. Nous nous réservons le droit d'interrompre, de discontinuer ou de modifier tout ou partie de nos services disponibles sur notre Marketplace, et de cesser de publier sur notre Marketplace, à tout moment à notre seule discrétion, sans préavis ni explication; et vous n'aurez droit à aucune compensation ou autre paiement en cas d'interruption, de discontinuité, de modification ou d’altération des services sur la Marketplace, ou si nous cessons de publier la Marketplace.</span>
                                            <span className="ayn-cond-content">8.4. Nous ne garantissons aucun résultat commercial concernant l'utilisation de notre Marketplace.</span>
                                            <span className="ayn-cond-content">8.5. Dans la mesure maximale autorisée par la loi applicable et sous réserve de la section 9.1 ci-dessous, nous excluons toutes les déclarations et garanties relatives à l'objet des présentes conditions générales, de notre Marketplace et de l'utilisation de notre Marketplace.</span>
                                        </p>
                                    </div>

                                    <div className="col-12">
                                        <h5 className="ayn-cond-subhead">9. Limitations et exclusions de responsabilité</h5>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <p>
                                            <span className="ayn-cond-content">9.1. Rien dans les présentes conditions générales ne viendra:</span>
                                            <span className="ayn-cond-content level-2">9.1.1. limiter toute responsabilité d'une manière non autorisée par la loi applicable; ou</span>
                                            <span className="ayn-cond-content level-2">9.1.2. exclure toute responsabilité ou tout droit légal qui ne peut être exclu en vertu de la loi applicable.</span>
                                            
                                            <span className="ayn-cond-content">9.2. Les limitations et exclusions de responsabilité énoncées dans la présente section 9 et ailleurs dans les présentes conditions générales:</span>
                                            <span className="ayn-cond-content level-2">9.2.1. sont soumises aux dispositions de la section 9.1; et</span>
                                            <span className="ayn-cond-content level-2">9.2.2. régissent toutes les responsabilités découlant des présentes conditions générales ou liées à l'objet des présentes conditions générales, y compris les responsabilités contractuelles, responsabilité délictuelle (y compris la négligence) et pour manquement à une obligation légale, sauf dans la mesure où les présentes conditions générales en disposent expressément autrement.</span>
                                            
                                            <span className="ayn-cond-content">9.3. En ce qui concerne les services qui vous sont offerts gratuitement, nous ne serons pas responsables envers vous de toute perte ou dommage de quelque nature que ce soit.</span>

                                            <span className="ayn-cond-content">9.4. Notre responsabilité globale envers vous en ce qui concerne tout contrat visant à vous fournir des services en vertu des présentes conditions générales, ne doit pas dépasser le montant total payé et payable à nous en vertu dudit contrat. Chaque transaction distincte sur notre Marketplace constitue un contrat distinct aux fins de la présente section 9.4.</span>
                                        </p>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <p>
                                            <span className="ayn-cond-content">9.5. Nonobstant la section 9.4 ci-dessus, nous ne serons pas responsables envers vous de toute perte ou dommage de quelque nature que ce soit, y compris en ce qui concerne:</span>
                                            <span className="ayn-cond-content level-2">9.5.1. toute perte occasionnée par une interruption ou un dysfonctionnement du site Web;</span>
                                            <span className="ayn-cond-content level-2">9.5.2. toute perte découlant d'un événement ou de plusieurs événements hors de notre contrôle raisonnable;</span>
                                            <span className="ayn-cond-content level-2">9.5.3. toute perte commerciale, y compris (sans limitation) la perte des bénéfices ou dommages sur les profits, des revenus, des recettes de l'utilisation, de la production, des économies anticipées, des affaires, des contrats, des opportunités commerciales ou des fonds de commerce;</span>
                                            <span className="ayn-cond-content level-2">9.5.4. toute perte ou corruption de toute donnée, base de données ou logiciel; ou</span>
                                            <span className="ayn-cond-content level-2">9.5.5. toute perte ou dommage spécial, indirect ou consécutif.</span>

                                            <span className="ayn-cond-content">9.6. Nous acceptons que nous avons un intérêt à limiter la responsabilité personnelle de nos dirigeants et employés et, eu égard à cet intérêt, vous reconnaissez que nous sommes une entité à responsabilité limitée; vous acceptez de ne pas porter plainte personnellement contre nos dirigeants ou employés pour toute perte que vous subissez en rapport avec l’utilisation de notre Marketplace ou les présentes conditions générales (cela ne limitera ni n'exclura la responsabilité de l'entité à responsabilité limitée elle-même pour le actes et omissions de nos dirigeants et employés).</span>

                                            <span className="ayn-cond-content">9.7. Notre Marketplace comprend des liens hypertexte vers d'autres sites Web détenus et exploités par des tiers; ces liens hypertexte ne sont pas des recommandations. Nous n'avons aucun contrôle sur les sites Web tiers et leur contenu, et nous déclinons toute responsabilité à leur égard ou pour toute perte ou dommage pouvant résulter de leur utilisation</span>
                                        </p>
                                    </div>

                                    <div className="col-12">
                                        <h5 className="ayn-cond-subhead">10. Indemnisation</h5>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <p>
                                            <span className="ayn-cond-content">10.1. Par la présente, vous nous indemnisez et vous vous engagez à nous tenir indemnisés contre:</span>
                                            <span className="ayn-cond-content level-2">10.1.1. toutes les pertes, dommages, coûts, responsabilités et dépenses (y compris, sans limitation, les frais de justice et tous les montants payés par nous à un tiers en règlement d'une réclamation ou d'un litige) encourus ou subis par nous et résultant directement ou indirectement de votre utilisation de notre Marketplace ou de toute violation par vous de toute disposition des présentes conditions générales ou des codes, politiques ou directives de Daneela; et</span>
                                            <span className="ayn-cond-content level-2">10.1.2. toute responsabilité en matière de TVA ou autre responsabilité en matière fiscale que nous pourrions encourir en relation avec toute vente, fourniture ou achat effectué via notre Marketplace, lorsque cette responsabilité découle de votre défaut de paiement, de retenue, de déclaration ou d'enregistrement pour le paiement de toute TVA ou autre taxe dûment due dans une quelconque juridiction.</span>
                                        </p>
                                    </div>

                                    <div className="col-12">
                                        <h5 className="ayn-cond-subhead">11. Violation des présentes conditions générales</h5>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <p>
                                            <span className="ayn-cond-content">11.1. Si nous autorisons la création et l'enregistrement d'un compte sur notre Marketplace, celui-ci restera ouvert indéfiniment, sous réserve des présentes conditions générales.</span>
                                            <span className="ayn-cond-content">11.2. Si vous enfreignez les présentes conditions générales ou si nous soupçonnons raisonnablement que vous avez enfreint les présentes conditions générales ou tout code, politique ou directive de Daneela de quelque manière que ce soit, nous nous réservons le droit de:</span>
                                            <span className="ayn-cond-content level-2">11.2.1. suspendre temporairement votre accès à notre Marketplace;</span>
                                            <span className="ayn-cond-content level-2">11.2.2. vous interdire définitivement l'accès à notre Marketplace;</span>
                                            <span className="ayn-cond-content level-2">11.2.3. empêcher les ordinateurs utilisant votre adresse IP d'accéder à notre Marketplace;</span>
                                            <span className="ayn-cond-content level-2">11.2.4. contacter l’un ou l’ensemble de vos fournisseurs de services Internet et leur demander de bloquer votre accès à notre Marketplace;</span>
                                            <span className="ayn-cond-content level-2">11.2.5. suspendre ou supprimer votre compte sur notre Marketplace; et / ou</span>
                                            <span className="ayn-cond-content level-2">11.2.6. intenter une action en justice contre vous, que ce soit pour rupture de contrat ou autre.</span>
                                        </p>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <p>
                                            <span className="ayn-cond-content">11.3. Lorsque nous suspendons, interdisons ou bloquons votre accès à notre Marketplace ou à une partie de notre Marketplace:</span>
                                            <span className="ayn-cond-content">11.3.1. vous ne devez prendre aucune mesure pour contourner une telle suspension, interdiction ou blocage (y compris, sans limitation, la création et / ou l'utilisation d'un compte différent).</span>
                                        </p>
                                    </div>

                                    <div className="col-12">
                                        <h5 className="ayn-cond-subhead">12. Intégralité de l’accord</h5>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <p>
                                            <span className="ayn-cond-content">12.1. Les présentes conditions générales, et les codes, politiques et directives de Daneela accessibles sur notre Marketplace constituent l'intégralité de l'accord entre vous et nous en ce qui concerne votre utilisation de notre Marketplace et remplacent tous les accords précédents entre vous et nous en ce qui concerne votre utilisation de notre Marketplace.</span>
                                        </p>
                                    </div>

                                    <div className="col-12">
                                        <h5 className="ayn-cond-subhead">13. Hiérarchie</h5>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <p>
                                            <span className="ayn-cond-content">13.1. En cas de conflit entre les présentes conditions générales, les conditions générales du vendeur et les codes, politiques et directives de Daneela, les présentes conditions générales, les conditions générales du vendeur et les codes, politiques et directives de Daneela prévaudront dans l'ordre indiqué ici.</span>
                                        </p>
                                    </div>

                                    <div className="col-12">
                                        <h5 className="ayn-cond-subhead">14. Modification</h5>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <p>
                                            <span className="ayn-cond-content">14.1. Nous pouvons réviser les présentes conditions générales, les conditions générales du vendeur et les codes, politiques et directives de Daneela de temps à autre.</span>
                                        </p>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <p>
                                            <span className="ayn-cond-content">14.2. Les conditions générales révisées s'appliquent à compter de la date de leur publication sur notre Marketplace.</span>
                                        </p>
                                    </div>

                                    <div className="col-12">
                                        <h5 className="ayn-cond-subhead">15. Non renonciation</h5>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <p>
                                            <span className="ayn-cond-content">15.1. Aucune renonciation à une violation d'une quelconque disposition des présentes conditions générales ne doit être interprétée comme une renonciation supplémentaire ou continue à toute autre violation de cette disposition ou à toute violation de toute autre disposition des présentes conditions générales.</span>
                                        </p>
                                    </div>

                                    <div className="col-12">
                                        <h5 className="ayn-cond-subhead">16. Divisibilité</h5>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <p>
                                            <span className="ayn-cond-content">16.1. Si une disposition des présentes conditions générales est jugée par un tribunal ou une autre autorité compétente comme étant illégale et/ou inapplicable, les autres dispositions resteront en vigueur.</span>
                                        </p>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <p>
                                            <span className="ayn-cond-content">16.2. Si une disposition illégale et/ou inapplicable des présentes conditions générales deviendrait légale ou applicable si une partie de celle-ci était supprimée, cette partie sera considérée comme supprimée, et le reste de la disposition continuera à produire ses effets.</span>
                                        </p>
                                    </div>

                                    <div className="col-12">
                                        <h5 className="ayn-cond-subhead">17. Cession</h5>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <p>
                                            <span className="ayn-cond-content">17.1. Vous acceptez par les présentes que nous puissions céder, transférer, sous- traiter ou traiter de toute autre manière nos droits et/ou obligations en vertu des présentes conditions générales.</span>
                                        </p>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <p>
                                            <span className="ayn-cond-content">17.2. Vous ne pouvez pas, sans notre consentement écrit préalable, céder, transférer, sous-traiter ou traiter de toute autre manière vos droits et/ou obligations en vertu des présentes conditions générales.</span>
                                        </p>
                                    </div>

                                    <div className="col-12">
                                        <h5 className="ayn-cond-subhead">18. Droits des tiers</h5>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <p>
                                            <span className="ayn-cond-content">18.1. Un contrat conclu dans le cadre des présentes conditions générales est à notre avantage et à votre avantage, et n'est pas destiné à bénéficier à un tiers ou à être exécutoire par un tiers.</span>
                                        </p>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <p>
                                            <span className="ayn-cond-content">18.2. L'exercice des droits des parties dans le cadre d'un contrat conclu en vertu des présentes conditions générales n'est soumis au consentement d'aucun tiers.</span>
                                        </p>
                                    </div>

                                    <div className="col-12">
                                        <h5 className="ayn-cond-subhead">19. Loi applicable et juridiction</h5>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <p>
                                            <span className="ayn-cond-content">19.1. Les présentes conditions générales sont régies et interprétées conformément au droit du Territoire.</span>
                                        </p>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <p>
                                            <span className="ayn-cond-content">19.23.2. Tout litige relatif aux présentes conditions générales est soumis à la compétence exclusive des tribunaux compétents du Territoire.</span>
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}