import { useState } from "react"

const Tab = (props) => {
    const [active, setActive] = useState(props.tabs[0].slug)
    const selectTab = (slug)=>{
        setActive(slug)
        props.onSelectTab(slug)
    }
    return (
        <div className="ayn-tab">
            <div className="ayn-tab-header">
                {props.tabs.map((t,i)=><button key={i} onClick={()=>selectTab(t.slug)} className={`ayn-tab-item ${active === t.slug ? 'active' : ''}`}>{t.name}</button>)}
            </div>
        </div>
    )
}
export default Tab