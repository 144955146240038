import { http } from './config';
import Messages from '../lib/Messages';

const messages = new Messages({
  INVALID_CREDENTIALS: 'Informations d\'authentification incorrectes.',
});

const detail = ({
  boutiqueInfo: (id, token) => {
    return http.get(`/api/1/marchands/user/${id}`, { bearer: token })
    .then((detail) => {
        return detail
    }).catch(messages.catch);
  },
});

export default detail