import Rating from "react-rating"
import vitepay from '../assets/images/vitepay.png'
import certifie from '../assets/images/certifie.png'
import { moyenNote } from '../lib/functions'

const DetailCard = (props) => {
    return (
        <div className="ayn-card ayn-detail-card">
            <figure className="ayn-detail-cover">
                <img src={props.cover} alt="img description" />
            </figure>
            {!props.isMobile &&
            <div className="ayn-mobile-card ayn-mobile-detail-card wt-companysinfo" style={{display: 'flex', padding: '16px 0', flexDirection: 'column' }}>
                <div style={{marginTop: -94}}>
                    <div className="ayn-vpay-img-container">
                        {props.vitepay && <img src={vitepay} alt="img vitepay" className="ayn-vpay-img" />}
                    </div>
                    <figure><img src={props.logo} alt="logo du marchant" /></figure>
                </div>
                <div className="ayn-mobile-card-content">
                    {props.isVerified && <span style={{color: '#999', fontSize: 12, lineHeight: '18px', marginBottom: 6}}><img src={certifie} alt="img certifie" className="ayn-certifie-img" /> Marchand certifié</span>}
                    <h2 style={{marginBottom: 4, textTransform: 'capitalize'}}>{props.name ? props.name.toLowerCase() : ''}</h2>
                    <span onClick={()=>props.onOpenUrl(props.url)} className="ayn-li ayn-store-card-li span-a">
                        <span>Voir le site <i className="fa fa-external-link" aria-hidden="true"></i></span>
                    </span>
                    <span className="ayn-li ayn-store-card-li">{props.address}</span>
                    <span className="ayn-mobile-card-stars ayn-li ayn-store-card-li">
                        <Rating
                            initialRating={moyenNote(props.rating)}
                            emptySymbol={<i className="fa fa-star-o fa-2x"></i>}
                            fullSymbol={<i className="fa fa-star fa-2x" style={{color: 'orange'}}></i>}
                            readonly
                        />
                    </span>
                </div>
            </div>}
            {props.isMobile &&
            <div className="ayn-mobile-card ayn-mobile-detail-card" style={{display: 'flex', padding: '16px 0'}}>
                <figure style={{margin: 0, padding: 8}}>
                {/* <div className="ayn-vpay-img-container">
                    {props.vitepay && <img src={vitepay} alt="img vitepay" className="ayn-vpay-img" />}
                </div> */}
                    <img src={props.logo} style={{ margin: 8 }} alt="logo du marchant" />
                </figure>
                <div className="ayn-mobile-card-content">
                    <div className="ayn-mobile-card-title">
                        <h4 style={{marginBottom: 0, whiteSpace: 'break-spaces'}} className="ayn-header">{props.name ? props.name.toLowerCase() : '' }</h4>
                    </div>
                    {/* {props.isVerified && <span><i className="fa fa-check-circle"></i></span>} */}
                    {props.isVerified && <span style={{color: '#999', fontSize: 12, lineHeight: '18px', marginBottom: 6}}><img src={certifie} alt="img certifie" className="ayn-certifie-img" /> Marchand certifié</span>}
                    <span onClick={()=>props.onOpenUrl(props.url)} className="ayn-li ayn-store-card-li  span-a">
                        <span>Voir le site <i className="fa fa-external-link" aria-hidden="true"></i></span>
                    </span>
                    <span className="ayn-li ayn-store-card-li" style={{color: '#999', whiteSpace: 'break-spaces'}}>{props.address}</span>
                    <span className="ayn-mobile-card-stars ayn-li ayn-store-card-li">
                        <Rating
                            initialRating={moyenNote(props.rating)}
                            emptySymbol={<i className="fa fa-star-o"></i>}
                            fullSymbol={<i className="fa fa-star" style={{color: 'orange'}}></i>}
                            readonly
                        />
                    </span>
                </div>
            </div>}
            {props.children}
        </div>
    )
}
export default DetailCard