import React from 'react';
import Header from '../../components/admin/Header';
import Page from '../../components/Page'
import Demande from '../../features/admin/Demande'

const DemandePage = (props) => {
    return (
        <Page mt={0} isAdmin={true}>
            <Header pathname="Dashbord" username="Admin" toast={props.toast} />
            <Demande {...props} />
        </Page>
    )
}
export default DemandePage