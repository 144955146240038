/*eslint array-callback-return: "off"*/
import React from 'react'
import Loader from '../../../components/Loader'
import Statcard from '../../../components/Statcard'
import messages from '../../../lib/messages.json'

/*eslint consistent-return: ["error", { "treatUndefinedAsUnspecified": false }]*/

const fields = { title: '', body: ''}
const fieldsError = { title: {}, body: {}}
const validations = { 
	title: {type: 'checkRequired', required: true, min: 8, number: false},
	body: {type: 'checkRequired', required: true, min: 8, number: false}
}
const formOneItems = ['title', 'body']

export default class Notification extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            errors: { ...fieldsError },
            ...fields,
            user: {},
            btnLoad: false

        }
        this.handleSendNotification = this.handleSendNotification.bind(this)
    }

    handleTextChange(e, name){ this.setState({ [name]: e.target.value }) }
    checkConfirmation(name, val, val2, min){
		const errors = this.state.errors
		errors[name]['required'] = !val ? `*Ce champ est obligatoire` : null
		errors[name]['min'] = val.length < min ? `*Ce champ doit avoir au moins ${min} caracteres` : null
		errors[name]['different'] = val !== val2 ? `*Mot de passe et confirmation ne correspondent pas` : null
		this.setState({errors})
	}
	checkRequired(name, val, min, number = false){
		const errors = this.state.errors
		errors[name]['required'] = !val ? `*Ce champ est obligatoire` : null
		errors[name]['min'] = val.length < min ? `*Ce champ doit avoir au moins ${min} caracteres` : null
		if(number){
			errors[name]['number'] = isNaN(val) ? `*Ce champ doit être numérique` : null
		}
		this.setState({errors})
	}
    checkValidations(items){
		for(let i=0; i<items.length; i++){
			let item = validations[items[i]]
			if(typeof(item) !== 'undefined'){
				if(item['type'] === 'checkRequired'){
					this.checkRequired(items[i], this.state[items[i]], item['min'], item['number'] )
				}else{
					if(item['type'] === 'checkConfirmation'){
						this.checkConfirmation(items[i], this.state[items[i]], this.state[item['val2']], item['min'] )
					}else{
						if(item['type'] === 'checkEmail'){
							this.checkEmail(items[i], this.state[items[i]], item['required'] )
						}else{
							if(item['type'] === 'checkUrl'){
								this.checkUrl(items[i], this.state[items[i]], item['required'] )
							}else{
								if(item['type'] === 'checkFile'){
									let params = this.state[`${items[i]}Params`]
									if(item['multiple']){
										this.checkFileOnSubmit(items[i], this.state[item['name']][item['pos']], item['width'], item['height'], params ? params.w : null, params ? params.h : null )
									}else{
										this.checkFileOnSubmit(items[i], this.state[items[i]], item['width'], item['height'], params ? params.w : null, params ? params.h : null )
									}
								}else{
									if(item['type'] === 'checkChip'){
										this.checkChip(items[i], this.state[items[i]], item['min'] )
									}
								}
							}
						}	
					}
				}
			}
		}
	}

    hasError(errors){
		let erreur = false
		Object.keys(errors).map(key => {
			let error = errors[key]
			Object.keys(error).map(k => {
				if(error[k]){
					erreur = true
				}
			})
		})
		return erreur
	}

    handleSendNotification(e){
        e.preventDefault()
        this.setState({ btnLoad: true })
		this.checkValidations(formOneItems)
		const error = this.hasError(this.state.errors)
		if(!error){
			const { title, body } = this.state
            this.props.controller.sendNotification({title, body})
            .then(res => {
                if (typeof (res.pror) === 'undefined') {
                    this.props.toast(messages.notificationSend, { appearance: 'success' })
                    this.setState({ btnLoad: false })
                } else {
                    if (res.pror.indexOf('401') > 0) {
                        this.props.toast(messages.sessionExpired, { appearance: 'info' })
                    } else {
                        this.props.toast(messages.requestFailed, { appearance: 'error' })
                        this.setState({ btnLoad: false })
                    }
                }
            })
        }else{
            this.props.toast(messages.formError, { appearance: 'error' })
            this.setState({ btnLoad: false })
        }
    }

    render() {
        // const { crud, permission } = this.props
        const { btnLoad, title, body, errors } = this.state

        return (
            <div className="row" style={{ margin: "0 40px" }}>

                <div className="col-12">
                    <Statcard title="Nouvelle notification" mb={24} float='left'>
                        <form className="wt-formtheme wt-formregister" id="form-inscription" onSubmit={this.handleSendNotification}>
                            <fieldset className="wt-registerformgroup">
                                <div className="form-group">
                                    <label>Titre *</label>
                                    <input type="text" onBlur={()=>this.checkRequired('title', title, 8)} className="form-control" placeholder="Titre de la notification" value={title} onChange={(e)=>this.handleTextChange(e, 'title')} required/>
                                    {Object.keys(errors.title).map((k, i) => {
                                        return <span key={i} className="error-span">{errors.title[k]}</span>
                                    })}
                                </div>
                                <div className="form-group">
                                    <label>Contenu *</label>
                                    <input type="text" onBlur={()=>this.checkRequired('body', body, 8)} className="form-control" placeholder="Contenu de la notification" value={body} onChange={(e)=>this.handleTextChange(e, 'body')} required/>
                                    {Object.keys(errors.body).map((k, i) => {
                                        return <span key={i} className="error-span">{errors.body[k]}</span>
                                    })}
                                </div>
                                
                                <div className="filter-actions-container" style={{float: 'right', marginTop: 16, marginBottom: 4}}>
                                    <button className="btn btn-primary filter-action-btn" type="submit">
                                        {btnLoad && <Loader size={16} />}
                                        {!btnLoad && 'Envoyer'}
                                    </button>
                                    {/* <button type="button" className="btn btn-secondary filter-action-btn" onClick={()=>this.setState({ ...fields, modal: false }) }>Annuler</button> */}
                                </div>
                            </fieldset>
                        </form>
                    </Statcard>
                </div>
            </div>
        )
    }

}