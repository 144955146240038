import React from 'react';
import Page from '../../components/Page'
import Home from '../../features/client/Home'
import { MarchandsSvc, PromotionsSvc } from '../../services';

const HomePage = (props) => {
    const marchandService = new MarchandsSvc(props.session)
    const promotionService = new PromotionsSvc(props.session)
    return (
        <Page mt={0}>
            <Home {...props} marchandController={marchandService} promotionController={promotionService} />
        </Page>
    )
}
export default HomePage