import React from 'react';
import Page from '../../components/Page'
import Categorie from '../../features/client/Categorie'
import { MarchandsSvc } from '../../services'

const CategoriePage = (props) => {
    const marchandService = new MarchandsSvc(props.session)
    return (
        <Page>
            <Categorie {...props} marchandController={marchandService} />
        </Page>
    )
}
export default CategoriePage