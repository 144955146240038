import Statcard from "../../Statcard"
import categoriesJSON from '../../../lib/categories.json'
import './detail.css'

const Row = ({ title, value, bt, bb }) => {
    return (
        <div className="row" style={{ border: `2px solid #ddd`, borderTop: `${bt}px solid #ddd`, borderBottom: `${bb}px solid #ddd`, margin: 0 }}>
            <div className="col-5 ayn-detail-title" style={{ backgroundColor: '#f7f7f7' }}>
                {title}
            </div>
            <div className="col-7 ayn-detail-text" style={{ borderLeft: '2px solid #ddd' }}>
                {value}
            </div>
        </div>
    )
}

const catAndSub = (cat, sub) =>{
    let res = []
    for(let i=0; i<cat.length; i++){
        let c = {}
        for(let j=0; j<categoriesJSON.length; j++){
            if(cat[i] === categoriesJSON[j]['name']){
                c.nom = cat[i]
                let subJSON = categoriesJSON[j]['children']
                let s = []
                for(let m=0; m<sub.length; m++){
                    for(let d=0; d<subJSON.length; d++){
                        if(sub[m] === subJSON[d]['name']){
                            s.push(sub[m])
                        }
                    }
                }
                c.children = s
            }
        }
        res.push(c)
    }
    return res
}

const Detail = ({data, user, onReinit}) => {
    const categories = catAndSub(data.tags.categories, data.tags.sousGategories )

    return (
        <>
            <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <Statcard title="INFORMATIONS DU PROPRIETAIRE" mb={24}>
                        <Row title="Nom et Prénom" value={user.name} bt='2' bb='1' />
                        <Row title="Email" value={user.email} bt='1' bb='2' />
                        <Row title="Rôle" value={user.role} bt='1' bb='2' />
                        <button className="btn btn-primary modal-action-btn" style={{margin: 0, marginTop: 12}} onClick={()=>onReinit()}>Reinitialiser le mot de passe</button>
                    </Statcard>

                    <Statcard title="INFORMATIONS DE LA BOUTIQUE" mb={24}>
                        <Row title="Nom de la boutique" value={data.boutique} bt='2' bb='1' />
                        <Row title="URL du site" value={data.url} bt='1' bb='1' />
                        <Row title="Adresse" value={data.adresse} bt='1' bb='1' />
                        <Row title="Numéro de téléphone" value={data.telephone} bt='1' bb='1' />
                        <Row title="Email" value={data.email} bt='1' bb='1' />
                        <Row title="Marchand vitepay" value={data.vitepay ? 'OUI' : 'NON'} bt='1' bb='1' />
                        <Row title="Le site peut-il s'ouvrir dans le FRAME" value={data.frame ? 'OUI' : 'NON'} bt='1' bb='2' />
                    </Statcard>

                    <Statcard title="LIENS DES RESEAUX SOCIAUX" mb={24}>
                        <Row title="Facebook" value={data.reseaux ? data.reseaux.facebook ? data.reseaux.facebook : 'n/a' : 'n/a'} bt='2' bb='1' />
                        <Row title="Instagram" value={data.reseaux ? data.reseaux.instagram ? data.reseaux.instagram : 'n/a' : 'n/a'} bt='1' bb='1' />
                        <Row title="Linkedin" value={data.reseaux ? data.reseaux.linkedin ? data.reseaux.linkedin : 'n/a' : 'n/a'} bt='1' bb='2' />
                    </Statcard>
                </div>

                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <Statcard title="AUTRES INFORMATIONS">
                        <div className="row detail-row">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                <div style={{ border: '2px solid #ddd' }}>
                                    <div style={{ height: 32, backgroundColor: '#f7f7f7', borderBottom: '2px solid #ddd', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <span className="ayn-detail-title">Catégories et sous-catégories</span>
                                    </div>
                                    <div style={{ padding: 8 }}>
                                        <div style={{ display: "flex", flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                                            {categories.map((c, i) => {
                                                return (
                                                    <div key={i} style={{ display: 'flex', flexDirection: 'column', width: '48%' }}>
                                                        <span className="ayn-detail-title">* {c.nom}</span>
                                                        {c.children.length > 0 &&
                                                        <ul className="wt-filtertag ayn-cat-ul form-cat-ul" style={{border: "1px solid #ddd", borderRadius: 8, marginBottom: 8, padding: 8}}>
                                                        {/* <ul className="wt-filtertag ayn-cat-ul form-cat-ul" style={{border: "1px solid #ddd", borderRadius: 8, marginBottom: 8, padding: "8px 8px 0px 8px"}}> */}
                                                            {c.children.map((s,j)=>{
                                                                return(
                                                                    <li className="ayn-cat-li" key={j}>
                                                                    <span className="cat-chip">
                                                                        <span>{s}</span>
                                                                    </span>
                                                                </li>
                                                                )
                                                            })}
                                                        </ul>}
                                                        {/* {c.children.map((s, j) => {
                                                            return (
                                                                <span key={j} className="ayn-detail-text" style={{ lineHeight: '14px', marginLeft: 24 }}>{s}</span>
                                                            )
                                                        })} */}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                <div style={{ border: '2px solid #ddd' }}>
                                    <div style={{ height: 32, backgroundColor: '#f7f7f7', borderBottom: '2px solid #ddd', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <span className="ayn-detail-title">Fourchette de prix et propositions</span>
                                    </div>
                                    <div style={{ height: 32, borderBottom: '2px solid #ddd', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div style={{ height: 30, width: '48%', backgroundColor: '#f7f7f7', borderRight: '2px solid #ddd', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <span className="ayn-detail-title">Fourchette de prix</span>
                                        </div>
                                        <div style={{ height: 30, width: '24%', borderRight: '2px solid #ddd', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <span className="ayn-detail-text">{data.tags ? data.tags.prix ? data.tags.prix.minimum ? data.tags.prix.minimum : 'n/a' : 'n/a' : 'n/a'}</span>
                                        </div>
                                        <div style={{ height: 30, width: '24%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <span className="ayn-detail-text">{data.tags ? data.tags.prix ? data.tags.prix.maximum ? data.tags.prix.maximum : 'n/a' : 'n/a' : 'n/a'}</span>
                                        </div>
                                    </div>
                                    <div style={{ height: 32, borderBottom: '2px solid #ddd', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div style={{ height: 30, width: '48%', backgroundColor: '#f7f7f7', borderRight: '2px solid #ddd', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <span className="ayn-detail-title">Livraison</span>
                                        </div>
                                        <div style={{ height: 30, width: '48%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <span className="ayn-detail-text">{data.services ? data.services.livraison ? data.services.livraison : 'n/a' : 'n/a'}</span>
                                        </div>
                                    </div>

                                    <div style={{ height: 32, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div style={{ height: 30, width: '48%', backgroundColor: '#f7f7f7', borderRight: '2px solid #ddd', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <span className="ayn-detail-title">Service après vente</span>
                                        </div>
                                        <div style={{ height: 30, width: '48%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <span className="ayn-detail-text">{data.services ? data.services.service ? 'OUI' : 'NON' : 'n/a' }</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row detail-row">
                            <div className="col-12">
                                <div style={{ border: '2px solid #ddd' }}>
                                    <div style={{ height: 32, backgroundColor: '#f7f7f7', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <span className="ayn-detail-title">Images du profil marchand</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row  detail-row">
                            <div className="col-5">
                                <div style={{ border: '2px solid #ddd' }}>
                                    <div style={{ height: 32, backgroundColor: '#f7f7f7', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <span className="ayn-detail-title">Logo</span>
                                    </div>
                                </div>
                                <div style={{ border: '2px solid #ddd', borderTop: 'none', display: 'flex', justifyContent: 'center' }}>
                                    <img src={data.logo} alt="logo" />
                                </div>
                            </div>
                            <div className="col-7">
                                <div style={{ border: '2px solid #ddd' }}>
                                    <div style={{ height: 32, backgroundColor: '#f7f7f7', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <span className="ayn-detail-title">Couverture</span>
                                    </div>
                                </div>
                                <div style={{ border: '2px solid #ddd', borderTop: 'none' }}>
                                    <img src={data.couverture} alt="couverture" />
                                </div>
                            </div>
                        </div>

                        <div className="row  detail-row">
                            <div className="col-12">
                                <div style={{ border: '2px solid #ddd' }}>
                                    <div style={{ height: 32, backgroundColor: '#f7f7f7', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <span className="ayn-detail-title">Produits phares</span>
                                    </div>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <div style={{ border: '2px solid #ddd', borderTop: 'none', width: '30%' }}>
                                        <img src={data.produits[0]} alt="produit phare 1" />
                                    </div>
                                    <div style={{ border: '2px solid #ddd', borderTop: 'none', width: '30%' }}>
                                        <img src={data.produits[1]} alt="produit phare 2" />
                                    </div>
                                    <div style={{ border: '2px solid #ddd', borderTop: 'none', width: '30%' }}>
                                        <img src={data.produits[2]} alt="produit phare 3" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Statcard>
                </div>
            </div>
        </>
    )
}

export default Detail