import React from 'react';
import Page from '../../components/Page'
import Connexion from '../../features/admin/Connexion'
import { LoginSvc } from '../../services';

const ConnexionPage = (props) => {
    const loginService = new LoginSvc(props.session)
    return (
        <Page login={true}>
            <Connexion {...props} controller={loginService} />
        </Page>
    )
}
export default ConnexionPage