import React from 'react';
import Header from '../../components/marchand/Header';
import Page from '../../components/Page'
import Commentaire from '../../features/marchand/Commentaire'
import { ForfaitsSvc, MarchandsSvc, NotesSvc } from '../../services';

const CommentairePage = (props) => {
    const marchandService = new MarchandsSvc(props.session)
    const forfaitService = new ForfaitsSvc(props.session)
    const notesService = new NotesSvc(props.session)
    const user = props.session.get('user')
    return (
        <Page mt={0} isAdmin={true}>
            <Header pathname="Commentaires" username={user?.username} session={props.session} toast={props.toast} />
            <Commentaire {...props} controller={marchandService} noteController={notesService} forfaitController={forfaitService} />
        </Page>
    )
}

export default CommentairePage