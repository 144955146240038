import React from 'react'
import Header from '../../components/admin/Header'
import Page from '../../components/Page'
import Inscription from '../../features/admin/Inscription'
import { MarchandsSvc, UsersSvc } from '../../services'

const InscriptionPage = (props) => {
    const marchandService = new MarchandsSvc(props.session)
    const userService = new UsersSvc(props.session)
    const user = props.session.get('user')
    return (
        <Page mt={0} isAdmin={true}>
            <Header pathname="Inscriptions" username={user?.username} session={props.session} toast={props.toast} />
            <Inscription {...props} controller={marchandService} userController={userService} />
        </Page>
    )
}
export default InscriptionPage