import React from 'react';
import Page from '../../components/Page'
import Condition from '../../features/client/Condition'

const ConditionPage = (props) => {
    return (
        <Page mt={0}>
            <Condition {...props} />
        </Page>
    )
}
export default ConditionPage