import React from "react"
import { Link } from "react-router-dom"
import { withToastHook } from '../../../pages/WithToastHook'
import messages from '../../../lib/messages.json'
import Loader from "../../../components/Loader"
import background from "../../../assets/images/bg.jpg"

class Connexion extends React.Component {
    constructor(props){
        super(props)
        this.state = { 
            email: '',
            password: '',
			connexion: true,
			load: false
        }
		// this.handleAuthentification = this.handleAuthentification.bind(this)
		this.handleForgetPassword = this.handleForgetPassword.bind(this)
		this.handleAuthentification = this.handleAuthentification.bind(this)

		const root = document.getElementById("root")
		root.style.backgroundImage = `url(${background})`
		root.style.backgroundSize = 'cover'
    }

	handleAuthentification(e) {
		e.preventDefault()
		this.setState({ load: true })
		const {email, password} = this.state
		this.props.controller.save({email, password})
		.then((access) => {
			if (typeof (access.pror) === 'undefined') {
				const user = {
					id: access.user._id,
					name: access.user.name,
					email,
					username: email.split('@')[0],
					password: password,
					status: access.user.status,
					token: access.token,
					role: access.user.role
				}
				this.props.addToast(messages.login, { appearance: 'success' })
				this.props.session.login(user)
				setTimeout(function(){ window.location = '/admin/dashbord' }, 1000)
            } else {
				this.setState({ load: false })
                this.props.addToast(access.pror, { appearance: 'error' })
            }
		})
		.catch(() => {
			this.setState({ load: false })
			this.props.addToast(messages.loginError, { appearance: 'error' })
		})
  
	 }

	 handleForgetPassword(e){
		e.preventDefault()
	 }

    render() {
        const { connexion, password, email, load } = this.state
        // const { isMobile } = this.props
		
        return (
            <div className="wt-haslayout wt-main-section">
					<div className="container">
						<div className="row justify-content-md-center">
							<div className="col-12" style={{display: 'flex', justifyContent: 'center'}}>
								<div className="wt-registerformhold" style={{borderRadius: 16, maxWidth: 360}}>
									{connexion &&
									<div style={{padding: 24, float: 'left'}}>
										<div className="wt-registerhead" style={{padding: 0}}>
											<div className="wt-title">
												<h3>Connexion</h3>
											</div>
											<div className="wt-description">
												<p>Veuillez saisir vos identifiants</p>
											</div>
										</div>
										<div className="wt-joinforms">
											<form className="wt-formtheme wt-formregister" id="form-inscription" onSubmit={this.handleAuthentification}>
												<fieldset className="wt-registerformgroup">
													<div className="form-group">
														<label>Adresse email *</label>
														<input type="email" name="email" value={email} onChange={(e)=>this.setState({email: e.target.value})} className="form-control" placeholder="Adresse email" required/>
													</div>
													<div className="form-group">
														<label>Mot de passe *</label>
														<input type="password" name="password" value={password} onChange={(e)=>this.setState({password: e.target.value})} className="form-control" placeholder="Mot de passe" required/>
													</div>
													<div className="form-group" style={{display: 'flex', flexDirection: 'column'}}>
														<button type="submit" disabled={load} className="wt-btn ayn-btn-submit-login">
															{load && <Loader size={16} />} 
															{!load && 'Se connecter'}
														</button>
														{/* <span style={{marginTop: 17, textAlign: 'center', display: 'block'}}>
															<Link to="#" onClick={()=>this.setState({connexion: false})} style={{marginRight: 16}}>Mot de passe oublié ?</Link>
															<a href="/admin/inscription">Créer un compte</a>
														</span> */}
													</div>
												</fieldset>
											</form>
										</div>
									</div>}
									{!connexion &&
									<div style={{padding: 24, float: 'left'}}>
										<div className="wt-registerhead">
											<div className="wt-title">
												<h3>Mot de passe oublié</h3>
											</div>
											<div className="wt-description">
												<p>Veuillez saisir votre adresse email</p>
											</div>
										</div>
										<div className="wt-joinforms">
											<form className="wt-formtheme wt-formregister" id="form-inscription" onSubmit={this.handleForgetPassword}>
												<fieldset className="wt-registerformgroup">
													<div className="form-group">
														<input type="email" name="username" className="form-control" placeholder="Adresse email" required/>
													</div>
													<div className="form-group"  style={{marginTop: 12, textAlign: 'center'}}>
														<button type="submit" className="wt-btn ayn-btn-submit-login">Valider</button>
														<span style={{marginTop: 17, textAlign: 'center', display: 'block'}}>
															<Link to="#" onClick={()=>this.setState({connexion: true})} style={{marginRight: 16}}>Se connecter</Link>
														</span>
													</div>
												</fieldset>
											</form>
										</div>
									</div>}
								</div>
							</div>
						</div>
					</div>
				</div>
        )
    }
}

export default withToastHook(Connexion)