/**
 * COMPOSANT APERCU DU MARCHAND DANS PAGE DETAIL DU MARCHANDS POUR LA PARTIE DE L'APPLICATION MISE A LA DISPOSITION DE LA GRANDE PUBLiQUE
 */
import React from "react"

const styles = {
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    iconContent: {
        display: 'flex',
        width: 58,
        height: 58,
        borderRadius: 80,
        border: '1px solid #001e37',
        alignItems: 'center',
        justifyContent: 'center'
    },
    icon: {fontSize: 24, color: '#001e37' },
    label: { fontSize: 12, color: '#001e37' }
}

export default class Apercu extends React.Component {
    constructor(props){
        super(props)
        this.state = { 
            call: false,
            itineraire: false
        }
    }

    render() {
        const { call, itineraire } = this.state
        const { url, telephone, produit1, produit2, produit3, canFrame  } = this.props
        return (
            <div>
                <div style={{display: "flex", justifyContent: 'space-evenly', paddingBottom: 16, borderBottom: '1px solid #ddd'}}>
                    <a style={{cursor: 'pointer'}} href={`tel://${telephone}`} /*onClick={()=>this.setState({call: true})}*/>
                        <div style={styles.item}>
                            <div style={styles.iconContent}> <i className="lnr lnr-phone" style={styles.icon}></i> </div>
                            <span style={styles.label}>APPELER</span>
                        </div>
                    </a>

                    {canFrame && 
                    <span onClick={()=>this.props.onOpenUrl(url)} style={{cursor: 'pointer'}} >
                        <div style={styles.item}>
                            <div style={styles.iconContent}> <i className="fas fa-globe" style={styles.icon}></i> </div>
                            <span style={styles.label}>SITE WEB</span>
                        </div>
                    </span>}
                    {!canFrame && 
                    <a href={url} style={{cursor: 'pointer'}} target="_blank" rel="noreferrer">
                        <div style={styles.item}>
                            <div style={styles.iconContent}> <i className="fas fa-globe" style={styles.icon}></i> </div>
                            <span style={styles.label}>SITE WEB</span>
                        </div>
                    </a>}
                </div>

                {!itineraire &&
                <div style={{padding: 16}}>
                    <h4 style={{marginBottom: 4}}>Produits phares</h4>
                    <div className="ayn-produit-phr-row">
                        <div className="ayn-produit-phr-col">
                            <img src={produit1} alt="img produit1" />
                        </div>
                        <div className="ayn-produit-phr-col">
                            <img src={produit2} alt="img produit2" />
                        </div>
                        <div className="ayn-produit-phr-col">
                            <img src={produit3} alt="img produit3" />
                        </div>
                    </div>
                </div>}

                {call &&
                <div style={{ display: 'block', position: 'absolute', top: 0,  width: '96%', height: '100%', background: 'rgba(0, 0, 0, 0.0)',  zIndex: 1000, opacity: 1, transition: 'all 0.5s ease-in-out'}}>
                    <div style={{position: 'absolute', bottom: 68, display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center'}}>
                        <div style={{background: 'rgba(0, 0, 0, 0.6)', height: 36, borderRadius: 12, padding: '4px 12px'}}>
                            <span style={{color: '#fff'}}>Appeler: {telephone}</span>
                        </div>

                        <span style={{background: 'rgba(0, 0, 0, 0.6)', height: 36, borderRadius: 12, padding: '4px 12px', marginTop: 24, color: '#fff', cursor: 'pointer'}} onClick={()=>this.setState({call: false})}>
                            Annuler
                        </span>
                    </div>
                </div>}

           </div>
        )
    }
}