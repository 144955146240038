import React, { Component } from 'react'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import NavBar from '../../components/admin/NavBar/index';
import Connexion from '../admin/Connexion';


export default class AdminLayout extends Component {
    constructor(props) {
        super(props);
        this.state = { isMobile: false }
    }

    updateDimensions = () => {
        const width = window.innerWidth
        if (width < 576) this.setState({ isMobile: true })
        else this.setState({ isMobile: false })
    }


    componentDidMount() {
        this.updateDimensions()
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() { window.removeEventListener('resize', this.updateDimensions) }


    render() {
        const { session, routes, toast/*, loginController*/ } = this.props
        const { isMobile } = this.state
        const user = session.get('user')

        if (window.location.pathname === '/admin/login') {
            return React.createElement(Connexion, {isMobile, session}, null)
        } else {
            return (
                <Router>
                    <div id="wt-wrapper" className="wt-wrapper wt-haslayout">
                        <div className="wt-contentwrapper" style={{ display: 'flex' }}>
                            <NavBar role={user?.role} />
                            <Switch>
                                { routes.map((prop, key) => {
                                    return <Route exact path={prop.path} key={key} render={(props) => React.createElement(prop.component, { session, isMobile, toast, ...props }, null)} />
                                }) }
                            </Switch>
                        </div>
                    </div>
                </Router>
            )
        }
    }
}