import React from 'react';
import Header from '../../components/admin/Header';
import Page from '../../components/Page'
import Certification from '../../features/admin/Certification'
import { CertificationsSvc } from '../../services';

const CertificationPage = (props) => {
    const certificationService = new CertificationsSvc(props.session)
    const user = props.session.get('user')
    return (
        <Page mt={0} isAdmin={true}>
            <Header pathname="Certifications" username={user?.username} session={props.session} toast={props.toast} />
            <Certification {...props} controller={certificationService} />
        </Page>
    )
}
export default CertificationPage