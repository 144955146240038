import React from 'react';
import Header from '../../components/marchand/Header';
import Page from '../../components/Page'
import Promotion from '../../features/marchand/Promotion'
import { PromotionsSvc, UsersSvc } from '../../services';

const PromotionPage = (props) => {
    const promotionService = new PromotionsSvc(props.session)
    const userService = new UsersSvc(props.session)
    const user = props.session.get('user')
    return (
        <Page mt={0} isAdmin={true}>
            <Header pathname="Promotions" username={user?.username} session={props.session} toast={props.toast} />
            <Promotion {...props} userController={userService} controller={promotionService} />
        </Page>
    )
}

export default PromotionPage