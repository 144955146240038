/**
 * COMPOSANT AVIS SUR MARCHAND DANS PAGE DETAIL DU MARCHANDS POUR LA PARTIE DE L'APPLICATION MISE A LA DISPOSITION DE LA GRANDE PUBLiQUE
 */
import React from "react"
import Rating from "react-rating"
import FacebookLogin from "react-facebook-login"
import Progress from "../../../components/Progress"
import ReactModal from "react-modal"
import messages from "../../../lib/messages.json"
import Loader from "../../../components/Loader"
import { moyenNote, ratingNote } from "../../../lib/functions"
import axios from "axios"

const styles = {
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    head: { textTransform: 'inherit', color: '#001e37' },
    icon: {fontSize: 36, color: '#001e37', fontWeight: '100' },
    label: { textAlign: 'center' },
    imageContent: {
        display: 'flex',
        width: 58,
        height: 58,
        minWidth: 58,
        borderRadius: 80,
        border: '1px solid #001e37',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 16
    }
}

export default class Avis extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            comments: [], av: '', maxChar: 300,
            login: false,
            modal: false,
            disabled: true, btnLoad: false, load: false, retourned: false, avisError: true,
            avis: '', avisRating: 0, noteRating: 0, notes: {},
            user: { auteur: '', avatar: '', fbUserId: '' }
        }
        this.responseFacebook = this.responseFacebook.bind(this)
        this.getFbInfo = this.getFbInfo.bind(this)
        this.handleSubmitAvis = this.handleSubmitAvis.bind(this)
    }
    //EVENEMENT LORSQUE ON COMMENCE A SAISIR
    handleTextChange(e, name){ this.setState({ [name]: e.target.value }) }
    // Soumission de l'avis des utilisateurs
    handleSubmitAvis(e){
        e.preventDefault()
        this.setState({ btnLoad: true })
        const { avisRating, user, avis } = this.state
        const date = new Date()
        const now = `${("0" + date.getDate()).slice(-2)}/${("0" + (date.getMonth()+1)).slice(-2)}/${date.getFullYear()}`
        const commentaire = {...user, note: avisRating, avis: {message: avis, date: now, reponse: null}, marchandId: this.props.marchandId }
        const that = this
        
        if(this.props.marchandId){
            this.props.avisController.save(commentaire)
            .then(res => {
                if (typeof (res.pror) === 'undefined') {
                    // this.props.toast(messages.avisSend, { appearance: 'success' })
                    this.setState({ modal: false, btnLoad: false, login: false, avisRating: 0, avis: '', retourned: true, avisError: false })
                    setTimeout(function(){ that.setState({ retourned: false }) },3000)
                } else {
                    if (res.pror.indexOf('401') > 0) {
                        this.props.toast(messages.sessionExpired, { appearance: 'info' })
                    } else {
                        // this.props.toast(messages.requestFailed, { appearance: 'error' })
                        this.setState({ btnLoad: false, retourned: true, avisError: true })
                        setTimeout(function(){ that.setState({ retourned: false }) },3000)
                    }
                }
            })
        }
    }
    // Soumission des notes des utilisateurs
    handleSubmitNote(avisRating){
        this.setState({ load: true })
        const { user } = this.state
        const commentaire = {...user, note: avisRating, marchandId: this.props.marchandId }
        const that = this
        
        if(this.props.marchandId){
            this.props.noteController.save(commentaire)
            .then(res => {
                if (typeof (res.pror) === 'undefined') {
                    // this.props.toast(messages.noteSend, { appearance: 'success' })

                    this.setState({ load: false, login: false, noteRating: 0, retourned: true, avisError: false })
                    setTimeout(function(){ that.setState({ retourned: false }) },3000)

                    this.setState({ load: false, login: false, noteRating: 0, retourned: true, avisError: false })
                    setTimeout(function(){ that.setState({ retourned: false }) },3000)
                } else {
                        this.setState({ load: false, login: false, retourned: true, avisError: true })
                        setTimeout(function(){ that.setState({ retourned: false }) },3000)
                    
                }
            })
        }
    }

    // Recuperation des notes sur un marchand
    getNotes(){
        const that = this
        if(this.props.marchandId){
            this.props.noteController.stats(this.props.marchandId)
            .then(res => {
                if (typeof (res.pror) === 'undefined') {
                    const notes = ratingNote(res)
                    this.setState({ notes, load: false, login: false, noteRating: 0, retourned: false, avisError: false })
                    setTimeout(function(){ that.setState({ retourned: false }) },3000)
                } else {
                    this.setState({ load: false, login: false, retourned: true, avisError: true })
                    setTimeout(function(){ that.setState({ retourned: false }) },3000)
                }
            })
        }
    }

    // Recuperation des information facebook de l'utisateur voulant donner un avis
    getFbInfo = (res) => {
        if (res.accessToken) {
            const user = {auteur: res.name, avatar: res.picture.data.url, fbUserId: res.userID }
            const comment = this.state.comments.find(c=>c.fbUserId)
            const avisRating = comment ? comment.note : 0
            const avis = comment ? comment.avis ? comment.avis.message : '' : ''
            this.setState({ user, login: true, modal: true, load: false, avis, avisRating })
        } else {
            this.setState({ load: false, login: false })
        }
    }

    // Recuperation des information facebook de l'utisateur voulant donner une note
    responseFacebook = (res) => {
        if (res.accessToken) {
            const user = { auteur: res.name, avatar: res.picture.data.url, fbUserId: res.userID }
            const comment = this.state.comments.find(c=>c.fbUserId)
            const noteRating = comment ? comment.note : 0
            this.setState({ login: true, user, load: false, noteRating })
        } else {
            this.setState({ login: false, load: false })
        }
    }

    // app id : 2862393507333579
    // secret key : aafa6b1ae9715c6d6573009d8bb0265f
    //Reconstition de photo profil des utilisateurs facebook
    getImage = (id) => {
        return axios.get(`https://graph.facebook.com/v3.2/${id}/picture?type=normal&access_token=2862393507333579%7Caafa6b1ae9715c6d6573009d8bb0265f`,{responseType: 'blob'})
        .then(function (response) {
            let imageNode = document.getElementById(`ayena_${id}`);
            let url = URL.createObjectURL(response.data)
            if(imageNode) imageNode.src =  url
        })
        .catch(function(e){
            let imageNode = document.getElementById(`ayena_${id}`);
            if(imageNode) imageNode.src =  ''
        })
    }

    // Lorsque le champ de saisi commentaire change de contenu
    handleCommentChange(value){
        const { maxChar } = this.state
        if(value.length <= maxChar){
            this.setState({ avis: value })
        }
    }
    componentDidMount(){
        // this.getImage()
        this.getNotes()
        this.setState({comments: this.props.avis})
    }

    render() {
        const { comments, maxChar, login, modal, avis, avisRating, noteRating, notes, btnLoad, load, disabled, retourned, avisError } = this.state
        
        return (
           <div>
               <div style={{display: "flex", flexDirection: 'column', alignItems: 'center', padding: '0 16px 16px 16px', borderBottom: '1px solid #ddd'}}>
                    <div style={styles.item}>
                        <i className="fa fa-user-circle" style={styles.icon}></i>
                    </div>

                    <h6 style={styles.head}>Donner une note</h6>
                    <p style={styles.label}>Partager votre expérience afin d'aider les autres utilisateur</p>

                    <span>
                        <Rating
                            initialRating={noteRating}
                            emptySymbol={<i className="fa fa-star-o fa-3x"></i>}
                            fullSymbol={<i className="fa fa-star fa-3x" style={{color: 'orange'}}></i>}
                            // fractions={4}
                            readonly={!login}
                            onClick={r=>this.handleSubmitNote(r)}
                        />
                    </span>
                    {!login &&
                    <FacebookLogin
                        appId="2862393507333579"
                        autoLoad={false}
                        fields="name,email,picture"
                        // scope="public_profile, email, user_birthday"
                        callback={this.responseFacebook}
                        textButton="Se connecter avec facebook"
                        onClick={()=>this.setState({ load: true })}
                    />}
               </div>

                <div style={{padding: 16, borderBottom: '1px solid #ddd'}}>
                    <p style={{color: '#001e37'}}>Résumé des notes clients</p>
                    <div className="row">
                        <div className="col-8">
                            <Progress process={notes.cinq} number='5' />
                            <Progress process={notes.quatre} number='4' />
                            <Progress process={notes.trois} number='3' />
                            <Progress process={notes.deux} number='2' />
                            <Progress process={notes.un} number='1' />
                        </div>
                        
                        <div className="col-4">
                            <h2 style={{textAlign: 'center', fontWeight: 400, marginBottom: 0, position: 'relative', top: -12}}>{moyenNote(this.props.avis)}</h2>
                            <div style={{textAlign: 'center', position: 'relative', top: -20}}>
                                <Rating
                                    initialRating={moyenNote(this.props.avis)}
                                    emptySymbol={<i className="fa fa-star-o"></i>}
                                    fullSymbol={<i className="fa fa-star" style={{color: 'orange'}}></i>}
                                    readonly
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{padding: 16, borderBottom: '1px solid #ddd'}}>
                    <p style={{color: '#001e37'}}>Commentaires et avis</p>
                    <FacebookLogin
                        appId="2862393507333579"
                        autoLoad={false}
                        fields="name,email,picture"
                        callback={this.getFbInfo}
                        textButton="Donner votre avis"
                        // icon="far fa-edit"
                        cssClass="ayn-btn-donner-avis"
                        onClick={()=>this.setState({ load: true })}
                    />
                    
                        {comments.map((c,i)=>{
                            return(
                                c.avis &&
                                <div style={{display: 'flex'}} key={i}>
                                    <div style={styles.imageContent}>
                                        <img id={`ayena_${c.fbUserId}`} src={this.getImage(c.fbUserId)} alt="profil" style={{width: 58, height: 58, borderRadius: 80}} />
                                    </div>
                                    <div style={{width: "calc(100% - 78px)"}}>
                                        <h6 style={{fontWeight: 400, marginBottom: 0, marginTop: 8}}>{c.auteur}</h6>
                                        {/* <span style={{fontSize: 10, position: 'relative', top: -10}}>{c.rating} avis</span> */}
                                        <div style={{position: 'relative', top: -5, marginBottom: 4}}>
                                            <Rating
                                                initialRating={c.note}
                                                emptySymbol={<i className="fa fa-star-o"></i>}
                                                fullSymbol={<i className="fa fa-star" style={{color: 'orange'}}></i>}
                                                readonly
                                            />
                                            <span style={{fontSize: 10, marginLeft: 16}}>{c.avis?.date}</span>
                                        </div>
                                        <p style={{fontSize: 12, opacity: 0.7, color: '#000', lineHeight: '14px', position: 'relative', top: -12, marginBottom: 0}}>{c.avis?.message}</p>
                                        <span style={{fontSize: 12, fontWeight: 'bold', float: 'right', position: 'relative', top: -14, color: '#000'}}>{`Reponses(${c.avis?.reponses ? 1/*c.avis.reponses.length*/ : 0 })`}</span>
                                        {c.avis && 
                                        // c.avis.reponse.map((r,j)=>{
                                            // return(
                                                c.avis.reponse &&
                                                <div style={{marginLeft: 16, marginBottom: 8, paddingLeft: 8, borderLeft: '1px solid #ddd'}}>
                                                    <h6 style={{fontWeight: 400, display: 'inline'}}>{c.avis.reponse.username}</h6>
                                                    <span style={{fontSize: 10, marginLeft: 16}}>{c.avis.reponse.date}</span>
                                                    <p style={{fontSize: 12, opacity: 0.7, color: '#000', lineHeight: '14px', marginBottom: 0}}>{c.avis.reponse.response}</p>
                                                </div>
                                                
                                            // )
                                        // })
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    
                </div>

                <ReactModal isOpen={modal} ariaHideApp={false} className="alert-modal">
                    <div className="modal-alert-body-content">
                        <h5 style={{fontWeight: '400', textTransform: 'uppercase'}}>Donner votre avis</h5>
                        <p> Les avis sont publics. Tout le monde peut voir le nom et la photo de votre compte Facebook.</p>
                        <form className="wt-formtheme wt-formregister" id="form-inscription" onSubmit={this.handleSubmitAvis}>
                            <fieldset className="wt-registerformgroup">
                                <div className="form-group">
                                    <label>Touchez pour noter</label>
                                    <span>
                                        <Rating
                                            initialRating={avisRating}
                                            emptySymbol={<i className="fa fa-star-o fa-3x"></i>}
                                            fullSymbol={<i className="fa fa-star fa-3x" style={{color: 'orange'}}></i>}
                                            // fractions={1}
                                            readonly={!login}
                                            onClick={(r)=>this.setState({avisRating: r, disabled})}
                                        />
                                    </span>
                                </div>
                                <div className="form-group">
                                    <label>Redigez un avis *</label>
                                    <textarea
                                        rows="2" className="form-control"
                                        placeholder="Dites aux autres utilisateurs ce que vous pensez de ce marchand. Le recommanderiez-vous, et pourquoi ?"
                                        value={avis}
                                        onChange={(e)=>this.handleCommentChange(e.target.value)} required>
                                    </textarea>
                                    <span className="error-span" style={{float: 'right'}}>{`${avis.length}/${maxChar}`}</span>
                                </div>
                                
                                <div className="filter-actions-container" style={{float: 'right', marginTop: 16}}>
                                    <button type="button" className="btn btn-secondary filter-action-btn" onClick={()=>this.setState({ modal: false, avis: '', avisRating: 0, login: false }) }>Annuler</button>
                                    <button className="btn btn-primary filter-action-btn" type="submit" disabled={avis ? false : true}>
                                        {btnLoad && <Loader size={12} />}
                                        {!btnLoad && 'Envoyer'}
                                    </button>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </ReactModal>

                <ReactModal isOpen={load} ariaHideApp={false} className="load-modal">
                    <div className="modal-alert-body-content">
                        <Loader size={48} color="#001e37" />
                    </div>
                </ReactModal>

                {retourned &&
                <div style={{ display: 'block', position: 'absolute', top: 0,  width: '96%', height: '100%', background: 'rgba(0, 0, 0, 0.0)',  zIndex: 1000, opacity: 1, transition: 'all 0.5s ease-in-out'}}>
                    <div style={{position: 'absolute', bottom: 68, display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center'}}>
                        <div style={{background: 'rgba(0, 0, 0, 0.6)', height: 36, borderRadius: 12, padding: '4px 12px'}}>
                            <span style={{color: '#fff'}}>
                                {!avisError && messages.noteSend}
                                {avisError && messages.requestFailed}
                            </span>
                        </div>
                    </div>
                </div>}

           </div>
        )
    }
}