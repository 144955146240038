import React from "react"
import { Link } from 'react-router-dom'
import messages from '../../../lib/messages.json'
import { formatNumber } from "../../../lib/functions"
import { Cropper } from "react-cropper";
import Resizer from "react-image-file-resizer";
import "cropperjs/dist/cropper.css";
import './index.css'

const un = [
    {mois: '1', montant: 200000, tx: 0 }, {mois: '2', montant: 400000, tx: 0 },
    {mois: '3', montant: 500000, tx: 0.25 }, {mois: '4', montant: 700000, tx: 0.25 }, {mois: '5', montant: 900000, tx: 0.25 },
    {mois: '6', montant: 1000000, tx: 0.30 }, {mois: '7', montant: 1200000, tx: 0.30 }, {mois: '8', montant: 1400000, tx: 0.30 },
    {mois: '9', montant: 1500000, tx: 0.35 }, {mois: '10', montant: 1700000, tx: 0.35 }, {mois: '11', montant: 1900000, tx: 0.35 },
    {mois: '12', montant: 2000000, tx: 0.40 }
]
const  deux = [
    {mois: '1', montant: 100000, tx: 0 }, {mois: '2', montant: 200000, tx: 0 },
    {mois: '3', montant: 250000, tx: 0.25 }, {mois: '4', montant: 350000, tx: 0.25 }, {mois: '5', montant: 450000, tx: 0.25 },
    {mois: '6', montant: 500000, tx: 0.30 }, {mois: '7', montant: 600000, tx: 0.30 }, {mois: '8', montant: 700000, tx: 0.30 },
    {mois: '9', montant: 750000, tx: 0.35 }, {mois: '10', montant: 850000, tx: 0.35 }, {mois: '11', montant: 950000, tx: 0.35 },
    {mois: '12', montant: 1000000, tx: 0.40 }
]

const trois = [
    {mois: '1', montant: 80000, tx: 0 }, {mois: '2', montant: 160000, tx: 0 },
    {mois: '3', montant: 200000, tx: 0.25 }, {mois: '4', montant: 280000, tx: 0.25 }, {mois: '5', montant: 360000, tx: 0.25 },
    {mois: '6', montant: 400000, tx: 0.30 }, {mois: '7', montant: 480000, tx: 0.30 }, {mois: '8', montant: 560000, tx: 0.30 },
    {mois: '9', montant: 600000, tx: 0.35 }, {mois: '10', montant: 680000, tx: 0.35 }, {mois: '11', montant: 760000, tx: 0.35 },
    {mois: '12', montant: 800000, tx: 0.40 }
]

const durees = [
    // {value: '1s', label: '1 Semaine'},
    // {value: '2s', label: '2 Semaines'},
    {value: '1', label: '1 Mois'}, {value: '2', label: '2 Mois'},  {value: '3', label: '3 Mois'}, {value: '4', label: '4 Mois'},
    {value: '5', label: '5 Mois'}, {value: '6', label: '6 Mois'}, {value: '7', label: '7 Mois'}, {value: '8', label: '8 Mois'},
    {value: '9', label: '9 Mois'}, {value: '10', label: '10 Mois'}, {value: '11', label: '11 Mois'}, {value: '12', label: '12 Mois'},
]

const positions = [
    {value: '1', label: 'Slider 1'},
    {value: '2', label: 'Slider 2'},
    {value: '3', label: 'Slider 3'}
]
const initialState = { 
    debut: '', fin: '', position: '1', duree: '1', montant: 200000, couverture: '', couverturePrvImg: '',
    debutError: false, dureeError: false, positionError: false, couvertureError: false, couvertureErrors: {}, aturalWidth: 0, naturalHeight: 0, slideWidth: 1000, slideHeight: 350 
}
export default class Promotion extends React.Component {
    constructor(props){
        super(props)
        this.state = { 
            ...initialState, calendrier: [],
            currentcouverture: '', startXcouverture: 0, startYcouverture: 0, sWidthcouverture: 0, sHeightcouverture: 0
        }
    }

    handleDureeChange(duree){
        const { debut, position } = this.state
        if(debut !== ''){
            const begin = new Date(debut)
            if(duree === '1s'){
                const end = new Date(begin.setDate(begin.getDate()+7))
                const fin = `${end.getFullYear()}-${("0" + (end.getMonth()+1)).slice(-2)}-${("0" + end.getDate()).slice(-2)}`
                this.setState({ fin, duree, debutError: false })
            }else{
                if(duree === '2s'){
                    const end = new Date(begin.setDate(begin.getDate()+14))
                    const fin = `${end.getFullYear()}-${("0" + (end.getMonth()+1)).slice(-2)}-${("0" + end.getDate()).slice(-2)}`
                    this.setState({ fin, duree, debutError: false })
                }else{
                    const end = new Date(begin.setMonth(begin.getMonth()+parseInt(duree)))
                    const fin = `${end.getFullYear()}-${("0" + (end.getMonth()+1)).slice(-2)}-${("0" + end.getDate()).slice(-2)}`

                    switch (position) {
                        case '1':
                            const pos = un.find(u=>u.mois === duree)
                            const tx = pos?.tx
                            const montant = (duree * 200000) - ((duree * 200000) * tx)
                            this.setState({ fin, duree, montant, debutError: false })
                            break;
                        case '2':
                            const pos2 = deux.find(u=>u.mois === duree)
                            const tx2 = pos2?.tx
                            const montant2 = (duree * 100000) - ((duree * 100000) * tx2)
                            this.setState({ fin, duree, montant: montant2, debutError: false })
                            break;
                        case '3':
                            const pos3 = trois.find(u=>u.mois === duree)
                            const tx3 = pos3?.tx
                            const montant3 = (duree * 80000) - ((duree * 80000) * tx3)
                            this.setState({ fin, duree, montant: montant3, debutError: false })
                            break;
                        default:
                            const pos1 = un.find(u=>u.mois === duree)
                            const tx1 = pos1?.tx
                            const montant1 = (duree * 200000) - ((duree * 200000) * tx1)
                            this.setState({ fin, duree, montant: montant1, debutError: false })
                            break;
                    }
                }
            }
        }else{ 
            switch (position) {
                case '1':
                    const pos = un.find(u=>u.mois === duree)
                    const tx = pos?.tx
                    const montant = (duree * 200000) - ((duree * 200000) * tx)
                    this.setState({ fin: '', duree, montant })
                    break;
                case '2':
                    const pos2 = deux.find(u=>u.mois === duree)
                    const tx2 = pos2?.tx
                    const montant2 = (duree * 100000) - ((duree * 100000) * tx2)
                    this.setState({ fin: '', duree, montant: montant2 })
                    break;
                case '3':
                    const pos3 = trois.find(u=>u.mois === duree)
                    const tx3 = pos3?.tx
                    const montant3 = (duree * 80000) - ((duree * 80000) * tx3)
                    this.setState({ fin: '', duree, montant: montant3 })
                    break;
                default:
                    const pos1 = un.find(u=>u.mois === duree)
                    const tx1 = pos1?.tx
                    const montant1 = (duree * 200000) - ((duree * 200000) * tx1)
                    this.setState({ fin: '', duree, montant: montant1 })
                    break;
            }
        }
    }

    handleDebutChange(debut){
        if(debut !== ''){
            const { duree } = this.state
            const begin = new Date(debut)
            if(duree === '1s'){
                const end = new Date(begin.setDate(begin.getDate()+7))
                const fin = `${end.getFullYear()}-${("0" + (end.getMonth()+1)).slice(-2)}-${("0" + end.getDate()).slice(-2)}`
                this.setState({ fin, debut, debutError: false })
            }else{
                if(duree === '2s'){
                    const end = new Date(begin.setDate(begin.getDate()+14))
                    const fin = `${end.getFullYear()}-${("0" + (end.getMonth()+1)).slice(-2)}-${("0" + end.getDate()).slice(-2)}`
                    this.setState({ fin, debut, debutError: false })
                }else{
                    const end = new Date(begin.setMonth(begin.getMonth()+parseInt(duree)))
                    const fin = `${end.getFullYear()}-${("0" + (end.getMonth()+1)).slice(-2)}-${("0" + end.getDate()).slice(-2)}`
                    this.setState({ fin, debut, debutError: false })
                }
            }
        }else{ this.setState({ debut, fin: '', debutError: true }) }
    }

    handlePositionChange(position){
        const { duree } = this.state
        if(position === ''){
            this.setState({ position, positionError: true })
        }else{
            this.setState({ position, positionError: false })
            switch (position) {
                case '1':
                    const pos = un.find(u=>u.mois === duree)
                    const tx = pos?.tx
                    const montant = (duree * 200000) - ((duree * 200000) * tx)
                    this.setState({ position, montant, positionError: false, slideWidth: 1000, slideHeight: 350, couverture: '', couverturePrvImg: '' })
                    break;
                case '2':
                    const pos2 = deux.find(u=>u.mois === duree)
                    const tx2 = pos2?.tx
                    const montant2 = (duree * 100000) - ((duree * 100000) * tx2)
                    this.setState({ position, montant: montant2, positionError: false, slideWidth: 1000, slideHeight: 250, couverture: '', couverturePrvImg: ''  })
                    break;
                case '3':
                    const pos3 = trois.find(u=>u.mois === duree)
                    const tx3 = pos3?.tx
                    const montant3 = (duree * 80000) - ((duree * 80000) * tx3)
                    this.setState({ position, montant: montant3, positionError: false, slideWidth: 1000, slideHeight: 250, couverture: '', couverturePrvImg: ''  })
                    break;
                default:
                    const pos1 = un.find(u=>u.mois === duree)
                    const tx1 = pos1?.tx
                    const montant1 = (duree * 200000) - ((duree * 200000) * tx1)
                    this.setState({ position, montant: montant1, positionError: false, slideWidth: 1000, slideHeight: 350, couverture: '', couverturePrvImg: ''  })
                    break;
            }
        }
    }

    async handleImageChoose(e){
		this.setState({ couverture: e.target.files[0], couverturePrvImg: URL.createObjectURL(e.target.files[0]), couvertureError: false, couvertureErrors: {} })
	}
    handleImageChoose2(file){
		this.setState({ couverture: file, couverturePrvImg: URL.createObjectURL(file) })
	}

    handleFileLoad(e, name, w, h){
		// this.setState({[`${name}Params`]: { w: e.target.naturalWidth, h: e.target.naturalHeight} })
		this.checkFile(name, w, h, e.target.naturalWidth, e.target.naturalHeight)
	}

    getCroppedImg(image, detail, name) {
		this.setState({ [`current${name}`]: image, [`startX${name}`]: detail.x, [`startY${name}`]: detail.y, [`sWidth${name}`]: detail.width, [`sHeight${name}`]: detail.height })
	}
    croppedImg(crop, filename, name, pos=0) {
		const canvas = document.createElement('canvas');
		canvas.width = this.state[`sWidth${name}`]
		canvas.height = this.state[`sHeight${name}`]
		const ctx = canvas.getContext('2d');
		
		ctx.drawImage(
			this.state[`current${name}`],
			this.state[`startX${name}`],
			this.state[`startY${name}`],
			this.state[`sWidth${name}`],
			this.state[`sHeight${name}`],
			0,
			0,
			this.state[`sWidth${name}`],
			this.state[`sHeight${name}`],
		);

		const base64Image = canvas.toDataURL('image/png')
		const file = this.b64toFile(base64Image, filename)
		this.resizeFile(file, crop.width, crop.height, name, pos)
	}
    resizeFile = (file, width, height, name, pos=0) =>{
		new Promise((resolve) => {
			Resizer.imageFileResizer( file, width, height, "png", 0, 0,
			(uri) => { resolve(uri); this.handleImageChoose2(uri) }, "file", width, height);
		});
	}

	b64toByteArrays(b64Data) {
		const sliceSize = 512;
	
		const byteCharacters = atob(
		  b64Data.toString().replace(/^data:image\/(png|jpeg|jpg|webp);base64,/, "")
		);
		const byteArrays = [];
	
		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		  let slice = byteCharacters.slice(offset, offset + sliceSize);
	
		  const byteNumbers = new Array(slice.length);
		  for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		  }
	
		  const byteArray = new Uint8Array(byteNumbers);
	
		  byteArrays.push(byteArray);
		}
		return byteArrays;
	}

	b64toFile(b64Data, fileName="test-logo") {
		const byteArrays = this.b64toByteArrays(b64Data);
		const file = new File(byteArrays, fileName, { type: 'image/png', lastModified: new Date() });
		return file;
	}

    checkFile(name, width, height, naturalWidth, naturalHeight){
        const { slideHeight, slideWidth } = this.state

        const couvertureErrors = this.state.couvertureErrors
	
        couvertureErrors['rectangle'] = naturalWidth > naturalHeight ?  null : `*La taille du fichier n'est pas valide, votre fichier doit être rectangulaire`
        couvertureErrors['min'] = naturalWidth < slideWidth || naturalHeight < slideHeight ?  `*La taille du fichier n'est pas valide, votre fichier doit avoir une taille minimale de ${slideWidth}x${slideHeight}` : null
		couvertureErrors['valide'] = width === naturalWidth && height === naturalHeight ?  null : `*La taille du fichier n'est pas valide, votre fichier est de (${naturalWidth}x${naturalHeight}) au lieu de (${width}x${height})`
		this.setState({couvertureErrors})

		// errors[name]['valide'] = width === naturalWidth && height === naturalHeight ?  null : `*La taille du fichier n'est pas valide, votre fichier est de (${naturalWidth}x${naturalHeight}) au lieu de (${width}x${height})`
		this.setState({
            couvertureError: (width === naturalWidth && height === naturalHeight) ? false : true,
            naturalWidth, naturalHeight, couvertureErrors
        })
	}

    formCheck(){
        let check = 0
        const { position, debut, duree, couverture, positionError, debutError, dureeError, couvertureError, couvertureErrors } = this.state
        // eslint-disable-next-line
        Object.keys(couvertureErrors).map((k, i) => {
            if(couvertureErrors[k] !== null){
                check = check + 1
                this.setState({ couvertureError: true})
            }
        })
        if(positionError === true || position === ''){  check = check + 1; this.setState({ positionError: true}) }
        if(debutError === true || debut === ''){  check = check + 1; this.setState({ debutError: true}) }
        if(dureeError === true || duree === ''){  check = check + 1; this.setState({ dureeError: true}) }
        if(couvertureError === true || couverture === ''){
            couvertureErrors['required'] = `*Ce champ est obligatoire`
            check = check + 1
            this.setState({ couvertureError: true, couvertureErrors }) 
        }
        return check
    }

    handleSubmit(){
        this.setState({ btnLoad: true })
        const check = this.formCheck()
        if(check === 0){
            const { duree, debut, fin, montant, position, couverture } = this.state
            if(this.props.marchandId){
                const files = {slider: couverture}
                const promotion = { debut, fin, niveau: position, duree, montant, marchandId: this.props.marchandId }

                // console.log("promotion", promotion)
    
                this.props.controller.demande(files, JSON.stringify(promotion))
                .then(res => {
                    if (typeof (res.pror) === 'undefined') {
                        this.setState({ btnLoad: false, ...initialState })
                        this.props.toast(messages.demandePromotion, { appearance: 'success' })
                    } else {
                        if (res.pror.indexOf('401') > 0) {
                            this.props.toast(messages.sessionExpired, { appearance: 'info' })
                        } else {
                            this.props.toast(res.pror, { appearance: 'error' })
                            this.setState({ btnLoad: false })
                        }
                    }
                })
    
            }else{
                this.props.toast(messages.requestFailed, { appearance: 'error' })
                this.setState({ btnLoad: false })
            }
        }else{ this.setState({ couvertureError: true, btnLoad: false }) }
    }

    // componentDidMount(){
    //     this.props.controller.calendrier()
    //     .then(res => {
    //         if (typeof (res.pror) === 'undefined') {
    //             this.setState({ calendrier: res })
    //         } else {
    //             if (res.pror.indexOf('401') > 0) {
    //                 this.props.toast(messages.sessionExpired, { appearance: 'info' })
    //             } else {
    //                 this.props.toast(res.pror, { appearance: 'error' })
    //                 this.setState({ btnLoad: false })
    //             }
    //         }
    //     })
    // }



    render() {
        const { position, debut, fin, duree, montant, /*calendrier,*/ couverturePrvImg, positionError, debutError, dureeError, /*couvertureError,*/ couvertureErrors, naturalHeight, naturalWidth, slideWidth, slideHeight } = this.state
        // const { isMobile } = this.props

        return (
            <div className="wt-haslayout wt-main-section" style={{padding: 0}}>
					<div className="container">
						<div className="row justify-content-md-center">
							<div className="col-xs-12 col-sm-12 col-md-10 push-md-1 col-lg-8 push-lg-2">
								<div className="marchand-admin-card">

                                    <div className="marchand-admin-card-section-header">
                                        <h4>Description</h4>
                                    </div>
                                    <div className="marchand-admin-card-section-body">
                                        <p className="marchand-admin-card-paragraphe">●  La promo est un format d'annonce publicitaire prenant la forme de bannière cliquable. </p>
                                        <p className="marchand-admin-card-paragraphe">●  La promo doit répondre au critère général d’utilisation et devra être validée par les administrateurs de la plateforme.</p>
                                        <p className="marchand-admin-card-paragraphe">●  Le marchand doit fournir le visuel répondant au format ………… </p>
                                    </div>

                                    <div className="marchand-admin-card-section-header">
                                        <h4>Types de promo et tarifs</h4>
                                    </div>
                                    <div className="marchand-admin-card-section-body">
                                        <p className="marchand-admin-card-paragraphe">■  Bannière principale page d’accueil : </p>
                                        <p className="marchand-admin-card-paragraphe level-two">      ●  200.000 FCFA/mois</p>
                                        <p className="marchand-admin-card-paragraphe level-two">      ●  Remise de 25% à partir de 3 mois</p>
                                        <p className="marchand-admin-card-paragraphe level-two">      ●  Remise de 30% à partir de 6 mois</p>
                                        <p className="marchand-admin-card-paragraphe level-two">      ●  Remise de 35% à partir de 9 mois</p>
                                        <p className="marchand-admin-card-paragraphe level-two">      ●  Remise de 40% pour abonnement de 1 an</p>

                                        <p className="marchand-admin-card-paragraphe">■  Bannière sous le box Marchand certifié : </p>
                                        <p className="marchand-admin-card-paragraphe level-two">      ●  100.000 FCFA/mois</p>
                                        <p className="marchand-admin-card-paragraphe level-two">      ●  Remise de 25% à partir de 3 mois</p>
                                        <p className="marchand-admin-card-paragraphe level-two">      ●  Remise de 30% à partir de 6 mois</p>
                                        <p className="marchand-admin-card-paragraphe level-two">      ●  Remise de 35% à partir de 9 mois</p>
                                        <p className="marchand-admin-card-paragraphe level-two">      ●  Remise de 40% pour abonnement de 1 an</p>

                                        <p className="marchand-admin-card-paragraphe">■  Bannière sous le box Marchand vitepay : </p>
                                        <p className="marchand-admin-card-paragraphe level-two">      ●  80.000 FCFA/mois</p>
                                        <p className="marchand-admin-card-paragraphe level-two">      ●  Remise de 25% à partir de 3 mois</p>
                                        <p className="marchand-admin-card-paragraphe level-two">      ●  Remise de 30% à partir de 6 mois</p>
                                        <p className="marchand-admin-card-paragraphe level-two">      ●  Remise de 35% à partir de 9 mois</p>
                                        <p className="marchand-admin-card-paragraphe level-two">      ●  Remise de 40% pour abonnement de 1 an</p>
                                    </div>

                                    {/* {calendrier.length > 0 &&
                                    <>
                                        <div className="marchand-admin-card-section-header">
                                            <h4>Calendrier</h4>
                                        </div>
                                        <div className="marchand-admin-card-section-body">
                                            <table className="table" style={{borderBottom: '1px solid #dbdbdb', borderLeft: '1px solid #dbdbdb', borderRight: '1px solid #dbdbdb' }}>
                                                <thead>
                                                    <tr>
                                                        <th>Position</th>
                                                        <th>Disponible à partir de</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {calendrier.map((c,i)=>{
                                                        const fin = new Date(c.fin)
                                                        fin.setDate(fin.getDate()+1)
                                                        return(
                                                            <tr key={i}>
                                                                <td># {c.position}</td>
                                                                <td>{formatDate(fin)}</td>
                                                            </tr>
                                                        )
                                                    }) }
                                                </tbody>
                                            </table>
                                        </div>
                                    </>} */}

                                    <div className="marchand-admin-card-section-header">
                                        <h4>Durée</h4>
                                    </div>
                                    <div className="marchand-admin-card-section-body">
                                        <div className="form-group form-group-half form-group-certif">
                                            <label>Niveau de la promotion *</label>
                                            <span className="wt-select">
                                                <select  value={position} style={{height: 40, fontSize: 12}} onChange={(e)=>this.handlePositionChange(e.target.value)} required>
                                                    {positions.map((r,i)=> <option value={r.value} key={i}>{r.label}</option>)}
                                                </select>
                                            </span>
                                            {positionError && <span className="error-span">*Ce champ est obligatoire.</span>}
                                        </div>
                                        <div className="form-group form-group-half form-group-certif">
                                            <label>Durée de la promotion *</label>
                                            <span className="wt-select">
                                                <select  value={duree} style={{height: 40, fontSize: 12}} onChange={(e)=>this.handleDureeChange(e.target.value)} required>
                                                    {durees.map((r,i)=> <option value={r.value} key={i}>{r.label}</option>)}
                                                </select>
                                            </span>
                                            {dureeError && <span className="error-span">*Ce champ est obligatoire.</span>}
                                            {positionError && <span className="error-span"></span>}
                                        </div>
                                    </div>
                                    <div className="marchand-admin-card-section-body">
                                        <div className="form-group form-group-half form-group-certif">
                                            <label>Début previsionnel *</label>
                                            <input type="date" value={debut} className="form-control" placeholder="Début" onChange={(e)=>this.handleDebutChange(e.target.value)} />
                                            {debutError && <span className="error-span">*Ce champ est obligatoire.</span>}
                                        </div>
                                        <div className="form-group form-group-half form-group-certif">
                                            <label>Fin previsionnelle de la promotion *</label>
                                            <input type="date" value={fin} className="form-control" placeholder="Fin" readOnly />
                                        </div>
                                    </div>

                                    {/* <div className="marchand-admin-card-section-body">
                                        <label>La fin previsionnelle de la promotion : <b>{fin !== '' ? formatDate(fin) : ''}</b> </label>
                                    </div> */}

                                    <div className="marchand-admin-card-section-header">
                                        <h4>Montant</h4>
                                    </div>
                                    <div className="marchand-admin-card-section-body">
                                        <h1>{formatNumber(montant)} FCFA</h1>
                                    </div>

                                    <div className="form-group form-group-label form-file-selecter" style={{marginBottom: 8}}>
                                        <label className="file-label">Veuillez importer la photo de couverture</label>
                                        <span className="file-label-span">{`(${slideWidth} x ${slideHeight})`}</span>
                                        <div className="wt-labelgroup">
                                            <label htmlFor="couverture">
                                                <span className="wt-btn file-selecter-btn">Choisir la photo de couverture</span>
                                                <input type="file" name="file" style={{display: 'none'}} id="couverture" min="1" max="1" onChange={(e)=>this.handleImageChoose(e)} accept=".png, .jpeg, .jpg" />
                                            </label>
                                        </div>

                                        {couverturePrvImg &&
                                            naturalWidth !== slideWidth &&
                                                naturalHeight !== slideHeight &&
                                                    couvertureErrors['rectangle'] === null &&
                                                    couvertureErrors['min'] === null &&
                                                    <>
                                                        <Cropper
                                                            style={{ height: 400, width: "100%" }} zoomTo={0} cropBoxResizable={false}
                                                            restore={false} src={couverturePrvImg} viewMode={1} guides={false}
                                                            // minCropBoxHeight={100} minCropBoxWidth={100} responsive={true}
                                                            dragMode='move' data={{ height: 800, width: 260, rotate: 0, scaleX: 1, scaleY: 1 }}
                                                            initialAspectRatio={position === '1' ? 16 / 5.60 :  16 / 4} aspectRatio={position === '1' ? 16 / 5.60 : 16 / 4} autoCropArea={0} heckOrientation={false}
                                                            
                                                            crop={(e)=>{
                                                                this.getCroppedImg(e.target, e.detail, 'couverture')
                                                            }}
                                                        />
                                                        <button className="btn btn-primary" type="button" style={{ fontSize: 12, marginTop: 8 }} onClick={()=>this.croppedImg({ width: slideWidth, height: slideHeight }, 'couverture.png', 'couverture')}>
                                                            Redimensionner
                                                        </button>
                                                    </>
                                        }
                                        <div className="wt-uploadingbox" style={{margin: 8}}>
                                            <div className="wt-designimg">
                                                <label htmlFor="demoq">
                                                    {couverturePrvImg && <img src={couverturePrvImg} onLoad={(e)=>this.handleFileLoad(e, 'couverture', slideWidth, slideHeight)} alt="img couverture" style={{borderRadius: 8, width: 400, height: 200}} />}
                                                    {!couverturePrvImg &&
                                                    <svg className="bd-placeholder-img img-thumbnail"  style={{padding: 0}}
                                                    width="400" height="200" xmlns="http://www.w3.org/2000/svg" 
                                                    role="img" preserveAspectRatio="xMidYMid slice" focusable="false">
                                                        <rect width="100%" height="100%" fill="#dee2e6"></rect>
                                                        <text x="45%" y="50%" fill="#000" dy=".3em">{`${slideWidth}x${slideHeight}`}</text>
                                                    </svg>}
                                                </label>
                                            </div>
                                        </div>
                                        {Object.keys(couvertureErrors).map((k, i) => {
                                            return <span key={i} className="error-span">{couvertureErrors[k]}</span>
                                        })}
                                    </div>
                                            
                                    <div className="marchand-admin-card-action-btn">
                                        <Link to="/marchands/demandes" type="btn" className="wt-btn marchand-admin-card-btn marchand-admin-card-btn-secondary">Annuler</Link>
                                        <button type="btn" className="wt-btn marchand-admin-card-btn marchand-admin-card-btn-primary" onClick={()=>this.handleSubmit()}>Valider</button>
                                    </div>

								</div>
							</div>
						</div>
					</div>
				</div>
        )
    }
}