const session = {

   // {
   //    // "admin": true,
   //    // "status": false,
   //    // "role": "Admin",
   //    // "_id": "6053245d80b4330e8d4ad390",
   //    // "name": "Ismael DOUMBIA",
   //    "email": "s.doumbia@logineo.com",
   //    "password": "$2a$10$8nWM2EK.OtYI4eOtu9C9ee5CmgKLVm84OdVkAaQrK78wPRQWPPHdO",
   //    "updatedAt": "2021-03-18T09:58:53.181Z",
   //    "createdAt": "2021-03-18T09:58:53.181Z",
   //    "__v": 0
  

    login: (user) =>{
       const store =  {
            userId: user.userId ? user.userId : user['id'],
            id: user['id'],
            email: user['email'],
            status: user['status'],
            name: user['name'],
            username: user['username'],
            role: user['role'],
            token: user['token'],
            password: user['password'],
       }
       
       localStorage.setItem('user', JSON.stringify(store))
       localStorage.setItem('auth', true)
    },


    store: ()=>{
       const session = {
          user: JSON.parse(localStorage.getItem('user')),
          auth: localStorage.getItem('auth')
        }
       return session
    },

    get: (item) => JSON.parse(localStorage.getItem(item)),
    remove: (item) => localStorage.removeItem(item),
    put: (item, value) => localStorage.setItem(item, value),
    reset: () => localStorage.clear(),
}
export default session