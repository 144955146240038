/*eslint array-callback-return: "off"*/
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import MobileStoreCard from "./MobileStoreCard";
import WebStoreCard from "./WebStoreCard";

const StoreCarousel = ({isMobile, stores, search, onView}) => {
    const webItems = () =>{
        const length = stores.length
        if(length > 8){
            return 4
        }else{
            return length / 2
        }
    }
    return (
        <Carousel
            additionalTransfrom={0}
            arrows={true}
            autoPlay
            autoPlaySpeed={5000}
            centerMode={false}
            className="store-carousel"
            containerClass="container-with-dots"
            customTransition="all 1s linear"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
                desktop: {
                    breakpoint: { max: 3000, min: 992 },
                    items: search ? 3 : webItems(),
                    partialVisibilityGutter: 40
                },
                mobile: {
                    breakpoint: { max: 576, min: 0 },
                    items: 1,
                    partialVisibilityGutter: 30
                },
                tablet: {
                    breakpoint: { max: 992, min: 576 },
                    items: 2,
                    partialVisibilityGutter: 30
                }
            }}
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
            transitionDuration={1000}
        >
            {!isMobile && stores.map((s,i, elts)=>{
                let next = elts[i+1]
                if((i+1)%2 !== 0){
                    return(
                        <div key={i} style={{marginLeft: 18}}>
                            <Link to={{pathname: '/front/categories/details', state: {store: s} }} onClick={()=>onView()}>
                                <WebStoreCard name={s.boutique} url={s.url} address={s.adresse} isVerified={s.certifie} cover={s.couverture} logo={s.logo} rating={s.avis} vitepay={s.vitepay} />
                            </Link>
                            {typeof(next) !== 'undefined' &&
                                <Link to={{pathname: '/front/categories/details', state: {store: next} }}  onClick={()=>onView()}>
                                    <WebStoreCard name={next.boutique} url={next.url} address={next.adresse} isVerified={next.certifie} cover={next.couverture} logo={next.logo} rating={next.avis} vitepay={next.vitepay} />
                                </Link>
                            }
                        </div>
                    )
                } }) 
            }
            {isMobile && stores.map((s,i, elts)=>{
                let next = elts[i+1]
                if((i+1)%2 !== 0){
                    return(
                        <div key={i} style={{marginLeft: 18}}>
                            <Link to={{pathname: '/front/categories/details', state: {store: s} }}  onClick={()=>onView()}>
                                <MobileStoreCard name={s.boutique} url={s.url} address={s.adresse} isVerified={s.certifie} cover={s.couverture} logo={s.logo} rating={s.avis} vitepay={s.vitepay} />
                            </Link>
                            {typeof(next) !== 'undefined' &&
                                <Link to={{pathname: '/front/categories/details', state: {store: next} }}  onClick={()=>onView()}>
                                    <MobileStoreCard name={next.boutique} url={next.url} address={next.adresse} isVerified={next.certifie} cover={next.couverture} logo={next.logo} rating={next.avis} vitepay={next.vitepay}/>
                                </Link>
                            }
                        </div>
                    )
                } })
            }
        </Carousel>
    )
}
export default StoreCarousel