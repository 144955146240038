/*eslint array-callback-return: "off"*/
import React from 'react'
import Switch from "react-switch";
import ReactModal from 'react-modal'
import Detail from '../../../components/admin/Detail'
import Poper from '../../../components/Poper'
import Table from '../../../components/Table/index'
import messages from '../../../lib/messages.json'
import Loader from '../../../components/Loader';

/*eslint consistent-return: ["error", { "treatUndefinedAsUnspecified": false }]*/


// Les colonne du tableua
const columns = [
    { name: 'boutique', header: 'Nom de la boutique' },
    { name: 'url', header: 'URL', url: true },
    { name: 'status', header: 'statut' },
    { name: 'vitepay', header: 'vitepay', vitepay: true },
    { name: 'certifie', header: 'certifié', certifie: true }
]
const listRegion = ['Bamako', 'Kayes', 'Koulikoro', 'Sikasso', 'Ségou', 'Mopti', 'Tombouctou', 'Gao', 'Kidal']
const filterFields = { filterStatut: '', filterRegion: '', filterCertifie: false, filterVitepay: false }
const searchFields = { searchUrl: '' }
export default class Marchand extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ...filterFields,
            ...searchFields,
            data: [],
            tempData: [],
            filterData: [],
            research: '', currentElement: {}, currentUser: {}, currentId: '',
            btnLoad: false, load: true, detailModal: false, reinitModal: false, certifieModal: false, certifie: false
        }
        this.handleApplyFilter = this.handleApplyFilter.bind(this)
		this.handleApplySearch = this.handleApplySearch.bind(this)
    }
    handleTextChange(e, name){ this.setState({ [name]: e.target.value }) }

    componentDidMount() {
        this.props.controller.marchands()
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                this.setState({ data, tempData: data, filterData: data, load: false })
            } else {
                if (data.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                    this.setState({ load: false })
                }
            }
        })
    }
    handleView(id){
        const { data } = this.state
        const currentElement = data.find(d=>d.id === id)
        // console.log("currentElement", currentElement)
        if(currentElement){
            this.props.userController.getById(currentElement.userId)
            .then(res => {
                if (typeof (res.pror) === 'undefined') {
                    this.setState({ currentElement, currentUser: res, detailModal: true })
                } else {
                    if (res.pror.indexOf('401') > 0) {
                        this.props.toast(messages.sessionExpired, { appearance: 'info' })
                    } else {
                        this.props.toast(messages.requestFailed, { appearance: 'error' })
                    }
                }
            })
        }
    }

    handleTreat(status){
        const { currentElement, data } = this.state
        currentElement.status = status
        this.setState({ btnLoad: true })
        
        this.props.controller.save({id: currentElement.id, status})
        .then(res => {
            
            if (typeof (res.pror) === 'undefined') {

                this.props.userController.status(currentElement.userId, { status: status === 'Accepter' ? true : false })
                .then(ress => {
                    if (typeof (ress.pror) === 'undefined') {
                        const pos = data.findIndex(d=>d.id === currentElement.id)
                        const st = data.find(s=> s.id === currentElement.id)
                        st.status = status
                        data[pos] = st
                        this.setState({ data, load: false, btnLoad: false })
                        this.props.toast(messages.statusChange, { appearance: 'success' })
                    } else {
                        if (ress.pror.indexOf('401') > 0) {
                            this.props.toast(messages.sessionExpired, { appearance: 'info' })
                        } else {
                            this.props.toast(messages.requestFailed, { appearance: 'error' })
                            this.setState({ btnLoad: false })
                        }
                    }
                })                
            } else {
                if (res.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                    this.setState({ btnLoad: false })
                }
            }
        })
    }

    handleCerifie(){
        const { currentId, certifie, data } = this.state
        this.setState({ btnLoad: true })
        if(currentId){
            this.props.controller.save({id: currentId, certifie })
            .then(res => {
                if (typeof (res.pror) === 'undefined') {
                    // const data = this.state.data.filter(d=>d.id !== ccurrentId)
                    const pos = data.findIndex(d=>d.id === currentId)
                    const st = data.find(s=> s.id === currentId)
                    st.certifie = certifie
                    data[pos] = st
                    this.setState({ data, load: false, btnLoad: false, certifieModal: false })
                    this.props.toast(messages.certifie, { appearance: 'success' })
                } else {
                    if (res.pror.indexOf('401') > 0) {
                        this.props.toast(messages.sessionExpired, { appearance: 'info' })
                    } else {
                        this.props.toast(messages.requestFailed, { appearance: 'error' })
                        this.setState({ btnLoad: false })
                    }
                }
            })
        }
    }

    handleReinitUserPassword = () =>{
        const { currentUser } = this.state
        this.setState({ btnLoad: true })
        if(currentUser._id){
            this.props.userController.reinitPassword(currentUser._id)
            .then(res => {
                if (typeof (res.pror) === 'undefined') {
                    this.setState({ btnLoad: false, reinitModal: false })
                    this.props.toast(messages.passwordReinit, { appearance: 'success' })
                } else {
                    if (res.pror.indexOf('401') > 0) {
                        this.props.toast(messages.sessionExpired, { appearance: 'info' })
                    } else {
                        this.props.toast(messages.requestFailed, { appearance: 'error' })
                        this.setState({ btnLoad: false })
                    }
                }
            })
        }
    }

    handleApplyFilter(e){
        e.preventDefault()
        this.setState({ btnLoad: true })
        const { filterStatut, filterRegion, filterCertifie, filterVitepay } = this.state
        this.props.controller.filter({ status: filterStatut, region: filterRegion, certifie: filterCertifie, vitepay: filterVitepay })
        .then(data => {
            if (typeof (data.pror) === 'undefined') {
                this.setState({ data, filterData: data, load: false, btnLoad: false })
                this.props.toast(messages.filter, { appearance: 'success' })
            } else {
                if (data.pror.indexOf('401') > 0) {
                    this.props.toast(messages.sessionExpired, { appearance: 'info' })
                } else {
                    this.props.toast(messages.requestFailed, { appearance: 'error' })
                    this.setState({ btnLoad: false })
                }
            }
        })
    }

    handleApplySearch(e){
        e.preventDefault()
        this.setState({ btnLoad: true })
        const { searchUrl } = this.state
        this.props.controller.search({url: searchUrl})
        .then(data => {
            if(data){
                if (typeof (data.pror) === 'undefined') {
                    this.setState({ data: [data], filterData: [data], load: false, btnLoad: false })
                    this.props.toast(messages.search, { appearance: 'success' })
                } else {
                    if (data.pror.indexOf('401') > 0) {
                        this.props.toast(messages.sessionExpired, { appearance: 'info' })
                    } else {
                        this.props.toast(messages.requestFailed, { appearance: 'error' })
                        this.setState({ btnLoad: false })
                    }
                }
            }else{
                this.setState({ data: [], filterData: [], load: false, btnLoad: false })
                    this.props.toast(messages.search, { appearance: 'success' })
            }
        })
    }

    searchChange(event) {
        const value = event.target.value
        const data = this.state.filterData.filter(dt => {
           return (
              dt.boutique.toLowerCase().includes(value.toLowerCase()) ||
              dt.url.toLowerCase().includes(value.toLowerCase())
           )
        })
        this.setState({ data, research: value })
    }


    render() {
        // const { crud, permission } = this.props
        const { data, currentElement, currentUser, load, btnLoad, detailModal, certifieModal, reinitModal,
            filterStatut, filterRegion, filterCertifie, filterVitepay, searchUrl, research, certifie } = this.state

        return (
            <div>
                <div className="table-actions-container">
                    <div className="">
                        <input type="text" value={research} className="form-control search-input" onChange={(e)=>this.searchChange(e)} placeholder="Rechercher dans le tableau" />
                    </div>
                    
                    <div>
                        <Poper trigger={<button className="btn table-actions-btn table-action-btn-filter">Filtrer</button>}>
                            <form className="wt-formtheme wt-formregister" id="form-filter" onSubmit={this.handleApplyFilter}>
                                <div className="form-group">
                                    <label>Statut</label>
                                    <span className="wt-select">
                                        <select  value={filterStatut} onChange={(e)=>this.handleTextChange(e, 'filterStatut')}>
                                            <option value=""> Statut </option>
                                            {/* <option value='Defaut'>Defaut</option> */}
                                            <option value='Accepter'>Accepter</option>
                                            <option value='Rejeter'>Rejeter</option>
                                        </select>
                                    </span>
                                </div>
                                <div className="form-group">
                                    <label>Region</label>
                                    <span className="wt-select">
                                        <select  value={filterRegion} onChange={(e)=>this.handleTextChange(e, 'filterRegion')}>
                                            <option value=""> Region </option>
                                            {listRegion.map((r,i)=> <option value={r} key={i}>{r}</option>)}
                                        </select>
                                    </span>
                                </div>
                                <div className="form-group" style={{display: 'flex', alignItems: 'center'}}>
                                    <span style={{marginRight: 32, fontSize: 12}}>Marchand Vitepay ?</span>
                                    <Switch onChange={(val)=>this.setState({filterVitepay: val})} checkedIcon={false} uncheckedIcon={false} onColor='#001e37' height={24} checked={filterVitepay} />
                                </div>
                                <div className="form-group" style={{display: 'flex', alignItems: 'center'}}>
                                    <span style={{marginRight: 32, fontSize: 12}}>Marchand certifié ?</span>
                                    <Switch onChange={(val)=>this.setState({filterCertifie: val})} checkedIcon={false} uncheckedIcon={false} onColor='#001e37' height={24} checked={filterCertifie} />
                                </div>
                                <div className="filter-actions-container">
                                    <button type="button" className="btn btn-secondary filter-action-btn" onClick={()=>this.setState({ ...filterFields, data: this.state.tempData, filterData: this.state.tempData }) }>Effacer</button>
                                    <button className="btn btn-primary filter-action-btn" type="submit">
                                        {btnLoad && <Loader size={16} />}
                                        {!btnLoad && 'Appliquer'}
                                    </button>
                                </div>
                            </form>
                        </Poper>
                        <Poper trigger={<button className="btn table-actions-btn table-action-btn-search">Rechercher</button>}>
                            <form className="wt-formtheme wt-formregister" id="form-filter" onSubmit={this.handleApplySearch}>
                                <div className="form-group">
                                    <label>URL de la boutique *</label>
                                    <input type="text" className="form-control" placeholder="URL de la boutique " value={searchUrl} onChange={(e)=>this.handleTextChange(e, 'searchUrl')} required/>
                                </div>
                                <div className="filter-actions-container">
                                    <button type="button" className="btn btn-secondary filter-action-btn" onClick={()=>this.setState({ ...searchFields, data: this.state.tempData, filterData: this.state.tempData }) }>Effacer</button>
                                    <button className="btn btn-primary filter-action-btn" type="submit">
                                        {btnLoad && <Loader size={16} />}
                                        {!btnLoad && 'Rechercher'}
                                    </button>
                                </div>
                            </form>
                        </Poper>
                    </div>
                </div>
                <Table
                    columns={columns}
                    data={data}
                    permission={'2'}
                    load={load}
                    viewOnly={true}
                    onDeleteOpen={(id) => console.log("***onDeleteOpen", id)}
                    onEditOpen={(id) => console.log("***onEditOpen", id)}
                    onViewOpen={(id) => this.handleView(id)}
                    onCertifie={(id, certifie) => this.setState({ certifieModal: true, currentId: id, certifie })}
                />

                <ReactModal isOpen={detailModal} ariaHideApp={false} className="detail">
                    <div className="modal-body-content">
                        <h5>Les informations détaillées</h5>
                        <Detail data={currentElement} user={currentUser} onReinit={()=>this.setState({ reinitModal: true })} />
                    </div>

                    <div className="modal-actions-container">
                        <button className="btn btn-primary modal-action-btn" disabled={currentElement.status === 'Accepter'} onClick={()=>this.handleTreat('Accepter')}>
                            {btnLoad && <Loader size={16} />}
                            {!btnLoad && 'Activer'}
                        </button>
                        <button className="btn btn-danger modal-action-btn" disabled={currentElement.status === 'Rejeter'}  onClick={()=>this.handleTreat('Rejeter')}>
                            {btnLoad && <Loader size={16} />}
                            {!btnLoad && 'Desactiver'}
                        </button>
                        <button type="button" className="btn btn-secondary modal-action-btn" onClick={()=>this.setState({detailModal: false })}>Fermer</button>
                    </div>
                </ReactModal>

                <ReactModal isOpen={reinitModal} ariaHideApp={false} className="alert-modal">
                    <div className="modal-alert-body-content">
                        <h5>Reinitialisation de mot de passe</h5>
                        <p>Êtes-vous sûr de vouloire reinitialiser ce marchand ?</p>
                    </div>

                    <div className="modal-alert-actions-container">
                        <button type="button" className="btn btn-secondary modal-action-btn" onClick={()=>this.setState({ reinitModal: false })}>Fermer</button>
                        <button className="btn btn-primary modal-action-btn" onClick={()=>this.handleReinitUserPassword()}>
                            {btnLoad && <Loader size={16} />}
                            {!btnLoad && 'Oui, je valide'}
                        </button>
                    </div>
                </ReactModal>

                <ReactModal isOpen={certifieModal} ariaHideApp={false} className="alert-modal">
                    <div className="modal-alert-body-content">
                        <h5>Certification - Décertification</h5>
                        {certifie && <p>Êtes-vous sûr de vouloire certifier ce marchand ?</p>}
                        {!certifie && <p>Êtes-vous sûr de vouloire décertifier ce marchand ?</p>}
                    </div>

                    <div className="modal-alert-actions-container">
                        <button type="button" className="btn btn-secondary modal-action-btn" onClick={()=>this.setState({certifieModal: false, currentId: '', certifie: false })}>Fermer</button>
                        <button className="btn btn-primary modal-action-btn" onClick={()=>this.handleCerifie()}>
                            {btnLoad && <Loader size={16} />}
                            {!btnLoad && 'Oui, je valide'}
                        </button>
                    </div>
                </ReactModal>

            </div>
        )
    }

}