/*global google*/
/**
 * COMPOSANT ITIERAIRE
 */
import React from "react"
import { withGoogleMap, GoogleMap,  DirectionsRenderer,  Marker } from "react-google-maps";
import shop from '../../../assets/images/shop.svg'
import location from '../../../assets/images/location.svg'

export default class Itineraire extends React.Component {
    constructor(props){
        super(props)
        this.state = { directions: null }
    }

    componentDidMount() {
        const { myLat, myLng, sLat, sLng } = this.props
        const DirectionsService = new google.maps.DirectionsService();
        const origin = { lat:myLat, lng: myLng }
        const destination = { lat: sLat, lng: sLng }
        // const destination = { lat: 12.38843, lng: -7.95390  }
        DirectionsService.route({ origin, destination, travelMode: google.maps.TravelMode.DRIVING }, (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            this.setState({ directions: result });
          } else {
            console.error(`error de recuperation des directions`, result)
          }
        })
    }

    render() {
        const { name, myLat, myLng/*, sLat, sLng*/} = this.props

        const GoogleMapExample = withGoogleMap(props => (
            <GoogleMap defaultCenter={{ lat: myLat, lng: myLng }} defaultZoom={11} >
                <Marker label={{text: name, className: 'map-marker-icon'}} position={{ lat: 12.38843, lng: -7.95390  }} icon={{url: shop, scaledSize: { width: 36, height: 36 } }} />
                <Marker label={{text: "Ma position", className: 'map-marker-icon'}} position={{ lat: myLat, lng: myLng  }} icon={{url: location, scaledSize: { width: 28, height: 28 } }} />
                
                <DirectionsRenderer directions={this.state.directions} />
            </GoogleMap>
        ));
        
        return (
            <div>
                <div style={{ height: 600, width: '100%' }}>
                    <GoogleMapExample
                    containerElement={<div style={{ height: `500px`, width: "100%" }} />}
                    mapElement={<div style={{ height: `100%` }} />} />
                </div>
            </div>
        )
    }
}